import React, { Component } from "react";
import LeadsTransferToDealer from "./LeadsTransferToDealer";
import LeadsTransferButton from "./LeadsTransferButton";

const styles = {
  actions: {
      display: 'flex',
      justifyContent: 'center'
  },
  completedContainer: {
      textAlign: 'center',
      padding: '2em 0px'
  }
};
export default class LeadsTransfer extends Component {
  constructor({ lead, id }) {
    super();
    this.state = {
      lead: lead,
      isEmptyState: true,
      isEditDealerLead: false
    };
  }


  triggerEditDealerLead = () => {
    this.setState({
      ...this.state,
      isEmptyState: false,
      isEditDealerLead: true
    })
  }

  triggerSubmitDealerLead = () => {
    this.setState({
      ...this.state,
      isEmptyState: true,
      isEditDealerLead: false
    })
  }

  render() {
    return (
      <div style={styles.completedContainer}>
        <div style={styles.actions}>
            {this.state.isEmptyState && <LeadsTransferButton addClick={this.triggerEditDealerLead} />}
            {this.state.isEditDealerLead && <LeadsTransferToDealer lead={this.state.lead} addClick={this.triggerSubmitDealerLead} />}
        </div>
      </div>
    );
  }
}
