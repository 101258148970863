import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from "@material-ui/icons/Delete";
import { Mutation } from "react-apollo";
import { toast } from "react-toastify";
import { DELETE_ADMIN_MESSAGE, DELETE_ADMIN_MESSAGE_STATUS } from 'queries';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        padding: '30px',
        '&:focus-visible':{
            outline:'transparent'
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        marginTop: theme.spacing(2),
    },
}));

function DeleteModal({ messageId }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isSubmit, setIsSubmit] = useState()

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = (e, deleteAdminMessage, deleteAdminMessageStatus) => {
        e.preventDefault();
        setIsSubmit(true)
        deleteAdminMessageStatus({
            variables: {
                id: messageId
            }
        }).then(() => {
            deleteAdminMessage({
                variables: {
                    id: messageId
                }
            }).then(() => {
                completedDelete()
            });
        })
    };

    const completedDelete = () => {
        toast("Message deleted successfully");
        handleClose();
        setIsSubmit(false)
    }


    return (
        <div>
            <DeleteIcon
                fontSize="small"
                style={{
                    color: "#3B6894",
                    width: "18px",
                    margin: "0px auto",
                    display: "block",
                    cursor: 'pointer'
                }}
                className='delete-modal'
                onClick={() => handleOpen()}
            />
            <Modal open={open} onClose={handleClose}>
                <div className={classes.paper}>
                    <Typography variant="h6" gutterBottom>
                        Are you sure you want to delete this message?
                    </Typography>
                    <div className={classes.buttonContainer}>
                        <Mutation mutation={DELETE_ADMIN_MESSAGE} >
                            {(deleteAdminMessage, { data, loading, called, error }) => {
                                if (error)
                                    return (
                                        <pre>
                                            Bad:{" "}
                                            {error.graphQLErrors.map(({ message }, i) => (
                                                <span key={i}>{message}</span>
                                            ))}
                                        </pre>
                                    );
                                return (
                                    <Mutation mutation={DELETE_ADMIN_MESSAGE_STATUS} >
                                        {(deleteAdminMessageStatus, { data, loading, called, error }) => {
                                            if (error)
                                                return (
                                                    <pre>
                                                        Bad:{" "}
                                                        {error.graphQLErrors.map(({ message }, i) => (
                                                            <span key={i}>{message}</span>
                                                        ))}
                                                    </pre>
                                                );
                                            return (
                                                <Button variant="contained" color="secondary" disabled={isSubmit} onClick={(e) => handleDelete(e, deleteAdminMessage, deleteAdminMessageStatus)}>
                                                    Confirm
                                                </Button>
                                            );
                                        }}
                                    </Mutation>
                                );
                            }}
                        </Mutation>
                        <Button variant="contained" onClick={handleClose}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default DeleteModal;
