import React, { useRef, useState, useEffect } from "react";
import { Mutation, Query, Subscription } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import {
  Paper,
  Typography,
  TextField,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormLabel,
  CircularProgress,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {
  PagingState,
  IntegratedPaging,
  RowDetailState,
} from "@devexpress/dx-react-grid";
import {
  Table,
  TableHeaderRow,
  Grid as Grids,
  PagingPanel,
  TableRowDetail,
} from "@devexpress/dx-react-grid-material-ui";
import { Dashboard as DashboardLayout } from "layouts";
import styles from "theme/skin";
import auth from "Auth";

import useForm from "components/useForm";
import axios from "axios";
import apollo from "apollo";
import { StatusEnum } from "pages/FormsDatasheets/enum";
import moment from "moment";
import { GET_USER_INFO } from "queries";
import {
  ADD_COMMERICAL_SALES_CREDIT_CLAIM,
  ADD_COMMERICAL_SALES_CREDIT_CLAIM_DATA,
  ADD_CO_OP_REQUEST_FORMS,
  getAllCommericalSalesCreditClaim,
} from "./queries";
import { CommericalSalesCreditClaimRequestRowDetail } from "../NewRequest/CommericalSalesCreditClaimRequestRowDetail";

function CommericalSalesCreditClaims(props) {
  const formRef = useRef(null);
  const { classes } = props;
  const APP_SERVER = apollo.APP_UPLOAD_SERVER_URL;
  const initialFormsData = new Array(11).fill(null).map(() => ({
    buyer_name: "",
    item_name: "",
    serial_no: "",
    dealer_cost: 0,
    credit: 0,
    total_credit: 0,
  }));
  const { values, handleChange, resetState, setValues } = useForm(submitForm);

  const [expandedRowIds, setExpandedRowIds] = useState([0]);
  const [files, setFiles] = useState({});
  const [formsData, setFormsData] = useState(initialFormsData);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [queryData, setQueryData] = useState();
  const [userData, setUserData] = useState();

  const formattedUTCDate = moment.utc();

  function submitForm() {}

  useEffect(() => {
    const formErrors = validateAllRows();
    setErrors(formErrors);
  }, [validateAllRows]);

  const columns = [
    { name: "date_submitted", title: "Date Submitted" },
    {
      name: "form",
      title: "Form",
      getCellValue: (row) => "Government/Commerical Sales Credit Claim",
    },
    {
      name: "submitted_by",
      title: "Submitted By",
      getCellValue: (row) => row?.profile?.email || "",
    },
    { name: "status", title: "status" },
  ];

  const validateRow = (row, rowIndex) => {
    let rowErrors = {};
    let isRowFilled = Object.values(row).some((value) => value);
    if (isRowFilled) {
      if (!row.buyer_name) rowErrors.buyer_name = "Buyer name is required";
      if (!row.item_name) rowErrors.item_name = "Item name is required";
      if (!row.serial_no) rowErrors.serial_no = "Serial no is required";
      if (!row.dealer_cost) rowErrors.dealer_cost = "Dealer cost is required";
      if (!row.credit) rowErrors.credit = "Credit is required";
      if (!row.total_credit)
        rowErrors.total_credit = "Total credit is required";
    }
    return rowErrors;
  };

  const validateAllRows = () => {
    let allErrors = {};
    formsData &&
      formsData.forEach((row, index) => {
        let rowErrors = validateRow(row, index);
        if (Object.keys(rowErrors).length > 0) {
          allErrors[index] = rowErrors;
        }
      });
    return allErrors;
  };

  const LastColumnCell = ({ column, value }) => (
    <Table.Cell
      style={
        column.name === "status"
          ? {
              color: StatusEnum[value.toLowerCase()]?.color,
              fontWeight: "bold",
            }
          : {}
      }
    >
      {column.name === "status"
        ? StatusEnum[value.toLowerCase()]?.label
        : value}
    </Table.Cell>
  );

  const completedAdd = () => {
    toast("Government Commerical Sales Credit Claim created succesfully");
    resetState();
    setFiles({})
  };

  const toggleRowExpand = (rowId) => {
    setExpandedRowIds((prevState) =>
      prevState.includes(rowId)
        ? prevState.filter((id) => id !== rowId)
        : [...prevState, rowId]
    );
  };

  const TableRow = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => toggleRowExpand(tableRow.rowId)}
      style={{ cursor: "pointer" }}
    />
  );

  const handleFileChange = (event, param) => {
    const inputFiles = event.target.files;
    if (inputFiles.length > 0) {
      const file = inputFiles[0];
      setFiles((prevState) => ({
        ...prevState,
        [param]: file,
      }));
    }
  };

  const handleFieldChange = (formIndex, fieldName, value) => {
    const newFormsData = formsData.map((form, index) => {
      if (index === formIndex) {
        const updatedForm = { ...form, [fieldName]: value };
        if (fieldName === "dealer_cost" || fieldName === "credit") {
          const dealer_cost = parseFloat(updatedForm.dealer_cost) || 0;
          const approvedPercentage = parseFloat(updatedForm?.credit) || 0;
          updatedForm.total_credit = (
            (approvedPercentage * dealer_cost) /
            100
          ).toFixed(2);
        }
        return updatedForm;
      }
      return form;
    });
    setFormsData(newFormsData);
  };

  const RowDetail = ({ row }) => {
    if (queryData) {
      const rowData = queryData?.find((rows) => rows?.id === row?.id);
      return (
        <div>
          <CommericalSalesCreditClaimRequestRowDetail
            isDisplay={true}
            comments={rowData?.co_op_request?.co_op_comments}
            userName={userData}
            isEdit={true}
            row={rowData}
            type="dealer"
            requirementDataArray={
              rowData?.government_commerical_sales_credit_claim_data
            }
          />{" "}
        </div>
      );
    }
    return <div>Row details not available.</div>;
  };

  return (
    <Query
      query={GET_USER_INFO}
      variables={{ id: auth.getSub() }}
      onCompleted={(data) => setUserData(data?.profiles_by_pk?.name)}
    >
      {({ data: userData, loading: userLoading, error }) => {
        return (
          <Mutation mutation={ADD_CO_OP_REQUEST_FORMS}>
            {(addCoOpRequestForms, { data, loading, called, error }) => {
              if (error)
                return (
                  <pre>
                    Bad:{" "}
                    {error.graphQLErrors.map(({ message }, i) => (
                      <span key={i}>{message}</span>
                    ))}
                  </pre>
                );
              return (
                <Mutation mutation={ADD_COMMERICAL_SALES_CREDIT_CLAIM}>
                  {(commClaimForm, { data, loading, called, error }) => {
                    if (error)
                      return (
                        <pre>
                          Bad:{" "}
                          {error.graphQLErrors.map(({ message }, i) => (
                            <span key={i}>{message}</span>
                          ))}
                        </pre>
                      );
                    return (
                      <Mutation
                        mutation={ADD_COMMERICAL_SALES_CREDIT_CLAIM_DATA}
                      >
                        {(
                          commClaimRequirementForm,
                          { data, loading, called, error }
                        ) => {
                          if (error)
                            return (
                              <pre>
                                Bad:{" "}
                                {error.graphQLErrors.map(({ message }, i) => (
                                  <span key={i}>{message}</span>
                                ))}
                              </pre>
                            );
                          return (
                            <DashboardLayout>
                              <form
                                ref={formRef}
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  const formErrors = validateAllRows();
                                  setErrors(formErrors);
                                  if (Object.keys(formErrors).length === 0) {
                                    setIsSubmit(true);
                                    values.profile_id = auth.getSub();
                                    values.country = values?.country;
                                    const uploadPromises = Object.keys(
                                      files
                                    )?.map((key) => {
                                      const file = files[key];
                                      if (file) {
                                        const data = new FormData();
                                        data.append("image", file, file.name);
                                        return axios
                                          .post(APP_SERVER + "/", data)
                                          .then((response) => {
                                            if (response.data.status === 200) {
                                              return {
                                                [key]: response.data.imageUrl,
                                              };
                                            } else {
                                              toast.error(
                                                "Media upload failed"
                                              );
                                              throw new Error(
                                                `Upload failed for ${key}`
                                              );
                                            }
                                          });
                                      } else {
                                        return Promise.resolve();
                                      }
                                    });

                                    Promise.all(uploadPromises)
                                      .then((uploadResults) => {
                                        const allUploadUrls = Object.assign(
                                          {},
                                          ...uploadResults
                                        );

                                        const requestData = {
                                          date_submitted:
                                            formattedUTCDate.format(),
                                          profile_id: auth.getSub(),
                                          form_type:
                                            "govt-com-sales-credit-claim",
                                          status: "new",
                                        };
                                        return addCoOpRequestForms({
                                          variables: {
                                            objects: requestData,
                                          },
                                        }).then((value) => {
                                          const co_op_requests_id =
                                            value?.data?.insert_co_op_requests
                                              ?.returning?.[0]?.id;
                                          const finalValues = {
                                            ...values,
                                            ...allUploadUrls,
                                            co_op_requests_id,
                                          };
                                          commClaimForm({
                                            variables: {
                                              objects: [finalValues],
                                            },
                                          }).then((value) => {
                                            const commClaimFormId =
                                              value?.data
                                                ?.insert_government_commerical_sales_credit_claim
                                                ?.returning?.[0]?.id;
                                            const updatedFormsData = formsData
                                              ?.filter(
                                                (form) => form.buyer_name !== ""
                                              )
                                              ?.map((form) => ({
                                                ...form,
                                                government_commerical_sales_credit_claim_id:
                                                  commClaimFormId,
                                              }));
                                            commClaimRequirementForm({
                                              variables: {
                                                objects: updatedFormsData,
                                              },
                                            }).then(() => {
                                              setFormsData(initialFormsData);
                                              setIsSubmit(false);

                                              completedAdd();
                                            });
                                          });
                                        });
                                      })
                                      .catch((error) => {
                                        console.error(error);
                                      });

                                    return false;
                                  } else {
                                    formRef.current.reportValidity();
                                  }
                                }}
                                autoComplete="off"
                              >
                                <div
                                  className={classes.root + " sectionContainer"}
                                >
                                  <Typography
                                    gutterBottom
                                    variant="h4"
                                    className="title"
                                  >
                                    <span>
                                      GOVERNMENT/COMMERICAL SALES CREDIT CLAIM
                                    </span>
                                  </Typography>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.subtitle3}
                                    align="left"
                                  >
                                    FORMS WAITING FOR APPROVAL
                                  </Typography>

                                  <Subscription
                                    subscription={
                                      getAllCommericalSalesCreditClaim
                                    }
                                    variables={{ profile_id: auth.getSub() }}
                                  >
                                    {({ data, loading, error: err }) => {
                                      if (data && data !== queryData) {
                                        setQueryData(
                                          data?.government_commerical_sales_credit_claim
                                        );
                                      }
                                      if (loading || !queryData)
                                        return (
                                          <div
                                            className="flex center"
                                            style={{ padding: "2em 0" }}
                                          >
                                            <CircularProgress color="secondary" />
                                          </div>
                                        );
                                      if (error)
                                        return (
                                          <p>
                                            Error Loading Government/Commercial
                                            Claim Forms
                                          </p>
                                        );
                                      return (
                                        <div className="custom-table-event">
                                          {queryData && (
                                            <Grids
                                              rows={
                                                data?.government_commerical_sales_credit_claim
                                              }
                                              columns={columns}
                                            >
                                              <RowDetailState
                                                expandedRowIds={expandedRowIds}
                                                onExpandedRowIdsChange={
                                                  setExpandedRowIds
                                                }
                                              />
                                              <PagingState
                                                defaultCurrentPage={0}
                                                pageSize={10}
                                              />
                                              <IntegratedPaging />
                                              <Table
                                                cellComponent={(props) => (
                                                  <LastColumnCell {...props} />
                                                )}
                                                rowComponent={TableRow}
                                              />
                                              <TableHeaderRow />
                                              <PagingPanel />
                                              <TableRowDetail
                                                contentComponent={RowDetail}
                                              />
                                            </Grids>
                                          )}
                                        </div>
                                      );
                                    }}
                                  </Subscription>

                                  <br></br>
                                  <br></br>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.subtitle3}
                                    style={{
                                      backgroundColor: "#BED4E5",
                                      textAlign: "left",
                                      padding: "1em",
                                      color: "#23333F",
                                      margin: "0px",
                                    }}
                                  >
                                    GOVERNMENT/COMMERICAL SALES CREDIT CLAIM{" "}
                                  </Typography>
                                  <Paper
                                    className={classes.paper}
                                    style={{
                                      padding: "2em",
                                      backgroundColor: "#F9FCFF",
                                      boxShadow: "unset",
                                    }}
                                  >
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      align="center"
                                      className={classes.subTitle2}
                                      style={{
                                        margin: "0px auto 15px",
                                        maxWidth: "80%",
                                      }}
                                    >
                                      <strong>CAROLINA SKIFF:</strong> 3231
                                      Fulford Road Waycross Georgia 31503{" "}
                                      <strong>PHONE:</strong> 912-287-0547{" "}
                                      <strong>FAX:</strong> 912-287-0533
                                    </Typography>
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      align="center"
                                      style={{
                                        margin: "0px auto",
                                        maxWidth: "80%",
                                      }}
                                      className={classes.subTitle2}
                                    >
                                      Carolina Skiff LLC will credit 5% of the
                                      Dealer boat cost for any Carolina Skiff
                                      product sold to a licensed commercial
                                      agency such as, Utility Commissions,
                                      Government Agency, Local State Agency,
                                      Boat Rental, Comercial Guide Fisherman or
                                      any other type of commercial business
                                      approved by Carolina Skiff LLC.
                                    </Typography>
                                    <br></br>
                                    <br></br>
                                    <div>
                                      <Grid container spacing={4}>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            required
                                            name="dealer_name"
                                            label="Dealer Name"
                                            className={classes.textField}
                                            style={{ width: "100%" }}
                                            onChange={handleChange}
                                            margin="normal"
                                            value={values?.dealer_name || ""}
                                            variant="outlined"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            required
                                            name="contact_name"
                                            label="Contact Name"
                                            className={classes.textField}
                                            style={{ width: "100%" }}
                                            onChange={handleChange}
                                            margin="normal"
                                            value={values?.contact_name || ""}
                                            variant="outlined"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            required
                                            name="phone"
                                            label="Phone"
                                            className={classes.textField}
                                            style={{ width: "100%" }}
                                            onChange={handleChange}
                                            margin="normal"
                                            value={values?.phone || ""}
                                            variant="outlined"
                                          />
                                        </Grid>
                                      </Grid>

                                      <Grid container spacing={4}>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            required
                                            name="fax"
                                            label="Fax"
                                            className={classes.textField}
                                            style={{ width: "100%" }}
                                            onChange={handleChange}
                                            margin="normal"
                                            value={values?.fax || ""}
                                            variant="outlined"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            required
                                            name="address"
                                            label="Address"
                                            className={classes.textField}
                                            style={{ width: "100%" }}
                                            onChange={handleChange}
                                            margin="normal"
                                            value={values?.address || ""}
                                            variant="outlined"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="address2"
                                            label="Address line 2 (Suites, Apt. # etc)"
                                            className={classes.textField}
                                            onChange={handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            value={values?.address2 || ""}
                                            style={{ width: "100%" }}
                                          />
                                        </Grid>
                                      </Grid>

                                      <Grid container spacing={4}>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            required
                                            name="city"
                                            label="City"
                                            className={classes.textField}
                                            onChange={handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            value={values?.city || ""}
                                            style={{ width: "100%" }}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            required
                                            name="country"
                                            label="Country"
                                            select
                                            className={classes.textField}
                                            onChange={handleChange}
                                            value={values?.country || ""}
                                            style={{ width: "100%" }}
                                            variant="outlined"
                                            SelectProps={{
                                              native: true,
                                              MenuProps: {
                                                className: classes.menu,
                                              },
                                            }}
                                            margin="normal"
                                          >
                                            <option key="" value="" />
                                            <option
                                              key="United States"
                                              value="United States"
                                            >
                                              United States
                                            </option>
                                          </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            required
                                            name="state"
                                            label="State"
                                            variant="outlined"
                                            select
                                            style={{ width: "100%" }}
                                            className={classes.textField}
                                            value={values?.state || ""}
                                            onChange={handleChange}
                                            SelectProps={{
                                              native: true,
                                              MenuProps: {
                                                className: classes.menu,
                                              },
                                            }}
                                            margin="normal"
                                          >
                                            <option key="" value="" />
                                            <option
                                              key="Alabama"
                                              value="Alabama"
                                            >
                                              Alabama
                                            </option>
                                            <option key="Alaska" value="Alaska">
                                              Alaska
                                            </option>
                                            <option
                                              key="Arizona"
                                              value="Arizona"
                                            >
                                              Arizona
                                            </option>
                                            <option
                                              key="Arkansas"
                                              value="Arkansas"
                                            >
                                              Arkansas
                                            </option>
                                            <option
                                              key="California"
                                              value="California"
                                            >
                                              California
                                            </option>
                                            <option
                                              key="Colorado"
                                              value="Colorado"
                                            >
                                              Colorado
                                            </option>
                                            <option
                                              key="Connecticut"
                                              value="Connecticut"
                                            >
                                              Connecticut
                                            </option>
                                            <option
                                              key="Delaware"
                                              value="Delaware"
                                            >
                                              Delaware
                                            </option>
                                            <option
                                              key="Florida"
                                              value="Florida"
                                            >
                                              Florida
                                            </option>
                                            <option
                                              key="Georgia"
                                              value="Georgia"
                                            >
                                              Georgia
                                            </option>
                                            <option key="Hawaii" value="Hawaii">
                                              Hawaii
                                            </option>
                                            <option key="Idaho" value="Idaho">
                                              Idaho
                                            </option>
                                            <option
                                              key="Illinois"
                                              value="Illinois"
                                            >
                                              Illinois
                                            </option>
                                            <option
                                              key="Indiana"
                                              value="Indiana"
                                            >
                                              Indiana
                                            </option>
                                            <option key="Iowa" value="Iowa">
                                              Iowa
                                            </option>
                                            <option key="Kansas" value="Kansas">
                                              Kansas
                                            </option>
                                            <option
                                              key="Kentucky"
                                              value="Kentucky"
                                            >
                                              Kentucky
                                            </option>
                                            <option
                                              key="Louisiana"
                                              value="Louisiana"
                                            >
                                              Louisiana
                                            </option>
                                            <option key="Maine" value="Maine">
                                              Maine
                                            </option>
                                            <option
                                              key="Maryland"
                                              value="Maryland"
                                            >
                                              Maryland
                                            </option>
                                            <option
                                              key="Massachusetts"
                                              value="Massachusetts"
                                            >
                                              Massachusetts
                                            </option>
                                            <option
                                              key="Michigan"
                                              value="Michigan"
                                            >
                                              Michigan
                                            </option>
                                            <option
                                              key="Minnesota"
                                              value="Minnesota"
                                            >
                                              Minnesota
                                            </option>
                                            <option
                                              key="Mississippi"
                                              value="Mississippi"
                                            >
                                              Mississippi
                                            </option>
                                            <option
                                              key="Missouri"
                                              value="Missouri"
                                            >
                                              Missouri
                                            </option>
                                            <option
                                              key="Montana"
                                              value="Montana"
                                            >
                                              Montana
                                            </option>
                                            <option
                                              key="Nebraska"
                                              value="Nebraska"
                                            >
                                              Nebraska
                                            </option>
                                            <option key="Nevada" value="Nevada">
                                              Nevada
                                            </option>
                                            <option
                                              key="New Hampshire"
                                              value="New Hampshire"
                                            >
                                              New Hampshire
                                            </option>
                                            <option
                                              key="New Jersey"
                                              value="New Jersey"
                                            >
                                              New Jersey
                                            </option>
                                            <option
                                              key="New Mexico"
                                              value="New Mexico"
                                            >
                                              New Mexico
                                            </option>
                                            <option
                                              key="New York"
                                              value="New York"
                                            >
                                              New York
                                            </option>
                                            <option
                                              key="North Carolina"
                                              value="North Carolina"
                                            >
                                              North Carolina
                                            </option>
                                            <option
                                              key="North Dakota"
                                              value="North Dakota"
                                            >
                                              North Dakota
                                            </option>
                                            <option key="Ohio" value="Ohio">
                                              Ohio
                                            </option>
                                            <option
                                              key="Oklahoma"
                                              value="Oklahoma"
                                            >
                                              Oklahoma
                                            </option>
                                            <option key="Oregon" value="Oregon">
                                              Oregon
                                            </option>
                                            <option
                                              key="Pennsylvania"
                                              value="Pennsylvania"
                                            >
                                              Pennsylvania
                                            </option>
                                            <option
                                              key="Rhode Island"
                                              value="Rhode Island"
                                            >
                                              Rhode Island
                                            </option>
                                            <option
                                              key="South Carolina"
                                              value="South Carolina"
                                            >
                                              South Carolina
                                            </option>
                                            <option
                                              key="South Dakota"
                                              value="South Dakota"
                                            >
                                              South Dakota
                                            </option>
                                            <option
                                              key="Tennessee"
                                              value="Tennessee"
                                            >
                                              Tennessee
                                            </option>
                                            <option key="Texas" value="Texas">
                                              Texas
                                            </option>
                                            <option key="Utah" value="Utah">
                                              Utah
                                            </option>
                                            <option
                                              key="Vermont"
                                              value="Vermont"
                                            >
                                              Vermont
                                            </option>
                                            <option
                                              key="Virginia"
                                              value="Virginia"
                                            >
                                              Virginia
                                            </option>
                                            <option
                                              key="Washington"
                                              value="Washington"
                                            >
                                              Washington
                                            </option>
                                            <option
                                              key="West Virginia"
                                              value="West Virginia"
                                            >
                                              West Virginia
                                            </option>
                                            <option
                                              key="Wisconsin"
                                              value="Wisconsin"
                                            >
                                              Wisconsin
                                            </option>
                                            <option
                                              key="Wyoming"
                                              value="Wyoming"
                                            >
                                              Wyoming
                                            </option>
                                          </TextField>
                                        </Grid>
                                      </Grid>
                                      <Grid container spacing={4}>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="zipcode"
                                            label="Zip / Postal Code"
                                            className={classes.textField}
                                            onChange={handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                            value={values?.zipcode || ""}
                                          />
                                        </Grid>
                                        {/* <Grid item xs={12} sm={4}>
                                                                                    <Typography
                                                                                        gutterBottom
                                                                                        variant="h6"
                                                                                        align="left"
                                                                                        className={classes.subTitle2}
                                                                                        style={{
                                                                                            width: "100%",
                                                                                            textAlign: "left",
                                                                                            marginTop: "25px",
                                                                                        }}
                                                                                    >
                                                                                        DATE: 3/25/2020{" "}
                                                                                    </Typography>
                                                                                </Grid> */}
                                      </Grid>
                                    </div>
                                    <br></br>
                                    <br></br>

                                    <div>
                                      <Typography
                                        gutterBottom
                                        variant="h6"
                                        align="center"
                                        style={{
                                          maxWidth: "80%",
                                          margin: "0px auto 15px",
                                        }}
                                        className={classes.subTitle2}
                                      >
                                        <b>
                                          GOVERNMENT/COMMERICAL SALES CREDIT
                                          CLAIM
                                        </b>
                                      </Typography>
                                      <Typography
                                        gutterBottom
                                        variant="h6"
                                        align="center"
                                        style={{
                                          maxWidth: "80%",
                                          margin: "0px auto 10px",
                                        }}
                                        className={classes.subTitle2}
                                      >
                                        Carolina Skiff LLC will credit 5% of the
                                        Dealer boat cost for any Carolina Skiff
                                        product sold to a licensed commercial
                                        agency such as, Utility Commissions,
                                        Government Agency, Local State Agency,
                                        Boat Rental, Comercial Guide Fisherman
                                        or any other type of commercial business
                                        approved by Carolina Skiff LLC.
                                      </Typography>

                                      <br></br>
                                      <br></br>
                                      {/* cliam form start */}
                                      <Grid
                                        container
                                        spacing={2}
                                        justify="space-around"
                                      >
                                        <Grid item xs={12} md={2}>
                                          <FormLabel
                                            variant="h5"
                                            align="center"
                                            className={classes.subTitle2}
                                            style={{
                                              width: "100%",
                                              display: "block",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <b>BUYER NAME</b>
                                          </FormLabel>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                          <FormLabel
                                            variant="h5"
                                            align="center"
                                            className={classes.subTitle2}
                                            style={{
                                              width: "100%",
                                              display: "block",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <b>ITEM/PRODUCT</b>
                                          </FormLabel>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                          <FormLabel
                                            variant="h5"
                                            align="center"
                                            className={classes.subTitle2}
                                            style={{
                                              width: "100%",
                                              display: "block",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <b>SERIAL NO.</b>
                                          </FormLabel>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                          <FormLabel
                                            variant="h5"
                                            align="center"
                                            className={classes.subTitle2}
                                            style={{
                                              width: "100%",
                                              display: "block",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <b>DEALER COST</b>
                                          </FormLabel>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                          <FormLabel
                                            variant="h5"
                                            align="center"
                                            className={classes.subTitle2}
                                            style={{
                                              width: "100%",
                                              display: "block",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <b>% CREDIT</b>
                                          </FormLabel>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                          <FormLabel
                                            variant="h5"
                                            align="center"
                                            className={classes.subTitle2}
                                            style={{
                                              width: "100%",
                                              display: "block",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <b>TOTAL CREDIT</b>
                                          </FormLabel>
                                        </Grid>
                                      </Grid>
                                      {formsData &&
                                        formsData?.length > 0 &&
                                        formsData?.map((form, index) => {
                                          return (
                                            <>
                                              <Grid
                                                container
                                                spacing={2}
                                                justify="space-around"
                                              >
                                                <Grid item xs={12} md={2}>
                                                  <OutlinedInput
                                                    error={
                                                      !!errors[index]
                                                        ?.buyer_name
                                                    }
                                                    required={
                                                      !!errors[index]
                                                        ?.buyer_name
                                                    }
                                                    name="buyer_name"
                                                    id="buyer_name"
                                                    endAdornment={
                                                      <InputAdornment position="end">
                                                        {" "}
                                                      </InputAdornment>
                                                    }
                                                    value={
                                                      form?.buyer_name || ""
                                                    }
                                                    style={{ width: "100%" }}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                      "aria-label": "weight",
                                                    }}
                                                    onChange={(e) =>
                                                      handleFieldChange(
                                                        index,
                                                        "buyer_name",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                  <OutlinedInput
                                                    error={
                                                      !!errors[index]?.item_name
                                                    }
                                                    required={
                                                      !!errors[index]?.item_name
                                                    }
                                                    name="item_name"
                                                    id="item_name"
                                                    endAdornment={
                                                      <InputAdornment position="end">
                                                        {" "}
                                                      </InputAdornment>
                                                    }
                                                    value={
                                                      form?.item_name || ""
                                                    }
                                                    style={{ width: "100%" }}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                      "aria-label": "weight",
                                                    }}
                                                    onChange={(e) =>
                                                      handleFieldChange(
                                                        index,
                                                        "item_name",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                  <OutlinedInput
                                                    error={
                                                      !!errors[index]?.serial_no
                                                    }
                                                    required={
                                                      !!errors[index]?.serial_no
                                                    }
                                                    name="serial_no"
                                                    id="serial_no"
                                                    type="number"
                                                    value={
                                                      form?.serial_no || ""
                                                    }
                                                    endAdornment={
                                                      <InputAdornment position="end">
                                                        {" "}
                                                      </InputAdornment>
                                                    }
                                                    style={{ width: "100%" }}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    onChange={(e) =>
                                                      handleFieldChange(
                                                        index,
                                                        "serial_no",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                  <OutlinedInput
                                                    error={
                                                      !!errors[index]
                                                        ?.dealer_cost
                                                    }
                                                    required={
                                                      !!errors[index]
                                                        ?.dealer_cost
                                                    }
                                                    name="dealer_cost"
                                                    id="dealer_cost"
                                                    type="number"
                                                    value={
                                                      form?.dealer_cost || ""
                                                    }
                                                    endAdornment={
                                                      <InputAdornment position="end">
                                                        {" "}
                                                      </InputAdornment>
                                                    }
                                                    style={{ width: "100%" }}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                      "aria-label": "weight",
                                                      pattern: "\\d*\\.?\\d+",
                                                      min: "0",
                                                    }}
                                                    onChange={(e) => {
                                                      const value =
                                                        e.target.value;
                                                      if (
                                                        value === "" ||
                                                        (!isNaN(value) &&
                                                          Number(value) >= 0)
                                                      ) {
                                                        handleFieldChange(
                                                          index,
                                                          "dealer_cost",
                                                          value.toString()
                                                        );
                                                      }
                                                    }}
                                                  />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                  <OutlinedInput
                                                    error={
                                                      !!errors[index]?.credit
                                                    }
                                                    required={
                                                      !!errors[index]?.credit
                                                    }
                                                    name="credit"
                                                    type="number"
                                                    id="credit"
                                                    endAdornment={
                                                      <InputAdornment position="end">
                                                        {" "}
                                                      </InputAdornment>
                                                    }
                                                    style={{ width: "100%" }}
                                                    startAdornment={
                                                      <InputAdornment position="start">
                                                        $
                                                      </InputAdornment>
                                                    }
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                      "aria-label": "weight",
                                                      pattern: "\\d*\\.?\\d+",
                                                      min: "0",
                                                    }}
                                                    value={form?.credit || ""}
                                                    onChange={(e) => {
                                                      const value =
                                                        e.target.value;
                                                      if (
                                                        value === "" ||
                                                        (!isNaN(value) &&
                                                          Number(value) >= 0)
                                                      ) {
                                                        handleFieldChange(
                                                          index,
                                                          "credit",
                                                          value.toString()
                                                        );
                                                      }
                                                    }}
                                                  />
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                  <OutlinedInput
                                                    error={
                                                      !!errors[index]
                                                        ?.total_credit
                                                    }
                                                    // required={!!(errors[index]?.total_credit)}
                                                    name="total_credit"
                                                    id="total_credit"
                                                    type="number"
                                                    value={(
                                                      (parseFloat(
                                                        form?.credit
                                                      ) *
                                                        parseFloat(
                                                          form?.dealer_cost
                                                        )) /
                                                      100
                                                    ).toFixed(2)}
                                                    disabled
                                                    endAdornme
                                                    nt={
                                                      <InputAdornment position="end">
                                                        {" "}
                                                      </InputAdornment>
                                                    }
                                                    style={{
                                                      width: "100%",
                                                      textAlign: "center",
                                                    }}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    defaultValue="50%"
                                                    align="center"
                                                    inputProps={{
                                                      "aria-label": "weight",
                                                      pattern: "\\d*\\.?\\d+",
                                                      min: "0",
                                                    }}
                                                  />
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                spacing={2}
                                                justify="space-around"
                                                key={form?.id}
                                              >
                                                <Grid item xs={12} md={2}>
                                                  {errors[index]
                                                    ?.buyer_name && (
                                                    <div className="error-message">
                                                      !required
                                                    </div>
                                                  )}
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                  {errors[index]?.item_name && (
                                                    <div className="error-message">
                                                      !required
                                                    </div>
                                                  )}
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                  {errors[index]?.serial_no && (
                                                    <div className="error-message">
                                                      !required
                                                    </div>
                                                  )}
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                  {errors[index]
                                                    ?.dealer_cost && (
                                                    <div className="error-message">
                                                      !required
                                                    </div>
                                                  )}
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                  {errors[index]?.credit && (
                                                    <div className="error-message">
                                                      !required
                                                    </div>
                                                  )}
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                  {/* {errors[index]?.total_credit && (
                                                                                                    <div className="error-message">!required</div>
                                                                                                )} */}
                                                </Grid>
                                              </Grid>
                                            </>
                                          );
                                        })}
                                      <br></br>
                                      <Grid
                                        container
                                        justify="flex-end"
                                        spacing={4}
                                      >
                                        <Grid item xs={12} sm={4}>
                                          <FormControl
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                            }}
                                            variant="outlined"
                                          >
                                            {/* <b variant="h5" align="center" className={classes.subTitle2}  style={{width:"100%",marginBottom:"10px"}}>TOTAL COST OF PROJECT</b> */}
                                            <FormLabel
                                              className={classes.subTitle2}
                                            >
                                              <b>Total Credit $</b>{" "}
                                            </FormLabel>
                                            <OutlinedInput
                                              id="outlined-adornment-weight"
                                              endAdornment={
                                                <InputAdornment position="end">
                                                  {" "}
                                                </InputAdornment>
                                              }
                                              disabled
                                              value={
                                                formsData
                                                  ?.reduce((sum, item) => {
                                                    return (
                                                      parseFloat(sum) +
                                                      parseFloat(
                                                        item?.total_credit
                                                      )
                                                    );
                                                  }, 0)
                                                  .toFixed(2) || 0
                                              }
                                              style={{
                                                width: "80%",
                                                maxWidth: "calc(100% - 100px)",
                                                marginLeft: "6px",
                                              }}
                                              aria-describedby="outlined-weight-helper-text"
                                              inputProps={{
                                                "aria-label": "weight",
                                              }}
                                            />
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                      {/* cliam form end */}
                                      <br></br>
                                      <br></br>

                                      <Typography
                                        gutterBottom
                                        variant="h6"
                                        align="left"
                                        className={classes.subTitle2}
                                      >
                                        <b>REQUIREMENTS</b>
                                      </Typography>
                                      <br></br>
                                      <br></br>

                                      <Grid
                                        container
                                        spacing={4}
                                        alignItems="center"
                                      >
                                        <Grid item xs={12} sm={6} md={7}>
                                          <FormControl
                                            sx={{ m: 1, width: "25ch" }}
                                            variant="outlined"
                                          >
                                            <FormLabel
                                              variant="h5"
                                              align="left"
                                              className={classes.subTitle2}
                                              style={{ width: "100%" }}
                                            >
                                              1. Copy of Be Of Sale dated and
                                              submitted within 20 days of sale
                                            </FormLabel>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5}>
                                          <input
                                            accept="image/*,application/pdf"
                                            className={classes.input}
                                            id="contained-button-file1"
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={(e) =>
                                              handleFileChange(e, "file1")
                                            }
                                          />
                                          <FormLabel htmlFor="contained-button-file1">
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              component="span"
                                            >
                                              CHOOSE FILE
                                            </Button>
                                          </FormLabel>
                                          <FormLabel
                                            htmlFor="contained-button-file1"
                                            className={classes.body2}
                                            style={{
                                              marginLeft: "8px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {files?.file1?.name
                                              ? files?.file1?.name
                                              : files?.file1 ||
                                                "NO FILES UPLOADED"}
                                          </FormLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={7}>
                                          <FormControl
                                            sx={{ m: 1, width: "25ch" }}
                                            variant="outlined"
                                          >
                                            <FormLabel
                                              variant="h5"
                                              align="left"
                                              className={classes.subTitle2}
                                              style={{ width: "100%" }}
                                            >
                                              2. If a Kit Boat Sale, Est all
                                              fiberglass accessories
                                            </FormLabel>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5}>
                                          <input
                                            accept="image/*,application/pdf"
                                            className={classes.input}
                                            id="contained-button-file2"
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={(e) =>
                                              handleFileChange(e, "file2")
                                            }
                                          />
                                          <FormLabel htmlFor="contained-button-file2">
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              component="span"
                                            >
                                              CHOOSE FILE
                                            </Button>
                                          </FormLabel>
                                          <FormLabel
                                            htmlFor="contained-button-file2"
                                            className={classes.body2}
                                            style={{
                                              marginLeft: "8px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {files?.file2?.name
                                              ? files?.file2?.name
                                              : files?.file2 ||
                                                "NO FILES UPLOADED"}
                                          </FormLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={7}>
                                          <FormControl
                                            sx={{ m: 1, width: "25ch" }}
                                            variant="outlined"
                                          >
                                            <FormLabel
                                              variant="h5"
                                              align="left"
                                              className={classes.subTitle2}
                                              style={{ width: "100%" }}
                                            >
                                              3. Warranty Card completed and
                                              submitted with this form and all
                                              other paperwork
                                            </FormLabel>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5}>
                                          <input
                                            accept="image/*,application/pdf"
                                            className={classes.input}
                                            id="contained-button-file3"
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={(e) =>
                                              handleFileChange(e, "file3")
                                            }
                                          />
                                          <FormLabel htmlFor="contained-button-file3">
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              component="span"
                                            >
                                              CHOOSE FILE
                                            </Button>
                                          </FormLabel>
                                          <FormLabel
                                            htmlFor="contained-button-file3"
                                            className={classes.body2}
                                            style={{
                                              marginLeft: "8px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {files?.file3?.name
                                              ? files?.file3?.name
                                              : files?.file3 ||
                                                "NO FILES UPLOADED"}
                                          </FormLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={7}>
                                          <FormControl
                                            sx={{ m: 1, width: "25ch" }}
                                            variant="outlined"
                                          >
                                            <FormLabel
                                              variant="h5"
                                              align="left"
                                              className={classes.subTitle2}
                                              style={{ width: "100%" }}
                                            >
                                              3. Copy of Business Purchase Order
                                              and or Business License and or
                                              Captain License
                                            </FormLabel>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5}>
                                          <input
                                            accept="image/*,application/pdf"
                                            className={classes.input}
                                            id="contained-button-file4"
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={(e) =>
                                              handleFileChange(e, "file4")
                                            }
                                          />
                                          <FormLabel htmlFor="contained-button-file4">
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              component="span"
                                            >
                                              CHOOSE FILE
                                            </Button>
                                          </FormLabel>
                                          <FormLabel
                                            htmlFor="contained-button-file4"
                                            className={classes.body2}
                                            style={{
                                              marginLeft: "8px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {files?.file4?.name
                                              ? files?.file4?.name
                                              : files?.file4 ||
                                                "NO FILES UPLOADED"}
                                          </FormLabel>
                                        </Grid>
                                      </Grid>
                                    </div>
                                    <br></br>

                                    <div>
                                      <Grid container>
                                        <Grid item xs={12} sm={12}>
                                          <TextField
                                            name="additional_info"
                                            label="Additional information. (OPTIONAL)"
                                            multiline
                                            rows="4"
                                            variant="outlined"
                                            className={classes.textField}
                                            onChange={handleChange}
                                            margin="normal"
                                            value={
                                              values?.additional_info || ""
                                            }
                                            style={{ width: "100%" }}
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>

                                    <Grid item xs={12} align="center">
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.button}
                                        type="submit"
                                        style={{
                                          width: "15em",
                                          margin: "2em 1em",
                                        }}
                                        disabled={isSubmit}
                                      >
                                        SUBMIT
                                      </Button>
                                      {/* <Button
                                                        color="secondary"
                                                        variant="contained"
                                                        className={classes.button}
                                                        type="submit"
                                                        style={{ width: "15em", margin: "2em 1em" }}
                                                    >
                                                        EDIT FORM
                                                    </Button>
                                                    <Button
                                                        color="dark"
                                                        variant="contained"
                                                        className={classes.button}
                                                        type="submit"
                                                        // onClick={this.clearForm}
                                                        style={{
                                                            width: "15em",
                                                            margin: "2em 1em",
                                                            backgroundColor: "#23333F",
                                                            color: "#ffffff",
                                                        }}
                                                    >
                                                        DOWNLOAD
                                                    </Button> */}
                                    </Grid>
                                  </Paper>
                                </div>
                              </form>
                            </DashboardLayout>
                          );
                        }}
                      </Mutation>
                    );
                  }}
                </Mutation>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
}
export default withStyles(styles)(CommericalSalesCreditClaims);
