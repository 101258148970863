import React, { useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { Button } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import auth from "../../../Auth";
import PropTypes from 'prop-types';

const styles = theme => ({
  button: {
    margin: 8,
  },
  buttonCancel: {
    backgroundColor: "black",
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: "100%",
    textAlign: "center"
  },
  dense: {
    marginTop: 19
  },
  formControl: {
    margin: theme.spacing(3)
  }
});

const UPDATE_CAREERS = gql`
   mutation updateCareers($id:uuid!, $title:String, $description:String, $store_location:String, $salary_range:String, $date_needed:date){
    update_careers(
        where: {id: {_eq: $id}},
        _set: {
          title: $title,
          description: $description,
          store_location: $store_location,
          salary_range: $salary_range,
          date_needed: $date_needed
        }
      ) {
        affected_rows
        returning {
            id
        }
      }
   }
`;

const CareersEdit = (props) => {
  const message = "";
  const { classes, careers, closeEditForm } = props;
  const { values, handleChange, resetState, setValues } = useForm(
    submitForm
  );

  function submitForm() {
    //console.log(values);
  }
  function completedAdd(payload) {
    toast("Career Successfully Updated");
    //resetState();
  }
  return (

    <Mutation mutation={UPDATE_CAREERS} onCompleted={completedAdd}>
      {(careersEdit, { data }) => {
        if (message) {
          return <div> {message} </div>
        }
        else {
          return (
            <div style={{ textAlign: "center" }}>
              {values.title !== undefined ? (
                setValues(values)
              ) : (
                  setValues(careers)
                )}
              <form
                onSubmit={e => {
                  values.profile_id = auth.getSub();
                  e.preventDefault();
                  careersEdit({
                    variables: {
                      id: props.careers.id,
                      title: values.title,
                      description: values.description,
                      store_location: values.store_location,
                      salary_range: values.salary_range,
                      date_needed: values.date_needed,
                      __typename: undefined,
                    }
                  });

                  return false;
                }}
                autoComplete="off"
              >
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    {/* Row 2*/}
                    <TextField
                      id="title"
                      name="title"
                      label="Job Title"
                      onChange={handleChange}
                      placeholder="Job Title"
                      value={values.title || ""}
                      className={classes.textField}
                      margin="normal"
                      required
                      variant="outlined"
                    />
                    <TextField
                      required
                      margin="normal"
                      variant="outlined"
                      id="date_needed"
                      name="date_needed"
                      type="date"
                      value={values.date_needed || ""}
                      onChange={handleChange}
                      placeholder="Date Needed"
                      className={classes.textField}
                    />
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <TextField
                      id="store_location"
                      name="store_location"
                      label="Store / Location"
                      onChange={handleChange}
                      value={values.store_location || ""}
                      placeholder="Store / Location"
                      className={classes.textField}
                      margin="normal"
                      required
                      variant="outlined"
                    />
                    <TextField
                      id="salary_range"
                      name="salary_range"
                      label="Salary Range"
                      value={values.salary_range || ""}
                      placeholder="Salary Range"
                      onChange={handleChange}
                      className={classes.textField}
                      margin="normal"
                      required
                      variant="outlined"
                    />
                  </Grid>

                  {/* Row 3 */}
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <TextField
                      id="description"
                      name="description"
                      label="Job Description"
                      value={values.description || ""}
                      placeholder="Job Description"
                      onChange={handleChange}
                      className={classes.textField}
                      margin="normal"
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.buttonCancel}
                      onClick={closeEditForm}
                      type="button"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      type="submit"
                    >
                      Update Career
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          )
        }
      }
      }
    </Mutation>
  );

};

CareersEdit.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(CareersEdit);