import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
  SearchPanel,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import {
  RowDetailState,
  PagingState,
  SearchState,
  IntegratedPaging,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {
  Typography,
  withStyles,
  CircularProgress,
  Button,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Mutation, Subscription } from "react-apollo";
import {
  getAllUpcomingBoatShowRequests,
  getAllPassBoatShowRequests,
  DeleteBoatShow,
} from "./queries";
import auth from "../../Auth";
import DeleteIcon from "@material-ui/icons/Delete";
import BoatShowsUpdate from "./BoatShowsUpdate";
import { toast } from "react-toastify";
import Modal from "@material-ui/core/Modal";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(),
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 275,
  },
  navBar: {
    display: "flex",
    flexWrap: "no-wrap",
  },
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: "30px",
    "&:focus-visible": {
      outline: "transparent",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    marginTop: theme.spacing(2),
  },
});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const RowDetail = ({ row }) => (
  <div>
    <table cellSpacing="0" className="detailTable">
      <tbody>
        <tr>
          <td>Visible On:</td>
          <td>{row?.site || ""}</td>
        </tr>
        <tr>
          <td>Dealer:</td>
          <td>{row?.dealer || ""}</td>
        </tr>
        <tr>
          <td>Boat Show Name:</td>
          <td>{row?.name || ""}</td>
        </tr>
        <tr>
          <td>Address:</td>
          <td>{row?.address || ""}</td>
        </tr>
        <tr>
          <td>Region:</td>
          <td>{row?.region || ""}</td>
        </tr>
        <tr>
          <td>Brands:</td>
          <td>{row?.brands || ""}</td>
        </tr>
        <tr>
          <td>Start Date:</td>
          <td>{row?.start_date || ""}</td>
        </tr>
        <tr>
          <td>End Date:</td>
          <td>{row?.end_date || ""}</td>
        </tr>
        {auth.getRole().toLowerCase() === "admin" && (
          <tr>
            <td colSpan="2">
              <BoatShowsUpdate
                key={row.id}
                boatShows={row}
                status={row.status}
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
    <div></div>
  </div>
);

class BoatShowTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isSubmit: false,
      boatId: null,
      columns: [
        { name: "name", title: "Boat Show Name" },
        { name: "dealer", title: "Dealer" },
        { name: "address", title: "Address" },
        { name: "start_date", title: "Start Date" },
        {
          name: "delete",
          title: "Action",
          getCellValue: (row) => {
            return (
              <DeleteIcon
                fontSize="small"
                style={{
                  color: "#3B6894",
                  width: "18px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({
                    boatId: row?.id,
                  });
                  this.handleOpen();
                }}
              />
            );
          },
        },
      ],
      activeTabIndex: 0,
    };
    this.changeSearchValue = (value) => this.setState({ searchValue: value });
  }

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleDelete = (e, deleteBoatShow) => {
    e.preventDefault();
    this.setState({
      isSubmit: true,
    });
    deleteBoatShow({
      variables: {
        id: this.state.boatId,
      },
    }).then(() => {
      this.completedDelete();
    });
  };

  completedDelete = () => {
    toast("Boat Show Deleted Successfully");
    this.handleClose();
    this.setState({
      isSubmit: false,
    });
  };

  handleTabChange = (event, value) => {
    //console.log( event.target );
    this.setState({ activeTabIndex: value });
  };

  render() {
    const { classes } = this.props;
    const { rows, columns, activeTabIndex, searchValue, open, isSubmit } =
      this.state;

    return (
      <div>
        <Modal open={open} onClose={this.handleClose}>
          <div className={classes.paper}>
            <Typography variant="h6" gutterBottom>
              Are you sure you want to delete this boat show?
            </Typography>
            <div className={classes.buttonContainer}>
              <Mutation
                mutation={DeleteBoatShow}
                onCompleted={this.completedAdd}
              >
                {(deleteBoatShow, { data, error }) => {
                  if (error)
                    return (
                      <pre>
                        Bad:{" "}
                        {error.graphQLErrors.map(({ message }, i) => (
                          <span key={i}>{message}</span>
                        ))}
                      </pre>
                    );
                  return (
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={isSubmit}
                      onClick={(e) => this.handleDelete(e, deleteBoatShow)}
                    >
                      Confirm
                    </Button>
                  );
                }}
              </Mutation>

              <Button variant="contained" onClick={this.handleClose}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
        <div className={classes.root + " tableStyles"}>
          <AppBar position="static">
            <Tabs value={activeTabIndex} onChange={this.handleTabChange}>
              <Tab label="Upcoming" />
              <Tab label="Past" />
            </Tabs>
          </AppBar>
          {activeTabIndex === 0 && (
            <TabContainer>
              <Subscription subscription={getAllUpcomingBoatShowRequests}>
                {({ data, loading, error }) => {
                  console.log(data, "data");
                  if (loading)
                    return (
                      <div className="flex center" style={{ padding: "2em 0" }}>
                        <CircularProgress color="secondary" />
                      </div>
                    );
                  if (error)
                    return <p>Error Loading All Upcoming Boat Shows</p>;
                  return (
                    <Grid rows={data?.boat_shows} columns={columns}>
                      <SearchState
                        value={searchValue}
                        onValueChange={this.changeSearchValue}
                      />
                      <PagingState defaultCurrentPage={0} pageSize={10} />
                      <IntegratedFiltering />
                      <IntegratedPaging />
                      <RowDetailState defaultExpandedRowIds={[0]} />
                      <Table />
                      <TableHeaderRow />
                      <TableRowDetail contentComponent={RowDetail} />
                      <PagingPanel />
                      <Toolbar />
                      <SearchPanel />
                    </Grid>
                  );
                }}
              </Subscription>
            </TabContainer>
          )}
          {activeTabIndex === 1 && (
            <TabContainer>
              <Subscription subscription={getAllPassBoatShowRequests}>
                {({ data, loading, error }) => {
                  if (loading) return <p>Loading...</p>;
                  if (error) return <p>Error Loading All Past Boat Shows</p>;
                  return (
                    <Grid rows={data?.boat_shows} columns={columns}>
                      <SearchState
                        value={searchValue}
                        onValueChange={this.changeSearchValue}
                      />
                      <PagingState defaultCurrentPage={0} pageSize={10} />
                      <IntegratedFiltering />
                      <IntegratedPaging />
                      <RowDetailState defaultExpandedRowIds={[0]} />
                      <Table />
                      <TableHeaderRow />
                      <TableRowDetail contentComponent={RowDetail} />
                      <PagingPanel />
                      <Toolbar />
                      <SearchPanel />
                    </Grid>
                  );
                }}
              </Subscription>
            </TabContainer>
          )}
        </div>
      </div>
    );
  }
}

BoatShowTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BoatShowTabs);
