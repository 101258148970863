import React, { Component } from "react";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { Button } from "@material-ui/core";

const styles = {
  button: {
    margin: 8,
    width: "205px"
  },
  actions: {
      display: 'flex',
      justifyContent: 'center'
  },
  completedContainer: {
      textAlign: 'center',
      padding: '2em 0px'
  },
  completedTitle: {
      fontSize: 14,
      color: '#1f3340',
      textTransform: 'uppercase',
      textAlign: 'center'  
  }
};

const UPDATE_BOAT_SHOWS = gql`
  mutation updateBoatshowStatus(
    $id: uuid!
    $dealer: String!
    $status: String!
    $modified_at: date!
  ) {
    update_boat_shows(
      where: { id: { _eq: $id } }
      _set: { dealer: $dealer, status: $status, modified_at: $modified_at }
    ) {
      affected_rows
      returning {
        id
        name
        dealer
        status
        modified_at
      }
    }
  }
`;

export default class BoatShowApproveDeny extends Component {
  constructor({ tag, id, dealer }) {
    super();
    this.state = {
      tag: tag,
      id: id,
      dealer: dealer
    };
  }

  componentDidUpdate = prevProps => {
    if (this.props.tag !== prevProps.tag) {
      this.setState({
        tag: this.props.tag
      });
    }
    if (this.props.dealer !== prevProps.dealer) {
      this.setState({
        dealer: this.props.dealer
      });
    }
  };

  componentWillReceiveProps = () => {
    //console.log("componentWillReceiveProps");
  };

  completedAdd = payload => {
    this.setState({ tag: "Pending" });
    this.setState({ dealer: "" });
  };

  render() {
    const tag = this.state.tag;
    return (
      <Mutation mutation={UPDATE_BOAT_SHOWS}>
        {(boat_shows, { data }) => {
          if (tag!=="Pending") {
            return (
              <div style={styles.completedContainer}>
                {" "}
                <span style={styles.completedTitle}>Boat Show was {tag}</span>
              </div>
            );
          } else {
            return (
              <div style={styles.completedContainer}>
                <div style={styles.actions}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={styles.button}
                    onClick={e => {
                      boat_shows({
                        variables: {
                          id: this.state.id,
                          dealer: this.state.dealer,
                          status: "Approved",
                          modified_at: "now()"
                        }
                      });
                      this.completedAdd();
                    }}
                  >
                   Approve
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={styles.button}
                    onClick={e => {
                      boat_shows({
                        variables: {
                          id: this.state.id,
                          dealer: this.state.dealer,
                          status: "Denied",
                          modified_at: "now()"
                        }
                      });
                      this.completedAdd();
                    }}
                  >
                    Deny
                  </Button>
                </div>
              </div>
            );
          }
        }}
      </Mutation>
    );
  }
}
