import React from 'react';
  // Shared layouts
import CareersTabs from "./CareersTabs";

class CareersGrid extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
  return (
    <div>
      <CareersTabs  />
     </div>
    );
  }
}

export default CareersGrid;