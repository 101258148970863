import React from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { ARCHIVE_MYDEALER_USER } from "queries";
import { Mutation } from "react-apollo";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import axios from "axios";
import auth0User from "components/Auth0User";

const styles = {
  button: {
    margin: 8,
    backgroundColor: "black",
    color: "white"
  },
  container: {
    padding: "2em"
  },
};

function SubmitUserForUnArchive(userId, callBackMutation) {
    let formData = {};
    let accessTokenPromise = auth0User.getToken();
    let AUTH_API_URL = auth0User.AUTH_API_URL();

    accessTokenPromise.then((accessToken) => {
        axios({
            url: AUTH_API_URL + "users/" + userId,
            method: "patch",
            headers: { Authorization: `Bearer ${accessToken}` },
            data: {
                connection: "Username-Password-Authentication",
                blocked: false,
            }
        })
        .then(function(response) {
            formData.profile_id = userId;
            formData.status = "active";
            formData.updated_at = "now()";
            callBackMutation({
                variables: formData
            });
            return true;
        })
        .catch(function(error) {
            toast.error("Error UnArchiving Dealer To Auth0: " + error, {
                position: toast.POSITION.TOP_LEFT
            });
        });
    })
}
function UnArchiveUserButton(props) {
  const { item } = props;
  //console.log(props);
  //console.log(item);
  function completedAdd(payload) {
     toast("Dealer has been succesfully Unarchived");
  }
  return (
    <Mutation mutation={ARCHIVE_MYDEALER_USER} onCompleted={completedAdd}>
    {(archiveDealerUser, { data, loading, called, error }) => {
      if (loading) return null;
      if (error) return `Error! ${error}`;
      
      return (
        <form
          onSubmit={e => {
            e.preventDefault();
            SubmitUserForUnArchive(item.profile_id, archiveDealerUser);
            return false;
          }}
          className={styles.container}
          autoComplete="off"
        >
            <Button variant="contained" type="submit" color="secondary" style={styles.button} >
            UnArchive Dealer
            </Button>
        </form>
      );
    }}
  </Mutation>
  );
}

UnArchiveUserButton.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(UnArchiveUserButton);