import React from 'react'
import { 
    Button
  } from '@material-ui/core';
  
const styles = {
    button: {
      margin: 8,
      backgroundColor: "black",
      color: "white"
    }
  };
const WarrantyClaimUpdateButton = props => {
  return <Button color="secondary" variant="contained" onClick={props.addClick} style={styles.button}>Under Review</Button>
}

export default WarrantyClaimUpdateButton