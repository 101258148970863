// Material helpers
import { createMuiTheme } from "@material-ui/core";

import palette from "./palette";
import typography from "./typography";
import overrides from "./overrides";

const theme = createMuiTheme({
  palette,
  typography: {
    ...typography,
    fontFamily: [
      'Lato',
      'Arial',
      'sans-serif'
    ].join(','),
  },
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  },
  spacing: factor => [0, 4, 8, 16, 32, 64][factor],
});

export default theme;
