import React from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { ARCHIVE_MYDEALER_USER } from "queries";
import { Mutation } from "react-apollo";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import axios from "axios";
import auth0User from "components/Auth0User";

const styles = {
  button: {
    margin: 8
  },
  container: {
    padding: "2em"
  },
};

function SubmitUserForArchive(userId) {

    let accessTokenPromise = auth0User.getToken();
    let AUTH_API_URL = auth0User.AUTH_API_URL();

    accessTokenPromise.then((accessToken) => {
        axios({
            url: AUTH_API_URL + "jobs/verification-email",
            method: "post",
            headers: { Authorization: `Bearer ${accessToken}` },
            data: {
                user_id: userId,
            }
        })
        .then(function(response) {
            toast("Admin Resend Email Notification was succesful");
            return true;
        })
        .catch(function(error) {
            toast.error("Error sending email notification email: " + error, {
                position: toast.POSITION.TOP_LEFT
            });
        });
    })
}
function SendEmailVerificationButton(props) {
  const { item } = props;
  //console.log(props);
  return (
        <form
          onSubmit={e => {
            e.preventDefault();
            SubmitUserForArchive(item.profile_id);
            return false;
          }}
          className={styles.container}
          autoComplete="off"
        >
            <Button variant="contained" type="submit" color="secondary" style={styles.button} >
            RESEND VERIFICATION EMAIL
            </Button>
        </form>
  );
}

SendEmailVerificationButton.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(SendEmailVerificationButton);
