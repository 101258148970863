import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Typography, withStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { CountryRegionData } from "react-country-region-selector";
import { ADD_MYDEALER_USER } from "queries";
import { Mutation } from "react-apollo";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import auth from "Auth";
import axios from "axios";
import auth0User from "components/Auth0User";
import InputLabel from "@material-ui/core/InputLabel";
import Geocode from "react-geocode";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "2em",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100%",
    margin: "1em",
    textAlign: "center",
  },
  dense: {
    marginTop: 19,
  },
  formControl: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  addDealer: {
    width: "200px",
    fontSize: "18px",
    fontWeight: "300",
    textTransform: "uppercase",
    textAlign: "center",
  },
  addDealerBtn: {
    width: "200px",
    padding: "1em",
    fontSize: "14px",
  },
  checkBoxTitle: {
    fontSize: "18px",
    textTransform: "uppercase",
    color: "#1f3340",
    fontWeight: "300",
  },
}));

const getRegions = (country) => {
  if (!country) {
    return [];
  }
  //console.log("regions");
  return country[2].split("|").map((regionPair) => {
    let [regionName, regionShortCode = null] = regionPair.split("~");
    return regionName;
  });
};

const AddDealer = (props) => {
  const { values, handleChange, resetState, handleChangeCheckbox } =
    useForm(submitForm);
  const classes = useStyles();
  const [isSubmit, setIsSubmit] = useState(false);

  // values.start_date = new Date().getDate()
  function submitForm() {
    //console.log(values);
  }

  function permissionsCreator() {
    let permissionsArray = [];

    if (values.DEALER_ALL === true) {
      let permissionsArray = [];
      permissionsArray.push("dealer:DEALER_ALL");
      return permissionsArray;
    }
    if (values.DEALER_LEADS === true) {
      permissionsArray.push("dealer:DEALER_LEADS");
    }
    if (values.DEALER_WARRANTY === true) {
      permissionsArray.push("dealer:DEALER_WARRANTY");
    }
    if (values.DEALER_FORMS_DATASHEETS === true) {
      permissionsArray.push("dealer:DEALER_FORMS_DATASHEETS");
    }
    if (values.DEALER_MEDIA === true) {
      permissionsArray.push("dealer:DEALER_MEDIA");
    }
    if (values.DEALER_ORDER_BOATS === true) {
      permissionsArray.push("dealer:DEALER_ORDER_BOATS");
    }
    if (values.DEALER_CSI === true) {
      permissionsArray.push("dealer:DEALER_CSI");
    }
    if (values.DEALER_FACTORY_CONTACTS === true) {
      permissionsArray.push("dealer:DEALER_FACTORY_CONTACTS");
    }
    if (values.DEALER_BOAT_SHOWS === true) {
      permissionsArray.push("dealer:DEALER_BOAT_SHOWS");
    }
    if (values.DEALER_SKIFF_MERCHANDISE === true) {
      permissionsArray.push("dealer:DEALER_SKIFF_MERCHANDISE");
    }

    return permissionsArray;
  }

  function createNewUser(data, callBackMutation) {
    setIsSubmit(true);
    let formData = {};
    let accessTokenPromise = auth0User.getToken();
    let AUTH_API_URL = auth0User.AUTH_API_URL();

    accessTokenPromise
      .then((accessToken) => {
        formData.name = data?.name; // data.firstname + " " + data.lastname;
        formData.email = data?.email;
        formData.company = data?.company;
        formData.address = data?.address;
        formData.address2 = data?.address2;
        formData.city = data?.city;
        formData.phone = data?.phone;
        formData.has_skiff = data?.has_skiff;
        formData.has_funchaser = data?.has_funchaser;
        formData.has_seachaser = data?.has_seachaser;

        // formData.country=values.country;
        formData.region = data?.region;
        formData.zipcode = data?.zipcode;

        formData.country = data?.country[0];
        formData.role = "Dealer";
        formData.parent_admin = auth.getSub();

        // console.log("VALUES")
        // console.log(values)

        axios
          .get(`https://maps.googleapis.com/maps/api/geocode/json`, {
            params: {
              address:
                values?.address +
                " " +
                values?.city +
                " " +
                values?.region +
                "," +
                values?.country[0] +
                " " +
                values?.zipcode,
              key: process.env.REACT_APP_GOOGLE_API_KEY,
            },
          })
          .then(function (response) {
            const { lat, lng } =
              response?.data?.results?.[0]?.geometry?.location;
            formData.location =
              '{ "type": "Point", "coordinates": [' + lng + ", " + lat + "] }";

            axios({
              url: AUTH_API_URL + "users-by-email",
              method: "get",
              headers: { Authorization: `Bearer ${accessToken}` },
              params: {
                email: formData?.email,
              },
            })
              .then(function (response) {
                // console.log("REsponse from email");
                // console.log(response);

                if (response.data !== undefined || response.data.length === 0) {
                  // console.log(response.data.length + " Data length");
                  let auth0permissions = permissionsCreator();

                  axios({
                    url: AUTH_API_URL + "users",
                    method: "post",
                    headers: { Authorization: `Bearer ${accessToken}` },
                    data: {
                      connection: "Username-Password-Authentication",
                      email: formData?.email,
                      name: formData?.name,
                      password: data?.password,
                      user_metadata: {
                        full_name: formData?.name,
                      },
                      email_verified: false,
                      app_metadata: {
                        role: "dealer",
                        permissions: auth0permissions,
                      },
                    },
                  })
                    .then(function (response) {
                      // console.log("New User Response ");
                      // console.log(response);

                      let user_id = response.data.user_id;
                      let permissions = permissionsCreator();
                      formData.permissions = permissions;
                      formData.profile_id = user_id;

                      // console.log("Calling Data");
                      // console.log(formData);

                      delete data.password; // or delete person["age"];
                      delete data.lastname;
                      delete data.firstname;
                      delete data.error;

                      callBackMutation({
                        variables: {
                          objects: [formData],
                        },
                      });
                      return true;
                    })
                    .catch(function (error) {
                      toast.error(
                        "Error Adding New Dealer To Auth0: " + error,
                        {
                          position: toast.POSITION.TOP_LEFT,
                        }
                      );
                      setIsSubmit(false);
                      // console.log(error);
                    });
                }
                // console.log("ERROR: User already exists");
              })
              .catch(function (error) {
                // console.log(error);
                toast.error("ERROR: User already exists", {
                  position: toast.POSITION.TOP_LEFT,
                });
                setIsSubmit(false);
              });
          })
          .catch((error) => {
            toast.error(
              "Error from getToken() with async( When promise gets rejected ): " +
                error,
              {
                position: toast.POSITION.TOP_LEFT,
              }
            );
            setIsSubmit(false);
          });
        return true;
      })
      .catch(function (error) {
        setIsSubmit(false);
        toast.error("Error Getting GEO Location For Address: " + error, {
          position: toast.POSITION.TOP_LEFT,
        });
        // console.log(error);
      });
  }

  function validatePassword() {
    if (values.password !== values.repassword) {
      //   values.setCustomValidity("Passwords Don't Match");
    } else {
      //  values.repassword.setCustomValidity('');
    }
  }

  function completedAdd(payload) {
    //console.log("completeAdd has been called" + payload);
    toast("New Dealer User has been created succesfully");
    resetState();
    setIsSubmit(false);
  }

  return (
    <Mutation mutation={ADD_MYDEALER_USER} onCompleted={completedAdd}>
      {(addNewDealerUser, { data, loading, called, error }) => {
        if (error)
          return (
            <pre>
              Bad:{" "}
              {error.graphQLErrors.map(({ message }, i) => (
                <span key={i}>{message}</span>
              ))}
            </pre>
          );
        return (
          <form
            onSubmit={(e) => {
              // values.profile_id = auth.getSub();
              // values.country = values.country[0];
              // let formData = {};
              // formData.name = values.firstname + " " + values.lastname;
              // formData.email = values.email;
              // formData.password = values.password;
              //auth0User.createNewUser(formData);
              e.preventDefault();
              createNewUser(values, addNewDealerUser);

              return false;
            }}
            className={classes.container}
            autoComplete="off"
          >
            <Typography gutterBottom variant="h2" className={classes.addDealer}>
              Add New Dealer
            </Typography>

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                {/* Row 1 */}

                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  placeholder=""
                  className={classes.textField}
                  margin="normal"
                  onChange={handleChange}
                  value={values.email || ""}
                  required
                  variant="outlined"
                />
                <TextField
                  id="business_email"
                  name="business_email"
                  label="Business Email"
                  placeholder=""
                  className={classes.textField}
                  margin="normal"
                  onChange={handleChange}
                  value={values.business_email || ""}
                  variant="outlined"
                />
                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  placeholder="Password"
                  className={classes.textField}
                  margin="normal"
                  onChange={handleChange}
                  value={values.password || ""}
                  required
                  type="password"
                  variant="outlined"
                  inputProps={{
                    pattern: ".{8,}",
                  }}
                  // helperText="Enter 8 Characters Secure Password"
                />

                {/* <TextField
                  id="repassword"
                  name="repassword"
                  label="Re-Password"
                  placeholder="Re-Password"
                  className={classes.textField}
                  margin="normal"
                  onChange={handleChange}
                  value={values.repassword}
                  required
                  type="password"
                  variant="outlined"
                /> */}
              </Grid>

              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                {/* Row 1 */}
                <TextField
                  id="name"
                  name="name"
                  label="Full Name"
                  placeholder="Full Name"
                  className={classes.textField}
                  margin="normal"
                  onChange={handleChange}
                  value={values.name || ""}
                  required
                  variant="outlined"
                />
                <TextField
                  id="phone"
                  name="phone"
                  label="Phone"
                  placeholder="Phone"
                  className={classes.textField}
                  margin="normal"
                  onChange={(e) => handleChange(e, "phone")}
                  value={values.phone || ""}
                  variant="outlined"
                />
                <TextField
                  id="company"
                  name="company"
                  label="Company Name"
                  placeholder="Company Name"
                  className={classes.textField}
                  margin="normal"
                  onChange={handleChange}
                  value={values.company || ""}
                  required
                  variant="outlined"
                />
              </Grid>
              {/* Row 2 */}
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <TextField
                  id="address"
                  name="address"
                  label="Address"
                  placeholder="Address"
                  className={classes.textField}
                  margin="normal"
                  onChange={handleChange}
                  value={values.address || ""}
                  required
                  variant="outlined"
                />
                <TextField
                  id="address2"
                  name="address2"
                  label="SUITE , APT # , Optional"
                  placeholder="SUITE , APT # , Optional"
                  className={classes.textField}
                  margin="normal"
                  onChange={handleChange}
                  value={values.address2 || ""}
                  variant="outlined"
                />
                <TextField
                  id="city"
                  name="city"
                  label="City"
                  placeholder="City"
                  className={classes.textField}
                  margin="normal"
                  onChange={handleChange}
                  value={values.city || ""}
                  required
                  variant="outlined"
                />
              </Grid>
              {/* Row 3 */}
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <TextField
                  id="country"
                  name="country"
                  label="Country"
                  value={values.country || ""}
                  select
                  onChange={handleChange}
                  className={classes.textField}
                  inputProps={{
                    name: "country",
                    id: "country",
                  }}
                  variant="outlined"
                >
                  {CountryRegionData.map((option, index) => (
                    <MenuItem key={option[0]} value={option}>
                      {option[0]}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="region"
                  name="region"
                  label="Region"
                  value={values.region || ""}
                  select
                  className={classes.textField}
                  onChange={handleChange}
                  variant="outlined"
                >
                  {getRegions(values.country).map((option, index) => (
                    <MenuItem key={option[0]} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="zipcode"
                  name="zipcode"
                  label="Zipcode"
                  placeholder="Zipcode"
                  margin="normal"
                  onChange={handleChange}
                  value={values.zipcode || ""}
                  required
                  className={classes.textField}
                  variant="outlined"
                >
                  <MenuItem>
                    <em>None</em>
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid container>
                {/* Row 2 */}
                <Grid item xs={6} align="left">
                  <FormControl
                    required
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <Typography className={classes.checkBoxTitle}>
                      Dealer Permissions
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="DEALER_ALL"
                            checked={values.DEALER_ALL || false}
                            onChange={handleChangeCheckbox}
                            value="DEALER_ALL"
                          />
                        }
                        label="ALL"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="DEALER_LEADS"
                            checked={values.DEALER_LEADS || false}
                            onChange={handleChangeCheckbox}
                            value="DEALER_LEADS"
                          />
                        }
                        label="LEADS"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            name="DEALER_WARRANTY"
                            checked={values.DEALER_WARRANTY || false}
                            onChange={handleChangeCheckbox}
                            value="DEALER_WARRANTY"
                          />
                        }
                        label="WARRANTY"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="DEALER_FORMS_DATASHEETS"
                            checked={values.DEALER_FORMS_DATASHEETS || false}
                            onChange={handleChangeCheckbox}
                            value="DEALER_FORMS_DATASHEETS"
                          />
                        }
                        label="FORMS & DATA SHEETS"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="DEALER_MEDIA"
                            checked={values.DEALER_MEDIA || false}
                            onChange={handleChangeCheckbox}
                            value="DEALER_MEDIA"
                          />
                        }
                        label="MEDIA"
                      />
                      {/* <FormControlLabel
                        control={
                          <Checkbox
                            name="DEALER_ORDER_BOATS"
                            checked={values.DEALER_ORDER_BOATS}
                            onChange={handleChangeCheckbox}
                            value="DEALER_ORDER_BOATS"
                          />
                        }
                        label="ORDER BOATS"
                      /> */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="DEALER_CSI"
                            checked={values.DEALER_CSI || false}
                            onChange={handleChangeCheckbox}
                            value="DEALER_CSI"
                          />
                        }
                        label="CSI"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            name="DEALER_FACTORY_CONTACTS"
                            checked={values.DEALER_FACTORY_CONTACTS || false}
                            onChange={handleChangeCheckbox}
                            value="DEALER_FACTORY_CONTACTS"
                          />
                        }
                        label="FACTORY CONTACTS"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            name="DEALER_BOAT_SHOWS"
                            checked={values.DEALER_BOAT_SHOWS || false}
                            onChange={handleChangeCheckbox}
                            value="DEALER_BOAT_SHOWS"
                          />
                        }
                        label=" BOAT SHOWS"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            name="DEALER_SKIFF_MERCHANDISE"
                            checked={values.DEALER_SKIFF_MERCHANDISE || false}
                            onChange={handleChangeCheckbox}
                            value="DEALER_SKIFF_MERCHANDISE"
                          />
                        }
                        label="SKIFF MERCHANDISE"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={6} align="left">
                  <FormControl
                    required
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <Typography className={classes.checkBoxTitle}>
                      BRANDS CARRIED
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="has_skiff"
                            checked={values.has_skiff || false}
                            onChange={handleChangeCheckbox}
                            value="has_skiff"
                          />
                        }
                        label="CAROLINA SKIFF"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="has_funchaser"
                            checked={values.has_funchaser || false}
                            onChange={handleChangeCheckbox}
                            value="has_funchaser"
                          />
                        }
                        label="FUN CHASER"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            name="has_seachaser"
                            checked={values.has_seachaser || false}
                            onChange={handleChangeCheckbox}
                            value="has_seachaser"
                          />
                        }
                        label="SEA CHASER"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
              {/* Row 3 */}
              <Grid item xs={12} align="center">
                <Button
                  disabled={isSubmit}
                  variant="contained"
                  color="primary"
                  className={classes.addDealerBtn}
                  type="submit"
                >
                  ADD DEALER
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Mutation>
  );
};

export default AddDealer;
