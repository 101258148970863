import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Tabs, Tab, AppBar, Typography, Box, CircularProgress } from '@material-ui/core';
import StatusTable from "./DealerStatusTable";
import { Dashboard as DashboardLayout } from "layouts";
import './CoOpTabData.scss';
import { Query, Subscription, compose } from "react-apollo";
import { GET_DEALER_CO_OP_REQUESTS_DATA } from "../queries";
import auth from "Auth";
import { withRouter } from "react-router-dom";
import styles from "layouts/Dashboard/styles";
import { GET_USER_INFO } from "queries";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  '.MuiAppBar-root': {
    backgroundColor: "#000"
  }
}));

function CoOpTabData(props) {
  const { history, location } = props;
  const classes = useStyles();

  const key = location?.search?.split("?type=")
  useEffect(() => {
    if (key && key[1]) {
      setValue(key[1] === "new" ? 0 : key[1] === "approved" ? 1 : key[1] === "pending" ? 2 : 3)
    }
  }, [key])


  function findKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  const tabData = {
    0: 'new',
    1: 'approved',
    2: 'pending',
    3: 'denied'
  }

  const [value, setValue] = useState(parseInt(findKeyByValue(tabData, location.search?.replace("?type=", ""))));
  const [queryData, setQueryData] = useState()

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(`/pre-approval-claim?type=${tabData[newValue]}`)
  };

  return (
    <DashboardLayout title="New Request">
      <div className={classes.root + " sectionContainer"}>
        <br></br><br></br>
        <Typography gutterBottom variant="h4" className="title">
          <span>
            {value === 0 ? 'New Requests' : value === 1 ? 'Approved Requests' : value === 2 ? 'Pending Requests' : 'Denied Requests'}
          </span>
        </Typography>
        <Box sx={{ flexGrow: 1 }} style={{ width: "88%", margin: "0px auto", }}>
          <div className={classes.root}>
            <AppBar position="static" className="custom-tab-area">
              <Tabs variant="standard" centered value={value} onChange={handleChange} style={{ backgroundColor: "#23333F" }} aria-label="simple tabs example"> {/*TabIndicatorProps={{style: { backgroundColor: "#000"}}}*/}
                <Tab label="New Requests" {...a11yProps(0)} style={{ marginRight: '2rem' }} md={{ padding: "0px 20px" }} />
                <Tab label="Approved Requests" {...a11yProps(1)} style={{ marginRight: '2rem' }} />
                <Tab label="Pending Requests" {...a11yProps(2)} style={{ marginRight: '2rem' }} />
                <Tab label="Denied Requests" {...a11yProps(3)} style={{ marginRight: '2rem' }} />
              </Tabs>
            </AppBar>
            <Query query={GET_USER_INFO} variables={{ id: auth.getSub() }}>
              {({ data: userData, loading: userLoading, error }) => {
                return (
                  <Subscription subscription={GET_DEALER_CO_OP_REQUESTS_DATA} variables={{ profile_id: auth.getSub(), status: tabData[value] }}>
                    {({ data, loading, error }) => {
                      console.log(error)
                      if (data && data !== queryData) {
                        setQueryData(data?.co_op_requests);
                      } if (loading || !queryData || userLoading)
                        return (
                          <div
                            className="flex center"
                            style={{ padding: "2em 0" }}
                          >
                            <CircularProgress color="secondary" />
                          </div>
                        );
                      if (error) return <p>Error Loading Advertising Claim Forms</p>;
                      return (
                        <>
                          <TabPanel value={value} index={0}>
                            <StatusTable queryData={queryData} userName={userData?.profiles_by_pk?.name} />
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                            <StatusTable queryData={queryData} userName={userData?.profiles_by_pk?.name} />
                          </TabPanel>
                          <TabPanel value={value} index={2}>
                            <StatusTable queryData={queryData} userName={userData?.profiles_by_pk?.name} />
                          </TabPanel>
                          <TabPanel value={value} index={3}>
                            <StatusTable queryData={queryData} userName={userData?.profiles_by_pk?.name} />
                          </TabPanel>
                        </>
                      );
                    }}
                  </Subscription>
                )
              }}
            </Query>
          </div>
        </Box>
      </div>
    </DashboardLayout>

  );
}

export default compose(withRouter, withStyles(styles))(CoOpTabData);
