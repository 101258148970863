import React from "react";
import { Query, Subscription } from "react-apollo";
import { getAllSurveys } from "../queries";
import { getAllDealerRatings } from "../queries";
import Paper from "@material-ui/core/Paper";
import { List, ListItem, ListItemText } from "@material-ui/core";
import PropTypes from "prop-types";
import { Print } from "@material-ui/icons";
import {
  SelectionState,
  RowDetailState,
  PagingState,
  IntegratedPaging,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
import { withStyles, Typography } from "@material-ui/core";
// Component styles
import styles from "theme/skin";
import auth from "Auth";

const ratingLabels = {
  1: "Extremely Dissatisfied",
  2: "Satisfied",
  3: "Somewhat Satisfied",
  4: "Extremely Satisfied",
};

const yesNoLabels = {
  0: "No",
  10: "Yes",
};

const RowDetail = ({ row }) => (
  <div>
    <Typography gutterBottom variant="h4" className="title">
      <span>About You</span>
    </Typography>
    <table cellSpacing="0" className="detailTable">
      <tbody>
        <tr>
          <td>Is this purchase your first new boat?</td>
          <td>{row.first_new_boat}</td>
        </tr>
        <tr>
          <td>Did you consider any other brands in your shopping process?</td>
          <td>
            {row.consider_brands} {row.consider_brands_yes}
          </td>
        </tr>
        <tr>
          <td>When did you decide this was the right boat for you?</td>
          <td>{row.when_right_boat}</td>
        </tr>
        <tr>
          <td>Where did you first see this boat?</td>
          <td>{row.first_see_boat}</td>
        </tr>
        <tr>
          <td>What was the deciding factor for choosing our brand?</td>
          <td>{row.deciding_factor}</td>
        </tr>
        <tr>
          <td>How many people will you normally have on your boat?</td>
          <td>{row.people_on_boat}</td>
        </tr>
        <tr>
          <td>How will you use your new boat most of the time?</td>
          <td>{row.use_new_boat}</td>
        </tr>
        <tr>
          <td>How many times per month do you plan to use your new boat?</td>
          <td>{row.plan_to_use_new_boat}</td>
        </tr>
        <tr>
          <td>What water type is your primary usage?</td>
          <td>{row.water_type_usage}</td>
        </tr>
        <tr>
          <td>Do you currently own another boat?</td>
          <td>{row.own_another_boat}</td>
        </tr>
      </tbody>
    </table>
    <Typography gutterBottom variant="h4" className="title">
      <span>About Your Dealer</span>
    </Typography>
    <table cellSpacing="0" className="detailTable">
      <tbody>
        <tr>
          <td>How was your overall sales experience with your dealer?</td>
          <td>{ratingLabels[row.sales_experience]}</td>
        </tr>
        <tr>
          <td>How likely are you to refer your selling dealer?</td>
          <td>{ratingLabels[row.refer_selling_dealer]}</td>
        </tr>
        <tr>
          <td>
            How satisfied were you with the overall model selection/inventory at
            your selling dealer?
          </td>
          <td>{ratingLabels[row.satisfied_model_selection]}</td>
        </tr>
        <tr>
          <td>
            How satisfied were you with the overall professionalism of your
            dealer?
          </td>
          <td>{ratingLabels[row.satisfied_professionalism]}</td>
        </tr>
        <tr>
          <td>
            How satisfied were you with the overall cleanliness of the
            dealership?
          </td>
          <td>{ratingLabels[row.satisfied_cleanliness]}</td>
        </tr>
        <tr>
          <td>
            How well do you think the dealer explained the documents and
            information you signed?
          </td>
          <td>{ratingLabels[row.dealer_explanation]}</td>
        </tr>
        <tr>
          <td>
            How satisfied were you with the time it took to complete your
            purchase?
          </td>
          <td>{ratingLabels[row.time_complete_purchase]}</td>
        </tr>
        <tr>
          <td>
            How likely are you to have your new boat serviced with your selling
            dealer?
          </td>
          <td>{ratingLabels[row.new_boat_serviced]}</td>
        </tr>
        <tr>
          <td>
            How satisfied were you with the dealer’s final delivery process?
          </td>
          <td>{ratingLabels[row.delivery_process]}</td>
        </tr>
        <tr>
          <td>
            Did the dealer provide an explanation of features and benefits with
            a demo how to use them?
          </td>
          <td>{yesNoLabels[row.features_explanation]}</td>
        </tr>
        <tr>
          <td>
            Did the dealer provide you with a thorough tour of their dealership?
          </td>
          <td>{yesNoLabels[row.dealership_tour]}</td>
        </tr>
        <tr>
          <td>Did the dealer introduce you to their Parts Manager?</td>
          <td>{yesNoLabels[row.parts_manager]}</td>
        </tr>
        <tr>
          <td>Did the dealer introduce you to their Service Manager?</td>
          <td>{yesNoLabels[row.service_manager]}</td>
        </tr>
        <tr>
          <td>
            Did the dealer answer all your questions regarding your new boat?
          </td>
          <td>{yesNoLabels[row.answer_questions]}</td>
        </tr>
        <tr>
          <td>Was the dealer knowledgeable about your boat?</td>
          <td>{yesNoLabels[row.knowledgeable]}</td>
        </tr>
        <tr>
          <td>Were you offered a sea trial?</td>
          <td>{yesNoLabels[row.sea_trial]}</td>
        </tr>
        <tr>
          <td>Were you offered an on-water delivery?</td>
          <td>{yesNoLabels[row.on_water_delivery]}</td>
        </tr>
        <tr>
          <td>Did the dealer thoroughly explain the warranty?</td>
          <td>{yesNoLabels[row.dealer_warranty]}</td>
        </tr>
        <tr>
          <td>Did the dealer explain the 90-day free Sea Tow trial?</td>
          <td>{yesNoLabels[row.explain_sea_trial]}</td>
        </tr>
        <tr>
          <td>
            Have you received a follow-up or thank you from the selling dealer
            since your purchase?
          </td>
          <td>{yesNoLabels[row.follow_up]}</td>
        </tr>
        <tr>
          <td>Overall satisfaction with boat</td>
          <td>{ratingLabels[row.satisfied_boat_overall]}</td>
        </tr>
        <tr>
          <td>Satisfaction with boat fit and finish</td>
          <td>{ratingLabels[row.satisfied_boat_finish]}</td>
        </tr>
        <tr>
          <td>Satisfaction with boat performance</td>
          <td>{ratingLabels[row.satisfied_boat_performance]}</td>
        </tr>
        <tr>
          <td>
            Satisfaction with sales price or deal offered for boat package...
          </td>
          <td>{ratingLabels[row.satisfied_boat_price]}</td>
        </tr>
      </tbody>
    </table>
    <Typography gutterBottom variant="h4" className="title">
      <span>Comments</span>
    </Typography>
    <table cellSpacing="0" className="detailTable">
      <tbody>
        <tr>
          <td>{row.comments}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

class Csi extends React.PureComponent {
  constructor(props) {
    super(props);

    function getSurveyScore(row) {
      const satisfactionRatings = [
        row.sales_experience,
        row.refer_selling_dealer,
        row.satisfied_model_selection,
        row.satisfied_professionalism,
        row.satisfied_cleanliness,
        row.dealer_explanation,
        row.time_complete_purchase,
        row.new_boat_serviced,
        row.delivery_process,
        row.satisfied_boat_overall,
        row.satisfied_boat_finish,
        row.satisfied_boat_performance,
        row.satisfied_boat_price,
      ];

      const yesNoRatings = [
        row.features_explanation,
        row.dealership_tour,
        row.parts_manager,
        row.service_manager,
        row.answer_questions,
        row.knowledgeable,
        row.sea_trial,
        row.on_water_delivery,
        row.dealer_warranty,
        row.explain_sea_trial,
        row.follow_up,
      ];

      const maxScore =
        satisfactionRatings.length * 4 + yesNoRatings.length * 10;

      const satisfactionScore = satisfactionRatings.reduce(
        (acc, current) => acc + current
      );
      const yesNoScore = yesNoRatings.reduce((acc, current) => acc + current);

      let sum = satisfactionScore + yesNoScore;
      let totalScore = (sum / maxScore) * 100;
      let totalScoreRounded = Math.round(totalScore * 100) / 100;

      return `${totalScoreRounded}%`;
    }

    this.state = {
      columns: [
        { name: "survey_date", title: "Date" },
        {
          name: "name",
          title: "Name",
          getCellValue: (row) =>
            row.first_name + row.last_name
              ? row.first_name + " " + row.last_name
              : undefined,
        },
        { name: "dealer_name", title: "Dealer Name" },
        {
          name: "survey_ratings",
          title: "Score",
          getCellValue: (row) => getSurveyScore(row),
        },
      ],
    };
    this.setSelectionValue = (value) => this.setState({ selection: value });
  }

  render() {
    const { columns, selection } = this.state;
    const { classes } = this.props;
    function formatValue(item) {
      return item.rating;
    }
    return (
      <DashboardLayout title="CSI">
        <Subscription subscription={getAllDealerRatings}>
          {({ data, loading, error }) => {
            console.log(
              error,
              data?.survey_rating_aggregate,
              auth.getSub(),
              auth.getRole()
            );
            if (loading) return <p>Loading...</p>;
            if (error) return <p>Error</p>;

            return (
              <div className={classes.root + " sectionContainer"}>
                <Typography gutterBottom variant="h4" className="title">
                  <span>Dealer Ratings</span>
                </Typography>
                <Paper className="tableStyles">
                  <List className="dashBoardList">
                    <ListItem alignItems="center">
                      <ListItemText
                        className={classes.label}
                        primary="Your Current Dealer Rating is:"
                      />
                      {auth.getRole() === "dealer"
                        ? !loading && data?.survey_rating_aggregate?.length > 0
                          ? `${
                              data?.survey_rating_aggregate.find(
                                (i) => i.profile_id === auth.getSub()
                              )?.rating
                            } %` || "0%"
                          : "No Rating Available %"
                        : !loading && data?.survey_rating_aggregate?.length > 0
                        ? data.survey_rating_aggregate.map(formatValue)
                        : "No Rating Available %"}
                    </ListItem>
                  </List>
                </Paper>
              </div>
            );
          }}
        </Subscription>
        <Query query={getAllSurveys}>
          {({ data, loading, error }) => {
            if (loading) return <p>Loading...</p>;
            if (error) return <p>Error</p>;
            return (
              <div className={classes.root + " sectionContainer"}>
                <Typography gutterBottom variant="h4" className="title">
                  <span>New Surveys</span>
                </Typography>

                <Paper className="tableStyles">
                  <div className={classes.header}>
                    <span>
                      Total rows selected: {selection ? selection.length : "0"}
                    </span>
                    {/* <a href="javascript:window.print()">
                      <Print className={classes.printIcon} />
                    </a> */}
                  </div>
                  <Grid rows={data.survey} columns={columns}>
                    <SelectionState
                      selection={selection}
                      onSelectionChange={this.setSelectionValue}
                    />
                    <PagingState defaultCurrentPage={0} pageSize={5} />
                    <IntegratedSelection />
                    <IntegratedPaging />
                    <RowDetailState defaultExpandedRowIds={[0]} />
                    <Table />
                    <TableHeaderRow />
                    <TableRowDetail contentComponent={RowDetail} />
                    <TableSelection showSelectAll />
                    <PagingPanel />
                  </Grid>
                </Paper>
              </div>
            );
          }}
        </Query>
      </DashboardLayout>
    );
  }
}
Csi.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Csi);
