import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";

export default class SidebarLink extends React.Component {
  render() {
    const style = {
      padding: "2px 15px",
      marginLeft: "40px"
    };
    return (
      <ListItem
        button
        component={Link}
        to={this.props.linkDestination}
        className={this.props.nested}
        style={style}
      >
        <ListItemText className="navLinks" primary={this.props.linkText} />
      </ListItem>
    );
  }
}
