import React from "react";
import "./PdfDocumentStyles.scss";

export const BoatShowCreditPdfDocument = ({ data }) => {
  return (
    <div className="document">
      <div className="page">
        <div className="sectionHeader">Boat Show Credit Form</div>
        <div className="rowContainer">
          <div className="row">
            <div className="column">
              <p className="field_text">
                Dealer Name: {data?.dealer_name || ""}
              </p>
              <p className="field_text">
                Contact Name: {data?.contact_name || ""}
              </p>
              <p className="field_text">Contact Phone: {data?.phone || ""}</p>
              <p className="field_text">Fax Number: {data?.fax || ""}</p>
              <p className="field_text">Address: {data?.address || ""}</p>
              <p className="field_text">Address 2: {data?.address2 || ""}</p>
              <p className="field_text">City: {data?.city || ""}</p>
              <p className="field_text">State: {data?.state || ""}</p>
              <p className="field_text">Country: {data?.country || ""} </p>
              <p className="field_text">Zip Code: {data?.zipcode || ""}</p>
              <p className="field_text">
                Booth Size: {data?.booth_size ? `$${data?.total_cost}` : ""}
              </p>
              <p className="field_text">
                % Devoted: {data?.devoted ? `${data?.devoted}%` : ""}
              </p>
              <p className="field_text">
                $ Booth Cost:{" "}
                {data?.booth_cost ? `$${data?.booth_cost}` : ""}
              </p>
              <p className="field_text">
                $ Devoted Cost:{" "}
                {data?.devoted_cost ? `$${data?.devoted_cost}` : ""}
              </p>
              <p
                className="field_text"
                style={{ wordBreak: "break-all", width: "40vw" }}
              >
                Additional Information: {data?.additional_info || ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
