import { useState, useEffect } from "react";
const useForm = (callback, comments) => {
  const [values, setValues] = useState({
    comments: comments
  });
  //const [errors, setErrors] = useState({});
  //const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = event => {
    if (event) event.preventDefault();
    callback();
  };

  const handleChange = (event, text) => {
    event.persist();

    let formattedPhoneNumber = "";
    if (text === "phone") {
      const numericInput = event.target.value.replace(/\D/g, "");
      if (numericInput.length === 10) {
        formattedPhoneNumber = numericInput.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      } else if (numericInput.length === 11) {
        formattedPhoneNumber = numericInput.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4");
      } else {
        formattedPhoneNumber = numericInput;
      }
    } else {
      formattedPhoneNumber = event.target.value
    }


    setValues(values => ({
      ...values,
      [event.target.name]: formattedPhoneNumber
    }));
    //console.log("UseForm")
    //console.log(values);
  };

  const handleChangeCheckbox = event => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.checked
    }));
    //console.log(values);
  };

  const resetState = event => {
    setValues({});
  };

  const updateValues = customValues => {
    setValues(customValues);
  };

  return {
    handleChange,
    handleChangeCheckbox,
    handleSubmit,
    values,
    resetState,
    setValues
  };
};

export default useForm;
