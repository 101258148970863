import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Typography, Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Mutation } from "react-apollo";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {ADD_BOAT_MODEL} from "../../../queries";

const useStyles = makeStyles(theme => ({
  container: {
    padding: "2em"
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100%",
    margin: "1em",
    textAlign: "center"
  },
  dense: {
    marginTop: 19
  },
  formControl: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  addBoatModel: {
    fontSize: "18px",
    fontWeight: "300",
    textTransform: "uppercase",
    textAlign: "center",
  }
}));

const AddBoatModel = props => {
  const { values, handleChange, resetState, handleChangeCheckbox } = useForm(
    submitForm
  );
  const classes = useStyles();

  function submitForm() {
  }

  function completedAdd() {
    toast("New boat model has been created successfully");
    props.refresh();
    resetState();
  }

  return (
    <Paper>
    <Mutation mutation={ADD_BOAT_MODEL} onCompleted={completedAdd}>
      {(addBoatModel, { data, loading, called, error }) => {
        if (error)
          return (
            <pre>
              Bad:{" "}
              {error.graphQLErrors.map(({ message }, i) => (
                <span key={i}>{message}</span>
              ))}
            </pre>
          );
        return (
          <form
            onSubmit={e => {
              let formData = { ...values };
              e.preventDefault();
              addBoatModel({
                variables: {
                  objects: [formData]
                }
              });
              return false;
            }}
            className={classes.container}
            autoComplete="off"
          >
            <Typography gutterBottom variant="h2" className={classes.addBoatModel}>
              Add Boat Model
            </Typography>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <TextField
                  required
                  variant="outlined"
                  name="model"
                  label="Model"
                  onChange={handleChange}
                  value={values.model}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <FormControlLabel
                    control={
                      <Checkbox
                          name="older_model"
                          checked={values.older_model || false}
                          onChange={handleChangeCheckbox}
                          value="true"
                      />
                    }
                    label="Older&nbsp;Model"
                />
              </Grid>
              <Grid container>
                <Grid item xs={12} align="center">
                  <InputLabel
                    id="errorMsgs"
                    value={error}
                    width="100%"
                    ref={error}
                  ></InputLabel>
                </Grid>
              </Grid>
              <Grid item xs={12} align="center" className={classes.dense}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addBoatModel}
                  type="submit"
                >
                  ADD BOAT MODEL
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Mutation>
    </Paper>
  );
};

export default AddBoatModel;