import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Mutation, Query } from "react-apollo";
import { gql } from "apollo-boost";
import { Button, Tooltip } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import auth from "../../Auth";
import PropTypes from "prop-types";
import {
  CountryRegionData,
  CountryDropdown,
  RegionDropdown,
} from "react-country-region-selector";
import { setRegion } from "react-geocode";
import { getAllDealerOptions } from "queries";
import InfoIcon from "@material-ui/icons/Info";
import "./BoatShowEdit.scss";

const styles = (theme) => ({
  button: {
    margin: 8,
  },
  // buttonCancel: {
  //   backgroundColor: '#000',
  // },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    textAlign: "center",
    margin: "auto",
    borderBottom: "none",
  },
  dense: {
    marginTop: 19,
  },
  customSelect: {
    "& .MuiSelect-select": {
      borderBottom: "none",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "white",
    },
  },
  formControl: {
    margin: theme.spacing(3),
  },
  removeBorderBottom: {
    borderBottom: "none !important",
  },
  whiteBackgroundOnFocus: {
    "&:focus": {
      backgroundColor: "white",
    },
  },
  textColor: {
    color: "red",
    fontSize: "12px",
    fontWeight: 500,
  },
});

const UPDATE_BOAT_SHOWS = gql`
  mutation updateBoatShows(
    $id: uuid!
    $dealer: String
    $name: String
    $start_date: date
    $end_date: date
    $address: String
    $address2: String
    $country: String
    $city: String
    $zipcode: String
    $region: String
    $description: String
    $site: String
  ) {
    update_boat_shows(
      where: { id: { _eq: $id } }
      _set: {
        dealer: $dealer
        name: $name
        start_date: $start_date
        end_date: $end_date
        address: $address
        address2: $address2
        country: $country
        city: $city
        zipcode: $zipcode
        region: $region
        description: $description
        site: $site
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const BoatShowsEdit = (props) => {
  const message = "";
  const { classes, boatShows, closeEditForm } = props;
  const { values, handleChange, resetState, setValues } = useForm(submitForm);

  const [dealer, setDealer] = useState(boatShows.dealer);
  const [name, setName] = useState(boatShows.name);
  const [start_date, setStartDate] = useState(boatShows.start_date);
  const [end_date, setEndDate] = useState(boatShows.end_date);
  const [address, setAddress] = useState(boatShows.address);
  const [address2, setAddress2] = useState(boatShows.address2);
  const [country, setCountry] = useState(boatShows.country);
  const [region, setRegion] = useState(boatShows.region);
  const [zipcode, setZipcode] = useState(boatShows.zipcode);
  const [city, setCity] = useState(boatShows.city);
  const [description, setDescription] = useState(boatShows.description);
  const [site, setSite] = useState(boatShows.site);

  const [formState, setFormState] = useState({
    site: boatShows.site.split(","),
  });

  function submitForm() {
    //console.log(values);
  }
  function completedAdd(payload) {
    toast("Boat Show Successfully Updated");
    //resetState();
  }

  const selectRegion = (region) => setRegion(region);
  const selectCountry = (country) => setCountry(country);

  return (
    <Query query={getAllDealerOptions}>
      {({ data: dealerData, loading, error }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error</p>;
        return (
          <Mutation mutation={UPDATE_BOAT_SHOWS} onCompleted={completedAdd}>
            {(boatShowsEdit, { data }) => {
              if (message) {
                return <div> {message} </div>;
              } else {
                return (
                  <div style={{ textAlign: "center" }}>
                    {values.title !== undefined
                      ? setValues(values)
                      : setValues(boatShows)}
                    <form
                      onSubmit={(e) => {
                        values.profile_id = auth.getSub();
                        e.preventDefault();
                        boatShowsEdit({
                          variables: {
                            id: boatShows.id,
                            dealer,
                            name,
                            start_date,
                            end_date,
                            address,
                            address2,
                            country,
                            city,
                            zipcode,
                            region,
                            description,
                            site,
                          },
                        });
                        return false;
                      }}
                      autoComplete="off"
                    >
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Typography
                          gutterBottom
                          variant="h2"
                          style={{
                            fontSize: "20px",
                            fontWeight: "300",
                            textTransform: "uppercase",
                            paddingBottom: "1em",
                          }}
                        >
                          {auth.getRole().toLowerCase() === "admin" &&
                            "Update Boat Show"}
                        </Typography>
                        <Grid container>
                          {/* Row 1 */}
                          <Grid item xs={12} lg={4} align="left">
                            <TextField
                              required
                              name="dealer"
                              label="Dealer"
                              placeholder="Dealer"
                              select
                              className={
                                classes.textField + " " + classes.borderNone
                              }
                              classes={{ root: classes.customSelect }}
                              onChange={(e) => setDealer(e.target.value)}
                              value={dealer}
                              style={{ width: "100%", borderBottom: "none" }}
                              SelectProps={{
                                native: true,
                                classes: {
                                  select: classes.removeBorderBottom,
                                  selectMenu: classes.whiteBackgroundOnFocus,
                                },
                              }}
                              margin="normal"
                            >
                              {dealerData?.profiles?.map((item) => {
                                return (
                                  <option
                                    className={classes.borderNone}
                                    key={item?.profile_id}
                                    value={item?.company}
                                  >
                                    {item?.company}
                                  </option>
                                );
                              })}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} lg={4} align="left">
                            <TextField
                              id="name"
                              name="name"
                              label="Name of Show"
                              placeholder="Name of Show"
                              className={classes.textField}
                              InputLabelProps={{ shrink: true }}
                              margin="normal"
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} lg={4} align="left">
                            <TextField
                              id="start_date"
                              name="start_date"
                              label="Start Date"
                              placeholder="Start Date"
                              type="date"
                              className={classes.textField}
                              margin="normal"
                              onChange={(e) => setStartDate(e.target.value)}
                              value={start_date}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} lg={4} align="left">
                            <TextField
                              id="end_date"
                              name="end_date"
                              label="End Date"
                              placeholder="End Date"
                              type="date"
                              className={classes.textField}
                              margin="normal"
                              onChange={(e) => setEndDate(e.target.value)}
                              value={end_date}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required
                            />
                          </Grid>
                        </Grid>
                        <Grid container>
                          {/* Row 2 */}
                          <Grid item xs={12} lg={4} align="left">
                            <TextField
                              id="address"
                              name="address"
                              label="Address"
                              className={classes.textField}
                              margin="normal"
                              onChange={(e) => setAddress(e.target.value)}
                              value={address}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} lg={4} align="left">
                            <TextField
                              id="address2"
                              name="address2"
                              label="Address2"
                              className={classes.textField}
                              margin="normal"
                              onChange={(e) => setAddress2(e.target.value)}
                              value={address2}
                            />
                          </Grid>
                          <Grid item xs={12} lg={4} align="left">
                            <CountryDropdown
                              showDefaultOption={false}
                              value={country}
                              onChange={(e) => selectCountry(e)}
                            />
                          </Grid>
                        </Grid>
                        <Grid container>
                          {/* Row 3 */}
                          <Grid item xs={12} lg={4} align="left">
                            <TextField
                              id="city"
                              name="city"
                              label="City"
                              className={classes.textField}
                              margin="normal"
                              onChange={(e) => setCity(e.target.value)}
                              value={city}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} lg={4} align="left">
                            <TextField
                              id="zipcode"
                              name="zipcode"
                              label="Zipcode"
                              className={classes.textField}
                              margin="normal"
                              onChange={(e) => setZipcode(e.target.value)}
                              value={zipcode}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} lg={4} align="left">
                            <RegionDropdown
                              showDefaultOption={false}
                              country={country}
                              value={region}
                              defaultOptionLabel={"State/Region"}
                              onChange={selectRegion}
                            />
                          </Grid>
                        </Grid>
                        {/* Row 4 */}
                        <Grid container>
                          <Grid item xs={12} align="center">
                            <TextField
                              id="description"
                              name="description"
                              label="Boat Event Description"
                              style={{ margin: 8, width: "100%" }}
                              placeholder="Boat Event Description"
                              helperText="Max 700 words"
                              margin="normal"
                              onChange={(e) => setDescription(e.target.value)}
                              value={description}
                              required
                              multiline
                              rows="4"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>
                        <div id="boat-show-edit-footer">
                          <Grid
                            container
                            style={{
                              marginBottom: "1em",
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              align="center"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "5px",
                                marginBottom: "1em",
                              }}
                            >
                              <TextField
                                name="site"
                                id="site"
                                select
                                label="Select Site"
                                style={{ width: "350px", border: "none" }}
                                className={
                                  classes.textField +
                                  " " +
                                  classes.prefixDropdown
                                }
                                onChange={(e) => setSite(e.target.value)}
                                value={site}
                                variant="outlined"
                                margin="normal"
                                required
                              >
                                <MenuItem value="CarolinaSkiff">
                                  Carolina Skiff
                                </MenuItem>
                                <MenuItem value="SeaChaser">
                                  Sea Chaser
                                </MenuItem>
                                <MenuItem value="CarolinaSkiff,SeaChaser">
                                  CarolinaSkiff/ SeaChaser
                                </MenuItem>
                              </TextField>
                              {/* <Tooltip style={{ color: "#404040" }} title="Please select both the sites if you want to display the boat show on both of them" arrow>
                              <InfoIcon />
                            </Tooltip> */}
                              {/* <p className={classes.textColor}>Please select both the sites if you want to display the boat show on both of them</p> */}
                            </Grid>
                          </Grid>
                        </div>
                        <Grid item xs={12} align="center">
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.buttonCancel}
                            onClick={closeEditForm}
                            type="button"
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            type="submit"
                          >
                            Update Boat Show
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                );
              }
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

BoatShowsEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(BoatShowsEdit);
