import React, { useState } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import {
  IntegratedPaging,
  PagingState,
  RowDetailState,
} from "@devexpress/dx-react-grid";
import "./../CoOpTabs/RequestRowDetail.scss";
import { FormTypeEnum, StatusEnum } from "pages/FormsDatasheets/enum";
import { MediaPreApprovalFormRequestRowDetails } from "../../NewRequest/MediaPreApprovalFormRequestRowDetails";
import AdvertisingClaimFormDetail from "../AdvertisingClaimForm/AdvertisingClaimFormDetail";
import { SpecialProjectFormRequestRowDetail } from "../../NewRequest/SpecialProjectFormRequestRowDetail";
import { CommericalSalesCreditClaimRequestRowDetail } from "../../NewRequest/CommericalSalesCreditClaimRequestRowDetail";
import { BoatShowCreditRowDetail } from "../../NewRequest/BoatShowCreditRowDetails";
import moment from "moment";

const DealerStatusTable = ({ queryData, userName }) => {
  const [expandedRowIds, setExpandedRowIds] = useState([0]);

  const columns = [
    {
      name: "date_submitted",
      title: "Submitted By",
      getCellValue: (row) =>
        moment(row?.date_submitted, "DD/MM/YYYY").format("MM/DD/YYYY"),
    },
    {
      name: "form_type",
      title: "Form",
      getCellValue: (row) => FormTypeEnum[row?.form_type] || "",
    },
    {
      name: "email",
      title: "Submitted By",
      getCellValue: (row) => row?.profile?.email || "",
    },
    { name: "status", title: "Status" },
  ];

  const LastColumnCell = ({ column, value }) => (
    <Table.Cell
      style={
        column.name === "status"
          ? { color: StatusEnum[value]?.color, fontWeight: "bold" }
          : {}
      }
    >
      {column.name === "status" ? StatusEnum[value]?.label : value}
    </Table.Cell>
  );

  const modifiedQueryData = queryData.map((item) => {
    return {
      ...item,
      submitted_by: item.profile?.email ?? "",
      date_submitted: new Date(item?.date_submitted).toLocaleDateString(),
    };
  });

  const RowDetail = ({ row }) => {
    if (queryData) {
      const findData = queryData?.find((rows) => rows?.id === row?.id);
      const comments = findData?.co_op_comments;
      if (findData?.form_type === "media-pre-approval") {
        const rowData = findData?.media_pre_approval_forms?.[0];
        return (
          <div>
            <MediaPreApprovalFormRequestRowDetails
              row={rowData}
              type="dealer"
              co_op_id={row?.id}
              isEdit={false}
              comments={comments}
              userName={userName}
            />
          </div>
        );
      }
      if (findData?.form_type === "co-op-advertising-claim") {
        const rowData = findData?.advertising_claim_forms?.[0];
        return (
          <div>
            <AdvertisingClaimFormDetail
              isEdit={false}
              row={rowData}
              type="dealer"
              co_op_id={row?.id}
              requirementDataArray={
                rowData?.advertising_claim_form_data_requirements
              }
              comments={comments}
              userName={userName}
            />
          </div>
        );
      }
      if (findData?.form_type === "special-project-request") {
        const rowData = findData?.special_project_forms?.[0];
        return (
          <div>
            <SpecialProjectFormRequestRowDetail
              isEdit={false}
              row={rowData}
              type="dealer"
              co_op_id={row?.id}
              comments={comments}
              userName={userName}
            />
          </div>
        );
      }
      if (findData?.form_type === "govt-com-sales-credit-claim") {
        const rowData =
          findData?.government_commerical_sales_credit_claims?.[0];
        return (
          <div>
            <CommericalSalesCreditClaimRequestRowDetail
              isEdit={false}
              row={rowData}
              type="dealer"
              co_op_id={row?.id}
              requirementDataArray={
                rowData?.government_commerical_sales_credit_claim_data
              }
              comments={comments}
              userName={userName}
            />
          </div>
        );
      }
      if (findData?.form_type === "boat-show-credit") {
        const rowData = findData?.boat_show_credits?.[0];
        return (
          <div>
            <BoatShowCreditRowDetail
              isEdit={false}
              row={rowData}
              type="dealer"
              co_op_id={row?.id}
              requirementDataArray={rowData?.boat_show_credits}
              comments={comments}
              userName={userName}
            />
          </div>
        );
      }
      return <div>Row details not available.</div>;
    }
  };

  const toggleRowExpand = (rowId) => {
    setExpandedRowIds((prevState) =>
      prevState.includes(rowId)
        ? prevState.filter((id) => id !== rowId)
        : [...prevState, rowId]
    );
  };

  const TableRow = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => toggleRowExpand(tableRow.rowId)}
      style={{ cursor: "pointer" }}
    />
  );

  return (
    <div className="request-table-pg custom-table-event">
      <Grid rows={modifiedQueryData} columns={columns}>
        <RowDetailState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={setExpandedRowIds}
        />
        <PagingState defaultCurrentPage={0} pageSize={10} />
        <IntegratedPaging />
        <Table
          cellComponent={(props) => <LastColumnCell {...props} />}
          rowComponent={TableRow}
        />
        <TableHeaderRow />
        <PagingPanel />
        <TableRowDetail contentComponent={RowDetail} />
      </Grid>
    </div>
  );
};

export default DealerStatusTable;
