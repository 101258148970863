import React from 'react'
import { 
    Button
  } from '@material-ui/core';

const styles = {
      button: {
        margin: 8,
        backgroundColor: "red",
        color: "white"
      }
    };
const WarrantyClaimDenyButton = props => {
  return <Button color="secondary" variant="contained" onClick={props.addClick} style={styles.button}>Deny</Button>
}

export default WarrantyClaimDenyButton