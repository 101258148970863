import React, { useState } from "react";
import { Mutation, Query } from "react-apollo";
import {
  ADD_CO_OP_COMMENTS,
  ADMIN_UPDATE_CO_OP_REQUEST_STATUS,
  UPDATE_ADVERTISING_CLAIM_FORM_STATUS,
  UPDATE_GOVT_CLAIM_FORM_STATUS,
  UPDATE_MEDIA_PRE_APPROVAL_FORM_STATUS,
  UPDATE_SPECIAL_PROJECT_FORM_STATUS,
} from "./queries";
import Button from "@material-ui/core/Button";
import { Fade, Grid, makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import CommentBox from "./CommentBox";
import auth from "Auth";
import { GET_USER_INFO } from "queries";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    maxHeight: "95%",
    overflow: "scroll",
    top: "3.5% !important",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #707070",
    boxShadow: "0px 3px 6px 0px #00000029",
    padding: theme.spacing(4),
    width: "768px",
    maxWidth: "90%",
  },
  SuccessButton: {
    backgroundColor: "#3B8010",
    color: "#fff",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#5D9930",
    },
  },
  WarningButton: {
    backgroundColor: "#E4C843",
    color: "#fff",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#FFD300",
    },
  },
  DangerButton: {
    backgroundColor: "#C34F3B",
    color: "#fff",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#D63013",
    },
  },
}));

export default function AdminStatusButtons({ co_op_id, id, type, statusType }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openPending, setOpenPending] = useState(false);
  const [openDeny, setOpenDeny] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [text, setText] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenPending = () => {
    setOpenPending(true);
  };

  const handleOpenDeny = () => {
    setOpenDeny(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDeny = () => {
    setOpenDeny(false);
  };

  const handleClosePending = () => {
    setOpenPending(false);
  };

  return (
    <div>
      {statusType === "approved" || statusType === "denied" ? null : (
        <Query query={GET_USER_INFO} variables={{ id: auth.getSub() }}>
          {({ data: userData, loading, error }) => {
            return (
              <Mutation mutation={ADMIN_UPDATE_CO_OP_REQUEST_STATUS}>
                {(
                  updateCoOpRequestStatus,
                  { data, loading, called, error }
                ) => {
                  if (error)
                    return (
                      <pre>
                        Bad:{" "}
                        {error.graphQLErrors.map(({ message }, i) => (
                          <span key={i}>{message}</span>
                        ))}
                      </pre>
                    );
                  return (
                    <Mutation
                      mutation={
                        type === "co-op-advertising-claim"
                          ? UPDATE_ADVERTISING_CLAIM_FORM_STATUS
                          : type === "media-pre-approval"
                          ? UPDATE_MEDIA_PRE_APPROVAL_FORM_STATUS
                          : type === "special-project-request"
                          ? UPDATE_SPECIAL_PROJECT_FORM_STATUS
                          : UPDATE_GOVT_CLAIM_FORM_STATUS
                      }
                    >
                      {(updateForm, { data, loading, called, error }) => {
                        if (error)
                          return (
                            <pre>
                              Bad:{" "}
                              {error.graphQLErrors.map(({ message }, i) => (
                                <span key={i}>{message}</span>
                              ))}
                            </pre>
                          );
                        return (
                          <Mutation mutation={ADD_CO_OP_COMMENTS}>
                            {(
                              appCoOpComment,
                              { data, loading, called, error }
                            ) => {
                              if (error)
                                return (
                                  <pre>
                                    Bad:{" "}
                                    {error.graphQLErrors.map(
                                      ({ message }, i) => (
                                        <span key={i}>{message}</span>
                                      )
                                    )}
                                  </pre>
                                );
                              return (
                                <Grid item xs={12} align="center">
                                  <div className="modals-area">
                                    <Button
                                      color="success"
                                      variant="contained"
                                      className={classes.SuccessButton}
                                      type="submit"
                                      onClick={handleOpen}
                                      style={{
                                        width: "15em",
                                        margin: "2em 1em",
                                      }}
                                    >
                                      APPROVE
                                    </Button>
                                    <Modal
                                      aria-labelledby="transition-modal-title"
                                      aria-describedby="transition-modal-description"
                                      className={classes.modal}
                                      open={open}
                                      onClose={handleClose}
                                      closeAfterTransition
                                      BackdropComponent={Backdrop}
                                      BackdropProps={{
                                        timeout: 500,
                                      }}
                                    >
                                      <Fade in={open}>
                                        <div className={classes.paper}>
                                          <div className="customModalBody">
                                            <div className="border-heading-section">
                                              <h5 className="mt-0">
                                                CO-OP PRE APPROVAL CLAIM
                                              </h5>
                                            </div>
                                            <h2 className="modal-title">
                                              APPROVAL COMMENTS
                                            </h2>
                                            <div className="disc-borderbox">
                                              <CommentBox
                                                text={text}
                                                setText={setText}
                                              />
                                            </div>

                                            <Button
                                              color="secondary"
                                              variant="contained"
                                              className={classes.SuccessButton}
                                              disabled={isDisable}
                                              onClick={() => {
                                                setIsDisable(true);
                                                updateCoOpRequestStatus({
                                                  variables: {
                                                    id: co_op_id,
                                                    status: "approved",
                                                  },
                                                }).then(() => {
                                                  updateForm({
                                                    variables: {
                                                      id,
                                                      status: "approved",
                                                    },
                                                  }).then(() => {
                                                    appCoOpComment({
                                                      variables: {
                                                        objects: [
                                                          {
                                                            co_op_id,
                                                            profile_id:
                                                              auth.getSub(),
                                                            profile_name:
                                                              userData
                                                                ?.profiles_by_pk
                                                                ?.name,
                                                            comment: text,
                                                            status: "approved",
                                                          },
                                                        ],
                                                      },
                                                    }).then(() => {
                                                      handleClose();
                                                      setIsDisable(false);
                                                      setText("");
                                                    });
                                                  });
                                                });
                                              }}
                                              style={{
                                                width: "13em",
                                                margin: "2em 1em",
                                              }}
                                            >
                                              APPROVE
                                            </Button>

                                            <Button
                                              color="secondary"
                                              variant="contained"
                                              className={classes.Button}
                                              onClick={() => handleClose()}
                                              style={{
                                                width: "120px",
                                                margin: "2em 1em",
                                              }}
                                            >
                                              CANCEL
                                            </Button>
                                          </div>
                                        </div>
                                      </Fade>
                                    </Modal>
                                    {statusType !== "pending" && (
                                      <>
                                        <Button
                                          color="success"
                                          variant="contained"
                                          className={classes.WarningButton}
                                          type="submit"
                                          onClick={handleOpenPending}
                                          style={{
                                            width: "15em",
                                            margin: "2em 1em",
                                          }}
                                        >
                                          PENDING REVISIONS
                                        </Button>
                                        <Modal
                                          aria-labelledby="transition-modal-title"
                                          aria-describedby="transition-modal-description"
                                          className={classes.modal}
                                          open={openPending}
                                          onClose={handleClosePending}
                                          closeAfterTransition
                                          BackdropComponent={Backdrop}
                                          BackdropProps={{
                                            timeout: 500,
                                          }}
                                        >
                                          <Fade in={openPending}>
                                            <div className={classes.paper}>
                                              <div className="customModalBody">
                                                <div className="border-heading-section">
                                                  <h5 className="mt-0">
                                                    CO-OP PRE APPROVAL CLAIM
                                                  </h5>
                                                </div>
                                                <h2 className="modal-title">
                                                  PENDING CLAIM COMMENTS
                                                </h2>
                                                <div className="disc-borderbox">
                                                  <CommentBox
                                                    text={text}
                                                    setText={setText}
                                                  />
                                                </div>
                                                <Button
                                                  color="secondary"
                                                  variant="contained"
                                                  className={
                                                    classes.WarningButton
                                                  }
                                                  disabled={isDisable}
                                                  onClick={() => {
                                                    setIsDisable(true);
                                                    updateCoOpRequestStatus({
                                                      variables: {
                                                        id: co_op_id,
                                                        status: "pending",
                                                      },
                                                    }).then(() => {
                                                      updateForm({
                                                        variables: {
                                                          id,
                                                          status: "pending",
                                                        },
                                                      }).then(() => {
                                                        appCoOpComment({
                                                          variables: {
                                                            objects: [
                                                              {
                                                                co_op_id,
                                                                profile_id:
                                                                  auth.getSub(),
                                                                profile_name:
                                                                  userData
                                                                    ?.profiles_by_pk
                                                                    ?.name,
                                                                comment: text,
                                                                status:
                                                                  "pending",
                                                              },
                                                            ],
                                                          },
                                                        }).then(() => {
                                                          handleClosePending();
                                                          setIsDisable(false);
                                                          setText("");
                                                        });
                                                      });
                                                    });
                                                  }}
                                                  style={{
                                                    width: "13em",
                                                    margin: "2em 1em",
                                                  }}
                                                >
                                                  PENDING CLAIM COMMENTS
                                                </Button>
                                                <Button
                                                  color="secondary"
                                                  variant="contained"
                                                  className={classes.Button}
                                                  onClick={() =>
                                                    handleClosePending()
                                                  }
                                                  style={{
                                                    width: "120px",
                                                    margin: "2em 1em",
                                                  }}
                                                >
                                                  CANCEL
                                                </Button>
                                              </div>
                                            </div>
                                          </Fade>
                                        </Modal>
                                      </>
                                    )}
                                    <Button
                                      color="success"
                                      variant="contained"
                                      className={classes.DangerButton}
                                      type="submit"
                                      onClick={handleOpenDeny}
                                      style={{
                                        width: "15em",
                                        margin: "2em 1em",
                                      }}
                                    >
                                      DENY
                                    </Button>
                                    <Modal
                                      aria-labelledby="transition-modal-title"
                                      aria-describedby="transition-modal-description"
                                      className={classes.modal}
                                      open={openDeny}
                                      onClose={handleCloseDeny}
                                      closeAfterTransition
                                      BackdropComponent={Backdrop}
                                      BackdropProps={{
                                        timeout: 500,
                                      }}
                                    >
                                      <Fade in={openDeny}>
                                        <div className={classes.paper}>
                                          <div className="customModalBody">
                                            <div className="border-heading-section">
                                              <h5 className="mt-0">
                                                CO-OP PRE APPROVAL CLAIM
                                              </h5>
                                            </div>
                                            <h2 className="modal-title">
                                              DENY CLAIM COMMENTS
                                            </h2>
                                            <div className="disc-borderbox">
                                              <CommentBox
                                                text={text}
                                                setText={setText}
                                              />
                                            </div>
                                            <Button
                                              color="secondary"
                                              variant="contained"
                                              className={classes.DangerButton}
                                              disabled={isDisable}
                                              onClick={() => {
                                                setIsDisable(true);
                                                updateCoOpRequestStatus({
                                                  variables: {
                                                    id: co_op_id,
                                                    status: "denied",
                                                  },
                                                }).then(() => {
                                                  updateForm({
                                                    variables: {
                                                      id,
                                                      status: "denied",
                                                    },
                                                  }).then(() => {
                                                    appCoOpComment({
                                                      variables: {
                                                        objects: [
                                                          {
                                                            co_op_id,
                                                            profile_id:
                                                              auth.getSub(),
                                                            profile_name:
                                                              userData
                                                                ?.profiles_by_pk
                                                                ?.name,
                                                            comment: text,
                                                            status: "deny",
                                                          },
                                                        ],
                                                      },
                                                    }).then(() => {
                                                      handleCloseDeny();
                                                      setIsDisable(false);
                                                      setText("");
                                                    });
                                                  });
                                                });
                                              }}
                                              style={{
                                                width: "13em",
                                                margin: "2em 1em",
                                              }}
                                            >
                                              DENY
                                            </Button>
                                            <Button
                                              color="secondary"
                                              variant="contained"
                                              className={classes.Button}
                                              onClick={() => handleCloseDeny()}
                                              style={{
                                                width: "120px",
                                                margin: "2em 1em",
                                              }}
                                            >
                                              CANCEL
                                            </Button>
                                          </div>
                                        </div>
                                      </Fade>
                                    </Modal>
                                  </div>

                                  {/* <Button
                 color="primary"
                 variant="contained"
                 className={classes.button}
                 type="submit"
                 style={{ width: "15em", margin: "2em 1em" }}
             >
                 APPROVE
             </Button>
             <Button
                 color="secondary"
                 variant="contained"
                 className={classes.button}
                 type="submit"
                 style={{ width: "15em", margin: "2em 1em" }}
             >
                 PENDING REVISIONS
             </Button>
             <Button
                 color="dark"
                 variant="contained"
                 // className={classes.button}
                 type="submit"
                 // onClick={this.clearForm}
                 style={{ width: "15em", margin: "2em 1em", backgroundColor: "#23333F", color: "#ffffff" }}
             >
                 DENY
             </Button> */}
                                </Grid>
                              );
                            }}
                          </Mutation>
                        );
                      }}
                    </Mutation>
                  );
                }}
              </Mutation>
            );
          }}
        </Query>
      )}
    </div>
  );
}
