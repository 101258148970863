import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Typography, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { ADD_NEW_CAREER } from "queries";
import { Mutation } from "react-apollo";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles(theme => ({
  container: {
    padding: "2em"
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100%",
    margin: "1em",
    textAlign: "center"
  },
  dense: {
    marginTop: 19
  },
  formControl: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  addCareer: {
    width: "200px",
    padding: "1em",
    fontSize: "14px"
  }
}));

const CareersAdd = props => {
  const { values, handleChange, resetState } = useForm(
    submitForm
  );
  const classes = useStyles();

  // values.start_date = new Date().getDate()
  function submitForm() {
    //console.log(values);
  }

  function completedAdd(payload) {
    //console.log("completeAdd has been called" + payload);
    toast("New Job Listing has been created succesfully");
    resetState();
  }

  return (
    <Mutation mutation={ADD_NEW_CAREER} onCompleted={completedAdd}>
      {(addNewCareer, { data, loading, called, error }) => {
        if (error)
          return (
            <pre>
              Bad:{" "}
              {error.graphQLErrors.map(({ message }, i) => (
                <span key={i}>{message}</span>
              ))}
            </pre>
          );
        return (
          <form
            onSubmit={e => {
              //   values.profile_id = auth.getSub();
              //   values.country = values.country[0];
              let formData = {};

              formData.title = values.title;
              formData.description = values.description;
              formData.date_needed = values.date_needed;
              formData.store_location = values.store_location;
              formData.salary_range = values.salary_range;

              e.preventDefault();
              addNewCareer({
                variables: {
                  objects: [formData]
                }
              });

              return false;
            }}
            className={classes.container}
            autoComplete="off"
          >
            <Typography gutterBottom variant="h4" className="addCareer">
              Add a new job listing by creating the title, description, store, dates needed and salary.
            </Typography>

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                {/* Row 1 */}

                <TextField
                  id="title"
                  name="title"
                  label="Job Title"
                  placeholder="Job Title"
                  className={classes.textField}
                  margin="normal"
                  onChange={handleChange}
                  value={values.title}
                  required
                  variant="outlined"
                />
                <TextField
                  required
                  variant="outlined"
                  name="date_needed"
                  label="Date Needed"
                  type="date"
                  onChange={handleChange}
                  value={values.date_needed}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                {/* Row 1 */}
                <TextField
                  id="store_location"
                  name="store_location"
                  label="Store / Location"
                  placeholder="Store / Location"
                  className={classes.textField}
                  margin="normal"
                  onChange={handleChange}
                  value={values.store_location}
                  required
                  variant="outlined"
                />
                <TextField
                  id="salary_range"
                  name="salary_range"
                  label="Salary Range"
                  placeholder="Salary Range"
                  className={classes.textField}
                  margin="normal"
                  onChange={handleChange}
                  value={values.salary_range}
                  required
                  variant="outlined"
                />
              </Grid>
              {/* Row 2 */}
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <TextField
                  id="description"
                  name="description"
                  label="Job description and requirements."
                  placeholder="Job description and requirements."
                  className={classes.textField}
                  margin="normal"
                  onChange={handleChange}
                  value={values.description || ""}
                  required
                  variant="outlined"
                />
              </Grid>

              <Grid container>
                <Grid item xs={12} align="center">
                  <InputLabel
                    id="errorMsgs"
                    value={error}
                    width="100%"
                    ref={error}
                  ></InputLabel>
                </Grid>
              </Grid>
              <Grid item xs={12} align="center">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addCareer}
                  type="submit"
                >
                  ADD JOB
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Mutation>
  );
};

export default CareersAdd;
