import React from "react";
import Paper from "@material-ui/core/Paper";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";
import Adduser from "./Settings/AddUser";
import UsersGrid from "./Settings/UsersGrid";

const styles = theme => ({
  button: {
    margin: theme.spacing()
  },
  input: {
    display: "none"
  },
  item: {
    height: "100%"
  },
  marginFix: {
    marginTop: 19,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5
  }
});
function AdminSettings(props) {
  //const client = apollo.clientForToken(auth.getIdToken());
  const { classes } = props;
  return (
    <DashboardLayout title="Admin Settings">
      <div className={classes.root}>
        <Paper>
          <Adduser />
          <UsersGrid />
        </Paper>
      </div>
    </DashboardLayout>
  );
}

AdminSettings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AdminSettings);
