import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import AlbumMediaList from "./Media/AlbumMediaList";
import MediaUploader from "./Media/MediaUploader";
import { Button, CircularProgress } from "@material-ui/core";
import auth from "Auth";
import apollo from "apollo";
import {
  EditingState,
  SelectionState,
  RowDetailState,
  PagingState,
  SortingState,
  IntegratedPaging,
  IntegratedSelection,
  FilteringState,
  IntegratedFiltering
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  TableRowDetail,
  TableSelection,
  PagingPanel,
  TableFilterRow
} from "@devexpress/dx-react-grid-material-ui";
// Shared layouts
import { toast } from "react-toastify";
import { Dashboard as DashboardLayout } from "layouts";
import { getAllMediaSubscription } from "queries";
import { Subscription } from "react-apollo";
// Externals
import PropTypes from "prop-types";
// Material helpers
import { Typography, withStyles } from "@material-ui/core";
import { UPDATE_MEDIA_BY_ID } from "queries";
import { DELETE_MEDIA_BY_ID } from "queries";

const RowDetail = ({ row }) => (
  <div>
    <AlbumMediaList items={row.media_items} />
    {/* <Button variant="contained">Remove All</Button> */}
  </div>
);

const styles = theme => ({
  button: {
    margin: theme.spacing()
  },
  input: {
    display: "none"
  },
  item: {
    height: "100%"
  },
  root: {
    padding: theme.spacing(4)
  },
  marginFix: {
    marginTop: 19,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5
  },
  header: {
    fontSize: "20px",
    fontWeight: 100,
    textTransform: "uppercase",
    padding: "1em"
  }
});

function Media(props) {
  const client = apollo.clientForToken(auth.getIdToken());
  const { classes } = props;

  function deleteMedia(id) {
    client.mutate({
      mutation: DELETE_MEDIA_BY_ID,
      variables: {
        id
      }
    });
    toast("Album has been deleted");
  }

  const [columns] = useState([
    { name: "album_name", title: "Model Name" },
    {
      name: "id",
      title: "Media Amount",
      getCellValue: row =>
        row.media_items_aggregate.aggregate
          ? row.media_items_aggregate.aggregate.count
          : undefined
    },
    { name: "brand", title: "Brand" },
    { name: "position", title: "Sort Order" }
  ]);

  const [selection, setSelection] = useState([]);

  const [editingStateColumnExtensions] = useState([
    { columnName: 'album_name', editingEnabled: false },
    { columnName: 'id', editingEnabled: false },
    { columnName: 'brand', editingEnabled: false },
  ]);

  const commitChanges = ({ changed }) => {
    if (changed && Object.values(changed)[0]) {
      console.log(selection);
      console.log(changed);
      client.mutate({
        mutation: UPDATE_MEDIA_BY_ID,
        variables: {
          id: mediadData[Object.keys(changed)[0]].id,
          ...Object.values(changed)[0],
        }
      });
    }
    //setRows(changedRows);
    toast("Album Position has been Updated");
  };

  function removeAllMedia() {
    try {
      if (selection.length > 0) {
        selection.map(function(selectedRow) {
          return deleteMedia(mediadData[selectedRow].id);
        });
        setSelection([]);
      }
    } catch (error) {
      toast.error("Error Removing All Media: " + error, {
        position: toast.POSITION.TOP_LEFT
      });
      //console.log(error);
    }
  }

  var mediadData = [];

  return (
    <DashboardLayout title="Media">
      <div className={classes.root + " sectionContainer"}>
        <Typography gutterBottom variant="h4" className="title">
          <span>MEDIA</span>
        </Typography>
        <MediaUploader />
        <Subscription subscription={getAllMediaSubscription}>
          {({ data, loading, error }) => {
            if (loading)
              return (
                <div className="flex center" style={{ padding: "2em 0" }}>
                  <CircularProgress color="secondary" />
                </div>
              );
            if (error) return <p>Error</p>;
            // idsArray = data.media;
            // console.log(idsArray);
            mediadData = data.media;

            return (
              <div className="tableStyles">
                <Paper>
                  <p className={classes.header}>All Albums</p>
                  <Grid rows={data.media} columns={columns}>
                    <SelectionState
                      selection={selection}
                      onSelectionChange={setSelection}
                    />
                    <EditingState
                      onCommitChanges={commitChanges}
                      columnExtensions={editingStateColumnExtensions}
                    />
                    <SortingState
                      defaultSorting={[{ columnName: 'position', direction: 'desc' }]}
                    />
                    <FilteringState defaultFilters={[]} />
                    <IntegratedFiltering />
                    <PagingState defaultCurrentPage={0} pageSize={5} />
                    <IntegratedSelection />
                    <IntegratedPaging />
                    <RowDetailState />
                    <Table />
                    <TableHeaderRow />
                    <TableEditRow />
                    <TableEditColumn
                      showEditCommand
                    />
                    <TableFilterRow />
                    <TableRowDetail contentComponent={RowDetail} />
                    <TableSelection showSelectAll />
                    <PagingPanel />
                  </Grid>
                </Paper>
                {data.media.length > 0 ? (
                  <Button
                    className={classes.marginFix}
                    variant="contained"
                    onClick={removeAllMedia}
                    color="primary"
                  >
                    Remove Selected
                  </Button>
                ) : (
                  ""
                )}
              </div>
            );
          }}
        </Subscription>
      </div>
    </DashboardLayout>
  );
}

Media.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Media);
