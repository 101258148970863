export const StatusEnum = {
    new: { label: 'New', color: 'rgb(33, 103, 188)' },
    pending: { label: 'Pending', color: '#EAC600' },
    approved: { label: 'Approved', color: '#6EA545' },
    denied: { label: 'Denied', color: '#CE2D18' }
};

export const FormTypeEnum = {
    "media-pre-approval": "Media Pre-Approval",
    "co-op-advertising-claim": "Co-Op Advertising Claim",
    "special-project-request": "Special Project Request",
    "govt-com-sales-credit-claim": "Government/Commercial Sales Credit Claim",
    "boat-show-credit": "Boat Show Credit"
};