import React, { useEffect, useState } from "react";
import { Mutation, Subscription } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { Query } from "react-apollo";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import useForm from "components/useForm";
import {
  Paper,
  Typography,
  TextField,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormLabel,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { RowDetailState } from "@devexpress/dx-react-grid";
import {
  Table,
  TableHeaderRow,
  TableRowDetail,
  Grid as Grids,
} from "@devexpress/dx-react-grid-material-ui";
import { Dashboard as DashboardLayout } from "layouts";
import styles from "theme/skin";
import auth from "Auth";
import {
  ADD_BOAT_REQUEST_FORM,
  ADD_CO_OP_REQUEST_FORMS,
  GET_BOAT_CREDIT_DATA,
  GET_BOAT_SHOW_CREDIT,
  UPDATE_BOAT_SHOW_CREDIT_FORM,
} from "./queries";
import moment from "moment";
import { StatusEnum } from "pages/FormsDatasheets/enum";
import { GET_USER_INFO } from "queries";
import DrawingComponent from "components/DrawingComponent";
import { BoatShowCreditRowDetail } from "../NewRequest/BoatShowCreditRowDetails";
import { withRouter } from "react-router";
import compose from "recompose/compose";
import axios from "axios";
import apollo from "apollo";

function BoatShowCredit(props) {
  const {
    classes,
    match: { params },
    history,
  } = props;
  const { values, resetState, setValues } = useForm(submitForm);
  const APP_SERVER = apollo.APP_UPLOAD_SERVER_URL;

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  function submitForm() {
    //console.log(values);
  }

  const [expandedRowIds, setExpandedRowIds] = useState([0]);
  const [queryData, setQueryData] = useState();
  const [userData, setUserData] = useState();
  const [edit, setEdit] = useState(null);
  const [editData, setEditData] = useState();
  const [isModal, setIsModal] = useState(false);
  const [file, setFile] = useState();
  // const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    setEdit(!isEmpty(params) ? params : null);
  }, [params]);

  useEffect(() => {
    if (values?.devoted || values?.booth_cost) {
      setValues((prevState) => ({
        ...prevState,
        devoted_cost: (
          (parseFloat(values?.booth_cost || 0, 2) *
            parseFloat(values?.devoted || 0, 2)) /
          100
        ).toFixed(2),
      }));
    }
  }, [values?.devoted, values?.booth_cost]);

  useEffect(() => {
    if (editData) {
      setValues((prevState) => ({
        ...prevState,
        dealer_name: editData?.dealer_name,
        additional_info: editData?.additional_info,
        address: editData?.address,
        address2: editData?.address2,
        city: editData?.city,
        contact_name: editData?.contact_name,
        country: editData?.country,
        fax: editData?.fax,
        id: editData?.id,
        phone: editData?.phone,
        state: editData?.state,
        zipcode: editData?.zipcode,
        booth_size: editData?.booth_size,
        devoted: editData?.devoted,
        devoted_cost: editData?.devoted_cost,
        booth_cost: editData?.booth_cost,
        booth_space_url_tool: editData?.booth_space_url_tool,
        booth_space_url: editData?.booth_space_url,
        date_submitted: editData?.date_submitted,
      }));
      // setImageUrl(editData?.booth_space_url_tool);
      setFile(editData?.booth_space_url);
    }
  }, [editData]);

  const formattedUTCDate = moment.utc();

  const columns = [
    { name: "date_submitted", title: "Date Submitted" },
    { name: "form", title: "Form", getCellValue: (row) => "Boat Show Credit" },
    { name: "status", title: "status" },
  ];

  const LastColumnCell = ({ column, value }) => (
    <Table.Cell
      style={
        column.name === "status"
          ? {
              color: StatusEnum[value.toLowerCase()]?.color,
              fontWeight: "bold",
            }
          : {}
      }
    >
      {column.name === "status"
        ? StatusEnum[value.toLowerCase()]?.label
        : value}
    </Table.Cell>
  );

  const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
  });

  const completedAdd = () => {
    if (edit) {
      toast("Boat show credit updated successfully");
    } else {
      toast("Boat show credit created successfully");
    }
    resetState();
  };

  const toggleRowExpand = (rowId) => {
    setExpandedRowIds((prevState) =>
      prevState.includes(rowId)
        ? prevState.filter((id) => id !== rowId)
        : [...prevState, rowId]
    );
  };

  const TableRow = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => toggleRowExpand(tableRow.rowId)}
      style={{ cursor: "pointer" }}
    />
  );

  const RowDetail = ({ row }) => {
    if (queryData) {
      const rowData = queryData?.find((rows) => rows?.id === row?.id);
      return (
        <div>
          <BoatShowCreditRowDetail
            isDisplay={true}
            comments={rowData?.co_op_request?.co_op_comments}
            userName={userData?.profiles_by_pk?.name}
            isEdit={true}
            row={rowData}
            type="dealer"
          />
        </div>
      );
    }
    return <div>Row details not available.</div>;
  };

  const handleChange = (event) => {
    event.persist();
    setValues((values) => {
      const updatedValues = {
        ...values,
        [event.target.name]: event.target.value,
      };

      // if (
      //   event.target.name === "booth_cost" ||
      //   event.target.name === "devoted"
      // ) {
      //   const totalCost =
      //     event.target.name === "booth_cost"
      //       ? parseFloat(event.target.value)
      //       : parseFloat(updatedValues?.booth_cost || 0);
      //   const devoted =
      //     event.target.name === "devoted"
      //       ? parseFloat(event.target.value)
      //       : parseFloat(updatedValues?.devoted || 0);

      //   if (!isNaN(totalCost) && !isNaN(devoted)) {
      //     updatedValues.devoted_cost = (
      //       (parseFloat(values?.booth_cost || 0, 2) *
      //         parseFloat(values?.devoted || 0, 2)) /
      //       100
      //     ).toFixed(2);
      //   }
      // }
      return updatedValues;
    });
  };

  const handleFileChange = (event) => {
    const inputFiles = event.target.files;
    if (inputFiles.length > 0) {
      const file = inputFiles[0];
      setFile(file);
    }
  };

  return (
    <Query
      query={GET_USER_INFO}
      variables={{ id: auth.getSub() }}
      onCompleted={(data) => setUserData(data)}
    >
      {({ data: userData, loading: userLoading, error }) => {
        return (
          <Query
            query={GET_BOAT_SHOW_CREDIT}
            variables={{ id: params?.id }}
            onCompleted={(data) => {
              setEditData(data?.boat_show_credit_by_pk);
            }}
          >
            {({ data: editDatas, loading: userLoading, error }) => {
              return (
                <Mutation mutation={ADD_CO_OP_REQUEST_FORMS}>
                  {(addCoOpRequestForms, { data, loading, called, error }) => {
                    if (error)
                      return (
                        <pre>
                          Bad:{" "}
                          {error.graphQLErrors.map(({ message }, i) => (
                            <span key={i}>{message}</span>
                          ))}
                        </pre>
                      );
                    return (
                      <Mutation
                        mutation={
                          !edit
                            ? ADD_BOAT_REQUEST_FORM
                            : UPDATE_BOAT_SHOW_CREDIT_FORM
                        }
                        onCompleted={completedAdd}
                      >
                        {(addBoatShow, { data, loading, called, error }) => {
                          if (error)
                            return (
                              <pre>
                                Bad:{" "}
                                {error.graphQLErrors.map(({ message }, i) => (
                                  <span key={i}>{message}</span>
                                ))}
                              </pre>
                            );
                          return (
                            <DashboardLayout>
                              <form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  if (edit) {
                                    if (file === editData?.booth_space_url) {
                                      const finalValues = {
                                        ...values,
                                        // booth_space_url_tool: imageUrl,
                                      };
                                      addBoatShow({
                                        variables: finalValues,
                                      }).then((res) => {
                                        if (res?.data) {
                                          setEdit({});
                                          history.replace("/boat-show-credit");
                                        }
                                      });
                                    } else {
                                      if (file) {
                                        const data = new FormData();
                                        data.append("image", file, file.name);
                                        axios
                                          .post(APP_SERVER + "/", data)
                                          .then((response) => {
                                            if (response.data.status === 200) {
                                              addBoatShow({
                                                variables: Object.assign(
                                                  {},
                                                  values,
                                                  {
                                                    booth_space_url:
                                                      response?.data?.imageUrl,
                                                    // booth_space_url_tool:
                                                    //   imageUrl,
                                                  }
                                                ),
                                              }).then((res) => {
                                                if (res?.data) {
                                                  setEdit({});
                                                  history.replace(
                                                    "/boat-show-credit"
                                                  );
                                                }
                                              });
                                            } else {
                                              toast.error(
                                                "Media upload failed"
                                              );
                                              throw new Error(
                                                `Upload failed for ${file.name}`
                                              );
                                            }
                                          })
                                          .catch((error) => {
                                            console.error(error);
                                          });
                                      } else {
                                        console.log("No file to upload.");
                                      }
                                    }
                                  } else {
                                    values.profile_id = auth.getSub();
                                    values.country = values.country;
                                    const requestData = {
                                      date_submitted: formattedUTCDate.format(),
                                      profile_id: auth.getSub(),
                                      form_type: "boat-show-credit",
                                      status: "new",
                                    };
                                    return addCoOpRequestForms({
                                      variables: {
                                        objects: requestData,
                                      },
                                    }).then((value) => {
                                      const co_op_requests_id =
                                        value?.data?.insert_co_op_requests
                                          ?.returning?.[0]?.id;
                                      if (file) {
                                        const data = new FormData();
                                        data.append("image", file, file.name);

                                        axios
                                          .post(APP_SERVER + "/", data)
                                          .then((response) => {
                                            if (response.data.status === 200) {
                                              const finalValues = {
                                                ...values,
                                                co_op_requests_id,
                                                booth_space_url:
                                                  response?.data?.imageUrl,
                                                // booth_space_url_tool: imageUrl,
                                              };
                                              addBoatShow({
                                                variables: {
                                                  objects: [finalValues],
                                                },
                                              });
                                            } else {
                                              toast.error(
                                                "Media upload failed"
                                              );
                                              throw new Error(
                                                `Upload failed for ${file.name}`
                                              );
                                            }
                                          })
                                          .catch((error) => {
                                            console.error(error);
                                          });
                                      } else {
                                        const finalValues = {
                                          ...values,
                                          co_op_requests_id,
                                          // booth_space_url_tool: imageUrl,
                                        };
                                        addBoatShow({
                                          variables: {
                                            objects: [finalValues],
                                          },
                                        });
                                      }
                                      return false;
                                    });
                                  }
                                }}
                                autoComplete="off"
                              >
                                <div
                                  className={classes.root + " sectionContainer"}
                                >
                                  <Typography
                                    gutterBottom
                                    variant="h4"
                                    className="title"
                                  >
                                    <span>Boat Show Credit FORM</span>
                                  </Typography>
                                  {!edit && (
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      className={classes.subtitle3}
                                      align="left"
                                    >
                                      FORMS WAITING FOR APPROVAL
                                    </Typography>
                                  )}
                                  {!edit && (
                                    <Subscription
                                      subscription={GET_BOAT_CREDIT_DATA}
                                      variables={{ profile_id: auth.getSub() }}
                                    >
                                      {({ data, loading, error }) => {
                                        if (data && data !== queryData) {
                                          setQueryData(data?.boat_show_credit);
                                        }
                                        if (loading || !queryData)
                                          return (
                                            <div
                                              className="flex center"
                                              style={{ padding: "2em 0" }}
                                            >
                                              <CircularProgress color="secondary" />
                                            </div>
                                          );
                                        if (error)
                                          return (
                                            <p>
                                              Error Loading Special Project
                                              Forms
                                            </p>
                                          );
                                        return (
                                          <div className="custom-table-event">
                                            <Grids
                                              rows={data?.boat_show_credit}
                                              columns={columns}
                                            >
                                              <RowDetailState
                                                expandedRowIds={expandedRowIds}
                                              />
                                              <Table
                                                cellComponent={(props) => (
                                                  <LastColumnCell {...props} />
                                                )}
                                                rowComponent={TableRow}
                                              />
                                              <TableRowDetail
                                                contentComponent={RowDetail}
                                              />
                                              <TableHeaderRow />
                                            </Grids>
                                          </div>
                                        );
                                      }}
                                    </Subscription>
                                  )}
                                  <br></br>
                                  <br></br>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.subtitle3}
                                    style={{
                                      backgroundColor: "#BED4E5",
                                      textAlign: "left",
                                      padding: "1em",
                                      color: "#23333F",
                                      margin: "0px",
                                    }}
                                  >
                                    BOAT SHOW CREDIT FORM
                                  </Typography>
                                  <Paper
                                    className={classes.paper}
                                    style={{
                                      backgroundColor: "#F9FCFF",
                                      boxShadow: "unset",
                                    }}
                                  >
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      align="center"
                                      className={classes.subTitle2}
                                      style={{
                                        margin: "30px auto 15px",
                                        maxWidth: "80%",
                                      }}
                                    >
                                      <strong>CAROLINA SKIFF:</strong> 3231
                                      Fulford Road Waycross Georgia 31503{" "}
                                      <strong>PHONE:</strong> 912-287-0547{" "}
                                      <strong>FAX:</strong> 912-287-0533
                                    </Typography>
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      align="center"
                                      style={{
                                        margin: "0px auto",
                                        maxWidth: "80%",
                                      }}
                                      className={classes.subTitle2}
                                    >
                                      All required paper work must be submitted
                                      with this form.
                                      <br></br>
                                      For qualifications and requirements of the
                                      Boat show credit program, please check
                                      your Dealer Manual under the Marketing
                                      Guide section.
                                    </Typography>
                                    <br></br>
                                    <br></br>
                                    <div>
                                      <Grid container spacing={4}>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            required
                                            name="dealer_name"
                                            label="Dealer Name"
                                            className={classes.textField}
                                            style={{ width: "100%" }}
                                            onChange={handleChange}
                                            margin="normal"
                                            value={values?.dealer_name || ""}
                                            variant="outlined"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            required
                                            name="contact_name"
                                            label="Contact Name"
                                            className={classes.textField}
                                            style={{ width: "100%" }}
                                            onChange={handleChange}
                                            margin="normal"
                                            value={values?.contact_name || ""}
                                            variant="outlined"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            required
                                            name="phone"
                                            label="Phone"
                                            className={classes.textField}
                                            style={{ width: "100%" }}
                                            onChange={handleChange}
                                            margin="normal"
                                            value={values?.phone || ""}
                                            variant="outlined"
                                          />
                                        </Grid>
                                      </Grid>

                                      <Grid container spacing={4}>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            required
                                            name="fax"
                                            label="Fax"
                                            className={classes.textField}
                                            style={{ width: "100%" }}
                                            onChange={handleChange}
                                            margin="normal"
                                            value={values?.fax || ""}
                                            variant="outlined"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            required
                                            name="address"
                                            label="Address"
                                            className={classes.textField}
                                            style={{ width: "100%" }}
                                            onChange={handleChange}
                                            margin="normal"
                                            value={values?.address || ""}
                                            variant="outlined"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="address2"
                                            label="Address line 2 (Suites, Apt. # etc)"
                                            className={classes.textField}
                                            onChange={handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            value={values?.address2 || ""}
                                            style={{ width: "100%" }}
                                          />
                                        </Grid>
                                      </Grid>

                                      <Grid container spacing={4}>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            required
                                            name="city"
                                            label="City"
                                            className={classes.textField}
                                            onChange={handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            value={values?.city || ""}
                                            style={{ width: "100%" }}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            required
                                            name="country"
                                            label="Country"
                                            select
                                            className={classes.textField}
                                            onChange={handleChange}
                                            value={values?.country || ""}
                                            style={{ width: "100%" }}
                                            variant="outlined"
                                            SelectProps={{
                                              native: true,
                                              MenuProps: {
                                                className: classes.menu,
                                              },
                                            }}
                                            margin="normal"
                                          >
                                            <option key="" value="" />
                                            <option
                                              key="United States"
                                              value="United States"
                                            >
                                              United States
                                            </option>
                                          </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            required
                                            name="state"
                                            label="State"
                                            variant="outlined"
                                            select
                                            style={{ width: "100%" }}
                                            className={classes.textField}
                                            value={values?.state || ""}
                                            onChange={handleChange}
                                            SelectProps={{
                                              native: true,
                                              MenuProps: {
                                                className: classes.menu,
                                              },
                                            }}
                                            margin="normal"
                                          >
                                            <option key="" value="" />
                                            <option
                                              key="Alabama"
                                              value="Alabama"
                                            >
                                              Alabama
                                            </option>
                                            <option key="Alaska" value="Alaska">
                                              Alaska
                                            </option>
                                            <option
                                              key="Arizona"
                                              value="Arizona"
                                            >
                                              Arizona
                                            </option>
                                            <option
                                              key="Arkansas"
                                              value="Arkansas"
                                            >
                                              Arkansas
                                            </option>
                                            <option
                                              key="California"
                                              value="California"
                                            >
                                              California
                                            </option>
                                            <option
                                              key="Colorado"
                                              value="Colorado"
                                            >
                                              Colorado
                                            </option>
                                            <option
                                              key="Connecticut"
                                              value="Connecticut"
                                            >
                                              Connecticut
                                            </option>
                                            <option
                                              key="Delaware"
                                              value="Delaware"
                                            >
                                              Delaware
                                            </option>
                                            <option
                                              key="Florida"
                                              value="Florida"
                                            >
                                              Florida
                                            </option>
                                            <option
                                              key="Georgia"
                                              value="Georgia"
                                            >
                                              Georgia
                                            </option>
                                            <option key="Hawaii" value="Hawaii">
                                              Hawaii
                                            </option>
                                            <option key="Idaho" value="Idaho">
                                              Idaho
                                            </option>
                                            <option
                                              key="Illinois"
                                              value="Illinois"
                                            >
                                              Illinois
                                            </option>
                                            <option
                                              key="Indiana"
                                              value="Indiana"
                                            >
                                              Indiana
                                            </option>
                                            <option key="Iowa" value="Iowa">
                                              Iowa
                                            </option>
                                            <option key="Kansas" value="Kansas">
                                              Kansas
                                            </option>
                                            <option
                                              key="Kentucky"
                                              value="Kentucky"
                                            >
                                              Kentucky
                                            </option>
                                            <option
                                              key="Louisiana"
                                              value="Louisiana"
                                            >
                                              Louisiana
                                            </option>
                                            <option key="Maine" value="Maine">
                                              Maine
                                            </option>
                                            <option
                                              key="Maryland"
                                              value="Maryland"
                                            >
                                              Maryland
                                            </option>
                                            <option
                                              key="Massachusetts"
                                              value="Massachusetts"
                                            >
                                              Massachusetts
                                            </option>
                                            <option
                                              key="Michigan"
                                              value="Michigan"
                                            >
                                              Michigan
                                            </option>
                                            <option
                                              key="Minnesota"
                                              value="Minnesota"
                                            >
                                              Minnesota
                                            </option>
                                            <option
                                              key="Mississippi"
                                              value="Mississippi"
                                            >
                                              Mississippi
                                            </option>
                                            <option
                                              key="Missouri"
                                              value="Missouri"
                                            >
                                              Missouri
                                            </option>
                                            <option
                                              key="Montana"
                                              value="Montana"
                                            >
                                              Montana
                                            </option>
                                            <option
                                              key="Nebraska"
                                              value="Nebraska"
                                            >
                                              Nebraska
                                            </option>
                                            <option key="Nevada" value="Nevada">
                                              Nevada
                                            </option>
                                            <option
                                              key="New Hampshire"
                                              value="New Hampshire"
                                            >
                                              New Hampshire
                                            </option>
                                            <option
                                              key="New Jersey"
                                              value="New Jersey"
                                            >
                                              New Jersey
                                            </option>
                                            <option
                                              key="New Mexico"
                                              value="New Mexico"
                                            >
                                              New Mexico
                                            </option>
                                            <option
                                              key="New York"
                                              value="New York"
                                            >
                                              New York
                                            </option>
                                            <option
                                              key="North Carolina"
                                              value="North Carolina"
                                            >
                                              North Carolina
                                            </option>
                                            <option
                                              key="North Dakota"
                                              value="North Dakota"
                                            >
                                              North Dakota
                                            </option>
                                            <option key="Ohio" value="Ohio">
                                              Ohio
                                            </option>
                                            <option
                                              key="Oklahoma"
                                              value="Oklahoma"
                                            >
                                              Oklahoma
                                            </option>
                                            <option key="Oregon" value="Oregon">
                                              Oregon
                                            </option>
                                            <option
                                              key="Pennsylvania"
                                              value="Pennsylvania"
                                            >
                                              Pennsylvania
                                            </option>
                                            <option
                                              key="Rhode Island"
                                              value="Rhode Island"
                                            >
                                              Rhode Island
                                            </option>
                                            <option
                                              key="South Carolina"
                                              value="South Carolina"
                                            >
                                              South Carolina
                                            </option>
                                            <option
                                              key="South Dakota"
                                              value="South Dakota"
                                            >
                                              South Dakota
                                            </option>
                                            <option
                                              key="Tennessee"
                                              value="Tennessee"
                                            >
                                              Tennessee
                                            </option>
                                            <option key="Texas" value="Texas">
                                              Texas
                                            </option>
                                            <option key="Utah" value="Utah">
                                              Utah
                                            </option>
                                            <option
                                              key="Vermont"
                                              value="Vermont"
                                            >
                                              Vermont
                                            </option>
                                            <option
                                              key="Virginia"
                                              value="Virginia"
                                            >
                                              Virginia
                                            </option>
                                            <option
                                              key="Washington"
                                              value="Washington"
                                            >
                                              Washington
                                            </option>
                                            <option
                                              key="West Virginia"
                                              value="West Virginia"
                                            >
                                              West Virginia
                                            </option>
                                            <option
                                              key="Wisconsin"
                                              value="Wisconsin"
                                            >
                                              Wisconsin
                                            </option>
                                            <option
                                              key="Wyoming"
                                              value="Wyoming"
                                            >
                                              Wyoming
                                            </option>
                                          </TextField>
                                        </Grid>
                                      </Grid>
                                      <Grid container spacing={4}>
                                        <Grid item xs={12} sm={4}>
                                          <TextField
                                            name="zipcode"
                                            label="Zip / Postal Code"
                                            className={classes.textField}
                                            onChange={handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                            value={values?.zipcode || ""}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <Typography
                                            gutterBottom
                                            variant="h6"
                                            align="left"
                                            className={classes.subTitle2}
                                            style={{
                                              width: "100%",
                                              textAlign: "left",
                                              marginTop: "25px",
                                            }}
                                          >
                                            DATE:{" "}
                                            {values?.date_submitted
                                              ? moment(
                                                  values?.date_submitted
                                                ).format("MM/DD/YYYY")
                                              : moment().format("MM/DD/YYYY")}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </div>
                                    <br></br>
                                    <br></br>

                                    <div>
                                      <Typography
                                        gutterBottom
                                        variant="h6"
                                        align="center"
                                        style={{
                                          maxWidth: "80%",
                                          margin: "0px auto 15px",
                                        }}
                                        className={classes.subTitle2}
                                      >
                                        <b>Booth Space Credit</b>
                                      </Typography>
                                      <Typography
                                        gutterBottom
                                        variant="h6"
                                        align="center"
                                        style={{
                                          maxWidth: "80%",
                                          margin: "0px auto 10px",
                                        }}
                                        className={classes.subTitle2}
                                      >
                                        The devoted space of the booth will be
                                        credited at 50% using the dealer accrual
                                        credits up to maximum amount.
                                      </Typography>
                                      <Typography
                                        gutterBottom
                                        variant="h6"
                                        align="center"
                                        style={{
                                          maxWidth: "80%",
                                          margin: "0px auto",
                                        }}
                                        className={classes.subTitle2}
                                      >
                                        This means that the credit is pro-rated
                                        to the number of boats on display within
                                        total amount of the booth space.
                                        <br></br>A layout drawing showing boat
                                        placements is required for space credit.
                                      </Typography>
                                      <br></br>
                                      <br></br>
                                      <Grid container>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          lg={12}
                                          className="boat-upload-wrapper"
                                        >
                                          <input
                                            accept="image/*"
                                            className={classes.input}
                                            id="contained-button-file1"
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={(e) =>
                                              handleFileChange(e)
                                            }
                                          />
                                          <FormLabel htmlFor="contained-button-file1">
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              component="span"
                                            >
                                              CHOOSE FILE
                                            </Button>
                                          </FormLabel>
                                          <FormLabel
                                            htmlFor="contain ed-button-file1"
                                            className={classes.body2}
                                            style={{
                                              marginLeft: "8px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {file?.name
                                              ? file?.name
                                              : file || "NO FILES UPLOADED"}
                                          </FormLabel>
                                          {/* <div className="divider">
                                            <p>
                                              <span>OR</span>
                                            </p>
                                          </div>
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className={classes.button}
                                            style={{
                                              width: "15em",
                                              margin: "2em 1em",
                                            }}
                                            onClick={() => setIsModal(true)}
                                          >
                                            Draw Boat
                                          </Button>
                                          <FormLabel
                                            htmlFor="contain ed-button-file1"
                                            className={classes.body2}
                                            style={{
                                              marginLeft: "8px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {imageUrl || "NO FILES UPLOADED"}
                                          </FormLabel>
                                          <DrawingComponent
                                            isModal={isModal}
                                            setIsModal={setIsModal}
                                            setImageUrl={setImageUrl}
                                          /> */}
                                        </Grid>
                                      </Grid>
                                      <br></br>
                                      <br></br>
                                      <Grid
                                        container
                                        justify="space-around"
                                        spacing={4}
                                      >
                                        <Grid item xs={12} sm={3}>
                                          <FormControl
                                            sx={{ m: 1, width: "25ch" }}
                                            variant="outlined"
                                          >
                                            <FormLabel
                                              variant="h5"
                                              align="center"
                                              className={classes.subTitle2}
                                              style={{
                                                width: "100%",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              <b>BOOTH SIZE</b>
                                            </FormLabel>
                                            <OutlinedInput
                                              id="outlined-adornment-weight"
                                              name="booth_size"
                                              type="number"
                                              onChange={handleChange}
                                              value={values.booth_size || ""}
                                              style={{ width: "100%" }}
                                              aria-describedby="outlined-weight-helper-text"
                                              inputProps={{
                                                "aria-label": "weight",
                                                pattern: "\\d*\\.?\\d+",
                                                min: "0",
                                              }}
                                            />
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <FormControl
                                            sx={{ m: 1, width: "25ch" }}
                                            variant="outlined"
                                          >
                                            <FormLabel
                                              variant="h5"
                                              align="center"
                                              className={classes.subTitle2}
                                              style={{
                                                width: "100%",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              <b>DEVOTED %</b>
                                            </FormLabel>
                                            <OutlinedInput
                                              id="outlined-adornment-weight"
                                              name="devoted"
                                              type="number"
                                              onChange={handleChange}
                                              value={values.devoted || ""}
                                              endAdornment={
                                                <InputAdornment position="end">
                                                  %
                                                </InputAdornment>
                                              }
                                              style={{ width: "100%" }}
                                              aria-describedby="outlined-weight-helper-text"
                                              inputProps={{
                                                "aria-label": "weight",
                                                pattern: "\\d*\\.?\\d+",
                                                min: "0",
                                              }}
                                            />
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <FormControl
                                            sx={{ m: 1, width: "25ch" }}
                                            variant="outlined"
                                          >
                                            <FormLabel
                                              variant="h5"
                                              align="center"
                                              className={classes.subTitle2}
                                              style={{
                                                width: "100%",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              <b>BOOTH COST $</b>
                                            </FormLabel>
                                            <OutlinedInput
                                              id="outlined-adornment-weight"
                                              name="booth_cost"
                                              type="number"
                                              value={values.booth_cost || ""}
                                              onChange={handleChange}
                                              startAdornment={
                                                <InputAdornment position="start">
                                                  $
                                                </InputAdornment>
                                              }
                                              endAdornment={
                                                <InputAdornment position="end">
                                                  {" "}
                                                </InputAdornment>
                                              }
                                              style={{ width: "100%" }}
                                              aria-describedby="outlined-weight-helper-text"
                                              inputProps={{
                                                "aria-label": "weight",
                                                pattern: "\\d*\\.?\\d+",
                                                min: "0",
                                              }}
                                            />
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <FormControl
                                            sx={{ m: 1, width: "25ch" }}
                                            variant="outlined"
                                          >
                                            <FormLabel
                                              variant="h5"
                                              align="center"
                                              className={classes.subTitle2}
                                              style={{
                                                width: "100%",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              <b>DEVOTED COST $</b>
                                            </FormLabel>
                                            <OutlinedInput
                                              id="outlined-adornment-weight"
                                              name="devoted_cost"
                                              type="number"
                                              disabled
                                              value={(
                                                (parseFloat(
                                                  values?.booth_cost || 0,
                                                  2
                                                ) *
                                                  parseFloat(
                                                    values?.devoted || 0,
                                                    2
                                                  )) /
                                                100
                                              ).toFixed(2)}
                                              onChange={handleChange}
                                              startAdornment={
                                                <InputAdornment position="start">
                                                  $
                                                </InputAdornment>
                                              }
                                              endAdornment={
                                                <InputAdornment position="end">
                                                  {" "}
                                                </InputAdornment>
                                              }
                                              style={{ width: "100%" }}
                                              aria-describedby="outlined-weight-helper-text"
                                              inputProps={{
                                                "aria-label": "weight",
                                                pattern: "\\d*\\.?\\d+",
                                                min: "0",
                                              }}
                                            />
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                    </div>
                                    <br></br>
                                    <br></br>

                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      align="left"
                                      className={classes.subTitle2}
                                    >
                                      <b>REQUIREMENTS</b>
                                    </Typography>
                                    <Typography>
                                      <ol>
                                        <li>
                                          Boat Show must have three (3) or more
                                          dealers in the same show.
                                        </li>
                                        <li>
                                          The Carolina Skiff and or Sea Chaser
                                          product must be own display at the
                                          show.
                                        </li>
                                        <li>
                                          A percentage of the booth space will
                                          be credited for the amount of devoted
                                          space.
                                        </li>
                                        <li>
                                          The boat show space must be paid in
                                          full and a copy of the paid invoice
                                          submitted.
                                        </li>
                                        <li>
                                          Only boat shows with-in your approved
                                          market area will receive Boat Show
                                          Credits.
                                        </li>
                                      </ol>
                                    </Typography>
                                    <br></br>
                                    <div>
                                      <Grid container>
                                        <Grid item xs={12} sm={12}>
                                          <TextField
                                            name="additional_info"
                                            label="Additional information. (OPTIONAL)"
                                            multiline
                                            rows="4"
                                            variant="outlined"
                                            className={classes.textField}
                                            onChange={handleChange}
                                            margin="normal"
                                            value={
                                              values?.additional_info || ""
                                            }
                                            style={{ width: "100%" }}
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>

                                    <Grid item xs={12} align="center">
                                      {/* {loading && <p>Loading...</p>}
                            {error && (
                            <p>
                                Warranty Registration Could Not be Submitted. Please Try
                                again.
                            </p>
                            )} */}
                                      {edit && (
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className={classes.button}
                                          onClick={() => {
                                            history.push("/boat-show-credit");
                                            setEdit(false);
                                            resetState();
                                            setFile({});
                                          }}
                                          style={{
                                            width: "15em",
                                            margin: "2em 1em",
                                          }}
                                        >
                                          CANCEL
                                        </Button>
                                      )}
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.button}
                                        type="submit"
                                        style={{
                                          width: "15em",
                                          margin: "2em 1em",
                                        }}
                                      >
                                        SUBMIT
                                      </Button>
                                    </Grid>
                                  </Paper>
                                </div>
                              </form>
                            </DashboardLayout>
                          );
                        }}
                      </Mutation>
                    );
                  }}
                </Mutation>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
}

export default compose(withRouter, withStyles(styles))(BoatShowCredit);
