import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, AppBar, Typography, Box, Paper } from '@material-ui/core';
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
// Component styles
import styles from "theme/skin";
import CoopPreApprovalForm from './CoopPreApprovalForm';
import MSRPdataSheet from './MSRPdataSheet';
import PreAuthClaimForms from './PreAuthClaimForms';
import './CoopPreApprovalForm.scss';

function TabPanel(props) {
  const { children, value, index, other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  '.MuiAppBar-root': {
    backgroundColor: "#000"
  }
}));
export default function CoOPRequests(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <DashboardLayout title="Co OP Requests">
      <div className={classes.root + " sectionContainer"}>
        <br></br><br></br>
        <Typography gutterBottom variant="h4" className="title">
          <span>Co OP Requests</span>
        </Typography>

        <Box sx={{ flexGrow: 1 }} style={{ width: "88%", margin: "0px auto", }}>
          <div className={classes.root}>
            <AppBar position="static" className='custom-tab-area'>
              <Tabs variant="standard" centered value={value} onChange={handleChange} style={{ backgroundColor: "#3B6894" }} aria-label="simple tabs example"> {/*TabIndicatorProps={{style: { backgroundColor: "#000"}}}*/}
                <Tab label="CO-OP Pre Approval Claim Forms" {...a11yProps(0)} style={{ marginRight: '2rem' }} md={{ padding: "0px 20px" }} />
                <Tab label="MSRP Data Sheets" {...a11yProps(1)} style={{ marginRight: '2rem' }} disabled />
                <Tab label="Pre-Auth Claim Forms" {...a11yProps(2)} style={{ marginRight: '2rem' }} disabled/>
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <CoopPreApprovalForm />
            </TabPanel>
            <TabPanel value={value} index={1} >
              <MSRPdataSheet />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PreAuthClaimForms />
            </TabPanel>
          </div>
        </Box>
      </div>
    </DashboardLayout>
  );
}

CoOPRequests.propTypes = {
  classes: PropTypes.object.isRequired
}; 
