import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const styles = {
  cardContainer: {
    display: "flex",
    flexWrap: "no-wrap",
  },
};

function LeadsOptionsRender(props) {
  const { options } = props;
  const [viewOpen, setViewOpen] = React.useState(false);

  const handleViewOpen = (specifications) => {
    setViewOpen(true);
  };

  const handleViewClose = () => {
    setViewOpen(false);
  };

  if (!options) return null;
  var optionsData = "";
  try {
    optionsData = JSON.parse(unescape(options));
  } catch (e) {
    return null;
  }
  optionsData = JSON.parse(unescape(options));

  const boatColor = optionsData?.boatColor;
  const boatOptions = optionsData?.boatOptions;
  const napOptions = optionsData?.napOption;
  const specialOptions = optionsData?.boatSpecialOptions;
  const totalPrice = optionsData?.totalprice;
  const boatSpecification = optionsData?.boatSpecification;

  let boatLI = "";
  let colorUI = "";
  let napLI = "";
  let specialUI = "";
  let specUI = "";

  if (boatSpecification && boatSpecification.length > 0) {
    specUI = boatSpecification
      .map(
        (spec, index) => `
          <div class="p-tb-1" key="${index}">
            <div style="font-weight: 600;">
              ${spec.heading || ""}
            </div>
            ${spec.lists
              .map(
                (listItem) => `
                <p>${listItem || ""}</p>
              `
              )
              .join("")} 
          </div>
        `
      )
      .join("");
  }

  if (boatOptions)
    boatLI = boatOptions?.map((item, index) => (
      <li className="p-tb-1" key={index}>
        {item?.title ? (
          <>
            <span>{item?.title || ""}</span>
            <span style={{ float: "right", fontWeight: 600 }}>
              {item?.price || "$0"}
            </span>
          </>
        ) : (
          <span>{item}</span>
        )}
      </li>
    ));
  else {
    boatLI = <li className="p-tb-1">No Option Selected</li>;
  }

  if (napOptions)
    napLI = napOptions?.map((item, index) => (
      <li className="p-tb-1" key={index}>
        {item?.title ? (
          <>
            <span>{item?.title || ""}</span>
            <span style={{ float: "right", fontWeight: 600 }}>
              {item?.price || "$0"}
            </span>
          </>
        ) : (
          <span>{item}</span>
        )}
      </li>
    ));
  else {
    napLI = <li className="p-tb-1">No Option Selected</li>;
  }

  if (typeof boatColor === "string") {
    colorUI = <span>{boatColor || ""}</span>;
  } else if (boatColor?.length > 0)
    colorUI = boatColor?.map((item, index) => (
      <li className="p-tb-1" key={index}>
        {item?.title ? (
          <>
            <span>{item?.title || ""}</span>
            <span style={{ float: "right", fontWeight: 600 }}>
              {item?.price || "$0"}
            </span>
          </>
        ) : (
          <span>{item}</span>
        )}
      </li>
    ));
  else {
    colorUI = <li className="p-tb-1">No Option Selected</li>;
  }

  if (specialOptions)
    specialUI = specialOptions?.map((item, index) => (
      <li className="p-tb-1" key={index}>
        {item?.title ? (
          <>
            <span>{item?.title || ""}</span>
            <span style={{ float: "right", fontWeight: 600 }}>
              {item?.option || ""}
            </span>
          </>
        ) : (
          <span>{item}</span>
        )}
      </li>
    ));
  else {
    specialUI = <li className="p-tb-1">No Option Selected</li>;
  }

  return (
    <div>
      {napOptions && napOptions?.length > 0 ? (
        <>
          <strong>Boat Model :</strong>
          <ul>{napLI}</ul>
        </>
      ) : null}
      <strong>Boat Color :</strong>
      <ul>{colorUI}</ul>
      <strong>Options :</strong>
      <ul> {boatLI}</ul>
      {napOptions && napOptions?.length > 0 ? (
        <>
          <strong>Total Price :</strong>
          <span style={{ float: "right", fontWeight: 600 }}>
            {totalPrice || "$0"}
          </span>
        </>
      ) : null}
      {specialOptions && specialOptions?.length > 0 ? (
        <>
          <strong>Special Option :</strong>
          <ul> {specialUI}</ul>
        </>
      ) : null}
      {boatSpecification && boatSpecification?.length > 0 ? (
        <div>
          <span style={{ fontWeight: 600 }}>Specifications: </span>
          <button
            style={{
              backgroundColor: "#697888",
              color: "#fff",
              border: "1px solid",
              borderRadius: "5px",
              padding: "5px 10px",
              cursor: "pointer",
            }}
            onClick={() => handleViewOpen()}
          >
            Click here to view
          </button>
        </div>
      ) : null}
      <Dialog open={viewOpen} onClose={handleViewClose} maxWidth="md" fullWidth>
        <DialogTitle style={{ fontWeight: 600 }}>
          Boat Specifications
        </DialogTitle>
        <DialogContent>
          {boatSpecification?.length > 0 ? (
            boatSpecification?.map((spec, idx) => (
              <div key={idx}>
                <p>
                  <b>{spec?.heading}</b>
                </p>
                <ul>
                  {spec?.lists?.map((item, itemIdx) => (
                    <li key={itemIdx}>{item}</li>
                  ))}
                </ul>
              </div>
            ))
          ) : (
            <p>No specifications available.</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

LeadsOptionsRender.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LeadsOptionsRender);
