import React from "react";
import { Query } from "react-apollo";
import { getAllDealerWarrantyRegistrations } from "../../queries";
import PropTypes from "prop-types";
import {
  Paper,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { Print } from "@material-ui/icons";
import {
  SelectionState,
  RowDetailState,
  SearchState,
  PagingState,
  SortingState,
  IntegratedSorting,
  IntegratedPaging,
  IntegratedFiltering,
  IntegratedSelection
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel
} from "@devexpress/dx-react-grid-material-ui";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
import { withStyles } from "@material-ui/core";
import GridContainer from "@material-ui/core/Grid";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { WarrantyRegistrationPdfDocument } from "../Administration/WarrantyRegistration/WarrantyRegistrationPdfDocument";

// Component styles
import styles from "theme/skin";

const RowDetail = ({ row }) => (
  <div>
  <PDFDownloadLink
      document={<WarrantyRegistrationPdfDocument data={row} />}
      fileName={"warranty_registration_" +  row.warranty_id + "_" +  row.first_name + "_" +  row.last_name +".pdf"}
      color="secondary"
      className="MuiButtonBase-root MuiButton-root MuiButton-containedSecondary MuiButton-contained"
      >
      {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Print To Pdf"
      }
      </PDFDownloadLink>
    <table cellSpacing="0" className="detailTable">
      <tbody>
        <tr>
          <td>Purchase Date:</td>
          <td>{row.purchase_date ? row.purchase_date : "-"}</td>
        </tr>
        <tr>
          <td>Admin Name:</td>
          <td>{row.admin_name ? row.admin_name : "-"}</td>
        </tr>
        <tr>
          <td>Admin Email:</td>
          <td>{row.admin_email ? row.admin_email : "-"}</td>
        </tr>
        <tr>
          <td>HIN:</td>
          <td>
            {row.hull_identification_number
              ? row.hull_identification_number
              : "-"}
          </td>
        </tr>
        <tr>
          <td>Brand:</td>
          <td>{row.brand ? row.brand : "-"}</td>
        </tr>
        <tr>
          <td>Model:</td>
          <td>{row.model ? row.model : "-"}</td>
        </tr>
        <tr>
          <td>Older Model:</td>
          <td>{row.boat_model ? (row.boat_model.older_model ? "Yes" : "No") : "-"}</td>
        </tr>
        <tr>
          <td>Motor Details:</td>
          <td>{row.motor_details ? row.motor_details : "-"}</td>
        </tr>
        <tr>
          <td>Accessories:</td>
          <td>{row.accessories ? row.accessories : "-"}</td>
        </tr>
        <tr>
          <td>Primary Use:</td>
          <td>{row.primary_use ? row.primary_use : "-"}</td>
        </tr>
        <tr>
          <td>First Name</td>
          <td>{row.first_name ? row.first_name : "-"}</td>
        </tr>
        <tr>
          <td>Last Name</td>
          <td>{row.last_name ? row.last_name : "-"}</td>
        </tr>
        <tr>
          <td>Phone</td>
          <td>{row.phone ? row.phone : "-"}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{row.email ? row.email : "-"}</td>
        </tr>
        <tr>
          <td>Submitted On:</td>
          <td>{row.submittedon ? row.submittedon : "-"}</td>
        </tr>
        <tr>
          <td>Status:</td>
          <td>{row.status ? row.status : "-"}</td>
        </tr>

        <tr>
          <td>Address:</td>
          <td>{row.address ? row.address : "-"}</td>
        </tr>

        <tr>
          <td>Address2:</td>
          <td>{row.address2 ? row.address2 : "-"}</td>
        </tr>

        <tr>
          <td>City:</td>
          <td>{row.city ? row.city : "-"}</td>
        </tr>

        <tr>
          <td>State:</td>
          <td>{row.region}</td>
        </tr>

        <tr>
          <td>Country:</td>
          <td>{row.country ? row.country : "-"}</td>
        </tr>

        <tr>
          <td>Zipcode:</td>
          <td>{row.zipcode ? row.zipcode : "-"}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

class Registrations extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "warranty_id", title: "#" },
        { name: "submittedon", title: "Submitted" },
        { name: "hull_identification_number", title: "HIN" },
        { name: "model", title: "Model" },
        { name: "status", title: "Status" }
      ]
    };
    this.changeSearchValue = value => this.setState({ searchValue: value });
  }

  render() {
    const { columns, selection, searchValue } = this.state;
    const { classes } = this.props;

    return (
      <DashboardLayout title="Warranty Registrations">
        <Query query={getAllDealerWarrantyRegistrations}>
          {({ data, loading, error }) => {
            if (loading)
            return (
              <div className="flex center" style={{ padding: "2em 0" }}>
                <CircularProgress color="secondary" />
              </div>
            );
            if (error) return <p>Error</p>;
            return (
              <div className={classes.root + " sectionContainer"}>
                <Typography gutterBottom variant="h4" className="title">
                  <span>Warranty Registrations</span>
                </Typography>

                <Paper className="tableStyles">
                  <div className={classes.header}>
                    <GridContainer container justify="space-between" className={classes.iconHeaderContainer}>
                      <GridContainer item xs={12} sm={3}>
                     {/*  <a href="javascript:window.print()">
                        <Print className={classes.printIcon} />
                      </a> */}
                      </GridContainer>
                    </GridContainer>
                  </div>
                  <Grid rows={data.warranty_registration} columns={columns}>
                    <SelectionState
                      selection={selection}
                      onSelectionChange={this.changeSelection}
                    />
                    <SearchState
                      value={searchValue}
                      onValueChange={this.changeSearchValue}
                    />
                    <SortingState
                      defaultSorting={[{ columnName: 'warranty_id', direction: 'desc' }]}
                    />
                    <PagingState defaultCurrentPage={0} pageSize={10} />
                    <IntegratedSelection />
                    <IntegratedFiltering />
                    <IntegratedSorting />
                    <IntegratedPaging />
                    <RowDetailState defaultExpandedRowIds={[0]} />
                    <Table />
                    <TableHeaderRow showSortingControls />
                    <TableRowDetail contentComponent={RowDetail} />
                    <TableSelection showSelectAll />
                    <PagingPanel />
                    <Toolbar />
                    <SearchPanel />
                  </Grid>
                </Paper>
              </div>
            );
          }}
        </Query>
      </DashboardLayout>
    );
  }
}
Registrations.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Registrations);
