import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { Button } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import auth from "../../../Auth";
import PropTypes from 'prop-types';

const styles = theme => ({
    button: {
        margin: 8,
    },
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "100%",
        textAlign: "center"
    },
    dense: {
        marginTop: 19
    },
    formControl: {
        margin: theme.spacing(3)
    }
});

const UPDATE_WARRANTY = gql`
  mutation updateWarrantyClaim(
    $id: uuid!
    $status: String!
    $comments: String!
    $fulfilledon: date!
  ) {
    update_warranty_claims(
      where: { id: { _eq: $id } }
      _set: { status: $status, comments: $comments, fulfilledon: $fulfilledon }
    ) {
      affected_rows
      returning {
        id
        status
        comments
        fulfilledon
      }
    }
  }
`;

    
const WarrantyClaimUpdateComment = (props) => {
    const message = "";
    const { classes } = props;
    const { values, handleChange, resetState } = useForm(submitForm, props.warranty.comments);

    function submitForm() {
    }
    function completedAdd(payload) {
        toast("Warranty Claim Successfully Updated");
        resetState();
    }
    return (
        
        <Mutation mutation={UPDATE_WARRANTY} onCompleted={completedAdd}>
        {(warranties, { data }) => {
            if (message) {
                return <div> {message} </div>
            }
            else {
                return (
                <div style={{textAlign: "center"}}>
                <form
                    onSubmit={e => {
                    //values.profile_id = auth.getSub();
                    props.addClick()
                    e.preventDefault();
                    warranties({
                        variables: {
                            id:props.warranty.id,
                            comments:values.comments,  
                            status:props.btnStatus,  
                            fulfilledon: "now()"
                        }
                    });

                    return false;
                    }}
            autoComplete="off"
          >
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >

              {/* Row 1 */}
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
              <TextField
                id="comments"
                name="comments"
                label="Comments"
                value={values.comments || ""}
                placeholder="Comments"
                onChange={handleChange}
                className={classes.textField}
                margin="normal"
                required={props.btnStatus !== 'approved'}
                variant="outlined"
              />
              </Grid>
              <Grid item xs={12} align="center">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  type="submit"
                >
                <span>{props.btnText}</span>
                </Button>
              </Grid>
            </Grid>
          </form>
                </div>
                )
            }
        }
        }
    </Mutation>
    );

};

WarrantyClaimUpdateComment.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(WarrantyClaimUpdateComment);