import { gql } from 'apollo-boost'

export const GET_ADMIN_CO_OP_REQUESTS_DATAS = gql`
subscription co_op_requests {
  co_op_requests(
    order_by: {created_at: desc}
  ) {
    id
    status
    date_submitted
    form_type
    profile{
      email
    }
    status
    co_op_comments(
        order_by: {created_at: asc}
      ){
      id
      comment
      created_at
      status
      profile_name
    }
    advertising_claim_forms{
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      fax
      address
      city
      country
      state
      zipcode
      additional_info
      paid_invoice_url
      tear_sheet_url
      claim_form_url
      affidavit_url
      photo_of_sign_url
      profile{
        name
        email
      }
      advertising_claim_form_data_requirements(
        order_by: {created_at: asc}
      ){
        advertising_claim_form_id
        issue_date
        advertising_description
        id
        gross_cost
        approved
        cost_of_approved
        program_credit
        co_op_credit
        created_at
        updated_at
      }
    }
    media_pre_approval_forms{
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      zipcode
      country
      state
      fax
      advertising_title
      media_name
      approved_credit
      additional_info
      profile{
        name 
        email
      }
    }
    special_project_forms {
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      country
      state
      zipcode
      fax
      additional_info
      description
      total_cost
      devoted
      cost_devoted
      total_credit
      profile{
        name
        email
      }
    }
    government_commerical_sales_credit_claims {
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      zipcode
      country
      state
      fax
      additional_info
      profile{
        name
        email
      }
      file1
      file2
      file3
      file4
      government_commerical_sales_credit_claim_data(
        order_by: {created_at: asc}
      ){
        id
        government_commerical_sales_credit_claim_id
        buyer_name
        item_name
        serial_no
        dealer_cost
        credit
        total_credit
      }
    }
  }
}
`;

export const GET_ADMIN_CO_OP_REQUESTS_DATA_STATUS = gql`
subscription co_op_requests($status : String) {
  co_op_requests(
    where: {status: {_eq: $status}},
    order_by: {created_at: desc}
  ) {
    id
    status
    date_submitted
    form_type
    profile{
      email
    }
    status
    co_op_comments(
        order_by: {created_at: asc}
      ){
      id
      comment
      created_at
      status
      profile_name
    }
    advertising_claim_forms{
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      fax
      address
      city
      country
      state
      zipcode
      additional_info
      paid_invoice_url
      tear_sheet_url
      claim_form_url
      affidavit_url
      photo_of_sign_url
      profile{
        name
        email
      }
      advertising_claim_form_data_requirements(
        order_by: {created_at: asc}
      ){
        advertising_claim_form_id
        issue_date
        advertising_description
        id
        gross_cost
        approved
        cost_of_approved
        program_credit
        co_op_credit
        created_at
        updated_at
      }
    }
    media_pre_approval_forms{
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      zipcode
      country
      state
      fax
      advertising_title
      media_name
      approved_credit
      additional_info
      profile{
        name 
        email
      }
    }
    special_project_forms {
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      country
      state
      zipcode
      fax
      additional_info
      description
      total_cost
      devoted
      cost_devoted
      total_credit
      profile{
        name
        email
      }
    }
    government_commerical_sales_credit_claims {
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      zipcode
      country
      state
      fax
      additional_info
      profile{
        name
        email
      }
      file1
      file2
      file3
      file4
      government_commerical_sales_credit_claim_data(
        order_by: {created_at: asc}
      ){
        id
        government_commerical_sales_credit_claim_id
        buyer_name
        item_name
        serial_no
        dealer_cost
        credit
        total_credit
      }
    }
  }
}
`;

export const GET_ADMIN_CO_OP_REQUESTS_DATA_WITHOUT_FORM_TYPE = gql`
subscription co_op_requests($status : String) {
  co_op_requests(
    where: {status: {_eq: $status}},
    order_by: {created_at: desc}
  ) {
    id
    status
    date_submitted
    form_type
    profile{
      email
    }
    status
    co_op_comments(
        order_by: {created_at: asc}
      ){
      id
      comment
      created_at
      status
      profile_name
    }
    advertising_claim_forms{
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      fax
      address
      city
      country
      state
      zipcode
      additional_info
      paid_invoice_url
      tear_sheet_url
      claim_form_url
      affidavit_url
      photo_of_sign_url
      profile{
        name
        email
      }
      advertising_claim_form_data_requirements(
        order_by: {created_at: asc}
      ){
        advertising_claim_form_id
        issue_date
        advertising_description
        id
        gross_cost
        approved
        cost_of_approved
        program_credit
        co_op_credit
        created_at
        updated_at
      }
    }
    media_pre_approval_forms{
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      zipcode
      country
      state
      fax
      advertising_title
      media_name
      approved_credit
      additional_info
      profile{
        name 
        email
      }
    }
    special_project_forms {
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      country
      state
      zipcode
      fax
      additional_info
      description
      total_cost
      devoted
      cost_devoted
      total_credit
      profile{
        name
        email
      }
    }
    government_commerical_sales_credit_claims {
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      zipcode
      country
      state
      fax
      additional_info
      profile{
        name
        email
      }
      file1
      file2
      file3
      file4
      government_commerical_sales_credit_claim_data(
        order_by: {created_at: asc}
      ){
        id
        government_commerical_sales_credit_claim_id
        buyer_name
        item_name
        serial_no
        dealer_cost
        credit
        total_credit
      }
    }
  }
}
`;

export const GET_ADMIN_CO_OP_REQUESTS_DATA = gql`
subscription co_op_requests($status : String, $form_type: String) {
  co_op_requests(
    where: {status: {_eq: $status}, form_type: {_eq: $form_type }},
    order_by: {created_at: desc}
  ) {
    id
    status
    date_submitted
    form_type
    profile{
      email
    }
    status
    co_op_comments(
        order_by: {created_at: asc}
      ){
      id
      comment
      created_at
      status
      profile_name
    }
    advertising_claim_forms{
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      fax
      address
      city
      country
      state
      zipcode
      additional_info
      paid_invoice_url
      tear_sheet_url
      claim_form_url
      affidavit_url
      photo_of_sign_url
      profile{
        name
        email
      }
      advertising_claim_form_data_requirements(
        order_by: {created_at: asc}
      ){
        advertising_claim_form_id
        issue_date
        advertising_description
        id
        gross_cost
        approved
        cost_of_approved
        program_credit
        co_op_credit
        created_at
        updated_at
      }
    }
    media_pre_approval_forms{
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      zipcode
      country
      state
      fax
      advertising_title
      media_name
      approved_credit
      additional_info
      profile{
        name 
        email
      }
    }
    special_project_forms {
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      country
      state
      zipcode
      fax
      additional_info
      description
      total_cost
      devoted
      cost_devoted
      total_credit
      profile{
        name
        email
      }
    }
    government_commerical_sales_credit_claims {
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      zipcode
      country
      state
      fax
      additional_info
      profile{
        name
        email
      }
      file1
      file2
      file3
      file4
      government_commerical_sales_credit_claim_data(
        order_by: {created_at: asc}
      ){
        id
        government_commerical_sales_credit_claim_id
        buyer_name
        item_name
        serial_no
        dealer_cost
        credit
        total_credit
      }
    }
    boat_show_credits {
      created_at
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      state
      country
      zipcode
      fax
      additional_info
      booth_cost
      booth_size
      devoted
      booth_cost
      devoted_cost
      booth_space_url
      booth_space_url_tool
      co_op_request {
        id
        co_op_comments {
          id
          co_op_id
          comment
          status
          created_at
          profile_name
        }
      }
    }
  }
}
`;

export const ADMIN_UPDATE_CO_OP_REQUEST_STATUS = gql`
  mutation update_co_op_requests($id: uuid!, $status: String!) {
    update_co_op_requests(
      where: { id: { _eq: $id } }
      _set: {
        status:$status
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_ADVERTISING_CLAIM_FORM_STATUS = gql`
  mutation update_advertising_claim_form_status($id: uuid!, $status: String) {
    update_advertising_claim_form(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export const UPDATE_MEDIA_PRE_APPROVAL_FORM_STATUS = gql`
  mutation update_media_pre_approval_forms($id: uuid!, $status: String) {
    update_media_pre_approval_forms(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export const UPDATE_SPECIAL_PROJECT_FORM_STATUS = gql`
  mutation update_special_project_forms($id: uuid!, $status: String) {
    update_special_project_forms(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export const UPDATE_GOVT_CLAIM_FORM_STATUS = gql`
  mutation update_government_commerical_sales_credit_claim($id: uuid!, $status: String) {
    update_government_commerical_sales_credit_claim(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export const ADD_CO_OP_COMMENTS = gql`
  mutation insert_co_op_comments(
    $objects: [co_op_comments_insert_input!]!
  ) {
    insert_co_op_comments(objects: $objects) {
      returning {
        id
      }
    }
  }
`;