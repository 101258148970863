import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
  // Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
import List from '@material-ui/core/List';
// Component styles
import styles from "theme/skin";
import WarrantyClaimsTabs from "./WarrantyClaims/WarrantyClaimsTabs";
class WarrantyClaims extends React.PureComponent {
  render() {
    const { classes } = this.props;  
  return (
    <DashboardLayout title="Warranty Claims">
      <div className={classes.root}>
        <List dense={true}>
          <WarrantyClaimsTabs />
        </List>
      </div>
     </DashboardLayout>
    );
  }
}
WarrantyClaims.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WarrantyClaims);