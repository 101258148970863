import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import apollo from "apollo";
import axios from "axios";
import FileSaver from 'file-saver';
import { toast } from "react-toastify";

const styles = {
  card: {
    maxWidth: "210px",
    width: "200px",
    marginRight: 25,
    marginBottom: 25,
    justifyContent: "end"
  },
  media: {
    height: "100px",
    width: "90%",
    margin: "0 auto"
  }
};

const APP_SERVER = apollo.APP_UPLOAD_SERVER_URL;

function getFileName(url) {
  return url.substring(url.lastIndexOf("/") + 1);
}

async function downloadImageHandler(imagelink) {
  var proxyUrl = 'https://cors-anywhere.herokuapp.com/'
  var imageURL = APP_SERVER + '/' + imagelink;
  
  axios.get(proxyUrl + imageURL, { responseType:"blob" })
      .then((response) => {
        console.log(response)
        //from downloadjs it will download your file
        //download(blob, "file.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");  
        //saveAs(blob, "image.jpg");
        var blob = new Blob([response.data], {type: response.headers.contentType});
        FileSaver.saveAs(blob, getFileName(imageURL));
      })
      .catch((error) => {
        toast("Image does not exist on this server");
        console.log(error)
      })
}

function AlbumMediaItem(props) {
  //const client = apollo.clientForToken(auth.getIdToken());

  const { classes } = props;
  const { item } = props;
  const { removeHandler } = props;
  if (item.media_type === "") item.media_type = "image";

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          component={item.media_type}
          className={classes.media}
          image={APP_SERVER + '/' + item.preview_link}
          title={item.name}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{ textAlign: "center" }}
          >
            {item.name}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className="flex center">
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => downloadImageHandler(item.preview_link)}
          style={{ width: "100px" }}
        >
          Download
        </Button>

        <Button
          size="small"
          color="primary"
          variant="contained"
          style={{ width: "100px" }}
          onClick={e =>
            window.confirm("Are you sure you wish to remove all items?") &&
            removeHandler(item.id)
          }
        >
          Delete
        </Button>
      </CardActions>
    </Card>
  );
}

AlbumMediaItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AlbumMediaItem);
