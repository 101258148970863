import React from "react";
import { Query } from "react-apollo";
import { getAllWarrantyInquiries } from "../../queries";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import { Print } from "@material-ui/icons";
import {
  SelectionState,
  RowDetailState,
  PagingState,
  IntegratedPaging,
  IntegratedSelection
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  PagingPanel
} from "@devexpress/dx-react-grid-material-ui";
import WarrantyInquiriesFulfilledBy from "./WarrantyInquiriesFulfilledBy";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
import { withStyles, Typography } from "@material-ui/core";

// Component styles
import styles from "theme/skin";

const RowDetail = ({ row }) => (
  <div>
    <table cellSpacing="0" className="detailTable">
      <tbody>
        <tr>
          <td>Date Received:</td>
          <td>{row.request_date}</td>
        </tr>
        <tr>
          <td>Model:</td>
          <td>{row.model}</td>
        </tr>
        <tr>
          <td>HIN:</td>
          <td>{row.hull_identification_number}</td>
        </tr>
        <tr>
          <td>First Name:</td>
          <td>{row.first_name}</td>
        </tr>
        <tr>
          <td> Last Name:</td>
          <td>{row.last_name}</td>
        </tr>
        <tr>
          <td>Address:</td>
          <td>{row.address}</td>
        </tr>
        <tr>
          <td>Address 2:</td>
          <td>{row.address1}</td>
        </tr>
        <tr>
          <td>Phone:</td>
          <td>{row.phone}</td>
        </tr>
        <tr>
          <td>City:</td>
          <td>{row.city}</td>
        </tr>
        <tr>
          <td>State:</td>
          <td>{row.region}</td>
        </tr>
        <tr>
          <td>Country:</td>
          <td>{row.country}</td>
        </tr>
        <tr>
          <td>Zipcode:</td>
          <td>{row.zipcode}</td>
        </tr>
        <tr>
          <td>Email:</td>
          <td>{row.email}</td>
        </tr>
        <tr>
          <td>Phone:</td>
          <td>{row.phone}</td>
        </tr>
        <tr>
          <td>Comments: </td>
          <td>{row.comments}</td>
        </tr>

        <tr>
          <td>Status:</td>
          <td>
            <WarrantyInquiriesFulfilledBy tag={row.fulfilledby} id={row.id} />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

class WarrantyInquiries extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "request_date", title: "Date" },
        { name: "model", title: "Model" },
        {
          name: "name",
          title: "Name",
          getCellValue: row =>
            row.first_name + row.last_name
              ? row.first_name + " " + row.last_name
              : undefined
        },
        { name: "fulfilledby", title: "Fulfilled By" },
        { name: "fulfilledon", title: "Fulfilled On" }
      ]
    };
    this.setSelectionValue = value => this.setState({ selection: value });
  }

  render() {
    const { columns, selection } = this.state;
    const { classes } = this.props;
    return (
      <DashboardLayout title="Warranty Inquiries">
        <Query query={getAllWarrantyInquiries}>
          {({ data, loading, error }) => {
            if (loading) return <p>Loading...</p>;
            if (error) return <p>Error</p>;
            return (
              <div className={classes.root + " sectionContainer"}>
                {" "}
                <Typography gutterBottom variant="h4" className="title">
                  <span>Warranty Inquiries</span>
                </Typography>
                <Paper className="tableStyles">
                  <div className={classes.header}>
                    <span>Total rows selected: {selection ? ( selection.length ) : ( "0" )}</span>
                    <a href="javascript:window.print()">
                      <Print className={classes.printIcon} />
                    </a>
                  </div>

                  <Grid rows={data.warranty_inquiries} columns={columns}>
                    <SelectionState
                      selection={selection}
                      onSelectionChange={this.setSelectionValue}
                    />
                    <PagingState defaultCurrentPage={0} pageSize={5} />
                    <IntegratedSelection />
                    <IntegratedPaging />
                    <RowDetailState defaultExpandedRowIds={[0]} />
                    <Table />
                    <TableHeaderRow />
                    <TableRowDetail contentComponent={RowDetail} />
                    <TableSelection showSelectAll />
                    <PagingPanel />
                  </Grid>
                </Paper>
                {/* <div className="flex center">
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                  >
                    Mark As Fulfilled
                  </Button>
                </div> */}
              </div>
            );
          }}
        </Query>
      </DashboardLayout>
    );
  }
}
WarrantyInquiries.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WarrantyInquiries);
