import React, { useState } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-material-ui";
import {
  IntegratedPaging,
  PagingState,
  RowDetailState,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import "../../FormsDatasheets/CoopPreapprovalClaimForms/ApprovalForms/CoOpTabs/RequestRowDetail.scss";
import { FormTypeEnum, StatusEnum } from "pages/FormsDatasheets/enum";
import AdvertisingClaimFormDetail from "pages/FormsDatasheets/CoopPreapprovalClaimForms/ApprovalForms/AdvertisingClaimForm/AdvertisingClaimFormDetail";
import { MediaPreApprovalFormRequestRowDetails } from "pages/FormsDatasheets/CoopPreapprovalClaimForms/NewRequest/MediaPreApprovalFormRequestRowDetails";
import { SpecialProjectFormRequestRowDetail } from "pages/FormsDatasheets/CoopPreapprovalClaimForms/NewRequest/SpecialProjectFormRequestRowDetail";
import { CommericalSalesCreditClaimRequestRowDetail } from "pages/FormsDatasheets/CoopPreapprovalClaimForms/NewRequest/CommericalSalesCreditClaimRequestRowDetail";
import { BoatShowCreditRowDetail } from "pages/FormsDatasheets/CoopPreapprovalClaimForms/NewRequest/BoatShowCreditRowDetails";

const columns = [
  {
    name: "date_submitted",
    title: "Submitted By",
    getCellValue: (row) => new Date(row?.date_submitted).toLocaleDateString(),
  },
  {
    name: "form_type",
    title: "Form",
    getCellValue: (row) => FormTypeEnum[row?.form_type] || "",
  },
  {
    name: "email",
    title: "Submitted By",
    getCellValue: (row) => row?.profile?.email || "",
  },
  { name: "status", title: "Status" },
];

const LastColumnCell = ({ column, value }) => (
  <Table.Cell
    style={
      column.name === "status"
        ? { color: StatusEnum[value]?.color, fontWeight: "bold" }
        : {}
    }
  >
    {column.name === "status" ? StatusEnum[value]?.label : value}
  </Table.Cell>
);

const AdminStatusTable = ({ queryData, type, status, userName }) => {
  const [expandedRowIds, setExpandedRowIds] = useState([0]);
  const [searchValue, setSearchValue] = useState("");

  const toggleRowExpand = (rowId) => {
    setExpandedRowIds((prevState) =>
      prevState.includes(rowId)
        ? prevState.filter((id) => id !== rowId)
        : [...prevState, rowId]
    );
  };

  const TableRow = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => toggleRowExpand(tableRow.rowId)}
      style={{ cursor: "pointer" }}
    />
  );

  const RowDetail = ({ row }) => {
    if (queryData) {
      const findData = queryData?.find((rows) => rows?.id === row?.id);
      const comments = findData?.co_op_comments;
      if (findData?.form_type === "media-pre-approval") {
        const rowData = findData?.media_pre_approval_forms?.[0];
        return (
          <div>
            <MediaPreApprovalFormRequestRowDetails
              userName={userName}
              comments={comments}
              isEdit={false}
              row={rowData}
              type="admin"
              co_op_id={row?.id}
            />
          </div>
        );
      }
      if (findData?.form_type === "co-op-advertising-claim") {
        const rowData = findData?.advertising_claim_forms?.[0];
        return (
          <div>
            <AdvertisingClaimFormDetail
              userName={userName}
              comments={comments}
              isEdit={false}
              row={rowData}
              type="admin"
              co_op_id={row?.id}
              requirementDataArray={
                rowData?.advertising_claim_form_data_requirements
              }
            />
          </div>
        );
      }
      if (findData?.form_type === "special-project-request") {
        const rowData = findData?.special_project_forms?.[0];
        return (
          <div>
            <SpecialProjectFormRequestRowDetail
              userName={userName}
              comments={comments}
              isEdit={false}
              row={rowData}
              type="admin"
              co_op_id={row?.id}
            />
          </div>
        );
      }
      if (findData?.form_type === "govt-com-sales-credit-claim") {
        const rowData =
          findData?.government_commerical_sales_credit_claims?.[0];
        return (
          <div>
            <CommericalSalesCreditClaimRequestRowDetail
              userName={userName}
              comments={comments}
              isEdit={false}
              row={rowData}
              type="admin"
              co_op_id={row?.id}
              requirementDataArray={
                rowData?.government_commerical_sales_credit_claim_data
              }
            />
          </div>
        );
      }
      if (findData?.form_type === "boat-show-credit") {
        const rowData = findData?.boat_show_credits?.[0];
        return (
          <div>
            <BoatShowCreditRowDetail
              userName={userName}
              comments={comments}
              isEdit={false}
              row={rowData}
              type="admin"
              co_op_id={row?.id}
              requirementDataArray={rowData?.boat_show_credits}
            />
          </div>
        );
      }
      return <div>Row details not available.</div>;
    }
  };

  return (
    <div className="request-table-pg custom-table-event">
      {/* {status === "new" || status === "pending" ? ( */}
        <Grid rows={queryData} columns={columns}>
          <RowDetailState
            expandedRowIds={expandedRowIds}
            onExpandedRowIdsChange={setExpandedRowIds}
          />
          <PagingState defaultCurrentPage={0} pageSize={10} />
          <IntegratedPaging />
          <SearchState value={searchValue} onValueChange={setSearchValue} />
          <IntegratedFiltering />
          <Table
            cellComponent={(props) => <LastColumnCell {...props} />}
            rowComponent={TableRow}
          />
          <TableHeaderRow />
          <PagingPanel />
          <Toolbar />
          <SearchPanel />
          <TableRowDetail contentComponent={RowDetail} />
        </Grid>
      {/* ) : (
        <Grid rows={queryData} columns={columns}>
          <PagingState defaultCurrentPage={0} pageSize={10} />
          <IntegratedPaging />
          <SearchState value={searchValue} onValueChange={setSearchValue} />
          <IntegratedFiltering />
          <Table cellComponent={(props) => <LastColumnCell {...props} />} />
          <TableHeaderRow />
          <PagingPanel />
          <Toolbar />
          <SearchPanel />
        </Grid>
      )} */}
    </div>
  );
};

export default AdminStatusTable;
