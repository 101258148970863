import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    Font,
    StyleSheet
} from "@react-pdf/renderer";
import WarrantyClaimPdfImages from "./WarrantyClaimPdfImages";

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff",
        textAlign: 'left',
        display: "flex",
        height: "100%",
        margin: 10
    },
    rowContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        width: "50%",
        alignItems: "left",
        margin: 2
    },
    rowHrline: {
        borderBottomColor: "#2D6C9B",
        borderBottomWidth: '2'
    },
    rowContainerFull: {
        display: "inline-block",
        textAlign: "left",
        width: "100%",
        marginBottom: 8
    },
    rowContainerWide: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        width: "100%",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap:"wrap"
    },
    rowFull: {
           margin: 'auto'
    },
    column: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "100%",
        flex: 1
    },
    columnFull: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "100%",
        flex: 1
    },
    columnHalf: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "50%",
        flex: 1
    },
    field_header: {
        padding: 2,
        fontSize: 12,
        fontFamily: 'Oswald',
        fontWeight: 700
    },
    field_text: {
        padding: 2,
        fontFamily: 'Oswald',
        fontSize: 9
    },
    sectionHeader: {
        color: '#2D6C9B',
        fontSize: 14,
        margin: 4,
        fontFamily: 'Oswald'
    },
    subHeader: {
        color: '#2D6C9B',
        fontSize: 12,
        margin: 6,
        fontFamily: 'Oswald'
    },
    pageNumbers: {
        position: 'absolute',
        fontSize: 14,
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    }
});

export function WarrantyClaimPdfDocument(props) {
    const newWarrantyClaim = props.data;

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.rowContainerWide}>
                    <View style={styles.columnHalf}>
                        <Text style={styles.sectionHeader}>Warranty Claim</Text>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_text}>Claim Status: {newWarrantyClaim.status}</Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.sectionHeader}>Dealer Information</Text>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_text}>{newWarrantyClaim.admin_name}</Text>
                                    <Text style={styles.field_text}>{newWarrantyClaim.admin_email}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_text}>Associate Name: {newWarrantyClaim.associate_name}</Text>
                                    <Text style={styles.field_text}>Associate Email: {newWarrantyClaim.associate_email}</Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.sectionHeader}>Product Information</Text>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_text}>Brand: {newWarrantyClaim.brand}</Text>
                                    <Text style={styles.field_text}>Model: {newWarrantyClaim.model}</Text>
                                    <Text style={styles.field_text}>Older Model: {newWarrantyClaim.boat_model ? (newWarrantyClaim.boat_model.older_model ? "Yes" : "No") : ""}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_text}>HIN: {newWarrantyClaim.hull_identification_number}</Text>
                                    <Text style={styles.field_text}>Purchase Date: {newWarrantyClaim.purchase_date}</Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.sectionHeader}>Customer Information</Text>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_text}>Phone: {newWarrantyClaim.phone}</Text>
                                    <Text style={styles.field_text}>Email: {newWarrantyClaim.email}</Text>
                                    <Text style={styles.field_text}>Address: </Text>
                                    <Text style={styles.field_text}>{newWarrantyClaim.first_name} {newWarrantyClaim.last_name}</Text>
                                    <Text style={styles.field_text}>{newWarrantyClaim.address}</Text>
                                    <Text style={styles.field_text}>{newWarrantyClaim.city}, {newWarrantyClaim.region} {newWarrantyClaim.country} {newWarrantyClaim.zipcode}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.columnHalf}>
                        <Text style={styles.sectionHeader}>Claim Information</Text>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Motor Details</Text>
                                    <Text style={styles.field_text}>{newWarrantyClaim.motor_details || " "}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Issues</Text>
                                    <Text style={styles.field_text}>{newWarrantyClaim.issues || " "}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Repairs</Text>
                                    <Text style={styles.field_text}>{newWarrantyClaim.repairs || " "}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Parts</Text>
                                    <Text style={styles.field_text}>{newWarrantyClaim.parts || " "}</Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.sectionHeader}>Labor Details</Text>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_text}>Claim Total: {newWarrantyClaim.claim_total || " "}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Labor Hours ($)</Text>
                                    <Text style={styles.field_text}>{newWarrantyClaim.labor_hours || " "}</Text>
                                    <Text style={styles.field_header}>Labor Total ($)</Text>
                                    <Text style={styles.field_text}>{newWarrantyClaim.labor_total || " "}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Parts</Text>
                                    <Text style={styles.field_text}>{newWarrantyClaim.parts_used || " "}</Text>
                                    <Text style={styles.field_header}>Other</Text>
                                    <Text style={styles.field_text}>{newWarrantyClaim.other || " "}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
            {newWarrantyClaim.photos !== null && newWarrantyClaim.photos.length > 0 && 
                <Page style={styles.page}>
                    <View style={styles.rowContainerFull}>
                        <View style={styles.rowFull}>
                            <View style={styles.columnFull}>
                                <WarrantyClaimPdfImages profileid={newWarrantyClaim.profile_id} photos={newWarrantyClaim.photos} />
                            </View>
                        </View>
                    </View>
                    <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }
        </Document>
    );
}