import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button, Divider, Grid, Paper, Typography } from "@material-ui/core";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

// Component styles
import styles from "theme/skin";
function CenteredGrid(props) {
  const { classes } = props;
  return (
    <DashboardLayout title="MSRP Datasheets">
      <div className={classes.root + " sectionContainer"}>
        <Typography gutterBottom variant="h2" align="center" className="title">
          <span>msrp forms</span>
        </Typography>

        <Paper className={classes.paper}>
          <Grid container justify="center">
            <Typography
              align="center"
              component="h2"
              className={classes.msrpTitle}
            >
              MSRP Datasheets - 2020
            </Typography>
            <Divider variant="middle" />
          </Grid>

          <div style={{ margin: "0 25%" }}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Button
                  color="primary"
                  variant="contained"
                  href="#"
                  className={classes.button}
                  style={{ margin: "2em 0px", width: "15em" }}
                  onClick={() => window.open('/docs/MSRP-2020-Carolina-Skiff-Boats-and-Parts-Final-8-22-19.xlsx') }
                >
                  Carolina Skiff
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  color="primary"
                  variant="contained"
                  href="#"
                  className={classes.button}
                  style={{ margin: "2em 0px", width: "15em" }}
                  onClick={() => window.open('/docs/MSRP-2020-Sea-Chaser-Boats-and-Parts-Final-8-22-19.xlsx') }
                >
                  Sea Chaser
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </div>
    </DashboardLayout>
  );
}

CenteredGrid.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CenteredGrid);
