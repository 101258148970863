import React, { useState } from "react";
import {CSVLink} from "react-csv";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import {
    Checkbox,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Paper,
    Popover,
    withStyles
} from "@material-ui/core";

let csvHeaders = [
    { label: 'name', key: 'name' },
    { label: 'email', key: 'email' },
    { label: 'company', key: 'company' },
    { label: 'phone', key: 'phone' },
    { label: 'country', key: 'country' },
    { label: 'region', key: 'region' },
    { label: 'zipcode', key: 'zipcode' },
    { label: 'city', key: 'city' },
    { label: 'address', key: 'address' },
    { label: 'address2', key: 'address2' },
    { label: 'status', key: 'status' },
    { label: 'has_carolinaskiff', key: 'has_skiff' },
    { label: 'has_funchaser', key: 'has_funchaser' },
    { label: 'has_seachaser', key: 'has_seachaser' },
    { label: 'permissions', key: 'permissions' },
    { label: 'created_at', key: 'created_at' },
    { label: 'location_coordinates', key: 'location.coordinates' }
];

const styles = {
    csvExport: {
        paddingRight: '15px'
    },
    settingsIcon: {
        color: 'rgb(0,0,238)',
        cursor: 'pointer'
    },
    settingsHeader: {
        fontSize: '1rem'
    },
    settingsItem: {
        fontSize: '.875rem'
    }
};

function DealersGridCommandIcons(props) {
    const { classes } = props;
    const [popoverAnchorElement, setPopoverAnchorElement] = useState(null);
    const [settingsPopoverVisible, setSettingsPopoverVisible] = useState(false);
    const [includeArchived, setIncludeArchived] = useState(localStorage.getItem('includeArchived') === 'true');

    return <div style={{float: 'right', paddingRight: '15px'}}>
        <CSVLink headers={csvHeaders} className={classes.csvExport} data={props.profiles.map(d => {
            let copy = JSON.parse(JSON.stringify(d)); // react-csv can't handle nulls when selecting from nested json.
            if (!copy.location) {
                copy.location = {};
            }
            return copy;
        })} target="_blank" filename={"export-dealers.csv"}>
            <Tooltip title="Download to CSV">
                <CloudDownloadOutlinedIcon />
            </Tooltip>
        </CSVLink>
        <SettingsIcon
            className={classes.settingsIcon}
            onClick={(event) => {
                setPopoverAnchorElement(event.currentTarget);
                setSettingsPopoverVisible(true);
            }}
        />
        <Popover
            variant="outlined"
            open={settingsPopoverVisible}
            anchorEl={popoverAnchorElement}
            onClose={() => {
                props.saveSettings(includeArchived);
                setSettingsPopoverVisible(false);
            }}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        >
            <Paper style={{height: '120px'}}>
                <List className={classes.root}
                      subheader={
                          <ListSubheader classes={{root: classes.settingsHeader}} component="div" id="grid-settings-subheader">
                              Settings
                          </ListSubheader>
                      }
                >
                    <ListItem key={0} role={undefined} dense button onClick={() => {
                        setIncludeArchived(!includeArchived);
                        localStorage.setItem('includeArchived', (!includeArchived).toString());
                    }}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={includeArchived}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': 'include-archived-checkbox' }}
                            />
                        </ListItemIcon>
                        <ListItemText classes={{primary: classes.settingsItem}} id={'include-archived-checkbox'} primary={`Include archived`} />
                    </ListItem>
                </List>
            </Paper>
        </Popover>
    </div>;
}

export default withStyles(styles)(DealersGridCommandIcons);