import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import BoatShowRequestsGrid from "./BoatShowRequestsGrid";
import BoatShowRequestForm from "./BoatShowRequestForm";
import BoatShowTabs from "./BoatShowTabs";
import { Paper, Typography } from "@material-ui/core";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});

class BoatShows extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <DashboardLayout title="Boat Shows">
          <div className={classes.root + " sectionContainer"}>
            <Typography
              gutterBottom
              variant="h2"
              align="center"
              className="title"
            >
              <span>Boat Shows</span>
            </Typography>
            <Paper>
              <div>
                <Typography
                  gutterBottom
                  variant="h2"
                  align="center"
                  style={{
                    fontSize: "20px",
                    fontWeight: "300",
                    textTransform: "uppercase",
                    padding: "1em 0px"
                  }}
                >
                  Pending Boat shows{" "}
                </Typography>
              </div>
              <BoatShowRequestsGrid />
            </Paper>
            <br />
            <Paper>
              <div className={classes.root}>
                <BoatShowRequestForm />
              </div>
            </Paper>
            <br />
            <Paper>
              <div>
                <Typography
                  gutterBottom
                  variant="h2"
                  align="center"
                  style={{
                    fontSize: "20px",
                    fontWeight: "300",
                    textTransform: "uppercase",
                    padding: "1em 0px"
                  }}
                >
                  All Upcoming Boat Shows
                </Typography>

                <BoatShowTabs />
              </div>
            </Paper>
          </div>
        </DashboardLayout>
      </div>
    );
  }
}

BoatShows.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BoatShows);
