import React from "react";
import {Mutation, Query} from "react-apollo";
import {DELETE_BOAT_MODEL, GET_BOAT_MODELS} from "../../../queries";
import PropTypes from "prop-types";
import {
  Button,
  Paper,
  Typography,
  CircularProgress, Dialog, DialogContent, DialogActions
} from "@material-ui/core";
import {
  RowDetailState,
  PagingState, CustomPaging
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar
} from "@devexpress/dx-react-grid-material-ui";
import { Grid as MaterialGrid, Paper as MaterialPaper } from "@material-ui/core";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
import { withStyles } from "@material-ui/core";
// Component styles
import styles from "theme/skin";
import AddBoatModel from "./AddBoatModel";

class WarrantyRegistrations extends React.PureComponent {
  constructor(props) {
    super(props);

    const { history } = props;

    const customStyles = {
      deleteBtn: {
        margin: 8,
        color: '#fff',
        backgroundColor: '#dc3545',
        borderColor: '#dc3545'
      },
      confirmDialogCancelContainer: {
        marginRight: 'auto'
      }
    }
    this.setSelectionValue = value => this.setState({ selection: value });
    this.onCurrentPageChange = value => this.onCurrentPageChange.bind(this);
    this.deleteRowClick = this.deleteRowClick.bind(this);
    this.state = {
      deleteDialogSettings: {
        dialogOpen: false,
        dialogModel: null
      },
      apolloArgs: {
        page: 0,
        limit: 25
      },
      columns: [
        { name: "model", title: "Model" },
        {
          name: "older_model",
          title: "Older Model",
          getCellValue: row => row.older_model ? "Yes" : "No"
        },
        {
          name: "edit",
          title: " ",
          getCellValue: row => <Button
              key={`edit-${row.id}`}
              variant="contained"
              color="secondary"
              onClick={() => {
                history.push(`/admin-boat-models/${row.id}/edit`);
              }}
          >Edit</Button>
        },
        {
          name: "delete",
          title: " ",
          getCellValue: row =>
              <div>
                <Button
                    variant="contained"
                    style={customStyles.deleteBtn}
                    onClick={() => this.deleteRowClick(row)}
                >Delete</Button>
              </div>
        }
      ],
      pageSizes: [25, 50, 100, 250, 500]
    };
  }
  
  deleteRowClick = (row) => {
    this.setState({
      deleteDialogSettings: {
        dialogOpen: true,
        dialogModel: row
      }
    });
  };
  
  changePageSize = (pageSize, refetch) => {
    this.setState(
      state => ({ apolloArgs: { ...state.apolloArgs, limit: pageSize } }),
      () => {
        const { apolloArgs } = this.state;
        refetch({
          skip: apolloArgs.page * apolloArgs.limit,
        });
      }
    );
  };

  loadMoreRegistrations = (currentPage, refetch, fetchMore) => {
    this.setState(
      state => ({
        ...state,
        apolloArgs: { ...state.apolloArgs, page: currentPage },
      }),
      () => {

        fetchMore({
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResult;
            }

            return {
              ...previousResult,
              data: fetchMoreResult.data,
            };
          },
        });

      }
    );
  };
  
  render() {
    const { columns, pageSizes, apolloArgs } = this.state;
    const { classes } = this.props;
    const { page, limit } = apolloArgs;
    return (
      <DashboardLayout title="Boat Models">
        <Query query={GET_BOAT_MODELS} notifyOnNetworkStatusChange={true}
               variables={{ skip: page * limit, limit }}
               fetchPolicy="cache-and-network">
          {({ data, loading, error, fetchMore, refetch }) => {
            this.refetch = refetch;
            if (loading)
              return (
                <div className="flex center" style={{ padding: "2em 0" }}>
                  <p>Loading...</p>
                  <CircularProgress />
                </div>
              );
            if (error) return <p>Error</p>;
            return (
              <div className={classes.root + " sectionContainer"}>
                <Typography gutterBottom variant="h4" className="title">
                  <span>Boat Models</span>
                </Typography>
                <AddBoatModel refresh={this.refetch} />
                <MaterialPaper className={classes.paper} style={{ margin: "2em 0px" }}>
                  <MaterialGrid container>
                    <MaterialGrid item xs align="center">
                    </MaterialGrid>
                  </MaterialGrid>
                </MaterialPaper>
                <Paper className="tableStyles">
                  <div className={classes.header}>
                    <span className={classes.headerTitle}>Boat Models</span>
                  </div>
                  <Grid rows={data.boat_models || []} columns={columns}>
                    <PagingState pageSize={apolloArgs.limit} currentPage={apolloArgs.page}
                                 onCurrentPageChange={_currentPage =>
                                     this.loadMoreRegistrations(_currentPage, refetch, fetchMore)
                                 }
                                 onPageSizeChange={_pageSize =>
                                     this.changePageSize(_pageSize, refetch)
                                 } />
                    <CustomPaging totalCount={data.boat_models_aggregate.aggregate.totalCount || 0} />
                    <RowDetailState />
                    <Table />
                    <TableHeaderRow />
                    <Toolbar />
                    <PagingPanel pageSize pageSizes={pageSizes} />
                  </Grid>
                </Paper>
              </div>
            );
          }}
        </Query>
        <Dialog open={this.state.deleteDialogSettings.dialogOpen}>
          <DialogContent dividers>
            {this.state.deleteDialogSettings.dialogOpen && <p>Are you sure you would like to delete the boat model <strong>{this.state.deleteDialogSettings.dialogModel.model}</strong>?</p>}
          </DialogContent>
          <DialogActions>
            <div>
              <Button autoFocus
                      variant="contained"
                      color="default"
                      onClick={() => this.setState({ deleteDialogSettings: {dialogOpen: false } }) }
              >Cancel</Button>
            </div>
            <Mutation
                mutation={DELETE_BOAT_MODEL}
                onCompleted={() =>
                {
                  this.setState({ deleteDialogSettings: {dialogOpen: false } })
                  this.refetch();
                }}
            >
              {(deleteBoatModelRequest, { loading, error }) => {
                return (
                    <div>
                      {loading && <p>Loading...</p>}
                      {error && <p>Error :( Please try again</p>}
                      {!loading && !error && <Button
                          variant="contained"
                          color="secondary"
                          style={styles.button}
                          onClick={() => {
                            deleteBoatModelRequest({
                              variables: {
                                id: this.state.deleteDialogSettings.dialogModel.id
                              }
                            });
                          }}
                      >Yes</Button>}
                    </div>
                );
              }}
            </Mutation>
          </DialogActions>
        </Dialog>
      </DashboardLayout>
    );
  }
}
WarrantyRegistrations.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WarrantyRegistrations);
