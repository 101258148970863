import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { Query } from "react-apollo";
import { GET_USER_INFO } from "queries";
import compose from "recompose/compose";
import PropTypes from "prop-types";
const styles = {};

function LeadsCurrentDealer(props) {
    const { item } = props;
    //console.log("LeadsCurrentDealer")
    //console.log(item)
    if(item.profile_id !=="") {
        return (
            <Query query={GET_USER_INFO} variables={{ id: item.profile_id }}>
            {({ loading, error, data }) => {
                if (loading) return null;
                if (error) return `Error! ${error}`;
                //console.log("QUERY DATA");
                //console.log(data);
                return (
                    <div>
                        {data.profiles_by_pk.name}
                    </div>
                );
            }}
            </Query>
        );
    } else {
        return (
            <div>
                NO Data
            </div>
        );
    }
}

LeadsCurrentDealer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(LeadsCurrentDealer);