import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import auth from 'Auth';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

import SidebarContentAdmin from 'components/SidebarContentAdmin';
import SidebarContent from 'components/SidebarContent';

// Component styles
import styles from './styles';
import boatLogo from "./../../../../images/boatlogo.svg";


class Sidebar extends Component {
	render() {
		const { classes, className } = this.props;
		const rootClassName = classNames(classes.root, className);
		return (
			<nav className={rootClassName}>
				<div className={classes.logoWrapper}>
					<Typography className={classes.nameText} variant='h6'>
						{auth.getRole().toLowerCase() === 'admin' ? (
							<Box>
								<Typography display='inline' className={classes.welcome}>
									Welcome
								</Typography>
								<Typography display='inline' className={classes.title}>
									Admin
								</Typography>
							</Box>
						) : (
							<Box>
								<Typography display='inline' className={classes.welcome}>
									Welcome
								</Typography>
								<Typography display='inline' className={classes.title}>
									Dealer
								</Typography>
							</Box>
						)}
					</Typography>
				</div>
				<div className={classes.profile}>
					<Typography className={classes.nameText} variant='h6'>
						<img
							alt='Carolina Skiff'
							className={classes.logoImage}
							src={boatLogo}
						/>
						{auth.getRole().toLowerCase() === 'admin' ? (
							auth.isAuthenticated() ? (
								' ' + auth.getNickName()
							) : (
								''
							)
						) : (
							<Link to='/profile' style={{ color: 'inherit' }}>
								{auth.isAuthenticated() ? ' ' + auth.getNickName() : ''}
							</Link>
						)}
					</Typography>
				</div>
				{/* <Divider className={classes.profileDivider} /> */}
				{auth.getRole().toLowerCase() === 'admin' ? (
					<SidebarContentAdmin userPermissions={auth.getPermissions()} />
				) : (
					<SidebarContent userPermissions={auth.getPermissions()} />
				)}{' '}
			</nav>
		);
	}
}

Sidebar.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Sidebar);
