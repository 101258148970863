import React, { Component } from "react";
import { Subscription } from "react-apollo";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
  SearchPanel,
  Toolbar
} from "@devexpress/dx-react-grid-material-ui";
import {
  RowDetailState,
  PagingState,
  SearchState,
  IntegratedPaging,
  IntegratedFiltering
} from "@devexpress/dx-react-grid";
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Typography, withStyles, TextField, Select } from "@material-ui/core";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import CareersUpdate from './CareersUpdate';
import { getAllApprovedCareers, getAllPendingCareers, getClosedCareers } from '../../../queries';

export const getPreviousOwners = gql`
    query getPreviousOwners($hull_identification_number: String!) {
        warranty_registration(
            where: { status: { _eq: "Archived" }, hull_identification_number: { _eq: $hull_identification_number } }
        ) {
            id
            profile_id
            hull_identification_number
            first_name
            last_name
            address
            address2
            city
            region
            country
            zipcode
            email
            phone
            purchase_date
            primary_use
            submittedon
            status
            warranty_id
        }
    }
`
const styles = theme => ({
  button: {
    margin: theme.spacing()
  },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 275
  },
    navBar: {
        display : 'flex',
        flexWrap: 'no-wrap',
    },
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};


const RowDetail = ({ row }) => (
    <div>
      <table cellSpacing="0" className="detailTable">
        <tbody>
          <tr>
            <td>Job Title:</td>
            <td>{row.title}</td>
          </tr>
          <tr>
            <td>Date:</td>
            <td>{row.date_needed}</td>
          </tr>
          <tr>
            <td>Location:</td>
            <td>{row.store_location}</td>
          </tr>
          <tr>
            <td>Salary Range:</td>
            <td>{row.salary_range}</td>
          </tr>
          <tr>
            <td>Description:</td>
            <td>{`${row.description.substr( 0, 50 )}...` }</td>
          </tr>
          <tr>
            <td>Submitted On:</td>
            <td>{row.submittedon}</td>
          </tr>
          <tr>
            <td colSpan="2">
              <CareersUpdate key={row.id} careers={row} status={row.status} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
  
class CareersTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCareerColumns: [
        { name: 'title', title: 'Job Title/Position' },
        { name: 'date_needed', title: 'Date' },
        { name: 'store_location', title: 'Store / Location' },
        { name: 'salary_range', title: 'Salary Range' },
        { name: 'description', title: 'Job Description' },
      ],
      closedCareerColumns: [
        { name: 'title', title: 'Job Title/Position' },
        { name: 'date_needed', title: 'Date' },
        { name: 'store_location', title: 'Store / Location' },
        { name: 'salary_range', title: 'Salary Range' },
        { name: 'description', title: 'Job Description' },
      ],
      activeTabIndex: 0,
    };
  }

  handleTabChange = (event, value) => {
      //console.log( event.target );
      this.setState({ activeTabIndex: value });
  };

  render() {
     const { classes } = this.props;
     const {
       currentCareerColumns,
       closedCareerColumns,
       activeTabIndex
     } = this.state;
    return (
      <div>
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs value={activeTabIndex} onChange={this.handleTabChange}>
              <Tab label="Approved" />
              <Tab label="Pending" />
              <Tab label="Closed" />
            </Tabs>
          </AppBar>
          {activeTabIndex === 0 && (
            <TabContainer>
                <div style={{textAlign: "center", fontWeight: "600", padding: 10}}>Approved Jobs</div> 
              <Subscription subscription={getAllApprovedCareers} >
                    {({ data, loading, error }) => {
                    if (loading) return <p>Loading...</p>;
                    if (error) return <p>Error</p>;
                    return (
                        <div className={classes.root}>
                        <Paper>
                            <Grid rows={data.careers} columns={currentCareerColumns}>
                            <PagingState defaultCurrentPage={0} pageSize={5} />
                            <RowDetailState defaultExpandedRowIds={[0]} />
                            <Table />
                            <TableHeaderRow />
                            <TableRowDetail contentComponent={RowDetail} />
                            </Grid>
                        </Paper>
                        </div>
                    );
                    }}
                </Subscription>
            </TabContainer>
          )}
          {activeTabIndex === 1 && (
            <TabContainer>
                <div style={{textAlign: "center", fontWeight: "600", padding: 10}}>Pending Jobs</div> 
              <Subscription subscription={getAllPendingCareers} >
                    {({ data, loading, error }) => {
                    if (loading) return <p>Loading...</p>;
                    if (error) return <p>Error</p>;
                    return (
                        <div className={classes.root}>
                        <Paper>
                            <Grid rows={data.careers} columns={currentCareerColumns}>
                            <PagingState defaultCurrentPage={0} pageSize={5} />
                            <RowDetailState defaultExpandedRowIds={[0]} />
                            <Table />
                            <TableHeaderRow />
                            <TableRowDetail contentComponent={RowDetail} />
                            </Grid>
                        </Paper>
                        </div>
                    );
                    }}
                </Subscription>
            </TabContainer>
          )}
          {activeTabIndex === 2 && (
            <TabContainer>
            <div style={{textAlign: "center", fontWeight: "600", padding: 10}}>Closed Jobs</div> 
              <Subscription subscription={getClosedCareers}>
                    {({ data, loading, error }) => {
                    if (loading) return <p>Loading...</p>;
                    if (error) return <p>Error</p>;
                    return (
                        <div className={classes.root}>
                          <Paper>
                            <Grid rows={data.careers} columns={closedCareerColumns}>
                              <PagingState defaultCurrentPage={0} pageSize={5} />
                              <RowDetailState defaultExpandedRowIds={[0]} />
                              <Table />
                              <TableHeaderRow />
                              <TableRowDetail contentComponent={RowDetail} />
                            </Grid>
                          </Paper>
                        </div>
                    );
                    }}
                </Subscription>
            </TabContainer>
          )}
        </div>
      </div>
    );
  }
}

CareersTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CareersTabs);