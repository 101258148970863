import React from 'react';
import './PdfDocumentStyles.scss';

export const SpecialProjectFormPdfDocument = ({ data }) => {

  return (
    <div className="document">
      <div className="page">
        <div className="sectionHeader">Special Project Form</div>
        <div className="rowContainer">
          <div className="row">
            <div className="column">
              <p className="field_text">
                Dealer Name: {data?.dealer_name || ""}
              </p>
              <p className="field_text">
                Contact Name: {data?.contact_name || ""}
              </p>
              <p className="field_text">
                Contact Phone: {data?.phone || ""}
              </p>
              <p className="field_text">
                Fax Number: {data?.fax || ""}
              </p>
              <p className="field_text">
                Address: {data?.address || ""}
              </p>
              <p className="field_text">
                Address 2: {data?.address2 || ""}
              </p>
              <p className="field_text">City: {data.city || ""}</p>
              <p className="field_text">State: {data?.state || ""}</p>
              <p className="field_text">Country: {data.country || ""} </p>
              <p className="field_text">
                Zip Code: {data?.zipcode || ""}
              </p>
              <p className="field_text">
                Description of Project: {data?.description || ""}
              </p>
              <p className="field_text">
                Total cost of project: {data?.total_cost ? `$${data?.total_cost}` : ""}
              </p>
              <p className="field_text">
                % Devoted: {data?.devoted ? `${data?.devoted}%` : ""}
              </p>
              <p className="field_text">
                Cost of devoted: {data?.cost_devoted ? `$${data?.cost_devoted}` : ""}
              </p>
              <p className="field_text">
                Total Credit: {data?.total_credit ? `$${data?.total_credit}` : ""}
              </p>
              <p className="field_text" style={{ wordBreak: "break-all", width: "40vw" }}>
                Additional Information: {data?.additional_info || ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
