import React, { useState } from "react";
import { Mutation, compose } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { Query } from "react-apollo";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import {
  Paper,
  Typography,
  TextField,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormLabel,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Dashboard as DashboardLayout } from "layouts";
import styles from "theme/skin";

import {
  GET_SPECIAL_PROJECT_FORMS_INFO,
  UPDATE_SPECIAL_PROJECT_FORM,
} from "./queries";
import { withRouter } from "react-router-dom";
import { GET_USER_INFO } from "queries";
import auth from "Auth";

function SpecialProjectFormEdit(props) {
  const { classes, history } = props;
  const [userData, setUserData] = useState()
  const {
    match: { params },
  } = props;
  const { values, resetState, handleChangeCheckbox, setValues } =
    useForm(submitForm);

  function submitForm() {
    //console.log(values);
  }

  const completedAdd = (payload) => {
    toast("Special project form was been succesfully updated");
    history.push("/special-project-forms")
  };

  function updateForm(data, callBackMutation) {
    delete data.status;
    delete data.date_submitted;
    delete data.__typename;

    callBackMutation({
      variables: data,
    });
    return true;
  }

  const handleChange = event => {
    event.persist();
    setValues(values => {
      const updatedValues = {
        ...values,
        [event.target.name]: event.target.value
      };

      if (event.target.name === 'total_cost' || event.target.name === 'devoted') {
        const totalCost = event.target.name === 'total_cost' ? parseFloat(event.target.value) : parseFloat(updatedValues.total_cost || 0);
        const devoted = event.target.name === 'devoted' ? parseFloat(event.target.value) : parseFloat(updatedValues.devoted || 0);

        if (!isNaN(totalCost) && !isNaN(devoted)) {
          updatedValues.total_credit = ((parseFloat(values?.total_cost || 0, 2) * parseFloat(values?.devoted || 0, 2)) / 100).toFixed(2);
          updatedValues.cost_devoted = (((parseFloat(values?.total_cost || 0, 2) * parseFloat(values?.devoted || 0, 2)) / 100) / evaluateBoatOptions(userData)).toFixed(2)
        }
      }
      return updatedValues;
    });
  };

  const evaluateBoatOptions = (data) => {
    if (data?.profiles_by_pk) {
      const { has_skiff, has_seachaser, has_funchaser } = data?.profiles_by_pk;
      return has_skiff + has_seachaser + has_funchaser
    }
  }

  return (
    <Query query={GET_USER_INFO} variables={{ id: auth.getSub() }} onCompleted={(data) => setUserData(data)}>
      {({ data: userData, loading: userLoading, error }) => {
        return (
          <Query query={GET_SPECIAL_PROJECT_FORMS_INFO} variables={{ id: params.id }}  >
            {({ loading, error, data }) => {
              if (loading) return null;
              if (error) return `Error! ${error}`;
              if (values.dealer_name === undefined) {
                setValues(data.special_project_forms_by_pk);
              }
              if (data.special_project_forms_by_pk !== undefined) {
                return (
                  <DashboardLayout>
                    <Mutation
                      mutation={UPDATE_SPECIAL_PROJECT_FORM}
                      onCompleted={completedAdd}
                    >
                      {(
                        updateSpecialProjectForm,
                        { data, loading, called, error }
                      ) => {
                        return (
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              updateForm(values, updateSpecialProjectForm);
                              return false;
                            }}
                            autoComplete="off"
                          >
                            <div className={classes.root + " sectionContainer"}>
                              <Typography gutterBottom variant="h4" className="title">
                                <span>EDIT SPECIAL PROJECT FORMS</span>
                              </Typography>

                              <br></br>
                              <br></br>
                              <Typography
                                gutterBottom
                                variant="h5"
                                className={classes.subtitle3}
                                style={{
                                  backgroundColor: "#BED4E5",
                                  textAlign: "left",
                                  padding: "1em",
                                  color: "#23333F",
                                  margin: "0px",
                                }}
                              >
                                EDIT SPECIAL PROJECT FORM
                              </Typography>
                              <Paper
                                className={classes.paper}
                                style={{
                                  padding: "2em",
                                  backgroundColor: "#F9FCFF",
                                  boxShadow: "unset",
                                }}
                              >
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  align="center"
                                  className={classes.subTitle2}
                                  style={{ margin: "0px auto 15px", maxWidth: "80%" }}
                                >
                                  <strong>CAROLINA SKIFF:</strong> 3231 Fulford Road
                                  Waycross Georgia 31503 <strong>PHONE:</strong>{" "}
                                  912-287-0547 <strong>FAX:</strong> 912-287-0533
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  align="center"
                                  style={{ margin: "0px auto", maxWidth: "80%" }}
                                  className={classes.subTitle2}
                                >
                                  All required paper work must be submitted with this form.
                                  <br></br>
                                  For qualifications and requirements of the Co-op
                                  advertising program, please check your Dealer Manual under
                                  the Marketing Guide section.
                                </Typography>
                                <br></br>
                                <br></br>
                                <div>
                                  <Grid container spacing={4}>
                                    <Grid item xs={12} sm={4}>
                                      <TextField
                                        required
                                        name="dealer_name"
                                        label="Dealer Name"
                                        className={classes.textField}
                                        style={{ width: "100%" }}
                                        onChange={handleChange}
                                        margin="normal"
                                        value={values?.dealer_name || ""}
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <TextField
                                        required
                                        name="contact_name"
                                        label="Contact Name"
                                        className={classes.textField}
                                        style={{ width: "100%" }}
                                        onChange={handleChange}
                                        margin="normal"
                                        value={values?.contact_name || ""}
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <TextField
                                        required
                                        name="phone"
                                        label="Phone"
                                        className={classes.textField}
                                        style={{ width: "100%" }}
                                        onChange={handleChange}
                                        margin="normal"
                                        value={values?.phone || ""}
                                        variant="outlined"
                                      />
                                    </Grid>
                                  </Grid>

                                  <Grid container spacing={4}>
                                    <Grid item xs={12} sm={4}>
                                      <TextField
                                        required
                                        name="fax"
                                        label="Fax"
                                        className={classes.textField}
                                        style={{ width: "100%" }}
                                        onChange={handleChange}
                                        margin="normal"
                                        value={values?.fax || ""}
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <TextField
                                        required
                                        name="address"
                                        label="Address"
                                        className={classes.textField}
                                        style={{ width: "100%" }}
                                        onChange={handleChange}
                                        margin="normal"
                                        value={values?.address || ""}
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <TextField
                                        name="address2"
                                        label="Address line 2 (Suites, Apt. # etc)"
                                        className={classes.textField}
                                        onChange={handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        value={values?.address2 || ""}
                                        style={{ width: "100%" }}
                                      />
                                    </Grid>
                                  </Grid>

                                  <Grid container spacing={4}>
                                    <Grid item xs={12} sm={4}>
                                      <TextField
                                        required
                                        name="city"
                                        label="City"
                                        className={classes.textField}
                                        onChange={handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        value={values?.city || ""}
                                        style={{ width: "100%" }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <TextField
                                        required
                                        name="country"
                                        label="Country"
                                        select
                                        className={classes.textField}
                                        onChange={handleChange}
                                        value={values?.country || ""}
                                        style={{ width: "100%" }}
                                        variant="outlined"
                                        SelectProps={{
                                          native: true,
                                          MenuProps: {
                                            className: classes.menu,
                                          },
                                        }}
                                        margin="normal"
                                      >
                                        <option key="" value="" />
                                        <option key="United States" value="United States">United States</option>
                                      </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <TextField
                                        required
                                        name="state"
                                        label="State"
                                        variant="outlined"
                                        select
                                        style={{ width: "100%" }}
                                        className={classes.textField}
                                        value={values?.state || ""}
                                        onChange={handleChange}
                                        SelectProps={{
                                          native: true,
                                          MenuProps: {
                                            className: classes.menu,
                                          },
                                        }}
                                        margin="normal"
                                      >
                                        <option key="" value="" />
                                        <option key="Alabama" value="Alabama">
                                          Alabama
                                        </option>
                                        <option key="Alaska" value="Alaska">
                                          Alaska
                                        </option>
                                        <option key="Arizona" value="Arizona">
                                          Arizona
                                        </option>
                                        <option key="Arkansas" value="Arkansas">
                                          Arkansas
                                        </option>
                                        <option key="California" value="California">
                                          California
                                        </option>
                                        <option key="Colorado" value="Colorado">
                                          Colorado
                                        </option>
                                        <option key="Connecticut" value="Connecticut">
                                          Connecticut
                                        </option>
                                        <option key="Delaware" value="Delaware">
                                          Delaware
                                        </option>
                                        <option key="Florida" value="Florida">
                                          Florida
                                        </option>
                                        <option key="Georgia" value="Georgia">
                                          Georgia
                                        </option>
                                        <option key="Hawaii" value="Hawaii">
                                          Hawaii
                                        </option>
                                        <option key="Idaho" value="Idaho">
                                          Idaho
                                        </option>
                                        <option key="Illinois" value="Illinois">
                                          Illinois
                                        </option>
                                        <option key="Indiana" value="Indiana">
                                          Indiana
                                        </option>
                                        <option key="Iowa" value="Iowa">
                                          Iowa
                                        </option>
                                        <option key="Kansas" value="Kansas">
                                          Kansas
                                        </option>
                                        <option key="Kentucky" value="Kentucky">
                                          Kentucky
                                        </option>
                                        <option key="Louisiana" value="Louisiana">
                                          Louisiana
                                        </option>
                                        <option key="Maine" value="Maine">
                                          Maine
                                        </option>
                                        <option key="Maryland" value="Maryland">
                                          Maryland
                                        </option>
                                        <option
                                          key="Massachusetts"
                                          value="Massachusetts"
                                        >
                                          Massachusetts
                                        </option>
                                        <option key="Michigan" value="Michigan">
                                          Michigan
                                        </option>
                                        <option key="Minnesota" value="Minnesota">
                                          Minnesota
                                        </option>
                                        <option key="Mississippi" value="Mississippi">
                                          Mississippi
                                        </option>
                                        <option key="Missouri" value="Missouri">
                                          Missouri
                                        </option>
                                        <option key="Montana" value="Montana">
                                          Montana
                                        </option>
                                        <option key="Nebraska" value="Nebraska">
                                          Nebraska
                                        </option>
                                        <option key="Nevada" value="Nevada">
                                          Nevada
                                        </option>
                                        <option
                                          key="New Hampshire"
                                          value="New Hampshire"
                                        >
                                          New Hampshire
                                        </option>
                                        <option key="New Jersey" value="New Jersey">
                                          New Jersey
                                        </option>
                                        <option key="New Mexico" value="New Mexico">
                                          New Mexico
                                        </option>
                                        <option key="New York" value="New York">
                                          New York
                                        </option>
                                        <option
                                          key="North Carolina"
                                          value="North Carolina"
                                        >
                                          North Carolina
                                        </option>
                                        <option
                                          key="North Dakota"
                                          value="North Dakota"
                                        >
                                          North Dakota
                                        </option>
                                        <option key="Ohio" value="Ohio">
                                          Ohio
                                        </option>
                                        <option key="Oklahoma" value="Oklahoma">
                                          Oklahoma
                                        </option>
                                        <option key="Oregon" value="Oregon">
                                          Oregon
                                        </option>
                                        <option
                                          key="Pennsylvania"
                                          value="Pennsylvania"
                                        >
                                          Pennsylvania
                                        </option>
                                        <option
                                          key="Rhode Island"
                                          value="Rhode Island"
                                        >
                                          Rhode Island
                                        </option>
                                        <option
                                          key="South Carolina"
                                          value="South Carolina"
                                        >
                                          South Carolina
                                        </option>
                                        <option
                                          key="South Dakota"
                                          value="South Dakota"
                                        >
                                          South Dakota
                                        </option>
                                        <option key="Tennessee" value="Tennessee">
                                          Tennessee
                                        </option>
                                        <option key="Texas" value="Texas">
                                          Texas
                                        </option>
                                        <option key="Utah" value="Utah">
                                          Utah
                                        </option>
                                        <option key="Vermont" value="Vermont">
                                          Vermont
                                        </option>
                                        <option key="Virginia" value="Virginia">
                                          Virginia
                                        </option>
                                        <option key="Washington" value="Washington">
                                          Washington
                                        </option>
                                        <option
                                          key="West Virginia"
                                          value="West Virginia"
                                        >
                                          West Virginia
                                        </option>
                                        <option key="Wisconsin" value="Wisconsin">
                                          Wisconsin
                                        </option>
                                        <option key="Wyoming" value="Wyoming">
                                          Wyoming
                                        </option>
                                      </TextField>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={4}>
                                    <Grid item xs={12} sm={4}>
                                      <TextField
                                        name="zipcode"
                                        label="Zip / Postal Code"
                                        className={classes.textField}
                                        onChange={handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        value={values?.zipcode || ""}
                                      />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={4}>
                                      <Typography
                                        gutterBottom
                                        variant="h6"
                                        align="left"
                                        className={classes.subTitle2}
                                        style={{
                                          width: "100%",
                                          textAlign: "left",
                                          marginTop: "25px",
                                        }}
                                      >
                                        DATE: 3/25/2020{" "}
                                      </Typography>
                                    </Grid> */}
                                  </Grid>
                                </div>
                                <br></br>
                                <br></br>

                                <div>
                                  <Typography
                                    gutterBottom
                                    variant="h6"
                                    align="center"
                                    style={{ maxWidth: "80%", margin: "0px auto 15px" }}
                                    className={classes.subTitle2}
                                  >
                                    <b>SPECIAL PROJECT PROGRAM</b>
                                  </Typography>
                                  <Typography
                                    gutterBottom
                                    variant="h6"
                                    align="center"
                                    style={{ maxWidth: "80%", margin: "0px auto 10px" }}
                                    className={classes.subTitle2}
                                  >
                                    Carolina Skiff will credit up to 50% of the total
                                    project amount for special Advertising or Marketing
                                    Projects:
                                  </Typography>
                                  <Typography
                                    gutterBottom
                                    variant="h6"
                                    align="center"
                                    style={{ maxWidth: "80%", margin: "0px auto" }}
                                    className={classes.subTitle2}
                                  >
                                    banners, catalogs, direct mail, clothing, boat giveaway,
                                    live radio, live TV, and any other type of promotional
                                    materials designed to promote the Dealership and
                                    Carolina Skiff LLC..
                                    <br></br>
                                    All special projects must be pre-approved by the
                                    Carolina Skiff Advertising Department. No competitive
                                    brand my be used or shown in any way for special
                                    projects to qualify.
                                    <br></br>
                                    All Special projects credits will be applied againist
                                    the accrued allowance.
                                  </Typography>
                                  <br></br>
                                  <br></br>
                                  <Grid container>
                                    <Grid item xs={12} sm={12}>
                                      <TextField
                                        name="description"
                                        label="Description of Project"
                                        multiline
                                        rows="6"
                                        variant="outlined"
                                        className={classes.textField}
                                        onChange={handleChange}
                                        margin="normal"
                                        value={values?.description || ""}
                                        style={{ width: "100%" }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <br></br>
                                  <br></br>
                                  <Grid container justify="space-around" spacing={4}>
                                    <Grid item xs={12} sm={4}>
                                      <FormControl
                                        sx={{ m: 1, width: "25ch" }}
                                        variant="outlined"
                                      >
                                        <FormLabel
                                          variant="h5"
                                          align="center"
                                          className={classes.subTitle2}
                                          style={{ width: "100%", marginBottom: "10px" }}
                                        >
                                          <b>TOTAL COST OF PROJECT</b>
                                        </FormLabel>
                                        <OutlinedInput
                                          id="outlined-adornment-weight"
                                          name="total_cost"
                                          onChange={handleChange}
                                          value={values.total_cost || ""}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              {" "}
                                            </InputAdornment>
                                          }
                                          style={{ width: "100%" }}
                                          aria-describedby="outlined-weight-helper-text"
                                          startAdornment={
                                            <InputAdornment position="start">
                                              $
                                            </InputAdornment>
                                          }
                                          inputProps={{
                                            "aria-label": "weight",
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <FormControl
                                        sx={{ m: 1, width: "25ch" }}
                                        variant="outlined"
                                      >
                                        <FormLabel
                                          variant="h5"
                                          align="center"
                                          className={classes.subTitle2}
                                          style={{ width: "100%", marginBottom: "10px" }}
                                        >
                                          <b>% DEVOTED</b>
                                        </FormLabel>
                                        <OutlinedInput
                                          id="outlined-adornment-weight"
                                          name="devoted"
                                          value={values.devoted || ""}
                                          onChange={handleChange}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              {" "}
                                            </InputAdornment>
                                          }
                                          style={{ width: "100%" }}
                                          aria-describedby="outlined-weight-helper-text"
                                          inputProps={{
                                            "aria-label": "weight",
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <FormControl
                                        sx={{ m: 1, width: "25ch" }}
                                        variant="outlined"
                                      >
                                        <FormLabel
                                          variant="h5"
                                          align="center"
                                          className={classes.subTitle2}
                                          style={{ width: "100%", marginBottom: "10px" }}
                                        >
                                          <b>COST OF DEVOTED %</b>
                                        </FormLabel>
                                        <OutlinedInput
                                          id="outlined-adornment-weight"
                                          name="cost_devoted"
                                          disabled
                                          value={(((parseFloat(values?.total_cost || 0, 2) * parseFloat(values?.devoted || 0, 2)) / 100) / evaluateBoatOptions(userData)).toFixed(2)}
                                          onChange={handleChange}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              {" "}
                                            </InputAdornment>
                                          }
                                          style={{ width: "100%" }}
                                          aria-describedby="outlined-weight-helper-text"
                                          inputProps={{
                                            "aria-label": "weight",
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                  <br></br>
                                  <Grid container justify="flex-end" spacing={4}>
                                    <Grid item xs={12} sm={4}>
                                      <FormControl
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                        variant="outlined"
                                      >
                                        {/* <b variant="h5" align="center" className={classes.subTitle2}  style={{width:"100%",marginBottom:"10px"}}>TOTAL COST OF PROJECT</b> */}
                                        <FormLabel className={classes.subTitle2}>
                                          <b>Total Credit $</b>{" "}
                                        </FormLabel>
                                        <OutlinedInput
                                          id="outlined-adornment-weight"
                                          name="total_credit"
                                          disabled
                                          value={((parseFloat(values?.total_cost || 0, 2) * parseFloat(values?.devoted || 0, 2)) / 100).toFixed(2)}
                                          onChange={handleChange}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              {" "}
                                            </InputAdornment>
                                          }
                                          style={{
                                            width: "80%",
                                            maxWidth: "calc(100% - 100px)",
                                            marginLeft: "6px",
                                          }}
                                          aria-describedby="outlined-weight-helper-text"
                                          inputProps={{
                                            "aria-label": "weight",
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </div>
                                <br></br>
                                <br></br>
                                <div>
                                  <Grid container>
                                    <Grid item xs={12} sm={12}>
                                      <TextField
                                        name="additional_info"
                                        label="Additional information. (OPTIONAL)"
                                        multiline
                                        rows="4"
                                        variant="outlined"
                                        className={classes.textField}
                                        onChange={handleChange}
                                        margin="normal"
                                        value={values?.additional_info || ""}
                                        style={{ width: "100%" }}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>

                                <Grid item xs={12} align="center">
                                  {/* {loading && <p>Loading...</p>}
                            {error && (
                            <p>
                                Warranty Registration Could Not be Submitted. Please Try
                                again.
                            </p>
                            )} */}
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() => {
                                      resetState();
                                      history.push("/special-project-forms")
                                    }}
                                    style={{ width: "15em", margin: "2em 1em" }}
                                  >
                                    CANCEL
                                  </Button>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.button}
                                    type="submit"
                                    style={{ width: "15em", margin: "2em 1em" }}
                                  >
                                    SUBMIT
                                  </Button>
                                </Grid>
                              </Paper>
                            </div>
                          </form>
                        );
                      }}
                    </Mutation>
                  </DashboardLayout>
                );
              }
            }}
          </Query >
        )
      }}
    </Query>
  );
}

export default compose(withRouter, withStyles(styles))(SpecialProjectFormEdit);
