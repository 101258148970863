import React from "react";

function ClaimForms(props) {
    return (
        <span>id</span>
    );
}


export default ClaimForms;
