import React from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import compose from "recompose/compose";
import PropTypes from "prop-types";

const styles = {
  button: {
    margin: 8
  },
  contentHeaderMenuLink: {
    textDecoration: "none",
    color: "white",
    padding: 8
  },
  content: {
    padding: "16px"
  }
};

function EditUserButton(props) {
  const { item, history } = props;

  function handleEditUser(id) {
    //console.log(id);
    history.push(`/admin-user-settings/user/${id}/edit`);
    //this.props.history.push(`/admin-user-settings/user/${id.profile_id}/edit`);
  }

  return (
    <div>
      <Button variant="contained" color="secondary" style={styles.button} onClick={() => handleEditUser(item.profile_id)}>
        Edit User
      </Button>
    </div>
  );
}

EditUserButton.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(EditUserButton);
