import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Typography, withStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { UPDATE_DEALER_USER } from "queries";
import { Mutation, Query } from "react-apollo";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import auth from "Auth";
import auth0 from "auth0-js";
import axios from "axios";
import auth0User from "components/Auth0User";
import { GET_USER_INFO } from "queries";
import { withRouter } from "react-router";
import compose from "recompose/compose";
import Geocode from "react-geocode";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "2em",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100%",
    margin: "1em",
    textAlign: "center",
  },
  dense: {
    marginTop: 19,
  },
  formControl: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  addDealer: {
    fontSize: "18px",
    fontWeight: "300",
    textTransform: "uppercase",
    textAlign: "center",
  },
  checkBoxTitle: {
    fontSize: "18px",
    textTransform: "uppercase",
    color: "#1f3340",
    fontWeight: "300",
  },
  updateDealerProfile: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    fontSize: "14px",
    fontWeight: 100,
  },
  password: {
    marginTop: "4rem",
  },
}));

const styles = (theme) => ({});
const EditDealer = (props) => {
  const {
    match: { params },
  } = props;
  const { values, handleChange, resetState, handleChangeCheckbox, setValues } =
    useForm(submitForm);
  const [isSubmit, setIsSubmit] = useState(false);
  const classes = useStyles();
  console.log(values, "values");
  const handleUpdatePassword = (event) => {
    if (window.confirm("Are you sure you want to reset dealer's password?")) {
      const webAuth = new auth0.WebAuth({
        domain: "dev-v-8-slj6.auth0.com",
        clientID: "MzOcwoze6f529Uk5GQOXLFHv5bvjetOV",
      });
      webAuth.changePassword(
        {
          connection: "Username-Password-Authentication",
          email: values.email,
        },
        (err, res) => {
          if (err) {
            toast.error(
              `There was an error trying to reset dealer password. ${res}`,
              {
                position: toast.POSITION.TOP_LEFT,
              }
            );
          } else {
            toast("A reset password link has been sent to dealer email");
          }
        }
      );
    }
  };

  function permissionsCreator() {
    let permissionsArray = [];

    if (values.DEALER_ALL === true) {
      permissionsArray.push("dealer:DEALER_ALL");
      return permissionsArray;
    }
    if (values.DEALER_LEADS === true) {
      permissionsArray.push("dealer:DEALER_LEADS");
    }
    if (values.DEALER_WARRANTY === true) {
      permissionsArray.push("dealer:DEALER_WARRANTY");
    }
    if (values.DEALER_FORMS_DATASHEETS === true) {
      permissionsArray.push("dealer:DEALER_FORMS_DATASHEETS");
    }
    if (values.DEALER_MEDIA === true) {
      permissionsArray.push("dealer:DEALER_MEDIA");
    }
    if (values.DEALER_ORDER_BOATS === true) {
      permissionsArray.push("dealer:DEALER_ORDER_BOATS");
    }
    if (values.DEALER_CSI === true) {
      permissionsArray.push("dealer:DEALER_CSI");
    }
    if (values.DEALER_FACTORY_CONTACTS === true) {
      permissionsArray.push("dealer:DEALER_FACTORY_CONTACTS");
    }
    if (values.DEALER_BOAT_SHOWS === true) {
      permissionsArray.push("dealer:DEALER_BOAT_SHOWS");
    }
    if (values.DEALER_SKIFF_MERCHANDISE === true) {
      permissionsArray.push("dealer:DEALER_SKIFF_MERCHANDISE");
    }

    return permissionsArray;
  }
  function updateUser(data, callBackMutation) {
    setIsSubmit(true);
    let formData = {};
    let accessTokenPromise = auth0User.getToken();
    let AUTH_API_URL = auth0User.AUTH_API_URL();

    accessTokenPromise
      .then((accessToken) => {
        formData.name = data?.name;
        formData.email = data?.email;
        formData.company = data?.company;
        formData.address = data?.address;
        formData.address2 = data?.address2;
        formData.city = data?.city;
        formData.region = data?.region;
        formData.zipcode = data?.zipcode;
        formData.phone = data?.phone;
        formData.country = data?.country;
        formData.role = "Dealer";
        formData.profile_id = data?.profile_id;
        formData.parent_admin = auth.getSub();
        formData.permissions = [];
        formData.has_skiff = data?.has_skiff;
        formData.has_seachaser = data?.has_seachaser;
        formData.has_funchaser = data?.has_funchaser;
        formData.location = data?.location;
        formData.business_email = data?.business_email;

        axios
          .get(`https://maps.googleapis.com/maps/api/geocode/json`, {
            params: {
              address:
                values?.address +
                " " +
                values?.city +
                " " +
                values?.region +
                "," +
                values?.country[0] +
                " " +
                values?.zipcode,
              key: process.env.REACT_APP_GOOGLE_API_KEY,
            },
          })
          .then(function (response) {
            const { lat, lng } =
              response?.data?.results?.[0]?.geometry?.location;
            formData.location =
              '{ "type": "Point", "coordinates": [' + lng + ", " + lat + "] }";

            axios({
              // url: AUTH_API_URL + "users-by-email",
              url: `${AUTH_API_URL}users/${data?.profile_id}`,
              method: "get",
              headers: { Authorization: `Bearer ${accessToken}` },
              // params: {
              //   email: formData.email
              // }
            })
              .then(function (response) {
                // console.log(response)
                if (response.data !== undefined) {
                  let auth0permissions = permissionsCreator();

                  axios({
                    url: AUTH_API_URL + "users/" + response?.data?.user_id,
                    method: "patch",
                    headers: { Authorization: `Bearer ${accessToken}` },
                    data: {
                      connection: "Username-Password-Authentication",
                      email: formData?.email,
                      name: formData?.name,
                      //password: data.password,
                      user_metadata: {
                        full_name: formData?.name,
                      },
                      app_metadata: {
                        role: "dealer",
                        permissions: auth0permissions,
                      },
                    },
                  })
                    .then(function (response) {
                      let permissions = permissionsCreator();
                      formData.permissions = permissions;
                      // console.log("Calling Data");
                      // console.log(formData);
                      delete formData.password;
                      delete formData.parent_admin;

                      callBackMutation({
                        variables: formData,
                      });
                      return true;
                    })
                    .catch(function (error) {
                      setIsSubmit(false);
                      toast.error(
                        "Error Updating New Dealer on Auth0: " + error,
                        {
                          position: toast.POSITION.TOP_LEFT,
                        }
                      );
                      // console.log(error)
                    });
                }
              })
              .catch(function (error) {
                setIsSubmit(false);
                toast.error("ERROR: Unable to update dealer", {
                  position: toast.POSITION.TOP_LEFT,
                });
                // console.log(error)
              });
          })
          .catch((error) => {
            setIsSubmit(false);
            toast.error(
              "Error from getToken() with async( When promise gets rejected ): " +
                error,
              {
                position: toast.POSITION.TOP_LEFT,
              }
            );
            // console.log(error)
          });
      })
      .catch(function (error) {
        setIsSubmit(false);
        toast.error("Error Getting GEO Location For Address: " + error, {
          position: toast.POSITION.TOP_LEFT,
        });
        // console.log(error)
      });

    /*  Geocode.setApiKey("AIzaSyAmR2Tn3xehfrBd0-83BrV0Dsg55E_UNT0");
     Geocode.setLanguage("en");
     Geocode.fromAddress(values.city + "," + values.region).then(
       response => {
         const { lat, lng } = response.results[0].geometry.location;
         //formData.location = "["+ lat +", "+lng+"]";
         formData.location = '{ "type": "Point", "coordinates": ['+ lat +', '+lng+'] }';
         console.log("lat, lng");
         console.log(lat, lng);
       },
       error => {
         console.error(error);
       }
     ); */
  }

  function updatePermissions(permissionsArray) {
    // console.log("Updating Permissions");
    // console.log(permissionsArray);

    if (permissionsArray.includes("dealer:DEALER_ALL")) {
      values.DEALER_ALL = true;
      return permissionsArray;
    }

    if (permissionsArray.includes("dealer:DEALER_LEADS")) {
      values.DEALER_LEADS = true;
    }

    if (permissionsArray.includes("dealer:DEALER_WARRANTY")) {
      values.DEALER_WARRANTY = true;
    }

    if (permissionsArray.includes("dealer:DEALER_FORMS_DATASHEETS")) {
      values.DEALER_FORMS_DATASHEETS = true;
    }

    if (permissionsArray.includes("dealer:DEALER_MEDIA")) {
      values.DEALER_MEDIA = true;
    }

    if (permissionsArray.includes("dealer:DEALER_ORDER_BOATS")) {
      values.DEALER_ORDER_BOATS = true;
    }

    if (permissionsArray.includes("dealer:DEALER_CSI")) {
      values.DEALER_CSI = true;
    }

    if (permissionsArray.includes("dealer:DEALER_FACTORY_CONTACTS")) {
      values.DEALER_FACTORY_CONTACTS = true;
    }

    if (permissionsArray.includes("dealer:DEALER_BOAT_SHOWS")) {
      values.DEALER_BOAT_SHOWS = true;
    }

    if (permissionsArray.includes("dealer:DEALER_SKIFF_MERCHANDISE")) {
      values.DEALER_SKIFF_MERCHANDISE = true;
    }

    return permissionsArray;
  }

  // values.start_date = new Date().getDate()
  function submitForm() {
    //console.log(values);
  }
  function completedAdd(payload) {
    toast("Dealer was been succesfully updated");
    //resetState();
    setIsSubmit(false);
  }
  return (
    <Query query={GET_USER_INFO} variables={{ id: params.userId }}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return `Error! ${error}`;
        //console.log(data.profiles_by_pk);
        //auth.setPermissions(data.profiles_by_pk.permissions);
        //console.log("CALLEDvalues")
        //console.log(values)
        if (values.email === undefined) {
          //console.log("CALLED")
          setValues(data.profiles_by_pk);
          //updateValues(data.profiles_by_pk);
        }
        updatePermissions(data.profiles_by_pk.permissions);
        if (data.profiles_by_pk !== undefined) {
          return (
            <Mutation mutation={UPDATE_DEALER_USER} onCompleted={completedAdd}>
              {(updateDealer, { data, loading, called, error }) => {
                return (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      updateUser(values, updateDealer);
                      return false;
                    }}
                    className={classes.container}
                    autoComplete="off"
                  >
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        wrap="nowrap"
                      ></Grid>
                      {/* Row 1 */}
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        wrap="nowrap"
                      >
                        <TextField
                          id="name"
                          name="name"
                          label="Full Name"
                          placeholder="Full Name"
                          className={classes.textField}
                          margin="normal"
                          onChange={handleChange}
                          value={values.name || ""}
                          required
                          variant="outlined"
                        />

                        <TextField
                          id="phone"
                          name="phone"
                          label="Phone"
                          placeholder="Phone"
                          className={classes.textField}
                          margin="normal"
                          onChange={(e) => handleChange(e, "phone")}
                          value={values.phone || ""}
                          variant="outlined"
                        />

                        <TextField
                          id="company"
                          name="company"
                          label="Company Name"
                          placeholder="Company Name"
                          className={classes.textField}
                          margin="normal"
                          onChange={handleChange}
                          value={values.company || ""}
                          required
                          variant="outlined"
                        />
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        wrap="nowrap"
                      >
                        <TextField
                          id="address"
                          name="address"
                          label="Address"
                          placeholder="Address"
                          className={classes.textField}
                          margin="normal"
                          onChange={handleChange}
                          value={values.address || ""}
                          required
                          variant="outlined"
                        />
                        <TextField
                          id="address2"
                          name="address2"
                          label="SUITE , APT # , Optional"
                          placeholder="SUITE , APT # , Optional"
                          className={classes.textField}
                          margin="normal"
                          onChange={handleChange}
                          value={values.address2 || ""}
                          variant="outlined"
                        />
                        <TextField
                          id="city"
                          name="city"
                          label="City"
                          placeholder="City"
                          className={classes.textField}
                          margin="normal"
                          onChange={handleChange}
                          value={values.city || ""}
                          required
                          variant="outlined"
                        />
                      </Grid>

                      {/* Row 3 */}
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        wrap="nowrap"
                      >
                        <TextField
                          id="country"
                          name="country"
                          label="Country"
                          select
                          className={classes.textField}
                          onChange={handleChange}
                          value={values.country || ""}
                          style={{ width: "90%" }}
                          variant="outlined"
                          SelectProps={{
                            native: true,
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                        >
                          <option key="" value="" />
                          <option key="United States" value="United States">
                            United States
                          </option>
                        </TextField>
                        <TextField
                          name="region"
                          label="State"
                          variant="outlined"
                          select
                          style={{ width: "90%" }}
                          className={classes.textField}
                          value={values.region || ""}
                          onChange={handleChange}
                          SelectProps={{
                            native: true,
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                        >
                          <option key="" value="" />
                          <option key="Alabama" value="Alabama">
                            Alabama
                          </option>
                          <option key="Alaska" value="Alaska">
                            Alaska
                          </option>
                          <option key="Arizona" value="Arizona">
                            Arizona
                          </option>
                          <option key="Arkansas" value="Arkansas">
                            Arkansas
                          </option>
                          <option key="California" value="California">
                            California
                          </option>
                          <option key="Colorado" value="Colorado">
                            Colorado
                          </option>
                          <option key="Connecticut" value="Connecticut">
                            Connecticut
                          </option>
                          <option key="Delaware" value="Delaware">
                            Delaware
                          </option>
                          <option key="Florida" value="Florida">
                            Florida
                          </option>
                          <option key="Georgia" value="Georgia">
                            Georgia
                          </option>
                          <option key="Hawaii" value="Hawaii">
                            Hawaii
                          </option>
                          <option key="Idaho" value="Idaho">
                            Idaho
                          </option>
                          <option key="Illinois" value="Illinois">
                            Illinois
                          </option>
                          <option key="Indiana" value="Indiana">
                            Indiana
                          </option>
                          <option key="Iowa" value="Iowa">
                            Iowa
                          </option>
                          <option key="Kansas" value="Kansas">
                            Kansas
                          </option>
                          <option key="Kentucky" value="Kentucky">
                            Kentucky
                          </option>
                          <option key="Louisiana" value="Louisiana">
                            Louisiana
                          </option>
                          <option key="Maine" value="Maine">
                            Maine
                          </option>
                          <option key="Maryland" value="Maryland">
                            Maryland
                          </option>
                          <option key="Massachusetts" value="Massachusetts">
                            Massachusetts
                          </option>
                          <option key="Michigan" value="Michigan">
                            Michigan
                          </option>
                          <option key="Minnesota" value="Minnesota">
                            Minnesota
                          </option>
                          <option key="Mississippi" value="Mississippi">
                            Mississippi
                          </option>
                          <option key="Missouri" value="Missouri">
                            Missouri
                          </option>
                          <option key="Montana" value="Montana">
                            Montana
                          </option>
                          <option key="Nebraska" value="Nebraska">
                            Nebraska
                          </option>
                          <option key="Nevada" value="Nevada">
                            Nevada
                          </option>
                          <option key="New Hampshire" value="New Hampshire">
                            New Hampshire
                          </option>
                          <option key="New Jersey" value="New Jersey">
                            New Jersey
                          </option>
                          <option key="New Mexico" value="New Mexico">
                            New Mexico
                          </option>
                          <option key="New York" value="New York">
                            New York
                          </option>
                          <option key="North Carolina" value="North Carolina">
                            North Carolina
                          </option>
                          <option key="North Dakota" value="North Dakota">
                            North Dakota
                          </option>
                          <option key="Ohio" value="Ohio">
                            Ohio
                          </option>
                          <option key="Oklahoma" value="Oklahoma">
                            Oklahoma
                          </option>
                          <option key="Oregon" value="Oregon">
                            Oregon
                          </option>
                          <option key="Pennsylvania" value="Pennsylvania">
                            Pennsylvania
                          </option>
                          <option key="Rhode Island" value="Rhode Island">
                            Rhode Island
                          </option>
                          <option key="South Carolina" value="South Carolina">
                            South Carolina
                          </option>
                          <option key="South Dakota" value="South Dakota">
                            South Dakota
                          </option>
                          <option key="Tennessee" value="Tennessee">
                            Tennessee
                          </option>
                          <option key="Texas" value="Texas">
                            Texas
                          </option>
                          <option key="Utah" value="Utah">
                            Utah
                          </option>
                          <option key="Vermont" value="Vermont">
                            Vermont
                          </option>
                          <option key="Virginia" value="Virginia">
                            Virginia
                          </option>
                          <option key="Washington" value="Washington">
                            Washington
                          </option>
                          <option key="West Virginia" value="West Virginia">
                            West Virginia
                          </option>
                          <option key="Wisconsin" value="Wisconsin">
                            Wisconsin
                          </option>
                          <option key="Wyoming" value="Wyoming">
                            Wyoming
                          </option>
                        </TextField>
                        <TextField
                          id="zipcode"
                          name="zipcode"
                          label="Zipcode"
                          placeholder="Zipcode"
                          margin="normal"
                          onChange={handleChange}
                          value={values.zipcode || ""}
                          required
                          className={classes.textField}
                          variant="outlined"
                        >
                          <MenuItem>
                            <em>None</em>
                          </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        wrap="nowrap"
                      >
                        <TextField
                          id="email"
                          name="email"
                          label="Email"
                          placeholder="Email"
                          className={classes.textField}
                          margin="normal"
                          onChange={handleChange}
                          value={values.email || ""}
                          variant="outlined"
                        />
                        <TextField
                          id="business_email"
                          name="business_email"
                          label="Business Email"
                          placeholder="Business Email"
                          className={classes.textField}
                          margin="normal"
                          onChange={handleChange}
                          value={values.business_email || ""}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid container>
                        {/* Row 2 */}
                        <Grid item xs={6} align="left">
                          <FormControl
                            required
                            component="fieldset"
                            className={classes.formControl}
                          >
                            <Typography className={classes.checkBoxTitle}>
                              Dealer Permissions
                            </Typography>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="DEALER_ALL"
                                    checked={values.DEALER_ALL || false}
                                    onChange={handleChangeCheckbox}
                                    value="DEALER_ALL"
                                  />
                                }
                                label="ALL"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="DEALER_LEADS"
                                    checked={values.DEALER_LEADS || false}
                                    onChange={handleChangeCheckbox}
                                    value="DEALER_LEADS"
                                  />
                                }
                                label="LEADS"
                              />

                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="DEALER_WARRANTY"
                                    checked={values.DEALER_WARRANTY || false}
                                    onChange={handleChangeCheckbox}
                                    value="DEALER_WARRANTY"
                                  />
                                }
                                label="WARRANTY"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="DEALER_FORMS_DATASHEETS"
                                    checked={
                                      values.DEALER_FORMS_DATASHEETS || false
                                    }
                                    onChange={handleChangeCheckbox}
                                    value="DEALER_FORMS_DATASHEETS"
                                  />
                                }
                                label="FORMS & DATA SHEETS"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="DEALER_MEDIA"
                                    checked={values.DEALER_MEDIA || false}
                                    onChange={handleChangeCheckbox}
                                    value="DEALER_MEDIA"
                                  />
                                }
                                label="MEDIA"
                              />
                              {/* <FormControlLabel
                                control={
                                  <Checkbox
                                    name="DEALER_ORDER_BOATS"
                                    checked={values.DEALER_ORDER_BOATS}
                                    onChange={handleChangeCheckbox}
                                    value="DEALER_ORDER_BOATS"
                                  />
                                }
                                label="ORDER BOATS"
                              /> */}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="DEALER_CSI"
                                    checked={values.DEALER_CSI || false}
                                    onChange={handleChangeCheckbox}
                                    value="DEALER_CSI"
                                  />
                                }
                                label="CSI"
                              />

                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="DEALER_FACTORY_CONTACTS"
                                    checked={
                                      values.DEALER_FACTORY_CONTACTS || false
                                    }
                                    onChange={handleChangeCheckbox}
                                    value="DEALER_FACTORY_CONTACTS"
                                  />
                                }
                                label="FACTORY CONTACTS"
                              />

                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="DEALER_BOAT_SHOWS"
                                    checked={values.DEALER_BOAT_SHOWS || false}
                                    onChange={handleChangeCheckbox}
                                    value="DEALER_BOAT_SHOWS"
                                  />
                                }
                                label=" BOAT SHOWS"
                              />

                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="DEALER_SKIFF_MERCHANDISE"
                                    checked={
                                      values.DEALER_SKIFF_MERCHANDISE || false
                                    }
                                    onChange={handleChangeCheckbox}
                                    value="DEALER_SKIFF_MERCHANDISE"
                                  />
                                }
                                label="SKIFF MERCHANDISE"
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>

                        <Grid item xs={6} align="left">
                          <FormControl
                            required
                            component="fieldset"
                            className={classes.formControl}
                          >
                            <Typography className={classes.checkBoxTitle}>
                              BRANDS CARRIED
                            </Typography>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="has_skiff"
                                    checked={values.has_skiff || false}
                                    onChange={handleChangeCheckbox}
                                    value="has_skiff"
                                  />
                                }
                                label="CAROLINA SKIFF"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="has_funchaser"
                                    checked={values.has_funchaser || false}
                                    onChange={handleChangeCheckbox}
                                    value="has_funchaser"
                                  />
                                }
                                label="FUN CHASER"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="has_seachaser"
                                    checked={values.has_seachaser || false}
                                    onChange={handleChangeCheckbox}
                                    value="has_seachaser"
                                  />
                                }
                                label="SEA CHASER"
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {/* Row 3 */}
                      <Grid container>
                        <Grid item xs={12} align="center">
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.addDealer}
                            type="submit"
                            disabled={isSubmit}
                          >
                            UPDATE DEALER
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} align="center">
                          <h2 className={classes.password}>
                            Reset Dealer Password
                          </h2>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.addDealer}
                            type="button"
                            onClick={handleUpdatePassword}
                          >
                            RESET PASSWORD
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Mutation>
          );
        }
      }}
    </Query>
  );
};

export default compose(withRouter, withStyles(styles))(EditDealer);
