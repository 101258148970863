import React from "react";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import { CountryRegionData } from "react-country-region-selector";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import auth from "../../../Auth";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100%",
    margin: "1em",
    textAlign: "center"
  },
}));

const styles = theme => ({
  button: {
    margin: 8,
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  selectField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100%",
    textAlign: "center"
  },
  textField: {
    // marginLeft: theme.spacing(),
    // marginRight: theme.spacing(),
    width: "100%",
    margin: "1em",
    maxWidth: "320px",
    textAlign: "center"
  },
  textInput: {
    width: "100%",
    margin: "1em",
    maxWidth: "320px",
    textAlign: "center"
  },
  dense: {
    marginTop: 19
  },
  formControl: {
    margin: theme.spacing(3)
  }
});

const getRegions = country => {
  if (!country) {
    return [];
  }
  return country[2].split("|").map(regionPair => {
    let [regionName = null] = regionPair.split("~");
    return regionName;
  });
};

const UPDATE_WARRANTY = gql`
   mutation updateWarrantyClaimTransfer($id:uuid!, $first_name:String!, $last_name:String!, $phone:String!, $email:String!, $address:String!, $address2:String, $city:String!, $region:String!, $country:String!, $zipcode:String!, $comments:String!, $previous_profile_id:String!, $previous_admin_name:String!, $previous_admin_email:String!, $previous_hull_identification_number:String!, $previous_purchase_date:String!, $previous_brand:String!, $previous_model:String!, $previous_motor_details:String!, $previous_accessories:String!, $previous_primary_use:String!, $previous_submittedon:date!, $previous_status:String!, $previous_model_id:uuid){
    update_warranty_registration(
        where: {id: {_eq: $id}},
        _set: {
          status: $previous_status
        }
      ) {
        affected_rows
      }
      insert_warranty_registration(
        objects: [
          {
            profile_id: $previous_profile_id
            admin_name: $previous_admin_name
            admin_email: $previous_admin_email
            hull_identification_number: $previous_hull_identification_number
            purchase_date: $previous_purchase_date
            brand: $previous_brand
            model: $previous_model
            model_id: $previous_model_id
            first_name: $first_name
            last_name: $last_name
            phone: $phone
            email: $email
            address: $address
            address2: $address2
            city: $city
            region: $region
            country: $country
            zipcode: $zipcode
            motor_details: $previous_motor_details
            accessories: $previous_accessories
            primary_use: $previous_primary_use
            submittedon: $previous_submittedon
            status: "New",
            comments: $comments
          }
        ]
      ) {
        affected_rows
      }
   }
`;


const WarrantyRegistrationTransferEdit = (props) => {
  const classes = useStyles();
  const message = "";
  const { values, handleChange, resetState } = useForm(submitForm);

  function submitForm() {
    //console.log(values);
  }
  function completedAdd(payload) {
    toast("Warranty Successfully Transferred");
    resetState();
  }
  return (

    <Mutation mutation={UPDATE_WARRANTY} onCompleted={completedAdd}>
      {(warranties, { data }) => {
        if (message) {
          return <div> {message} </div>
        }
        else {
          return (
            <div style={{ textAlign: "center" }}>
              <form
                onSubmit={e => {
                  values.profile_id = auth.getSub();
                  values.country = values.country[0];
                  e.preventDefault();
                  warranties({
                    variables: {
                      id: props.registration.id,
                      first_name: values.first_name,
                      last_name: values.last_name,
                      phone: values.phone,
                      email: values.email,
                      address: values.address,
                      address2: values.address2,
                      city: values.city,
                      region: values.region,
                      country: values.country,
                      zipcode: values.zipcode,
                      comments: values.comments,
                      previous_profile_id: props.registration.profile_id,
                      previous_admin_name: props.registration.admin_name,
                      previous_admin_email: props.registration.admin_email,
                      previous_hull_identification_number: props.registration.hull_identification_number,
                      previous_purchase_date: props.registration.purchase_date,
                      previous_brand: props.registration.brand,
                      previous_model: props.registration.model,
                      previous_model_id: props.registration.model_id,
                      // previous_first_name: props.registration.first_name,
                      // previous_last_name: props.registration.last_name,
                      // previous_phone: props.registration.phone,
                      // previous_email: props.registration.email,
                      // previous_address: props.registration.address,
                      // previous_address2: props.registration.address2,
                      // previous_city: props.registration.city,
                      // previous_region: props.registration.region,
                      // previous_country: props.registration.country,
                      // previous_zipcode: props.registration.zipcode,
                      previous_motor_details: props.registration.motor_details,
                      previous_accessories: props.registration.accessories,
                      previous_primary_use: props.registration.primary_use,
                      previous_submittedon: props.registration.submittedon,
                      previous_status: 'Archived'
                    }
                  });

                  return false;
                }}
                autoComplete="off"
              >
                <Typography gutterBottom variant="h3">
                  <span>Transfer Warranty to the following customer</span>
                </Typography>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    {/* Row 1*/}
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      value={values.email || ""}
                      placeholder=""
                      onChange={handleChange}
                      className={styles.textField}
                      style={{
                        width: "100%",
                        margin: "1em",
                        textAlign: "center"
                      }}
                      margin="normal"
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    {/* Row 2*/}
                    <TextField
                      id="phone"
                      name="phone"
                      label="Phone"
                      onChange={(e) => handleChange(e, 'phone')}
                      placeholder="Phone"
                      value={values.phone || ""}
                      className={classes.textInput}
                      style={{
                        width: "100%",
                        margin: "1em",
                        textAlign: "center"
                      }}
                      // margin="normal"
                      required
                      variant="outlined"
                    />
                    <TextField
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      onChange={handleChange}
                      placeholder="First Name"
                      value={values.first_name || ""}
                      className={styles.textField}
                      style={{
                        width: "100%",
                        margin: "1em",
                        textAlign: "center"
                      }}
                      margin="normal"
                      required
                      variant="outlined"
                    />
                    <TextField
                      id="last_name"
                      name="last_name"
                      label="Last Name"
                      onChange={handleChange}
                      placeholder="Last Name"
                      value={values.last_name || ""}
                      className={styles.textField}
                      style={{
                        width: "100%",
                        margin: "1em",
                        textAlign: "center"
                      }}
                      margin="normal"
                      required
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <TextField
                      id="address"
                      name="address"
                      label="Address"
                      onChange={handleChange}
                      value={values.address || ""}
                      placeholder="Address"
                      className={styles.textField}
                      style={{
                        width: "100%",
                        margin: "1em",
                        textAlign: "center"
                      }}
                      margin="normal"
                      required
                      variant="outlined"
                    />
                    <TextField
                      id="address2"
                      name="address2"
                      onChange={handleChange}
                      value={values.address2 || ""}
                      label="SUITE , APT # , Optional"
                      placeholder="SUITE , APT # , Optional"
                      className={styles.textField}
                      style={{
                        width: "100%",
                        margin: "1em",
                        textAlign: "center"
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                    <TextField
                      id="city"
                      name="city"
                      label="City"
                      value={values.city || ""}
                      placeholder="City"
                      onChange={handleChange}
                      className={styles.textField}
                      style={{
                        width: "100%",
                        margin: "1em",
                        textAlign: "center"
                      }}
                      margin="normal"
                      required
                      variant="outlined"
                    />
                  </Grid>

                  {/* Row 3 */}
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <TextField
                      id="country"
                      name="country"
                      label="Country"
                      value={values.country || ""}
                      select
                      required
                      onChange={handleChange}
                      className={styles.selectField}
                      style={{
                        width: "100%",
                        margin: "1em",
                        textAlign: "center"
                      }}
                      inputProps={{
                        name: "country",
                        id: "country"
                      }}
                      variant="outlined"
                    >
                      {CountryRegionData.map((option, index) => (
                        <MenuItem key={option[0]} value={option}>
                          {option[0]}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      id="region"
                      name="region"
                      label="Region"
                      value={values.region || ""}
                      select
                      required
                      onChange={handleChange}
                      className={styles.selectField}
                      style={{
                        width: "100%",
                        margin: "1em",
                        textAlign: "center"
                      }}
                      variant="outlined"
                    >
                      {getRegions(values.country).map((option, index) => (
                        <MenuItem key={option[0]} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      id="zipcode"
                      name="zipcode"
                      label="Zipcode"
                      placeholder="Zipcode"
                      margin="normal"
                      required
                      value={values.zipcode || ""}
                      onChange={handleChange}
                      className={styles.textField}
                      style={{
                        width: "100%",
                        margin: "1em",
                        textAlign: "center"
                      }}
                      variant="outlined"
                    >
                      <MenuItem>
                        <em>None</em>
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <TextField
                      variant="outlined"
                      required
                      name="comments"
                      label="Comments"
                      onChange={handleChange}
                      placeholder="Comments"
                      value={values.comments || ""}
                      className={styles.textField}
                      style={{
                        width: "100%",
                        margin: "1em",
                        textAlign: "center"
                      }}
                      rows={3}
                      multiline={true}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      className={styles.button}
                      type="submit"
                    >
                      Submit Warranty For Transfer
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          )
        }
      }
      }
    </Mutation>
  );

};

export default WarrantyRegistrationTransferEdit;