import palette from "../palette";

export default {
  root: {
    height: "50px",
    color: palette.text.secondary
  },
  multiline: {
    height: "14vh"
  }
};
