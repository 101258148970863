import React from "react";
import { Query, Mutation } from "react-apollo";
import { getAllNewDealerRequests, deleteNewDealerRequestById } from "../../queries";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { NewDealerPdfDocument } from "./NewDealerRequest/NewDealerPdfDocument";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";

import {
  Paper,
  Typography
} from "@material-ui/core";
import {
  SelectionState,
  RowDetailState,
  PagingState,
  IntegratedPaging,
  IntegratedSelection
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  PagingPanel
} from "@devexpress/dx-react-grid-material-ui";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
import { withStyles } from "@material-ui/core";
import NewDealerFulfilledBy from "./NewDealerRequest/NewDealerFulfilledBy";
// Component styles
import styles from "theme/skin"

const style = {
  button: {
    margin: 8,
    width: "205px"
  },
  actions: {
      display: 'flex',
      justifyContent: 'center'
  },
  completedContainer: {
      textAlign: 'center',
      padding: '2em 0px'
  },
  completedTitle: {
      fontSize: 14,
      color: '#1f3340',
      textTransform: 'uppercase',
      textAlign: 'center',
      clear: 'both'
  },
  deleteRequestButtonContainer: {
      float: 'right'
  },
  primaryButton: {
      color: '#FFFFFF',
      backgroundColor: '#518EBD'
  },
  dangerButton: {
      color: '#fff',
      backgroundColor: '#dc3545',
      borderColor: '#dc3545'
  },
  confirmDialogCancelContainer: {
      marginRight: 'auto'
  }
};

class RowDetailAlt extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false
        };
    }

    render() {
        return <div>
            <div style={style.completedContainer}>
                <div style={style.deleteRequestButtonContainer}>
                    <Button
                        variant="contained"
                        style={Object.assign({}, style.button, style.dangerButton)}
                        onClick={e => this.setState({dialogOpen: true})}
                    >Delete Request</Button>
                    <Dialog open={this.state.dialogOpen}>
                        <DialogContent dividers>
                            <p>Are you sure you would like to delete the new dealer request for <strong>{this.props.row['legal_name']}</strong>?</p>
                        </DialogContent>
                        <DialogActions>
                            <div style={style.confirmDialogCancelContainer}>
                                <Button autoFocus
                                        variant="contained"
                                        color="default"
                                        onClick={() => this.setState({dialogOpen: false})}
                                >Cancel</Button>
                            </div>
                            <Mutation
                                mutation={deleteNewDealerRequestById}
                                update={(cache, { data: { deletedNewDealerRequestResponse } }) => {
                                    const { new_dealer_requests } = cache.readQuery({ query: getAllNewDealerRequests });
                                    cache.writeQuery({
                                        query: getAllNewDealerRequests,
                                        data: { new_dealer_requests:  new_dealer_requests.filter(x => x.id !== this.props.row['id'])},
                                    });
                                    this.setState({dialogOpen: false});
                                }}
                            >
                                {(deleteDealerRequests, { loading, error }) => {
                                    return (
                                        <div>
                                            {loading && <p>Loading...</p>}
                                            {error && <p>Error :( Please try again</p>}
                                            {!loading && !error && <Button
                                                variant="contained"
                                                color="secondary"
                                                style={styles.button}
                                                onClick={() => {
                                                    deleteDealerRequests({
                                                        variables: {
                                                            id:this.props.row['id']
                                                        }
                                                    });
                                                }}
                                            >Yes</Button>}
                                        </div>

                                    );
                                }}
                            </Mutation>
                        </DialogActions>
                    </Dialog>
                </div>

                <p style={style.completedTitle}>View applicants information</p>
                <div style={style.actions}>
                    <PDFDownloadLink
                        document={<NewDealerPdfDocument data={this.props.row} />}
                        fileName={"new-dealer-request" + this.props.row.dealer_name + "-" + this.props.row.request_date +".pdf"}
                        color="secondary"
                        className="MuiButtonBase-root MuiButton-root MuiButton-containedSecondary MuiButton-contained"
                    >
                        {({ blob, url, loading, error }) =>
                            loading ? "Loading document..." : "Download Pdf"
                        }
                    </PDFDownloadLink>
                </div>
            </div>
            <NewDealerFulfilledBy tag={this.props.row.fulfilledby} id={this.props.row.id} />
        </div>
    }
}

class NewDealerRequests extends React.PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            columns: [
                { name: "request_date", title: "Date" },
                {
                    title: "Brand",
                    getCellValue: (row) => {
                        if (row.carolinaskiff) {
                            return 'Carolina Skiff'
                        }
                        if (row.seachaser) {
                            return 'Sea Chaser'
                        }
                        return '';
                    }
                },
                { name: "legal_name", title: "Name" },
                { name: "fulfilledby", title: "Fulfilled By" },
                { name: "fulfilledon", title: "Fulfilled On" }
            ],
            expandedRows: [],
            hasLoadedOneTime: false
        };
    this.setSelectionValue = value => this.setState({ selection: value });
  }

  expandFirstRowOnFirstLoad(data) {
        if (data.new_dealer_requests.length > 0 && !this.state.hasLoadedOneTime) {
            this.setState({
                expandedRows: [data.new_dealer_requests[0].id],
                hasLoadedOneTime: true
            })
        }
  }

  render() {
    const { columns, selection } = this.state;
    const { classes } = this.props;
    return (
      <DashboardLayout title="New Dealer Requests">
        <Query query={getAllNewDealerRequests} onCompleted={this.expandFirstRowOnFirstLoad.bind(this)}>
          {({ data, loading, error }) => {
            if (loading) return <p>Loading...</p>;
            if (error) return <p>Error</p>;
            return (
              <div className={classes.root + " sectionContainer"}>
                <Typography gutterBottom variant="h4" className="title">
                  <span>New Dealer Requests</span>
                </Typography>
                <Paper className="tableStyles">
                  <div className={classes.header}>
                    <span>Total rows selected: {selection ? ( selection.length ) : ( "0" )}</span>
                  </div>
                  <Grid rows={data.new_dealer_requests} columns={columns} getRowId={(row) => row.id}>
                    <SelectionState
                      selection={selection}
                      onSelectionChange={this.setSelectionValue}
                    />
                    <PagingState defaultCurrentPage={0} pageSize={5} />
                    <IntegratedSelection />
                    <IntegratedPaging />
                    <RowDetailState onExpandedRowIdsChange={(rowIds) => this.setState({expandedRows: rowIds})} expandedRowIds={this.state.expandedRows} />
                    <Table />
                    <TableHeaderRow />
                    <TableRowDetail contentComponent={RowDetailAlt} />
                    <TableSelection showSelectAll />
                    <PagingPanel />
                  </Grid>
                </Paper>
              </div>
            );
          }}
        </Query>
      </DashboardLayout>
    );
  }
}
NewDealerRequests.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NewDealerRequests);