import { white, black } from '../common/colors';

export default {
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    muted: '#9EA0A4'
  },
  primary: {
    contrastText: white,
    main: '#3B6894',
    light: '#F6F9FD',
    dark: '#4883bd'
  },
  secondary: {
    contrastText: white,
    main: '#697888',
    light: '',
    dark: '#91a1b3'
  },
  success: {
    contrastText: white,
    main: '#45B880',
    light: '#F1FAF5',
    dark: '#00783E'
  },
  info: {
    contrastText: white,
    main: '#23333F',
    light: '#777777',
    dark: '#000000'
  },
  warning: {
    contrastText: white,
    main: '#FFB822',
    light: '#FDF8F3',
    dark: '#95591E'
  },
  danger: {
    contrastText: white,
    main: '#ED4740',
    light: '#FEF6F6',
    dark: '#BF0E08'
  },
  text: {
    primary: '#1f3340',
    secondary: '#66788A',
    disabled: '#A6B1BB'
  },
  background: {
    default: '#fafafa',
    dark: '#172B4D',
    paper: white
  },
  border: '#DFE3E8',
  divider: '#DFE3E8'
};
