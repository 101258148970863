import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Tabs, Tab, AppBar, Typography, Box, Grid, CircularProgress } from '@material-ui/core';
import './CoopPreApprovalForm.scss';
import { Query, Subscription, compose } from "react-apollo";
import { GET_ADMIN_CO_OP_REQUESTS_DATA, GET_ADMIN_CO_OP_REQUESTS_DATA_WITHOUT_FORM_TYPE } from "./queries";
import AdminStatusTable from "pages/Administration/CoopRequests/AdminStatusTable";
import { withRouter } from "react-router-dom";
import styles from "layouts/Dashboard/styles";
import { FormTypeEnum } from "pages/FormsDatasheets/enum";
import { GET_USER_INFO } from "queries";
import auth from "Auth";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  '.MuiAppBar-root': {
    backgroundColor: "#000"
  }
}));

function CoopPreApprovalForm(props) {
  const { location } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [queryData, setQueryData] = useState()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabData = {
    0: 'new',
    1: 'approved',
    2: 'pending',
    3: 'denied'
  }

  return (
    <div className={classes.root + " sectionContainer"}>
      <Grid container spacing={2} >
        <br></br>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" className={classes.subtitle1} >
            <span>
              {location?.search ? FormTypeEnum[location?.search?.replace("?type=", "")] : "CO - OP Pre Approval Claim Forms"}
            </span>
          </Typography>
        </Grid>
        <br></br>
        <Grid item xs={12} sm={6}>

        </Grid>
      </Grid>
      <br></br>
      <Box sx={{ flexGrow: 1 }} style={{}}>
        <div className={classes.root}>
          <AppBar position="static" className="custom-tab-area" >
            <Tabs variant="standard" centered value={value} onChange={handleChange} style={{ backgroundColor: "#23333F" }} aria-label="simple tabs example"> {/*TabIndicatorProps={{style: { backgroundColor: "#000"}}}*/}
              <Tab label="New Requests" {...a11yProps(0)} style={{ marginRight: '2rem' }} md={{ padding: "0px 20px" }} />
              <Tab label="Approved Requests" {...a11yProps(1)} style={{ marginRight: '2rem' }} />
              <Tab label="Pending Requests" {...a11yProps(2)} style={{ marginRight: '2rem' }} />
              <Tab label="Denied Requests" {...a11yProps(3)} style={{ marginRight: '2rem' }} />
            </Tabs>
          </AppBar>
          <Query query={GET_USER_INFO} variables={{ id: auth.getSub() }}>
            {({ data: userData, loading: userLoading, error }) => {
              return (
                <Subscription subscription={location?.search ? GET_ADMIN_CO_OP_REQUESTS_DATA : GET_ADMIN_CO_OP_REQUESTS_DATA_WITHOUT_FORM_TYPE} variables={location?.search ? { status: tabData[value], form_type: location?.search?.replace("?type=", "") } : { status: tabData[value] }}>
                  {({ data, loading, error }) => {
                    if (data && data !== queryData) {
                      setQueryData(data?.co_op_requests);
                    }
                    if (loading || !queryData || userLoading)
                      return (
                        <div
                          className="flex center"
                          style={{ padding: "2em 0" }}
                        >
                          <CircularProgress color="secondary" />
                        </div>
                      );
                    if (error) return <p>Error Loading Advertising Claim Forms</p>;
                    return (
                      <>
                        <TabPanel value={value} index={0}>
                          <AdminStatusTable type="admin" status="new" queryData={queryData} userName={userData?.profiles_by_pk?.name} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <AdminStatusTable type="admin" status="approved" queryData={queryData} userName={userData?.profiles_by_pk?.name} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          <AdminStatusTable type="admin" status="pending" queryData={queryData} userName={userData?.profiles_by_pk?.name} />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                          <AdminStatusTable type="admin" status="denied" queryData={queryData} userName={userData?.profiles_by_pk?.name} />
                        </TabPanel>
                      </>
                    );
                  }}
                </Subscription>
              )
            }}
          </Query>
        </div>
      </Box>
    </div>
  );
}

export default compose(withRouter, withStyles(styles))(CoopPreApprovalForm);
