import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper, Typography, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { GET_WARRANTY_CLAIM, UPDATE_WARRANTY_CLAIM } from "queries";
import { useLazyQuery } from "@apollo/client";
import { Mutation, Query } from "react-apollo";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
// Component styles
import styles from "theme/skin";
// import EditUser from "./Settings/EditUser";
import { withRouter } from "react-router";
import compose from "recompose/compose";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(4)
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100%",
    margin: "1em",
    textAlign: "center"
  },
  dense: {
    marginTop: 19
  },
  formControl: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  addWarrantyRegistration: {
    fontSize: "18px",
    fontWeight: "300",
    textTransform: "uppercase",
    textAlign: "center",
  },
  updateWarrantyClaim: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    fontSize: "14px",
    fontWeight: 100
  }
}));

const getRegions = country => {
  if (!country) {
    return [];
  }

  return country.split("|").map(regionPair => {
    let [regionName, regionShortCode = null] = regionPair.split("~");
    return regionName;
  });
};

const WarrantyClaimEdit = props => {

  const { match: { params } } = props;
  const { values, handleChange, resetState, handleChangeCheckbox, setValues } = useForm(
    submitForm
  );

  const classes = useStyles();

  function submitForm() {
  }

  function completedUpdate() {
    toast("Warranty Claim was been succesfully updated");
  }

  return (
    <DashboardLayout title="Edit Warranty Claim">
      <div className={classes.root + " sectionContainer"}>
        <Typography gutterBottom variant="h4" className="title">
          <span>Edit Warranty Claim</span>
        </Typography>

        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs align="center">
              <Query query={GET_WARRANTY_CLAIM} variables={{ id: params.userId }}>
                {({ loading, error, data }) => {
                  if (loading) return null;
                  if (error) return `Error! ${error}`;

                  if (values.first_name === undefined) {
                    const values = { ...data.warranty_claims_by_pk };
                    values.name = `${values.first_name} ${values.last_name}`;
                    setValues(values);
                  }
                  if (data.warranty_claims_by_pk !== undefined) {
                    return (
                      <Mutation mutation={UPDATE_WARRANTY_CLAIM} onCompleted={completedUpdate}>
                        {(updateNewWarrantyClaim) => {
                          if (error)
                            return (
                              <pre>
                                Bad:{" "}
                                {error.graphQLErrors.map(({ message }, i) => (
                                  <span key={i}>{message}</span>
                                ))}
                              </pre>
                            );
                          return (
                            <form
                              onSubmit={e => {
                                e.preventDefault();
                                const name = values.name.split(' ');
                                updateNewWarrantyClaim({
                                  variables: {
                                    ...values,
                                    __typename: undefined,
                                    name: undefined,
                                    id: params.userId,
                                    first_name: name[0],
                                    last_name: name[1],
                                    claim_total: values.claim_total && ( values.claim_total.trim() == '' ) ? null : values.claim_total,
                                    labor_hours: values.labor_hours && ( values.labor_hours.trim() == '' ) ? null : values.labor_hours,
                                    labor_total: values.labor_total && ( values.labor_total.trim() == '' ) ? null : values.labor_total,
                                    motor_details: values.motor_details && ( values.motor_details.trim() == '' ) ? null : values.motor_details,
                                    other: values.other && ( values.other.trim() == '' ) ? null : values.other,
                                    parts: values.parts && ( values.parts.trim() == '' ) ? null : values.parts,
                                    parts_used: values.parts_used && ( values.parts_used.trim() == '' ) ? null : values.parts_used,
                                    repairs: values.repairs && ( values.repairs.trim() == '' ) ? null : values.repairs
                                  }
                                });

                                return false;
                              }}
                              className={classes.container}
                              autoComplete="off"
                            >

                              <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="center"
                                  wrap="nowrap"
                                >
                                  {/* Row 1 */}

                                  <TextField
                                    required
                                    variant="outlined"
                                    name="admin_name"
                                    label="Dealer Name"
                                    onChange={handleChange}
                                    value={values.admin_name}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />

                                  <TextField
                                    required
                                    variant="outlined"
                                    name="admin_email"
                                    label="Dealer Email"
                                    onChange={handleChange}
                                    value={values.admin_email}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="center"
                                  wrap="nowrap"
                                >
                                  {/* Row 2 */}

                                  <TextField
                                    required
                                    variant="outlined"
                                    name="name"
                                    label="Customer Name"
                                    onChange={handleChange}
                                    value={values.name}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />

                                  <TextField
                                    required
                                    variant="outlined"
                                    name="email"
                                    label="Customer Email"
                                    onChange={handleChange}
                                    value={values.email}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />

                                  <TextField
                                    required
                                    variant="outlined"
                                    name="phone"
                                    label="Customer Phone"
                                    onChange={(e) => handleChange(e, 'phone')}
                                    value={values.phone}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>

                                <Grid
                                  container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="center"
                                  wrap="nowrap"
                                >
                                  {/* Row 3 */}

                                  <TextField
                                    required
                                    variant="outlined"
                                    name="hull_identification_number"
                                    label="Hull ID# (HIN)"
                                    onChange={handleChange}
                                    value={values.hull_identification_number}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="center"
                                  wrap="nowrap"
                                >
                                  {/* Row 4 */}

                                  <TextField
                                    variant="outlined"
                                    name="comments"
                                    label="Comments"
                                    onChange={handleChange}
                                    value={values.comments}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    multiline
                                  />

                                </Grid>


                                <Grid
                                  container
                                  direction="row"
                                  justify="center"
                                  alignItems="center"
                                  wrap="nowrap"
                                  className={classes.dense}
                                >
                                  <Typography gutterBottom variant="h2" className={classes.addWarrantyRegistration}>
                                    Additional Information
                                </Typography>
                                </Grid>
                                <Grid
                                  container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="center"
                                  wrap="nowrap"
                                >
                                  {/* Row 4 */}

                                  <TextField
                                    variant="outlined"
                                    name="motor_details"
                                    label="Motor(s)"
                                    onChange={handleChange}
                                    value={values.motor_details}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    multiline
                                  />

                                  <TextField
                                    variant="outlined"
                                    name="issues"
                                    label="Issue(s)"
                                    onChange={handleChange}
                                    value={values.issues}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    multiline
                                  />

                                  <TextField
                                    variant="outlined"
                                    name="repairs"
                                    label="Repair(s)"
                                    onChange={handleChange}
                                    value={values.repairs}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    multiline
                                  />

                                </Grid>
                                <Grid
                                  container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="center"
                                  wrap="nowrap"
                                >
                                  {/* Row 4 */}

                                  <TextField
                                    variant="outlined"
                                    name="parts"
                                    label="Part(s)"
                                    onChange={handleChange}
                                    value={values.parts}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    multiline
                                  />

                                  <TextField
                                    variant="outlined"
                                    name="labor_hours"
                                    label="Labor Hours ($)"
                                    onChange={handleChange}
                                    value={values.labor_hours}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    multiline
                                  />

                                  <TextField
                                    variant="outlined"
                                    name="labor_total"
                                    label="Labor Total ($)"
                                    onChange={handleChange}
                                    value={values.labor_total}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    multiline
                                  />

                                </Grid>
                                <Grid
                                  container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="center"
                                  wrap="nowrap"
                                >
                                  {/* Row 4 */}

                                  <TextField
                                    variant="outlined"
                                    name="parts_used"
                                    label="Parts Used ($)"
                                    onChange={handleChange}
                                    value={values.parts_used}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    multiline
                                  />

                                  <TextField
                                    variant="outlined"
                                    name="other"
                                    label="Other ($)"
                                    onChange={handleChange}
                                    value={values.other}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    multiline
                                  />

                                  <TextField
                                    variant="outlined"
                                    name="claim_total"
                                    label="Claim Total ($)"
                                    onChange={handleChange}
                                    value={values.claim_total}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    multiline
                                  />

                                </Grid>
                                <Grid item xs={12} align="center">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.updateWarrantyClaim}
                                    type="submit"
                                  >
                                    UPDATE WARRANTY Claim
                                  </Button>
                                </Grid>
                              </Grid>
                            </form>
                          );
                        }}
                      </Mutation>
                    );
                  }
                }}
              </Query>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </DashboardLayout>
  );
};

WarrantyClaimEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(WarrantyClaimEdit);
