import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper, Typography, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { getAllDealerOptions, GET_WARRANTY_REGISTRATION, UPDATE_WARRANTY_REGISTRATION, GET_BOAT_MODELS } from "queries";
import { useLazyQuery } from "@apollo/client";
import { Mutation, Query } from "react-apollo";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
// Component styles
import styles from "theme/skin";
// import EditUser from "./Settings/EditUser";
import { withRouter } from "react-router";
import compose from "recompose/compose";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(4)
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100%",
    margin: "1em",
    textAlign: "center"
  },
  dense: {
    marginTop: 19
  },
  formControl: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  updateWarrantyRegistration: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    fontSize: "14px",
    fontWeight: 100
  }
}));

const getRegions = country => {
  if (!country) {
    return [];
  }

  return country.split("|").map(regionPair => {
    let [regionName, regionShortCode = null] = regionPair.split("~");
    return regionName;
  });
};

const WarrantyRegistrationEdit = props => {

  const { match: { params } } = props;
  const { values, handleChange, resetState, handleChangeCheckbox, setValues } = useForm(
    submitForm
  );

  const classes = useStyles();

  const [getAllDealers, allDealerOptions] = useLazyQuery(getAllDealerOptions);
  const [getAllBoatModels, allBoatModels] = useLazyQuery(GET_BOAT_MODELS);

  function submitForm() {
  }

  function completedUpdate() {
    toast("Warranty Registration was been successfully updated");
  }

  useEffect(() => {
    getAllDealers();
    getAllBoatModels();
  }, [])

  return (
    <DashboardLayout title="Edit Warranty Registration">
      <div className={classes.root + " sectionContainer"}>
        <Typography gutterBottom variant="h4" className="title">
          <span>Edit Warranty Registration</span>
        </Typography>

        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs align="center">
              <Query query={GET_WARRANTY_REGISTRATION} variables={{ id: params.userId }}>
                {({ loading, error, data }) => {
                  if (loading) return null;
                  if (error) return `Error! ${error}`;

                  if (values.first_name === undefined) {
                    setValues(data.warranty_registration_by_pk);
                  }
                  let warrantyData = data;
                  if (data.warranty_registration_by_pk !== undefined) {
                    return (
                      <Mutation mutation={UPDATE_WARRANTY_REGISTRATION} onCompleted={completedUpdate}>
                        {(updateNewWarrantyRegistration, { data, loading, called, error }) => {
                          if (error)
                            return (
                              <pre>
                                Bad:{" "}
                                {error.graphQLErrors.map(({ message }, i) => (
                                  <span key={i}>{message}</span>
                                ))}
                              </pre>
                            );
                          return (
                            <form
                              onSubmit={e => {
                                e.preventDefault();
                                updateNewWarrantyRegistration({
                                  variables: {
                                    ...values,
                                    __typename: undefined,
                                    id: params.userId
                                  }
                                });

                                return false;
                              }}
                              className={classes.container}
                              autoComplete="off"
                            >

                              <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="center"
                                  wrap="nowrap"
                                >
                                  {/* Row 1 */}

                                  <TextField
                                    required
                                    variant="outlined"
                                    name="first_name"
                                    label="Customer First Name"
                                    onChange={handleChange}
                                    value={values.first_name}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />

                                  <TextField
                                    required
                                    variant="outlined"
                                    name="last_name"
                                    label="Customer Last Name"
                                    onChange={handleChange}
                                    value={values.last_name}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />

                                  <TextField
                                    required
                                    variant="outlined"
                                    name="phone"
                                    label="Customer Phone"
                                    type="phone"
                                    onChange={(e) => handleChange(e, 'phone')}
                                    value={values.phone}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />

                                  <TextField
                                    required
                                    variant="outlined"
                                    name="email"
                                    label="Customer Email"
                                    type="email"
                                    onChange={handleChange}
                                    value={values.email}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>

                                <Grid
                                  container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="center"
                                  wrap="nowrap"
                                >
                                  {/* Row 2 */}

                                  <TextField
                                    name="profile_id"
                                    label="Dealer"
                                    variant="outlined"
                                    select
                                    className={classes.textField}
                                    value={values.profile_id || ""}
                                    onChange={handleChange}
                                    SelectProps={{
                                      native: true,
                                      MenuProps: {
                                        className: classes.menu
                                      }
                                    }}
                                  >
                                    <option value=""></option>
                                    {allDealerOptions.data && allDealerOptions.data.profiles.map((option) => (
                                      <option key={option.profile_id} value={option.profile_id}>
                                        {option.name}{option.name}{option.status === 'archived' ? ' - Archived' : ''}
                                      </option>
                                    ))}
                                  </TextField>

                                  <TextField
                                    required
                                    variant="outlined"
                                    name="hull_identification_number"
                                    label="Hull ID# (HIN)"
                                    onChange={handleChange}
                                    value={values.hull_identification_number}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />

                                </Grid>
                                <Grid
                                  container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="center"
                                  wrap="nowrap"
                                >
                                  {/* Row 3 */}

                                  <TextField
                                    required
                                    variant="outlined"
                                    name="brand"
                                    label="Brand"
                                    onChange={handleChange}
                                    value={values.brand}
                                    className={classes.textField}
                                    select
                                    SelectProps={{
                                      native: true,
                                      MenuProps: {
                                        className: classes.menu
                                      }
                                    }}
                                  >
                                    <option value=""></option>
                                    <option key="Carolina Skiff" value="Carolina Skiff">Carolina Skiff</option>
                                    <option key="Sea Chaser" value="Sea Chaser">Sea Chaser</option>
                                    <option key="Fun Chaser" value="Fun Chaser">Fun Chaser</option>
                                  </TextField>

                                  <TextField
                                    required
                                    variant="outlined"
                                    name="model"
                                    label="Model"
                                    onChange={(e) => {
                                      handleChange(e);
                                      setValues(values => ({
                                        ...values,
                                        model_id: e.target.options[e.target.selectedIndex].dataset.modelId || null
                                      }));
                                    }}
                                    value={values.model}
                                    className={classes.textField}
                                    select
                                    SelectProps={{
                                      native: true,
                                      MenuProps: {
                                        className: classes.menu
                                      }
                                    }}
                                  >
                                    {
                                        allBoatModels.data && allBoatModels.data.boat_models &&
                                        !allBoatModels.data.boat_models.some(x => x.model === warrantyData.warranty_registration_by_pk.model) &&
                                        <option key={`${warrantyData.warranty_registration_by_pk.model}`} value={warrantyData.warranty_registration_by_pk.model} data-model-id={''}>{warrantyData.warranty_registration_by_pk.model}</option>
                                    }
                                    {
                                        allBoatModels.data && allBoatModels.data.boat_models &&
                                        allBoatModels.data.boat_models.map((boat) => {
                                          return <option key={`${boat.model}`} value={boat.model} data-model-id={boat.id}>{boat.model} {boat.older_model ? ' - Older Model' : ''}</option>
                                        })
                                    }
                                  </TextField>

                                  <TextField
                                    required
                                    variant="outlined"
                                    name="purchase_date"
                                    label="Date of Purchase"
                                    type="date"
                                    value={values.purchase_date || " "}
                                    onChange={handleChange}
                                    className={classes.textField}
                                  />
                                </Grid>
                                <Grid
                                  container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="center"
                                  wrap="nowrap"
                                >
                                  {/* Row 4 */}

                                  <TextField
                                    required
                                    variant="outlined"
                                    name="address"
                                    label="Street Address"
                                    onChange={handleChange}
                                    value={values.address}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />

                                </Grid>

                                <Grid
                                  container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="center"
                                  wrap="nowrap"
                                >
                                  {/* Row 5 */}
                                  <TextField
                                    variant="outlined"
                                    name="address2"
                                    label="Address Line 2"
                                    onChange={handleChange}
                                    value={values.address2}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    wrap="nowrap"
                                >
                                  <TextField
                                      variant="outlined"
                                      name="comments"
                                      label="Comments"
                                      onChange={handleChange}
                                      value={values.comments}
                                      className={classes.textField}
                                      rows={3}
                                      multiline={true}
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                  />
                                </Grid>
                                <Grid item xs={12} align="center">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.updateWarrantyRegistration}
                                    type="submit"
                                  >
                                    UPDATE WARRANTY REGISTRATION
                                  </Button>
                                </Grid>
                              </Grid>
                            </form>
                          );
                        }}
                      </Mutation>
                    );
                  }
                }}
              </Query>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </DashboardLayout>
  );
};

WarrantyRegistrationEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(WarrantyRegistrationEdit);
