import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SelectionState,
  RowDetailState,
  PagingState,
  IntegratedPaging,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { withStyles, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import { Mutation, Subscription } from "react-apollo";
import { DeleteBoatShow, getAllPendingBoatShowRequests } from "./queries";
import BoatShowApproveDeny from "./BoatShowApproveDeny";
import auth from "../../Auth";
import { toast } from "react-toastify";
import { Typography, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Modal from "@material-ui/core/Modal";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(),
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 275,
  },
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: "30px",
    "&:focus-visible": {
      outline: "transparent",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    marginTop: theme.spacing(2),
  },
});

const RowDetail = ({ row }) => (
  <div>
    <table cellSpacing="0" className="detailTable">
      <tbody>
        <tr>
          <td>Visible On:</td>
          <td>{row?.site || ""}</td>
        </tr>
        <tr>
          <td>Dealer:</td>
          <td>{row?.dealer || ""}</td>
        </tr>
        <tr>
          <td>Status:</td>
          <td>{row?.status || ""}</td>
        </tr>
        <tr>
          <td>Boat Show Name:</td>
          <td>{row?.name || ""}</td>
        </tr>
        <tr>
          <td>Address:</td>
          <td>
            {row?.address || ""}&nbsp;{row?.address2 || ""}
            <br />
            {row?.city || ""}&nbsp;{row?.region || ""}, {row?.country || ""}{" "}
            {row?.zipcode || ""}
            <br />
          </td>
        </tr>
        <tr>
          <td>Brands:</td>
          <td>{row?.brands || ""}</td>
        </tr>
        <tr>
          <td>Description:</td>
          <td>{row?.description || ""}</td>
        </tr>
        <tr>
          <td>Start Date:</td>
          <td>{row?.start_date || ""}</td>
        </tr>
        <tr>
          <td>End Date:</td>
          <td>{row?.end_date || ""}</td>
        </tr>
      </tbody>
    </table>
    {auth.getRole().toLowerCase() === "admin" ? (
      <BoatShowApproveDeny tag={row.status} id={row.id} dealer={row.dealer} />
    ) : (
      ""
    )}
  </div>
);

function completedAdd(payload) {
  toast("Boat Show Deleted Successfully");
}

class BoatShowRequestsGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isSubmit: false,
      boatId: null,
      columns: [
        { name: "created_at", title: "Date Submitted" },
        { name: "dealer", title: "Dealer" },
        { name: "name", title: "Boat Show Name" },
        { name: "city", title: "Location" },
        { name: "status", title: "Status" },
        {
          name: "delete",
          title: "Action",
          getCellValue: (row) => {
            return (
              <DeleteIcon
                fontSize="small"
                style={{
                  color: "#3B6894",
                  width: "18px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({
                    boatId: row?.id,
                  });
                  this.handleOpen();
                }}
              />
            );
          },
        },
      ],
    };
  }

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleDelete = (e, deleteBoatShow) => {
    e.preventDefault();
    this.setState({
      isSubmit: true,
    });
    deleteBoatShow({
      variables: {
        id: this.state.boatId,
      },
    }).then(() => {
      this.completedDelete();
    });
  };

  completedDelete = () => {
    toast("Boat Show Deleted Successfully");
    this.handleClose();
    this.setState({
      isSubmit: false,
    });
  };

  render() {
    const { columns, open, isSubmit } = this.state;
    const { classes } = this.props;

    return (
      <>
        <Modal open={open} onClose={this.handleClose}>
          <div className={classes.paper}>
            <Typography variant="h6" gutterBottom>
              Are you sure you want to delete this boat show?
            </Typography>
            <div className={classes.buttonContainer}>
              <Mutation
                mutation={DeleteBoatShow}
                onCompleted={this.completedAdd}
              >
                {(deleteBoatShow, { data, error }) => {
                  if (error)
                    return (
                      <pre>
                        Bad:{" "}
                        {error.graphQLErrors.map(({ message }, i) => (
                          <span key={i}>{message}</span>
                        ))}
                      </pre>
                    );
                  return (
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={isSubmit}
                      onClick={(e) => this.handleDelete(e, deleteBoatShow)}
                    >
                      Confirm
                    </Button>
                  );
                }}
              </Mutation>

              <Button variant="contained" onClick={this.handleClose}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
        <Subscription subscription={getAllPendingBoatShowRequests}>
          {({ data, loading, error }) => {
            if (loading)
              return (
                <div className="flex center" style={{ padding: "2em 0" }}>
                  <CircularProgress color="secondary" />
                </div>
              );
            
            if (error) return <p>Error Loading Pending Boat Shows</p>;
            return (
              <div className="tableStyles">
                <Grid rows={data.boat_shows} columns={columns}>
                  <PagingState defaultCurrentPage={0} pageSize={5} />
                  <IntegratedPaging />
                  <RowDetailState defaultExpandedRowIds={[0]} />
                  <Table />
                  <TableHeaderRow />
                  <TableRowDetail contentComponent={RowDetail} />
                  <PagingPanel />
                </Grid>
              </div>
            );
          }}
        </Subscription>
      </>
    );
  }
}

BoatShowRequestsGrid.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BoatShowRequestsGrid);
