import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import {
  AttachMoney,
  DirectionsBoat,
  Description,
  Inbox,
  Person,
  Star,
} from "@material-ui/icons";
import "./SidebarContentAdmin.scss";
import auth from "../Auth";
const styles = {
  content: {
    padding: "4px",
    height: "100%",
    backgroundColor: "white",
    marginTop: "3em",
  },
};

function ListItemLink(props) {
  const { icon, primary, to } = props;
  const isCurrentRoute = window.location.pathname === to;
  const style = {
    padding: "2px",
  };
  return (
    <div>
      {auth.isAuthenticated() ? (
        <ListItem
          className={isCurrentRoute ? "navTabActive" : ""}
          button
          component={Link}
          to={to}
          style={style}
        >
          <ListItemIcon className="navItemIcon">{icon}</ListItemIcon>
          <ListItemText className="navLinks" primary={primary} />
        </ListItem>
      ) : (
        ""
      )}
    </div>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.node.isRequired,
  primary: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};
function hasPermissions(userPermissions, permission) {
  if (userPermissions.includes("admin:ADMIN_ALL")) return true;

  if (userPermissions.includes(permission)) return true;
}

function AdminComponentProperty(props) {
  const { classes } = props;
  const [showChildList, setShowChildList] = useState(false);

  const toggleChildList = () => {
    setShowChildList((prev) => !prev);
  };

  useEffect(() => {
    if (window.location.pathname === "/admin-co-op-requests") {
      setShowChildList(true);
    }
  }, [window.location.pathname]);

  const style = { padding: "2px" };
  const userPermissions = props.userPermissions;
  return (
    <div className={classes.root} style={styles.content}>
      <List component="nav" className={props.root}>
        <ListItemLink to="/dashboard" primary="Dashboard" icon={<Inbox />} />

        {hasPermissions(userPermissions, "admin:ADMIN_LEADS") ? (
          <ListItemLink to="/admin-leads" primary="Leads" icon={<Star />} />
        ) : (
          ""
        )}

        {hasPermissions(userPermissions, "admin:ADMIN_MY_DEALERS") ? (
          <ListItemLink
            to="/admin-my-dealers"
            primary="My Dealers"
            icon={<Star />}
          />
        ) : (
          ""
        )}

        {hasPermissions(userPermissions, "admin:ADMIN_WARRANTY_CLAIMS") ? (
          <ListItemLink
            to="/admin-warranty-claims"
            primary="Warranty Claims"
            icon={<Star />}
          />
        ) : (
          ""
        )}

        {hasPermissions(userPermissions, "admin:ADMIN_WARRANTY_LOOKUP") ? (
          <ListItemLink
            to="/admin-warranty-registrations"
            primary="Warranty Registrations"
            icon={<Description />}
          />
        ) : (
          ""
        )}

        {hasPermissions(userPermissions, "admin:ADMIN_CAREERS") ? (
          <ListItemLink to="/admin-careers" primary="Careers" icon={<Star />} />
        ) : (
          ""
        )}

        {hasPermissions(userPermissions, "admin:ADMIN_CSI") ? (
          <ListItemLink to="/admin-csi" primary="CSI" icon={<Star />} />
        ) : (
          ""
        )}

        {hasPermissions(userPermissions, "admin:ADMIN_MEDIA") ? (
          <ListItemLink to="/admin-media" primary="Media" icon={<Star />} />
        ) : (
          ""
        )}

        {hasPermissions(userPermissions, "admin:ADMIN_WARRANTY_INQ") ? (
          <ListItemLink
            to="/admin-warranty-inquiries"
            primary="Warranty Inquiries"
            icon={<Star />}
          />
        ) : (
          ""
        )}

        {hasPermissions(userPermissions, "admin:ADMIN_CO_OP_REQ") ? (
          <>
            <div onClick={toggleChildList}>
              <ListItemLink
                to="/admin-co-op-requests"
                primary="CO OP Requests"
                icon={<Star />}
              />
            </div>

            {showChildList && (
              <ul className="co-request-sublist">
                <li>
                  <ListItemLink
                    to="/admin-co-op-requests?type=media-pre-approval"
                    primary="MEDIA PRE-APPROVAL"
                    // Add icon if needed
                  />
                </li>
                <li>
                  <ListItemLink
                    to="/admin-co-op-requests?type=co-op-advertising-claim"
                    primary="CO-OP ADVERTISING CLAIM"
                    // Add icon if needed
                  />
                </li>
                <li>
                  <ListItemLink
                    to="/admin-co-op-requests?type=special-project-request"
                    primary="SPECIAL PROJECT REQUEST"
                    // Add icon if needed
                  />
                </li>
                <li>
                  <ListItemLink
                    to="/admin-co-op-requests?type=govt-com-sales-credit-claim"
                    primary="GOVT/COM SALES CREDIT CLAIM"
                    // Add icon if needed
                  />
                </li>
                <li>
                  <ListItemLink
                    to="/admin-co-op-requests?type=boat-show-credit"
                    primary="BOAT SHOW CREDIT REQUEST"
                    // Add icon if needed
                  />
                </li>
                {/* <li>
                <ListItemLink
                  to="/admin-co-op-requests?boat-show-credit-form"
                  primary="BOAT SHOW CREDIT FORM"
                // Add icon if needed
                />
              </li> */}
              </ul>
            )}
          </>
        ) : (
          ""
        )}
        {hasPermissions(userPermissions, "admin:ADMIN_BOAT_SHOW_REQUESTS")
          ? "" // <ListItemLink to="/admin-boat-show-requests" primary="Boat Show Requests" icon={<Star />} />
          : ""}

        {hasPermissions(userPermissions, "admin:ADMIN_NEW_DEALER_REQ") ? (
          <ListItemLink
            to="/admin-new-dealer-requests"
            primary="New Dealer Requests"
            icon={<Star />}
          />
        ) : (
          ""
        )}

        {hasPermissions(userPermissions, "admin:ADMIN_CONTACT_REQ") ? (
          <ListItemLink
            to="/admin-contact-us-requests"
            primary="Contact Us Requests"
            icon={<Star />}
          />
        ) : (
          ""
        )}

        {hasPermissions(userPermissions, "admin:ADMIN_ALL") ? (
          <ListItemLink
            to="/admin-user-settings"
            primary="Admin User Settings"
            icon={<Star />}
          />
        ) : (
          ""
        )}
        <ListItemLink
          to="/factory-contacts"
          primary="Factory Contacts"
          icon={<Person />}
        />

        {/* <ListItemLink to='/admin-boat-order-requests' primary='Order Boat Requests' icon={<Star />} /> */}

        <ListItemLink
          to="/boat-shows"
          primary="Boat Shows"
          icon={<DirectionsBoat />}
        />

        {hasPermissions(userPermissions, "admin:ADMIN_WARRANTY_CLAIMS") ||
        hasPermissions(userPermissions, "admin:ADMIN_WARRANTY_LOOKUP") ? (
          <ListItemLink
            to="/admin-boat-models"
            primary="Boat Models"
            icon={<DirectionsBoat />}
          />
        ) : (
          ""
        )}

        <ListItem
          button
          to="#"
          style={style}
          component={Link}
          onClick={() => window.open("https://carolinaskiff.mybrightsites.com")}
        >
          <ListItemIcon className="navItemIcon">
            <AttachMoney />
          </ListItemIcon>
          <ListItemText
            className="navLinks"
            primary="Carolina Skiff Merchandise"
          />
        </ListItem>
        <ListItem
          button
          to="#"
          style={style}
          component={Link}
          onClick={() => window.open("https://seachaser.mybrightsites.com")}
        >
          <ListItemIcon className="navItemIcon">
            <AttachMoney />
          </ListItemIcon>
          <ListItemText className="navLinks" primary="Sea Chaser Merchandise" />
        </ListItem>
      </List>
    </div>
  );
}

AdminComponentProperty.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminComponentProperty);
