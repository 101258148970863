import React from "react";
import Paper from "@material-ui/core/Paper";
import AlbumMediaList from "./Media/AlbumMediaList";
import { Button, Typography } from "@material-ui/core";
import {
  SelectionState,
  RowDetailState,
  PagingState,
  IntegratedPaging,
  IntegratedSelection,
  FilteringState,
  IntegratedFiltering
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  PagingPanel,
  TableFilterRow
} from "@devexpress/dx-react-grid-material-ui";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
import { getAllMediaSubscription } from "queries";
import { Subscription } from "react-apollo";
// Externals
import PropTypes from "prop-types";
// Material helpers
import { withStyles, CircularProgress } from "@material-ui/core";

const RowDetail = ({ row }) => (
  <div>
    <AlbumMediaList items={row.media_items} />
    <div className="flex center">
      <Button
        color="primary"
        variant="contained"
        style={{ width: "200px" }}
      >
        Download All
      </Button>
    </div>
  </div>
);

const styles = theme => ({
  button: {
    margin: theme.spacing()
  },
  input: {
    display: "none"
  },
  root: {
    padding: theme.spacing(4)
  },
  item: {
    height: "100%"
  }
});

class Media extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "album_name", title: "Model Name" },
        {
          name: "id",
          title: "Media Amount",
          getCellValue: row =>
            row.media_items_aggregate.aggregate
              ? row.media_items_aggregate.aggregate.count
              : undefined
        }
      ]
    };
  }

  render() {
    const { columns, selection } = this.state;
    const { classes } = this.props;

    return (
      <DashboardLayout title="Media">
        <div className={classes.root + " sectionContainer"}>
          <Typography gutterBottom variant="h4" className="title">
            <span>MEDIA</span>
          </Typography>
          <div className="tableStyles">
            <Paper>
              <Subscription subscription={getAllMediaSubscription}>
                {({ data, loading, error }) => {
                  if (loading)
                    return (
                      <div className="flex center" style={{ padding: "2em 0" }}>
                        <CircularProgress color="secondary" />
                      </div>
                    );
                  if (error) return <p>Error{console.log(error)}</p>;
                  return (
                    <Grid rows={data.media} columns={columns}>
                      <SelectionState
                        selection={selection}
                        onSelectionChange={this.changeSelection}
                      />
                      <FilteringState defaultFilters={[]} />
                      <IntegratedFiltering />
                      <PagingState defaultCurrentPage={0} pageSize={5} />
                      <IntegratedSelection />
                      <IntegratedPaging />
                      <RowDetailState defaultExpandedRowIds={[0]} />
                      <Table />
                      <TableHeaderRow />
                      <TableFilterRow />
                      <TableRowDetail contentComponent={RowDetail} />
                      <TableSelection showSelectAll />
                      <PagingPanel />
                    </Grid>
                  );
                }}
              </Subscription>
            </Paper>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

Media.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Media);
