import React from "react";
import { Query } from "react-apollo";
import { getAllContactRequest } from "../../queries";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { Print } from "@material-ui/icons";
import {
  SelectionState,
  RowDetailState,
  PagingState,
  IntegratedPaging,
  IntegratedSelection
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  PagingPanel
} from "@devexpress/dx-react-grid-material-ui";
import ContactUsFulfilledBy from "./ContactUsFulfilledBy";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
import { withStyles, Typography } from "@material-ui/core";
// Component styles
import styles from "theme/skin";

const RowDetail = ({ row }) => (
  <div>
    <table cellSpacing="0" className="detailTable">
      <tbody>
        <tr>
          <td>Brand:</td>
          <td>{row.brand}</td>
        </tr>
        <tr>
          <td>First Name:</td>
          <td>{row.first_name}</td>
        </tr>
        <tr>
          <td>Last Name:</td>
          <td>{row.last_name}</td>
        </tr>
        <tr>
          <td>Address:</td>
          <td>{row.address} {row.address2}</td>
        </tr>
        <tr>
          <td>Phone:</td>
          <td>{row.phone}</td>
        </tr>
        <tr>
          <td>City:</td>
          <td>{row.city}</td>
        </tr>
        <tr>
          <td>State:</td>
          <td>{row.region}</td>
        </tr>
        <tr>
          <td>Country:</td>
          <td>{row.country}</td>
        </tr>
        <tr>
          <td>Zipcode:</td>
          <td>{row.zipcode}</td>
        </tr>
        <tr>
          <td>Email:</td>
          <td>{row.email}</td>
        </tr>
        <tr>
          <td>Phone:</td>
          <td>{row.phone}</td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{row.comments}</td>
        </tr>
        <tr>
          <td colSpan="2">
            <ContactUsFulfilledBy tag={row.fulfilledby} id={row.id} />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

class ContactUsRequests extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "contact_date", title: "Date" },
        { name: "brand", title: "Brand" },
        {
          name: "name",
          title: "Name",
          getCellValue: row =>
            row.first_name + row.last_name
              ? row.first_name + " " + row.last_name
              : undefined
        },
        { name: "fulfilledby", title: "Fulfilled By" },
        { name: "fulfilledon", title: "Fulfilled On" }
      ]
    };
    this.setSelectionValue = value => this.setState({ selection: value });
  }

  render() {
    const { columns, selection } = this.state;
    const { classes } = this.props;
    return (
      <DashboardLayout title="Contact Us Requests">
        <Query query={getAllContactRequest}>
          {({ data, loading, error }) => {
            if (loading) return <p>Loading...</p>;
            if (error) return <p>Error</p>;
            return (
              <div className={classes.root + " sectionContainer"}>
                <Typography gutterBottom variant="h4" className="title">
                  <span>Contact Us Requests</span>
                </Typography>

                <Paper className="tableStyles">
                  <div className={classes.header}>
                    <span>Total rows selected: {selection ? ( selection.length ) : ( "0" )}</span>
                    <a href="javascript:window.print()">
                      <Print className={classes.printIcon} />
                    </a>
                  </div>
                  <Grid rows={data.contact_requests} columns={columns}>
                    <SelectionState
                      selection={selection}
                      onSelectionChange={this.setSelectionValue}
                    />
                    <PagingState defaultCurrentPage={0} pageSize={5} />
                    <IntegratedSelection />
                    <IntegratedPaging />
                    <RowDetailState defaultExpandedRowIds={[0]} />
                    <Table />
                    <TableHeaderRow />
                    <TableRowDetail contentComponent={RowDetail} />
                    <TableSelection showSelectAll />
                    <PagingPanel />
                  </Grid>
                </Paper>
                {/* <div className="flex center">
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                  >
                    Mark As Fulfilled
                  </Button>
                </div> */}
              </div>
            );
          }}
        </Query>
      </DashboardLayout>
    );
  }
}
ContactUsRequests.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ContactUsRequests);
