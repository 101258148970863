import React, { useState } from "react";
import "../../../FormsDatasheets/CoopPreapprovalClaimForms/ApprovalForms/CoOpTabs/RequestRowDetail.scss";
import CommericalSalesCreditClaimEditButton from "../ApprovalForms/CommericalSalesCreditClaimEditButton";
import { CommericalSalesCreditClaimPdfDocument } from "./CommericalSalesCreditClaimPdfDocument";
import AdminStatusButtons from "pages/Administration/CoopRequests/AdminStatusButtons";
import { makeStyles } from "@material-ui/styles";
import PDFimg from "../../../../images/pdf-file-format.png";
import { Grid, Typography } from "@material-ui/core";
import { CSVLink } from "react-csv";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import excelimg from "../../../../images/excel.png";
import PdfImage from "../../../../images/pdf-icon-red-and-white-color-for-free-png.webp";
import moment from "moment";
import CommentBox from "pages/Administration/CoopRequests/CommentBox";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  Table,
  TableHeaderRow,
  Grid as Grids,
} from "@devexpress/dx-react-grid-material-ui";
import { isPdf } from "common/colors";

const useStyles = makeStyles((theme) => ({
  PrimaryButton: {
    backgroundColor: "#456790",
    color: "#fff",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#2167BC",
    },
  },
  CustomSuccessButton: {
    backgroundColor: "#3B8010",
    color: "#fff",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#5D9930",
    },
    padding: "0.58rem 1rem",
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "3px",
    verticalAlign: "middle",
    textDecoration: "none",
  },
  modal: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    maxHeight: "95%",
    overflow: "scroll",
    top: "3.5% !important",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #707070",
    boxShadow: "0px 3px 6px 0px #00000029",
    padding: theme.spacing(4),
    width: "768px",
    maxWidth: "90%",
  },
}));

export const CommericalSalesCreditClaimRequestRowDetail = ({
  isDisplay,
  comments,
  isEdit,
  row,
  type,
  co_op_id,
  requirementDataArray,
  userName,
}) => {
  const classes = useStyles();
  const [openPrint, setOpenPrint] = useState(false);
  const [isComment, setIsComment] = useState(false);
  const [text, setText] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";

  const handleOpenPrint = () => {
    setOpenPrint(true);
  };

  const handleClosePrint = () => {
    setOpenPrint(false);
  };

  const tableBody = (
    <tbody>
      <tr>
        <td>Dealer Name:</td>
        <td>{row?.dealer_name || ""}</td>
      </tr>
      <tr>
        <td>Contact Name:</td>
        <td>{row?.contact_name || ""}</td>
      </tr>
      <tr>
        <td>Contact Phone:</td>
        <td>{row?.phone || ""}</td>
      </tr>
      <tr>
        <td>Fax Number:</td>
        <td>{row?.fax || ""}</td>
      </tr>
      <tr>
        <td>Address:</td>
        <td>{row?.address || ""}</td>
      </tr>
      <tr>
        <td>Address 2:</td>
        <td>{row?.address2 || ""}</td>
      </tr>
      <tr>
        <td>City:</td>
        <td>{row?.city || ""}</td>
      </tr>
      <tr>
        <td>Country:</td>
        <td>{row?.country || ""}</td>
      </tr>
      <tr>
        <td>State:</td>
        <td>{row?.state || ""}</td>
      </tr>
      <tr>
        <td>Zip Code:</td>
        <td>{row?.zipcode || ""}</td>
      </tr>
      <tr>
        <td>Additional Information:</td>
        <td className="wordBreak">{row?.additional_info || ""}</td>
      </tr>
    </tbody>
  );

  const data1 = [
    ["Dealer Name", row?.dealer_name || ""],
    ["Contact Name", row?.contact_name || ""],
    ["Contact Phone", row?.phone || ""],
    ["Fax Number", row?.fax || ""],
    ["Address", row?.address || ""],
    ["Address 2", row?.address2 || ""],
    ["City", row?.city || ""],
    ["State", row?.state || ""],
    ["Country", row?.country || ""],
    ["Zip Code", row?.zipcode || ""],
    ["Additional Information", row?.additional_info || ""],
  ];

  // Add an empty row for separation
  const separator = [
    ["", ""],
    ["", ""],
  ];

  const headers2 = [
    [
      "Buyer Name",
      "Item/Product",
      "Serial No",
      "Dealer Cost",
      "% Credit",
      "Total Credit",
    ],
  ];

  let data2 = [];
  if (requirementDataArray) {
    data2 = requirementDataArray?.map((item) => [
      `${item?.buyer_name || ""}`,
      `${item?.item_name || ""}`,
      `${item?.serial_no || ""}`,
      `$${item?.dealer_cost || ""}`,
      `${item?.credit || ""}%`,
      `$${item?.total_credit || ""}`,
    ]);
  }
  let combinedData = [...data1, ...separator];
  if (requirementDataArray) {
    combinedData = [...data1, ...separator, ...headers2, ...data2];
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      handleClosePrint();
    }
  };

  const generatePdf = () => {
    const input = document.getElementById("pdf-content");
    html2canvas(input, { scale: 1 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "pt", "a4");
        pdf.addImage(imgData, "PNG", 0, 0);
        pdf.save(
          `government_commercial_sales_credit_claim${row?.dealer_name}.pdf`
        );
      })
      .catch((err) => {
        console.error("Error generating PDF: ", err);
      });
  };

  const columns = [
    { name: "buyer_name", title: "Buyer Name" },
    {
      name: "item_name",
      title: "Item/Product",
    },
    { name: "serial_no", title: "Serial No" },
    {
      name: "dealer_cost",
      title: "Dealer Cost",
      getCellValue: (row) => `$${row?.dealer_cost}`,
    },
    {
      name: "credit",
      title: "% Credit",
      getCellValue: (row) => `${row?.credit}%`,
    },
    {
      name: "total_credit",
      title: "Total Credit",
      getCellValue: (row) => `$${row?.total_credit}`,
    },
  ];

  return (
    <div className="request-detail-pg">
      {type !== "admin" && (
        <>
          {isEdit && <CommericalSalesCreditClaimEditButton item={row} />}
          <Button
            className={`${classes.PrimaryButton} MuiButtonBase-root MuiButton-root MuiButton-containedSecondary MuiButton-contained`}
            onClick={generatePdf}
          >
            <>
              <img
                src={PDFimg}
                alt="PDF"
                width="18px"
                style={{ marginRight: "8px" }}
              />
              Print To Pdf
            </>
          </Button>
          <div id="pdf-content" className="offscreen">
            <CommericalSalesCreditClaimPdfDocument
              data={row}
              requirementData={requirementDataArray}
            />
          </div>
        </>
      )}
      {type === "admin" && (
        <>
          <Button
            color="primary"
            variant="contained"
            className={classes.PrimaryButton}
            type="submit"
            onClick={handleOpenPrint}
            style={{ margin: "2em 2em 2em 0em" }}
          >
            {" "}
            <img
              src={PDFimg}
              alt="PDF"
              width="18px"
              style={{ marginRight: "8px" }}
            />
            PRINT TO PDF
          </Button>
          <div id="pdf-content" className="offscreen">
            <CommericalSalesCreditClaimPdfDocument
              data={row}
              requirementData={requirementDataArray}
            />
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openPrint}
            width="fullWidth"
            onClose={handleClosePrint}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openPrint}>
              <div className={classes.paper}>
                <div className="customModalBody">
                  <table
                    cellSpacing="0"
                    className="request-detail-table"
                    width="100%"
                    style={{ padding: "0px" }}
                  >
                    {tableBody}
                  </table>
                  <Typography
                    gutterBottom
                    variant="h5"
                    className={classes.subtitle3}
                    align="left"
                    color="primary"
                  >
                    Photo(s)/Video Files:
                  </Typography>
                  <br></br>
                  <div className="imgs-row-section">
                    <Grid container spacing={4}>
                      {row?.file1 && (
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <div className="imgs-itembox">
                            {isPdf(row?.file1) ? (
                              <a
                                href={`${process.env.REACT_APP_MEDIA_URL}/${row?.file1}`}
                                target="_blank"
                              >
                                <img
                                  src={PdfImage}
                                  alt="image1"
                                  className="#"
                                  height={200}
                                  weight={200}
                                />
                              </a>
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_MEDIA_URL}/${row?.file1}`}
                                alt="image1"
                                className="#"
                              />
                            )}
                          </div>
                        </Grid>
                      )}
                      {row?.file2 && (
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <div className="imgs-itembox">
                            {isPdf(row?.file2) ? (
                              <a
                                href={`${process.env.REACT_APP_MEDIA_URL}/${row?.file2}`}
                                target="_blank"
                              >
                                <img
                                  src={PdfImage}
                                  alt="file2"
                                  className="#"
                                  height={200}
                                  weight={200}
                                />
                              </a>
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_MEDIA_URL}/${row?.file2}`}
                                alt="file2"
                                className="#"
                              />
                            )}
                          </div>
                        </Grid>
                      )}
                      {row?.file3 && (
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <div className="imgs-itembox">
                            {isPdf(row?.file3) ? (
                              <a
                                href={`${process.env.REACT_APP_MEDIA_URL}/${row?.file3}`}
                                target="_blank"
                              >
                                <img
                                  src={PdfImage}
                                  alt="file3"
                                  className="#"
                                  height={200}
                                  weight={200}
                                />
                              </a>
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_MEDIA_URL}/${row?.file3}`}
                                alt="file3"
                                className="#"
                              />
                            )}
                          </div>
                        </Grid>
                      )}
                      {row?.file4 && (
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <div className="imgs-itembox">
                            {isPdf(row?.file4) ? (
                              <a
                                href={`${process.env.REACT_APP_MEDIA_URL}/${row?.file4}`}
                                target="_blank"
                              >
                                <img
                                  src={PdfImage}
                                  alt="file4"
                                  className="#"
                                  height={200}
                                  weight={200}
                                />
                              </a>
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_MEDIA_URL}/${row?.file4}`}
                                alt="file4"
                                className="#"
                              />
                            )}
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                  <hr style={{ backgroundColor: "#BED4E5" }}></hr>
                  <span
                    onKeyDown={handleKeyPress}
                    onClick={() => handleClosePrint()}
                  >
                    <Button
                      className={`${classes.PrimaryButton} MuiButtonBase-root MuiButton-root MuiButton-containedSecondary MuiButton-contained`}
                      onClick={generatePdf}
                    >
                      <>
                        <img
                          src={PDFimg}
                          alt="PDF"
                          width="18px"
                          style={{ marginRight: "8px" }}
                        />
                        Print To Pdf
                      </>
                    </Button>
                  </span>
                  <Button
                    color="secondary"
                    variant="contained"
                    className={classes.Button}
                    onClick={() => handleClosePrint()}
                    style={{ width: "120px", margin: "2em 1em" }}
                  >
                    CLOSE
                  </Button>
                </div>
              </div>
            </Fade>
          </Modal>
          <CSVLink
            data={combinedData}
            filename={
              "government_commercial_sales_credit_claim_" +
              row?.dealer_name +
              ".csv"
            }
            className={classes.CustomSuccessButton}
          >
            <img
              src={excelimg}
              alt="PDF"
              width="20px"
              style={{ marginRight: "8px" }}
            />
            Export to EXCEL
          </CSVLink>
        </>
      )}

      <table cellSpacing="0" className="request-detail-table" width="100%">
        {tableBody}
      </table>
      {row?.government_commerical_sales_credit_claim_data?.length > 0 && (
        <Grids
          rows={row?.government_commerical_sales_credit_claim_data}
          columns={columns}
        >
          <Table />
          <TableHeaderRow />
        </Grids>
      )}
      <table cellSpacing="0" className="request-detail-table" width="100%">
        <tbody>
          {comments && comments?.length > 0 && (
            <tr>
              <td>Comments:</td>
              <td>{""}</td>
            </tr>
          )}
          {comments &&
            comments?.length > 0 &&
            comments.map((item) => {
              return (
                <tr>
                  {item?.status ? (
                    <td>{capitalize(item?.status)} Comments:</td>
                  ) : (
                    <td>
                      <div>{item?.profile_name || ""}</div>
                      <span>
                        {moment(item?.created_at).format("MMMM D, YYYY h:mm a")}
                      </span>
                    </td>
                  )}
                  <td>
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.comment || "" }}
                    ></div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <Typography
        gutterBottom
        variant="h5"
        className={classes.subtitle3}
        align="left"
      >
        Photo(s)/Video Files:
      </Typography>
      <br></br>
      <div className="imgs-row-section">
        <Grid container spacing={4}>
          {row?.file1 && (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className="imgs-itembox">
                {isPdf(row?.file1) ? (
                  <a
                    href={`${process.env.REACT_APP_MEDIA_URL}/${row?.file1}`}
                    target="_blank"
                  >
                    <img
                      src={PdfImage}
                      alt="image1"
                      className="#"
                      height={200}
                      weight={200}
                    />
                  </a>
                ) : (
                  <img
                    src={`${process.env.REACT_APP_MEDIA_URL}/${row?.file1}`}
                    alt="image1"
                    className="#"
                  />
                )}
              </div>
            </Grid>
          )}
          {row?.file2 && (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className="imgs-itembox">
                {isPdf(row?.file2) ? (
                  <a
                    href={`${process.env.REACT_APP_MEDIA_URL}/${row?.file2}`}
                    target="_blank"
                  >
                    <img
                      src={PdfImage}
                      alt="image2"
                      className="#"
                      height={200}
                      weight={200}
                    />
                  </a>
                ) : (
                  <img
                    src={`${process.env.REACT_APP_MEDIA_URL}/${row?.file2}`}
                    alt="image2"
                    className="#"
                  />
                )}
              </div>
            </Grid>
          )}
          {row?.file3 && (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className="imgs-itembox">
                {isPdf(row?.file3) ? (
                  <a
                    href={`${process.env.REACT_APP_MEDIA_URL}/${row?.file3}`}
                    target="_blank"
                  >
                    <img
                      src={PdfImage}
                      alt="image3"
                      className="#"
                      height={200}
                      weight={200}
                    />
                  </a>
                ) : (
                  <img
                    src={`${process.env.REACT_APP_MEDIA_URL}/${row?.file3}`}
                    alt="image3"
                    className="#"
                  />
                )}
              </div>
            </Grid>
          )}
          {row?.file4 && (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className="imgs-itembox">
                {isPdf(row?.file4) ? (
                  <a
                    href={`${process.env.REACT_APP_MEDIA_URL}/${row?.file4}`}
                    target="_blank"
                  >
                    <img
                      src={PdfImage}
                      alt="image4"
                      className="#"
                      height={200}
                      weight={200}
                    />
                  </a>
                ) : (
                  <img
                    src={`${process.env.REACT_APP_MEDIA_URL}/${row?.file4}`}
                    alt="image4"
                    className="#"
                  />
                )}
              </div>
            </Grid>
          )}
        </Grid>
      </div>

      {type === "admin" && (
        <AdminStatusButtons
          co_op_id={co_op_id}
          id={row?.id}
          type="govt-com-sales-credit-claim"
          statusType={row?.status}
        />
      )}
      {!isDisplay && (
        <>
          {!isComment && (
            <Button onClick={() => setIsComment(true)}>
              Add your comments here
            </Button>
          )}
          {isComment && (
            <CommentBox
              co_op_id={co_op_id}
              isComment={isComment}
              setIsComment={setIsComment}
              text={text}
              setText={setText}
              userName={userName}
            />
          )}
        </>
      )}
    </div>
  );
};
