import React, { Component } from "react";
import WarrantyRegistrationTransferButton from "./WarrantyRegistrationTransferButton";
import EditWarrantyRegistrationButton from "./EditWarrantyRegistrationButton";
import WarrantyRegistrationTransferEdit from "./WarrantyRegistrationTransferEdit";
export default class WarrantyRegistrationTransfer extends Component {

    constructor({registration}) {
        super()
        this.state = {
            registration: registration,
            isEmptyState: true
        }
    }

    triggerEditWarrantyState = () => {
        this.setState({
          ...this.state,
          isEmptyState: false,
          isEditWarrantyState: true
        })
      }

    render() {
        return (
        <div style={{textAlign: "center", padding: 10}}>
            {this.state.isEmptyState && <WarrantyRegistrationTransferButton addTrip={this.triggerEditWarrantyState} />}
            {this.state.isEmptyState && <EditWarrantyRegistrationButton registration={this.state.registration} />}

            {this.state.isEditWarrantyState && <WarrantyRegistrationTransferEdit registration={this.state.registration} addTrip={this.triggerEditWarrantyState} />}
        </div>
        );
    }

}

