import { gql } from "apollo-boost";

export const ADD_BOATSHOWS = gql`
  mutation insert_boat_shows($objects: [boat_shows_insert_input!]!) {
    insert_boat_shows(objects: $objects) {
      returning {
        address
        address2
        city
        country
        created_at
        description
        end_date
        modified_at
        name
        profile_id
        dealer
        brands
        region
        start_date
        zipcode
        site
      }
    }
  }
`;

export const getAllPendingBoatShowRequests = gql`
  subscription {
    boat_shows(
      where: { status: { _eq: "Pending" } }
      order_by: { created_at: desc }
    ) {
      id
      name
      profile_id
      status
      address
      address2
      city
      zipcode
      region
      country
      description
      brands
      dealer
      created_at
      modified_at
      start_date
      end_date
      site
    }
  }
`;

export const getAllPassBoatShowRequests = gql`
  subscription {
    boat_shows(where: { end_date: { _lt: "now()" } }) {
      id
      name
      profile_id
      status
      address
      address2
      city
      zipcode
      region
      country
      description
      created_at
      modified_at
      start_date
      end_date
      site
      dealer
      brands
    }
  }
`;

export const getAllUpcomingBoatShowRequests = gql`
  subscription {
    boat_shows(
      where: { start_date: { _gte: "now()" }, status: { _eq: "Approved" } }
    ) {
      id
      created_at
      modified_at
      profile_id
      name
      dealer
      address
      address2
      city
      region
      description
      zipcode
      country
      brands
      start_date
      end_date
      site
    }
  }
`;

export const DeleteBoatShow = gql`
  mutation delete_boat_shows($id: uuid!) {
    delete_boat_shows(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
