import React, { Component } from "react";
import { Mutation } from "react-apollo";
import ApolloCacheUpdater from "apollo-cache-updater";
import { gql } from "apollo-boost";
import { 
    Button
  } from '@material-ui/core';

const styles = {
    button: {
        margin: 8,
    }
};
const UPDATE_WARRANTY_INQUIRY = gql`
   mutation updateWarrantyInquiriesFulfilledBy($id:uuid!, $fulfilledby:String!, $fulfilledon:date!){
    update_warranty_inquiries(
        where: {id: {_eq: $id}},
        _set: {
          fulfilledby: $fulfilledby,
          fulfilledon: $fulfilledon
        }
      ) {
        affected_rows
        returning {
            id
            fulfilledby
            fulfilledon
        }
      }
   }
`;

export default class WarrantyInquiriesFulfilledBy extends Component {

    constructor({tag, id}) {
        super()
        this.state = {
            tag: tag,
            id: id
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.tag !== prevProps.tag) 
        {
            this.setState(
                {
                    tag: this.props.tag
                }
            )
        }
    }

    
    componentWillReceiveProps = () => {
        console.log("componentWillReceiveProps")
    }

    completedAdd = payload => {
        this.setState(
            { tag: 'fulfilledby' }
        )
    }

    render() {
        const tag = this.state.tag
        return (
            <Mutation
                mutation={UPDATE_WARRANTY_INQUIRY}
            >
                {(leads, { data }) => {
                    if (tag) {
                        return <div> Fulfilled {tag}</div>
                    }
                    else {
                        return (
                        <div>
                        Completed Contact Request By:
                        <Button variant="contained" color="secondary" style={styles.button} onClick={e => {
                            leads({
                                variables: {
                                    id:this.state.id, 
                                    fulfilledby: "via Email",
                                    fulfilledon: "now()"
                                },
                            })
                            this.completedAdd()
                            }}>Via Email</Button>
                        <Button variant="contained" color="secondary" style={styles.button} onClick={e => {
                            leads({
                                variables: {
                                    id:this.state.id, 
                                    fulfilledby: "Via Phone",
                                    fulfilledon: "now()"
                                },
                            })
                            this.completedAdd()
                            }}>Via Phone</Button>
                            <Button variant="contained" color="secondary" style={styles.button} onClick={e => {
                                leads({
                                    variables: {
                                        id:this.state.id, 
                                        fulfilledby: "Via Other",
                                        fulfilledon: "now()"
                                    },
                                })
                                this.completedAdd()
                                }}>Via Other</Button>
                        </div>
                        )
                    }
                }
                }
            </Mutation>
        );
    }

}