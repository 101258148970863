import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Typography, withStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { UPDATE_ADMIN_USER } from "queries";
import { Mutation, Query } from "react-apollo";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import axios from "axios";
import auth0User from "components/Auth0User";
import InputLabel from "@material-ui/core/InputLabel";
import { GET_USER_INFO } from "queries";
import { withRouter } from "react-router";
import compose from "recompose/compose";
import { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100%",
    maxWidth: "320px",
    textAlign: "center"
  },
  dense: {
    marginTop: 19
  },
  formControl: {
    margin: theme.spacing(3)
  },
  addAdminUser: {
    width: "200px",
    margin: "1em",
    padding: "1em",
    fontSize: "14px",
    fontWeight: 100
  },
  checkBoxTitle: {
    fontSize: "18px",
    textTransform: "uppercase",
    color: "#1f3340",
    fontWeight: "300",
  },
}));

const styles = theme => ({});
const EditUser = props => {
  
  const { match: { params } } = props;

  const { values, handleChange, resetState, handleChangeCheckbox, setValues } = useForm(
    submitForm
  );

  const classes = useStyles();

  function permissionsCreator() {
    let permissionsArray = [];
    console.log("CHECK")
    console.log(values)
    if (values.ADMIN_ALL === true && 
      values.ADMIN_LEADS === false && 
      values.ADMIN_MY_DEALERS === false && 
      values.ADMIN_WARRANTY_CLAIMS === false && 
      values.ADMIN_WARRANTY_LOOKUP === false && 
      values.ADMIN_CAREERS === false && 
      values.ADMIN_CSI === false &&
      values.ADMIN_MEDIA === false && 
      values.ADMIN_CO_OP_REQ === false && 
      values.ADMIN_BOAT_SHOW_REQUESTS === false && 
      values.ADMIN_WARRANTY_INQ === false && 
      values.ADMIN_NEW_DEALER_REQ === false && 
      values.ADMIN_CONTACT_REQ === false) {
      permissionsArray.push("admin:ADMIN_ALL");
      return permissionsArray;
    }
    if (values.ADMIN_LEADS === true) {
      permissionsArray.push("admin:ADMIN_LEADS");
    }
    if (values.ADMIN_MY_DEALERS === true) {
      permissionsArray.push("admin:ADMIN_MY_DEALERS");
    }
    if (values.ADMIN_WARRANTY_CLAIMS === true) {
      permissionsArray.push("admin:ADMIN_WARRANTY_CLAIMS");
    }
    if (values.ADMIN_WARRANTY_LOOKUP === true) {
      permissionsArray.push("admin:ADMIN_WARRANTY_LOOKUP");
    }
    if (values.ADMIN_CAREERS === true) {
      permissionsArray.push("admin:ADMIN_CAREERS");
    }
    if (values.ADMIN_CSI === true) {
      permissionsArray.push("admin:ADMIN_CSI");
    }
    if (values.ADMIN_MEDIA === true) {
      permissionsArray.push("admin:ADMIN_MEDIA");
    }
    if (values.ADMIN_CO_OP_REQ === true) {
      permissionsArray.push("admin:ADMIN_CO_OP_REQ");
    }
    if (values.ADMIN_BOAT_SHOW_REQUESTS === true) {
      permissionsArray.push("admin:ADMIN_BOAT_SHOW_REQUESTS");
    }
    if (values.ADMIN_WARRANTY_INQ === true) {
      permissionsArray.push("admin:ADMIN_WARRANTY_INQ");
    }
    if (values.ADMIN_NEW_DEALER_REQ === true) {
      permissionsArray.push("admin:ADMIN_NEW_DEALER_REQ");
    }
    if (values.ADMIN_CONTACT_REQ === true) {
      permissionsArray.push("admin:ADMIN_CONTACT_REQ");
    }

    return permissionsArray;
  }
  function updateUser(data, callBackMutation) {
    let formData = {};
    let accessTokenPromise = auth0User.getToken();
    let AUTH_API_URL = auth0User.AUTH_API_URL();

    accessTokenPromise.then((accessToken) => {
      formData.name = data.name;
      formData.email = data.email;
      //formData.role = "Admin";
      formData.profile_id = data.profile_id;
      formData.permissions = [];
          
      axios({
        url: AUTH_API_URL + "users-by-email",
        method: "get",
        headers: { Authorization: `Bearer ${accessToken}` },
        params: {
          email: formData.email
        }
      })
        .then(function(response) {
          //console.log("Response from email");
          //console.log(response.data[0].user_id);

          if (response.data !== undefined || response.data.length === 0) {
            //console.log(response.data.length + " Data length");
            let auth0permissions = permissionsCreator();

            axios({
              url: AUTH_API_URL + "users/" + response.data[0].user_id,
              method: "patch",
              headers: { Authorization: `Bearer ${accessToken}` },
              data: {
                connection: "Username-Password-Authentication",
                email: formData.email,
                //password: data.password,
                user_metadata: {
                  full_name: formData.name
                },
                app_metadata: {
                  role: "admin",
                  permissions: auth0permissions
                }
              }
            })
              .then(function(response) {
                      let permissions = permissionsCreator();
                      formData.permissions = permissions;
                      delete formData.password; 
                      callBackMutation({
                        variables: formData
                      });
                      return true;
              })
              .catch(function(error) {
                toast.error("Error Updating New Admin on Auth0: " + error, {
                  position: toast.POSITION.TOP_LEFT
                });
              });
          }
        })
        .catch(function(error) {
          toast.error("ERROR: User already exists", {
            position: toast.POSITION.TOP_LEFT
          });
        });
      }).catch((error) => {   
        toast.error("Error from getToken() with async( When promise gets rejected ): " + error, {
          position: toast.POSITION.TOP_LEFT
        });  
      });
  }

  function updatePermissions(permissionsArray) {
    //console.log("Updating Permissions");
    //console.log(permissionsArray);

    if (permissionsArray.includes("admin:ADMIN_ALL")) {
      values.ADMIN_ALL = true;
      return permissionsArray;
    }

    if (permissionsArray.includes("admin:ADMIN_LEADS")) {
      values.ADMIN_LEADS = true;
    }

    if (permissionsArray.includes("admin:ADMIN_MY_DEALERS")) {
      values.ADMIN_MY_DEALERS = true;
    }

    if (permissionsArray.includes("admin:ADMIN_WARRANTY_CLAIMS")) {
      values.ADMIN_WARRANTY_CLAIMS = true;
    }

    if (permissionsArray.includes("admin:ADMIN_WARRANTY_LOOKUP")) {
      values.ADMIN_WARRANTY_LOOKUP = true;
    }

    if (permissionsArray.includes("admin:ADMIN_CAREERS")) {
      values.ADMIN_CAREERS = true;
    }

    if (permissionsArray.includes("admin:ADMIN_CSI")) {
      values.ADMIN_CSI = true;
    }

    if (permissionsArray.includes("admin:ADMIN_MEDIA")) {
      values.ADMIN_MEDIA = true;
    }

    if (permissionsArray.includes("admin:ADMIN_CO_OP_REQ")) {
      values.ADMIN_CO_OP_REQ = true;
    }

    if (permissionsArray.includes("admin:ADMIN_BOAT_SHOW_REQUESTS")) {
      values.ADMIN_BOAT_SHOW_REQUESTS = true;
    }

    if (permissionsArray.includes("admin:ADMIN_WARRANTY_INQ")) {
      values.ADMIN_WARRANTY_INQ = true;
    }

    if (permissionsArray.includes("admin:ADMIN_NEW_DEALER_REQ")) {
      values.ADMIN_NEW_DEALER_REQ = true;
    }

    if (permissionsArray.includes("admin:ADMIN_CONTACT_REQ")) {
      values.ADMIN_CONTACT_REQ = true;
    }

    return permissionsArray;
  }

  // values.start_date = new Date().getDate()
  function submitForm() {
    //console.log(values);
  }
  function completedAdd(payload) {
    toast("Admin User was been succesfully updated");
    //resetState();
  }
  return (
    <Query query={GET_USER_INFO} variables={{ id: params.userId }}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return `Error! ${error}`;
        //console.log(data.profiles_by_pk);
        //auth.setPermissions(data.profiles_by_pk.permissions);
        //console.log("CALLEDvalues")
        //console.log(values)
        if (values.email === undefined) {
          //console.log("CALLED")
          setValues(data.profiles_by_pk);
          //updateValues(data.profiles_by_pk);
        }
        updatePermissions(data.profiles_by_pk.permissions);
        if (data.profiles_by_pk !== undefined) {
          return (
            <Mutation mutation={UPDATE_ADMIN_USER} onCompleted={completedAdd}>
              {(updateAdmin, { data, loading, called, error }) => {
                return (
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      updateUser(values, updateAdmin);

                      return false;
                    }}
                    className={classes.container}
                    autoComplete="off"
                  >
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        wrap="nowrap"
                      >
                        {/* Row 1 */}
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        wrap="nowrap"
                      >
                        {/* Row 1 */}

                        <TextField
                          id="name"
                          name="name"
                          label="Full Name"
                          placeholder="Full Name"
                          className={classes.textField}
                          margin="normal"
                          onChange={handleChange}
                          value={values.name || ""}
                          required
                          variant="outlined"
                        />
                      </Grid>

                      <Grid container>
                        {/* Row 2 */}
                        <Grid item xs={6} align="left">
                          
                        <TextField
                          id="email"
                          name="email"
                          label="Email"
                          placeholder="Email"
                          className={classes.textField}
                          margin="normal"
                          onChange={handleChange}
                          value={values.email || ""}
                          required
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                  <FormControl
                    required
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <Typography className={classes.checkBoxTitle}>
                    User Permissions
                            </Typography>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="ADMIN_ALL"
                                  checked={values.ADMIN_ALL || false}
                                  onChange={handleChangeCheckbox}
                                  value="ADMIN_ALL"
                                />
                              }
                              label="ALL"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="ADMIN_LEADS"
                                  checked={values.ADMIN_LEADS || false}
                                  onChange={handleChangeCheckbox}
                                  value="ADMIN_LEADS"
                                />
                              }
                              label="ADMIN LEVEL LEADS"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="ADMIN_MY_DEALERS"
                                  checked={values.ADMIN_MY_DEALERS || false}
                                  onChange={handleChangeCheckbox}
                                  value="ADMIN_MY_DEALERS"
                                />
                              }
                              label="ADMIN LEVEL MY DEALERS"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="ADMIN_WARRANTY_CLAIMS"
                                  checked={values.ADMIN_WARRANTY_CLAIMS || false}
                                  onChange={handleChangeCheckbox}
                                  value="ADMIN_WARRANTY_CLAIMS"
                                />
                              }
                              label="ADMIN LEVEL WARRANTY CLAIMS"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="ADMIN_WARRANTY_LOOKUP"
                                  checked={values.ADMIN_WARRANTY_LOOKUP || false}
                                  onChange={handleChangeCheckbox}
                                  value="ADMIN_WARRANTY_LOOKUP"
                                />
                              }
                              label="ADMIN LEVEL WARRANTY LOOKUP"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="ADMIN_CAREERS"
                                  checked={values.ADMIN_CAREERS || false}
                                  onChange={handleChangeCheckbox}
                                  value="ADMIN_CAREERS"
                                />
                              }
                              label="ADMIN LEVEL CAREERS"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="ADMIN_CSI"
                                  checked={values.ADMIN_CSI || false}
                                  onChange={handleChangeCheckbox}
                                  value="ADMIN_CSI"
                                />
                              }
                              label="ADMIN LEVEL CSI"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="ADMIN_MEDIA"
                                  checked={values.ADMIN_MEDIA || false}
                                  onChange={handleChangeCheckbox}
                                  value="ADMIN_MEDIA"
                                />
                              }
                              label="ADMIN LEVEL MEDIA"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="ADMIN_CO_OP_REQ"
                                  checked={values.ADMIN_CO_OP_REQ || false}
                                  onChange={handleChangeCheckbox}
                                  value="ADMIN_CO_OP_REQ"
                                />
                              }
                              label="ADMIN LEVEL CO OP REQUESTS"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="ADMIN_BOAT_SHOW_REQUESTS"
                                  checked={values.ADMIN_BOAT_SHOW_REQUESTS || false}
                                  onChange={handleChangeCheckbox}
                                  value="ADMIN_BOAT_SHOW_REQUESTS"
                                />
                              }
                              label="ADMIN LEVEL BOAT SHOW REQUESTS"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="ADMIN_WARRANTY_INQ"
                                  checked={values.ADMIN_WARRANTY_INQ || false}
                                  onChange={handleChangeCheckbox}
                                  value="ADMIN_WARRANTY_INQ"
                                />
                              }
                              label="ADMIN LEVEL WARRANTY INQURIES"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="ADMIN_NEW_DEALER_REQ"
                                  checked={values.ADMIN_NEW_DEALER_REQ || false}
                                  onChange={handleChangeCheckbox}
                                  value="ADMIN_NEW_DEALER_REQ"
                                />
                              }
                              label="ADMIN LEVEL NEW DEALER REQUESTS"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="ADMIN_CONTACT_REQ"
                                  checked={values.ADMIN_CONTACT_REQ || false}
                                  onChange={handleChangeCheckbox}
                                  value="ADMIN_CONTACT_REQ"
                                />
                              }
                              label="ADMIN LEVEL CONTACT US REQUESTS"
                            />
                          </FormGroup>
                        </FormControl>
                        </Grid>
                      </Grid>
                      {/* Row 3 */}
                      <Grid container>
                        <Grid item xs={12} align="center">
                          <InputLabel
                            id="errorMsgs"
                            value={error || ""}
                            width="100%"
                            ref={error}
                          ></InputLabel>
                        </Grid>
                        <Grid item xs={12} align="center">
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.addAdminUser}
                            type="submit"
                          >UPDATE ADMIN
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Mutation>
          );
        }
      }}
    </Query>
  );
};

export default compose(withRouter, withStyles(styles))(EditUser);
