import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import Callback from "components/Callback";
import GuardedRoute from "components/GuardedRoute";
import withSplashScreen from "components/withSplashScreen";
import Can from "components/Can";
import Dashboard from "pages/Dashboard/Dashboard";
import AdminLeads from "pages/Administration/Leads/Leads";
import AdminMyDealers from "pages/Administration/MyDealers";
import AdminDealersEdit from "pages/Administration/MyDealersEdit";
import AdminCareers from "pages/Administration/Careers";
import AdminCsi from "pages/Administration/Csi";
import AdminMedia from "pages/Administration/Media";
import AdminUserSettings from "pages/Administration/AdminSettings";
import AdminEditUser from "pages/Administration/AdminSettingsEdit";
import AdminWarrantyRegistrations from "pages/Administration/WarrantyRegistrations";
import AdminWarrantyRegistrationEdit from "pages/Administration/WarrantyRegistration/WarrantyRegistrationEdit";
import AdminWarrantyClaims from "pages/Administration/WarrantyClaims";
import AdminWarrantyClaimsEdit from "pages/Administration/WarrantyClaims/WarrantyClaimEdit";
import AdminWarrantyInquiries from "pages/Administration/WarrantyInquiries";
import AdminCoopRequests from "pages/Administration/CoopRequests/CoopRequests";
import AdminNewDealerRequests from "pages/Administration/NewDealerRequests";
import AdminContactUsRequests from "pages/Administration/ContactUsRequests";
import Csi from "pages/Csi";
import FactoryContacts from "pages/FactoryContacts";
import Leads from "pages/Leads";
import DealerProfile from "pages/Profiles/EditDealerProfile";
import Media from "pages/Media";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import WarrantyClaims from "pages/Warranty/Claims";
import WarrantyRegistrations from "pages/Warranty/Registrations";
import WarrantyRegister from "pages/Warranty/Register";
import WarrantyPreauthClaim from "pages/Warranty/PreauthClaim";
import WarrantyDownloadForms from "pages/Warranty/DownloadForms";
import CoopPreapprovalClaimForms from "pages/FormsDatasheets/CoopPreapprovalClaimForms";
import MsrpDatasheets from "pages/FormsDatasheets/MsrpDatasheets";
import BoatShows from "pages/BoatShow/BoatShows";
import { DirectionsBoat } from "@material-ui/icons";
import AddBoatModel from "./pages/Administration/BoatModels/AddBoatModel";
import auth from "./Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApolloProvider as DepricatedApolloProvider } from "react-apollo";
import { ApolloProvider } from '@apollo/client';
import apollo from "apollo";
import BoatModels from "pages/Administration/BoatModels/BoatModels";
import EditBoatModel from "pages/Administration/BoatModels/EditBoatModel";
import ClaimForms from "pages/FormsDatasheets/CoopPreapprovalClaimForms/ClaimForms";
import NewRequest from "pages/FormsDatasheets/CoopPreapprovalClaimForms/ApprovalForms/CoOpTabs/CoOpTabData";
import MediaPreApprovalForm from "pages/FormsDatasheets/CoopPreapprovalClaimForms/ApprovalForms/MediaPreApprovalForm";
import MediaPreApprovalFormEdit from "pages/FormsDatasheets/CoopPreapprovalClaimForms/ApprovalForms/MediaPreApprovalFormEdit";
import SpecialProjectForms from "pages/FormsDatasheets/CoopPreapprovalClaimForms/ApprovalForms/SpecialProjectForms";
import AdvertisingClaimForm from "pages/FormsDatasheets/CoopPreapprovalClaimForms/ApprovalForms/AdvertisingClaimForm/AdvertisingClaimForm";
import CommericalSalesCreditClaim from "pages/FormsDatasheets/CoopPreapprovalClaimForms/ApprovalForms/CommericalSalesCreditClaim";
import CoopPreApprovalForm from "pages/Administration/CoopRequests/CoopPreApprovalForm";
import MSRPdataSheet from "pages/Administration/CoopRequests/MSRPdataSheet";
import PreAuthClaimForms from "pages/Administration/CoopRequests/PreAuthClaimForms";
import SpecialProjectFormEdit from "pages/FormsDatasheets/CoopPreapprovalClaimForms/ApprovalForms/SpecialProjectFormEdit";
import CommericalSalesCreditClaimEdit from "pages/FormsDatasheets/CoopPreapprovalClaimForms/ApprovalForms/CommericalSalesCreditClaimEdit";
import CommericalSalesCreditClaims from "pages/FormsDatasheets/CoopPreapprovalClaimForms/ApprovalForms/CommericalSalesCreditClaims";
import BoatShowCredit from "pages/FormsDatasheets/CoopPreapprovalClaimForms/ApprovalForms/BoatShowCredit";

//TODO Need to fix can component for ui based
class App extends Component {
  state = {}

  constructor(props) {
    super(props)
    this.state = {
      role: '',
      permissions: ''
    }
    this.loadData().then(data =>
      this.setState({ permissions: data.permissions })
    )
  }
  async loadData() {

    let data = [];
    if (auth.getRole() !== undefined) {
      data.role = auth.getRole().toLowerCase();
      data.permissions = auth.getPermissions();
      this.state.role = auth.getRole().toLowerCase();
      this.state.permissions = auth.getPermissions();
    }
    return data
  }
  async componentDidMount() {
    if (this.props.location.pathname === "/callback") return;
    try {
      //await auth.silentAuth();
      this.forceUpdate();
    } catch (err) {
      if (err.error === "login_required") return;
      //console.log(err.error);
    }
  }

  hasPermissions(userPermissions, permission) {
    if (userPermissions.includes("admin:ADMIN_ALL")) return true;

    if (userPermissions.includes(permission)) return true;
  }

  render() {
    if (this.state && this.state.length > 0) {
      return <div>Loading...</div>
    }
    const client = apollo.clientForToken(auth.getIdToken());

    let isAdmin = false;
    let getCurrentUserPermissions = '';
    if (auth.getRole() !== undefined) isAdmin = auth.getRole().toLowerCase();
    if (auth.getPermissions() !== undefined) getCurrentUserPermissions = auth.getPermissions();

    const rules = {
      visitor: {
        static: ["posts:list", "home-page:visit"]
      },
      dealer: {
        static: getCurrentUserPermissions,
        dynamic: {
          "posts:edit": ({ userId, postOwnerId }) => {
            if (!userId || !postOwnerId) return false;
            return userId === postOwnerId;
          }
        }
      },
      admin: {
        static: [
          "admin:ADMIN_LEADS",
          "admin:ADMIN_MY_DEALERS",
          "admin:ADMIN_CAREERS",
          "admin:ADMIN_WARRANTY_CLAIMS",
          "admin:ADMIN_WARRANTY_LOOKUP",
          "admin:ADMIN_CSI",
          "admin:ADMIN_MEDIA",
          "admin:ADMIN_WARRANTY_INQ",
          "admin:ADMIN_CO_OP_REQ",
          "admin:ADMIN_NEW_DEALER_REQ",
          "admin:ADMIN_CONTACT_REQ",
          "admin:ADMIN_ALL"
        ]
      },
      Admin: {
        static: getCurrentUserPermissions
      }
    };
    //console.log("rendering isAdmin permisions");
    //console.log(isAdmin);
    return (
      <div>
        <DepricatedApolloProvider client={client}>
          <ApolloProvider client={client}>
            <Route exact path="/callback" render={(props) => <Callback {...props} />} />
            <Route exact path="/not-found" render={(props) => <NotFound {...props} />} />
            <Route exact path="/" render={(props) => <Home {...props} />} />

            {isAdmin === "admin" ? (
              <div>
                <ToastContainer />
                {/* <GuardedRoute exact path="/dashboard" component={Dashboard} /> */}
                <Can
                  role={auth.getRole()}
                  perform="admin:ADMIN_LEADS"
                  userRule={rules}
                  yes={() => (
                    <GuardedRoute
                      exact
                      path="/admin-leads"
                      component={AdminLeads}
                    />
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="admin:ADMIN_MY_DEALERS"
                  userRule={rules}
                  yes={() => (
                    <Switch>
                      <GuardedRoute exact path="/admin-my-dealers" component={AdminMyDealers} />
                      <GuardedRoute path="/admin-my-dealers/dealers/:userId/edit" component={AdminDealersEdit} />
                    </Switch>
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="admin:ADMIN_CAREERS"
                  userRule={rules}
                  yes={() => (
                    <GuardedRoute exact path="/admin-careers" component={AdminCareers} />
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="admin:ADMIN_WARRANTY_CLAIMS"
                  userRule={rules}
                  yes={() => (
                    <Switch>
                      <GuardedRoute exact path="/admin-warranty-claims" component={AdminWarrantyClaims} />
                      <GuardedRoute path="/admin-warranty-claims/:userId/edit" component={AdminWarrantyClaimsEdit} />
                    </Switch>
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="admin:ADMIN_WARRANTY_LOOKUP"
                  userRule={rules}
                  yes={() => (
                    <Switch>
                      <GuardedRoute exact path="/admin-warranty-registrations" component={AdminWarrantyRegistrations} />
                      <GuardedRoute path="/admin-warranty-registrations/:userId/edit" component={AdminWarrantyRegistrationEdit} />
                    </Switch>
                  )}
                  no={() => ""}
                />
                {
                  (this.hasPermissions(getCurrentUserPermissions, "admin:ADMIN_WARRANTY_CLAIMS") ||
                    this.hasPermissions(getCurrentUserPermissions, "admin:ADMIN_WARRANTY_LOOKUP")) &&
                  <Switch>
                    <GuardedRoute exact path="/admin-boat-models" component={BoatModels} />
                    <GuardedRoute exact path="/admin-boat-models/:boatId/edit" component={EditBoatModel} />
                  </Switch>
                }
                <Can
                  role={auth.getRole()}
                  perform="admin:ADMIN_CSI"
                  userRule={rules}
                  yes={() => (
                    <GuardedRoute exact path="/admin-csi" component={AdminCsi} />
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="admin:ADMIN_MEDIA"
                  userRule={rules}
                  yes={() => (
                    <GuardedRoute exact path="/admin-media" component={AdminMedia} />
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="admin:ADMIN_WARRANTY_INQ"
                  userRule={rules}
                  yes={() => (
                    <GuardedRoute exact path="/admin-warranty-inquiries" component={AdminWarrantyInquiries} />
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="admin:ADMIN_CO_OP_REQ"
                  userRule={rules}
                  yes={() => (
                    <GuardedRoute exact path="/admin-co-op-requests" component={AdminCoopRequests} />
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="admin:ADMIN_NEW_DEALER_REQ"
                  userRule={rules}
                  yes={() => (
                    <GuardedRoute exact path="/admin-new-dealer-requests" component={AdminNewDealerRequests} />
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="admin:ADMIN_CONTACT_REQ"
                  userRule={rules}
                  yes={() => (
                    <GuardedRoute exact path="/admin-contact-us-requests" component={AdminContactUsRequests} />
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="admin:ADMIN_ALL"
                  userRule={rules}
                  yes={() => (
                    <Switch>
                      <GuardedRoute exact path="/admin-user-settings" component={AdminUserSettings} />
                      <GuardedRoute path="/admin-user-settings/user/:userId/edit" component={AdminEditUser} />
                    </Switch>
                  )}
                  no={() => ""}
                />
                <GuardedRoute exact path="/dashboard" component={Dashboard} />
                <GuardedRoute exact path="/factory-contacts" component={FactoryContacts} />
                <GuardedRoute exact path="/boat-shows" component={BoatShows} />
              </div>
            ) : (
              <div>
                <ToastContainer />
                <GuardedRoute exact path="/dashboard" component={Dashboard} />
                <GuardedRoute exact path="/profile" component={DealerProfile} />
                <Can
                  role={auth.getRole()}
                  perform="dealer:DEALER_LEADS"
                  userRule={rules}
                  yes={() => (
                    <GuardedRoute exact path="/leads" component={Leads} />
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="dealer:DEALER_CSI"
                  userRule={rules}
                  yes={() => (
                    <GuardedRoute exact path="/csi" component={Csi} />
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="dealer:DEALER_FACTORY_CONTACTS"
                  userRule={rules}
                  yes={() => (
                    <GuardedRoute exact path="/factory-contacts" component={FactoryContacts} />
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="dealer:DEALER_MEDIA"
                  userRule={rules}
                  yes={() => (
                    <GuardedRoute exact path="/media" component={Media} />
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="dealer:DEALER_FORMS_DATASHEETS"
                  userRule={rules}
                  yes={() => (
                    <div>
                      <GuardedRoute exact path="/forms-co-op-pre-approval-claim-forms" component={CoopPreapprovalClaimForms} />
                      <GuardedRoute exact path="/forms-data-sheets/:id" component={ClaimForms} />
                      <GuardedRoute exact path="/forms-msrp-datasheets" component={MsrpDatasheets} />
                    </div>
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="dealer:DEALER_WARRANTY"
                  userRule={rules}
                  yes={() => (
                    <div>
                      <GuardedRoute exact path="/warranty-claims" component={WarrantyClaims} />
                      <GuardedRoute exact path="/warranty-registrations" component={WarrantyRegistrations} />
                      <GuardedRoute exact path="/warranty-register" component={WarrantyRegister} />
                      <GuardedRoute exact path="/warranty-preauth-claim" component={WarrantyPreauthClaim} />
                      <GuardedRoute exact path="/warranty-download-forms" component={WarrantyDownloadForms} />
                    </div>
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="dealer:DEALER_BOAT_SHOWS"
                  userRule={rules}
                  yes={() => (
                    <GuardedRoute exact path="/boat-shows" component={BoatShows} />
                  )}
                  no={() => ""}
                />
                <Can
                  role={auth.getRole()}
                  perform="dealer:DEALER_ALL"
                  userRule={rules}
                  yes={() => (
                    <div>
                      <GuardedRoute exact path="/leads" component={Leads} />
                      <GuardedRoute exact path="/csi" component={Csi} />
                      <GuardedRoute exact path="/media" component={Media} />
                      <GuardedRoute exact path="/factory-contacts" component={FactoryContacts} />
                      <GuardedRoute exact path="/forms-co-op-pre-approval-claim-forms" component={CoopPreapprovalClaimForms} />
                      <GuardedRoute exact path="/forms-msrp-datasheets" component={MsrpDatasheets} />
                      <GuardedRoute exact path="/warranty-register" component={WarrantyRegister} />
                      <GuardedRoute exact path="/warranty-preauth-claim" component={WarrantyPreauthClaim} />
                      <GuardedRoute exact path="/warranty-download-forms" component={WarrantyDownloadForms} />
                      <GuardedRoute exact path="/warranty-claims" component={WarrantyClaims} />
                      <GuardedRoute exact path="/warranty-registrations" component={WarrantyRegistrations} />
                      <GuardedRoute exact path="/boat-shows" component={BoatShows} />
                      <GuardedRoute exact path="/pre-approval-claim" component={NewRequest} />
                      <GuardedRoute exact path="/media-pre-approval-form" component={MediaPreApprovalForm} />
                      <GuardedRoute path="/media-pre-approval-form/:id/edit" component={MediaPreApprovalFormEdit} />
                      <GuardedRoute exact path="/special-project-forms" component={SpecialProjectForms} />
                      <GuardedRoute path="/special-project-form/:id/edit" component={SpecialProjectFormEdit} />
                      <GuardedRoute exact path="/advertising-claim-form" component={AdvertisingClaimForm} />
                      <GuardedRoute exact path="/commerical-sales-credit-claim" component={CommericalSalesCreditClaims} />
                      <GuardedRoute path="/commerical-sales-credit-claim/:id/edit" component={CommericalSalesCreditClaimEdit} />
                      <GuardedRoute exact path="/boat-show-credit" component={BoatShowCredit} />
                      <GuardedRoute path="/boat-show-credit/:id/edit" component={BoatShowCredit} />
                      <GuardedRoute exact path="/co-op-pre-approval-form" component={CoopPreApprovalForm} />
                      <GuardedRoute exact path="/msrp-data-sheet" component={MSRPdataSheet} />
                      <GuardedRoute exact path="/pre-auth-claim-forms" component={PreAuthClaimForms} />
                    </div>
                  )}
                  no={() => ""}
                />
              </div>
            )}
          </ApolloProvider>
        </DepricatedApolloProvider>
      </div>
    );
  }
}
export default withSplashScreen(withRouter(App));
//export default withRouter(App);
