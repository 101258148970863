import React, { useState, useEffect } from "react";
import { Mutation } from "react-apollo";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Query, Subscription } from "react-apollo";
import { toast } from "react-toastify";
import {
  Paper,
  Typography,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormLabel,
  IconButton,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { RowDetailState } from "@devexpress/dx-react-grid";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Table,
  TableHeaderRow,
  TableRowDetail,
  Grid as Grids,
} from "@devexpress/dx-react-grid-material-ui";
import useForm from "components/useForm";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
// Component styles
import styles from "theme/skin";
import auth from "Auth";
import {
  ADD_COMMERICAL_SALES_CREDIT_CLAIM,
  ADD_COMMERICAL_SALES_CREDIT_CLAIM_DATA,
  ADD_CO_OP_REQUEST_FORMS,
  getAllCommericalSalesCreditClaim,
} from "./queries";
import { CircularProgress } from "@material-ui/core";
import { CountryRegionData } from "react-country-region-selector";
import apollo from "apollo";
import axios from "axios";
import { CommericalSalesCreditClaimRequestRowDetail } from "../NewRequest/CommericalSalesCreditClaimRequestRowDetail";
import moment from 'moment';
import { StatusEnum } from "pages/FormsDatasheets/enum";
import { GET_USER_INFO } from "queries";

const APP_SERVER = apollo.APP_UPLOAD_SERVER_URL;

const getRegions = (country) => {
  if (!country) {
    return [];
  }
  //console.log("regions");
  return country[2].split("|").map((regionPair) => {
    let [regionName, regionShortCode = null] = regionPair.split("~");
    return regionName;
  });
};

function CommericalSalesCreditClaim(props) {
  const { classes } = props;
  const now = new Date();
  const formattedUTCDate = moment.utc();

  const client = apollo.clientForToken(auth.getIdToken());
  const { values, handleChange, resetState } = useForm(submitForm);
  const [expandedRowIds, setExpandedRowIds] = useState([0]);
  const [salesData, setSalesData] = useState([
    {
      buyer_name: "",
      item_name: "",
      serial_no: "",
      dealer_cost: "",
      credit: "",
      total_credit: "",
    },
    {
      buyer_name: "",
      item_name: "",
      serial_no: "",
      dealer_cost: "",
      credit: "",
      total_credit: "",
    },
    {
      buyer_name: "",
      item_name: "",
      serial_no: "",
      dealer_cost: "",
      credit: "",
      total_credit: "",
    },
    {
      buyer_name: "",
      item_name: "",
      serial_no: "",
      dealer_cost: "",
      credit: "",
      total_credit: "",
    },
    {
      buyer_name: "",
      item_name: "",
      serial_no: "",
      dealer_cost: "",
      credit: "",
      total_credit: "",
    },
    {
      buyer_name: "",
      item_name: "",
      serial_no: "",
      dealer_cost: "",
      credit: "",
      total_credit: "",
    },
    {
      buyer_name: "",
      item_name: "",
      serial_no: "",
      dealer_cost: "",
      credit: "",
      total_credit: "",
    },
    {
      buyer_name: "",
      item_name: "",
      serial_no: "",
      dealer_cost: "",
      credit: "",
      total_credit: "",
    },
    {
      buyer_name: "",
      item_name: "",
      serial_no: "",
      dealer_cost: "",
      credit: "",
      total_credit: "",
    },
    {
      buyer_name: "",
      item_name: "",
      serial_no: "",
      dealer_cost: "",
      credit: "",
      total_credit: "",
    },
    {
      buyer_name: "",
      item_name: "",
      serial_no: "",
      dealer_cost: "",
      credit: "",
      total_credit: "",
    },
  ]);
  const [errors, setErrors] = useState({});
  const [file1, setFile1] = useState({});
  const [file2, setFile2] = useState({});
  const [file3, setFile3] = useState({});
  const [file4, setFile4] = useState({});
  const [queryData, setQueryData] = useState()
  const [userData, setUserData] = useState()

  // useEffect(() => {
  //   const formErrors = validateAllRows();
  //   setErrors(formErrors);
  // }, [validateAllRows]);
  var government_commerical_sales_credit_claim_id;
  function submitForm() { }

  const columns = [
    { name: "date_submitted", title: "Date Submitted" },
    {
      name: "form",
      title: "Form",
      getCellValue: (row) => "Government/Commerical Sales Credit Claim",
    },
    { name: "status", title: "status" },
  ];

  const LastColumnCell = ({ column, value }) => (
    <Table.Cell style={column.name === 'status' ? { color: StatusEnum[value.toLowerCase()]?.color, fontWeight: 'bold' } : {}}>
      {column.name === 'status' ? StatusEnum[value.toLowerCase()]?.label : value}
    </Table.Cell>
  );

  function addGovernmentCommericalSalesCreditClaimData(data) {
    // client.mutate({
    //   mutation: ADD_COMMERICAL_SALES_CREDIT_CLAIM_DATA,
    //   variables: {
    //     objects: [data],
    //   },
    // });
  }
  function governmentCommericalSalesCreditClaimData() {
    // const resp = salesData.map((data) => {
    //   data.government_commerical_sales_credit_claim_id =
    //     government_commerical_sales_credit_claim_id;
    //   addGovernmentCommericalSalesCreditClaimData(data);
    // });
  }

  const completedAdd = (payload) => {
    government_commerical_sales_credit_claim_id =
      payload.insert_government_commerical_sales_credit_claim.returning[0].id;
    toast("Government Commerical Sales Credit Claim created succesfully");
    governmentCommericalSalesCreditClaimData();
    resetState();
  };

  const toggleRowExpand = (rowId) => {
    setExpandedRowIds((prevState) =>
      prevState.includes(rowId)
        ? prevState.filter((id) => id !== rowId)
        : [...prevState, rowId]
    );
  };

  const TableRow = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => toggleRowExpand(tableRow.rowId)}
      style={{ cursor: "pointer" }}
    />
  );
  const handleChangeFile1 = (event) => {
    const inputFiles = event.target.files;
    if (inputFiles.length > 0) {
      const file = inputFiles[0];
      setFile1({ file1: file });
    }
  };
  const handleChangeFile2 = (event) => {
    const inputFiles = event.target.files;
    if (inputFiles.length > 0) {
      const file = inputFiles[0];
      setFile2({ file2: file });
    }
  };
  const handleChangeFile3 = (event) => {
    const inputFiles = event.target.files;
    if (inputFiles.length > 0) {
      const file = inputFiles[0];
      setFile3({ file3: file });
    }
  };
  const handleChangeFile4 = (event) => {
    const inputFiles = event.target.files;
    if (inputFiles.length > 0) {
      const file = inputFiles[0];
      setFile4({ file4: file });
    }
  };
  const handleDataChange = (evt, index) => {
    if (evt.target) {
      const e = [...salesData];

      e[index][evt.target.name] = evt.target.value;
      setSalesData(e);
    }
  };

  const uploadFile1 = async () => {
    const data = new FormData();
    data.append("image", file1?.file1, file1?.file1?.name);
    const file1Resp = await fileUploader(data);
    return file1Resp;
  };
  const uploadFile2 = async () => {
    const data = new FormData();
    data.append("image", file2?.file2, file2?.file2?.name);
    const file2Resp = await fileUploader(data);
    return file2Resp;
  };
  const uploadFile3 = async () => {
    const data = new FormData();
    data.append("image", file3?.file3, file3?.file3?.name);
    const file3Resp = await fileUploader(data);
    return file3Resp;
  };
  const uploadFile4 = async () => {
    const data = new FormData();
    data.append("image", file4?.file3, file4?.file4?.name);
    const file4Resp = await fileUploader(data);
    return file4Resp;
  };
  async function fileUploader(data) {
    const response = await axios.post(APP_SERVER + "/", data);
    if (response.data.status === 200) {
      return response.data.imageUrl;
    } else {
      return false;
    }
  }
  const validateRow = (row, rowIndex) => {
    let rowErrors = {};
    let isRowFilled = Object.values(row).some((value) => value);
    if (isRowFilled) {
      if (!row.buyer_name) rowErrors.buyer_name = "Buyer name is required";
      if (!row.item_name) rowErrors.item_name = "Item name is required";
      if (!row.serial_no) rowErrors.serial_no = "Serial no is required";
      if (!row.dealer_cost) rowErrors.dealer_cost = "Dealer cost is required";
      if (!row.credit) rowErrors.credit = "Credit is required";
      if (!row.total_credit)
        rowErrors.total_credit = "Total credit is required";
    }
    return rowErrors;
  };
  const validateAllRows = () => {
    let allErrors = {};
    salesData.forEach((row, index) => {
      let rowErrors = validateRow(row, index);
      if (Object.keys(rowErrors).length > 0) {
        allErrors[index] = rowErrors;
      }
    });
    return allErrors;
  };

  const getTotalCredit = (amount, percentage) => {
    const discountAmount = (percentage / 100) * amount;
    return discountAmount;
  };

  // const getGrandTotalCredit = () => {
  //   let sum = 0;
  //   for (let key in salesData) {
  //     sum += salesData[key].total_credit;
  //   }
  //   return sum;
  // };

  const RowDetail = ({ row }) => {
    if (queryData) {
      const rowData = queryData?.find(rows => rows?.id === row?.id);
      return (
        <div>
          <CommericalSalesCreditClaimRequestRowDetail isDisplay={true} comments={rowData?.co_op_request?.co_op_comments} userName={userData} isEdit={true} row={rowData} type="dealer" requirementDataArray={rowData?.government_commerical_sales_credit_claim_data} />
        </div>
      );
    }
    return (
      <div>
        Row details not available.
      </div>
    );
  };

  return (
    // <Query query={GET_USER_INFO} variables={{ id: auth.getSub() }} onCompleted={(data) => setUserData(data?.profiles_by_pk?.name)}>
    //   {({ data: userData, loading: userLoading, error }) => {
    //     return (
    <Mutation mutation={ADD_CO_OP_REQUEST_FORMS}>
      {(addCoOpRequestForms, { data, loading, called, error }) => {
        if (error)
          return (
            <pre>
              Bad:{" "}
              {error.graphQLErrors.map(({ message }, i) => (
                <span key={i}>{message}</span>
              ))}
            </pre>
          );
        return (
          <Mutation
            mutation={ADD_COMMERICAL_SALES_CREDIT_CLAIM}
            onCompleted={completedAdd}
          >
            {(addNewCommericalSalesCreditClaim, { data, loading, called, error }) => {
              if (error)
                return (
                  <pre>
                    Bad:{" "}
                    {error.graphQLErrors.map(({ message }, i) => (
                      <span key={i}>{message}</span>
                    ))}
                  </pre>
                );
              return (
                <DashboardLayout>
                  <form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      const formErrors = validateAllRows();
                      setErrors(formErrors);
                      if (Object.keys(formErrors).length === 0) {
                        values.profile_id = auth.getSub();
                        values.country = values.country[0];
                        const file1Resp = await uploadFile1();
                        const file2Resp = await uploadFile2();
                        const file3Resp = await uploadFile3();
                        const file4Resp = await uploadFile4();
                        if (file1Resp && file2Resp && file3Resp) {
                          values.file1 = file1Resp;
                          values.file2 = file2Resp;
                          values.file3 = file3Resp;
                          values.file4 = file4Resp;
                          const requestData = {
                            date_submitted: formattedUTCDate.format(),
                            profile_id: auth.getSub(),
                            form_type: "govt-com-sales-credit-claim",
                            status: "new"
                          }
                          return addCoOpRequestForms({
                            variables: {
                              objects: requestData
                            }
                          }).then((value) => {
                            const co_op_requests_id = value?.data?.insert_co_op_requests?.returning?.[0]?.id;

                            const finalValues = {
                              ...values,
                              co_op_requests_id
                            };
                            addNewCommericalSalesCreditClaim({
                              variables: {
                                objects: [finalValues],
                              },
                            });
                          })
                        }
                      }
                    }}
                    className={classes.container}
                    autoComplete="off"
                  >
                    <div className={classes.root + " sectionContainer"}>
                      <Typography gutterBottom variant="h4" className="title">
                        <span>GOVERNMENT/COMMERICAL SALES CREDIT CLAIM</span>
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="h5"
                        className={classes.subtitle3}
                        align="left"
                      >
                        FORMS WAITING FOR APPROVAL
                      </Typography>
                      {/* <Subscription subscription={getAllCommericalSalesCreditClaim} variables={{ profile_id: auth.getSub() }}>
                              {({ data, loading, error }) => {
                                console.log(error)
                                if (data && data !== queryData) {
                                  setQueryData(data?.government_commerical_sales_credit_claim);
                                }
                                if (loading || !queryData)
                                  return (
                                    <div
                                      className="flex center"
                                      style={{ padding: "2em 0" }}
                                    >
                                      <CircularProgress color="secondary" />
                                    </div>
                                  );
                                if (error)
                                  return <p>Error Loading Government/Commerical sales credit claim</p>;
                                return ( */}
                      <div className="custom-table-event">
                        <Grids
                          rows={data?.government_commerical_sales_credit_claim || []}
                          columns={columns}
                        >
                          <RowDetailState expandedRowIds={expandedRowIds} />
                          <Table
                            cellComponent={(props) => (
                              <LastColumnCell {...props} />
                            )}
                            rowComponent={TableRow}
                          />
                          <TableRowDetail
                            contentComponent={RowDetail}
                          />
                          <TableHeaderRow />
                        </Grids>
                      </div>
                      {/* );
                              }}
                            </Subscription> */}
                      <br></br>
                      <br></br>
                      <Typography
                        gutterBottom
                        variant="h5"
                        className={classes.subtitle3}
                        style={{
                          backgroundColor: "#BED4E5",
                          textAlign: "left",
                          padding: "1em",
                          color: "#23333F",
                          margin: "0px",
                        }}
                      >
                        GOVERNMENT/COMMERICAL SALES CREDIT CLAIM
                      </Typography>
                      <Paper
                        className={classes.paper}
                        style={{
                          padding: "2em",
                          backgroundColor: "#F9FCFF",
                          boxShadow: "unset",
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          align="center"
                          className={classes.subTitle2}
                          style={{ margin: "0px auto 15px", maxWidth: "80%" }}
                        >
                          <strong>CAROLINA SKIFF:</strong> 3231 Fulford Road Waycross
                          Georgia 31503 <strong>PHONE:</strong> 912-287-0547{" "}
                          <strong>FAX:</strong> 912-287-0533
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h6"
                          align="center"
                          style={{ margin: "0px auto", maxWidth: "80%" }}
                          className={classes.subTitle2}
                        >
                          All required paper work must be submitted with this form.
                          Submit all paper work to Carolina Skiff Advertising
                          Department.
                        </Typography>
                        <br></br>
                        <br></br>
                        <div>
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                required
                                name="dealer_name"
                                label="Dealer Name"
                                className={classes.textField}
                                style={{ width: "100%" }}
                                onChange={handleChange}
                                margin="normal"
                                value={values?.dealer_name || ""}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                required
                                name="contact_name"
                                label="Contact Name"
                                className={classes.textField}
                                style={{ width: "100%" }}
                                onChange={handleChange}
                                margin="normal"
                                value={values?.contact_name || ""}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                required
                                name="phone"
                                label="Phone"
                                className={classes.textField}
                                style={{ width: "100%" }}
                                onChange={handleChange}
                                margin="normal"
                                value={values?.phone || ""}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                required
                                name="fax"
                                label="Fax"
                                className={classes.textField}
                                style={{ width: "100%" }}
                                onChange={handleChange}
                                margin="normal"
                                value={values?.fax || ""}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                required
                                name="address"
                                label="Address"
                                className={classes.textField}
                                style={{ width: "100%" }}
                                onChange={handleChange}
                                margin="normal"
                                value={values?.address || ""}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                name="address2"
                                label="Address line 2 (Suites, Apt. # etc)"
                                className={classes.textField}
                                onChange={handleChange}
                                margin="normal"
                                variant="outlined"
                                value={values?.address2 || ""}
                                style={{ width: "100%" }}
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                required
                                name="city"
                                label="City"
                                className={classes.textField}
                                onChange={handleChange}
                                margin="normal"
                                variant="outlined"
                                value={values?.city || ""}
                                style={{ width: "100%" }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                required
                                name="country"
                                label="Country"
                                select
                                className={classes.textField}
                                onChange={handleChange}
                                value={values?.country || ""}
                                style={{ width: "100%" }}
                                variant="outlined"
                                SelectProps={{
                                  native: true,
                                  MenuProps: {
                                    className: classes.menu,
                                  },
                                }}
                                margin="normal"
                              >
                                <option key="" value="" />
                                <option key="United States" value="United States">United States</option>
                              </TextField>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                required
                                name="state"
                                label="State"
                                variant="outlined"
                                select
                                style={{ width: "100%" }}
                                className={classes.textField}
                                value={values?.state || ""}
                                onChange={handleChange}
                                SelectProps={{
                                  native: true,
                                  MenuProps: {
                                    className: classes.menu,
                                  },
                                }}
                                margin="normal"
                              >
                                <option key="" value="" />
                                <option key="Alabama" value="Alabama">
                                  Alabama
                                </option>
                                <option key="Alaska" value="Alaska">
                                  Alaska
                                </option>
                                <option key="Arizona" value="Arizona">
                                  Arizona
                                </option>
                                <option key="Arkansas" value="Arkansas">
                                  Arkansas
                                </option>
                                <option key="California" value="California">
                                  California
                                </option>
                                <option key="Colorado" value="Colorado">
                                  Colorado
                                </option>
                                <option key="Connecticut" value="Connecticut">
                                  Connecticut
                                </option>
                                <option key="Delaware" value="Delaware">
                                  Delaware
                                </option>
                                <option key="Florida" value="Florida">
                                  Florida
                                </option>
                                <option key="Georgia" value="Georgia">
                                  Georgia
                                </option>
                                <option key="Hawaii" value="Hawaii">
                                  Hawaii
                                </option>
                                <option key="Idaho" value="Idaho">
                                  Idaho
                                </option>
                                <option key="Illinois" value="Illinois">
                                  Illinois
                                </option>
                                <option key="Indiana" value="Indiana">
                                  Indiana
                                </option>
                                <option key="Iowa" value="Iowa">
                                  Iowa
                                </option>
                                <option key="Kansas" value="Kansas">
                                  Kansas
                                </option>
                                <option key="Kentucky" value="Kentucky">
                                  Kentucky
                                </option>
                                <option key="Louisiana" value="Louisiana">
                                  Louisiana
                                </option>
                                <option key="Maine" value="Maine">
                                  Maine
                                </option>
                                <option key="Maryland" value="Maryland">
                                  Maryland
                                </option>
                                <option
                                  key="Massachusetts"
                                  value="Massachusetts"
                                >
                                  Massachusetts
                                </option>
                                <option key="Michigan" value="Michigan">
                                  Michigan
                                </option>
                                <option key="Minnesota" value="Minnesota">
                                  Minnesota
                                </option>
                                <option key="Mississippi" value="Mississippi">
                                  Mississippi
                                </option>
                                <option key="Missouri" value="Missouri">
                                  Missouri
                                </option>
                                <option key="Montana" value="Montana">
                                  Montana
                                </option>
                                <option key="Nebraska" value="Nebraska">
                                  Nebraska
                                </option>
                                <option key="Nevada" value="Nevada">
                                  Nevada
                                </option>
                                <option
                                  key="New Hampshire"
                                  value="New Hampshire"
                                >
                                  New Hampshire
                                </option>
                                <option key="New Jersey" value="New Jersey">
                                  New Jersey
                                </option>
                                <option key="New Mexico" value="New Mexico">
                                  New Mexico
                                </option>
                                <option key="New York" value="New York">
                                  New York
                                </option>
                                <option
                                  key="North Carolina"
                                  value="North Carolina"
                                >
                                  North Carolina
                                </option>
                                <option
                                  key="North Dakota"
                                  value="North Dakota"
                                >
                                  North Dakota
                                </option>
                                <option key="Ohio" value="Ohio">
                                  Ohio
                                </option>
                                <option key="Oklahoma" value="Oklahoma">
                                  Oklahoma
                                </option>
                                <option key="Oregon" value="Oregon">
                                  Oregon
                                </option>
                                <option
                                  key="Pennsylvania"
                                  value="Pennsylvania"
                                >
                                  Pennsylvania
                                </option>
                                <option
                                  key="Rhode Island"
                                  value="Rhode Island"
                                >
                                  Rhode Island
                                </option>
                                <option
                                  key="South Carolina"
                                  value="South Carolina"
                                >
                                  South Carolina
                                </option>
                                <option
                                  key="South Dakota"
                                  value="South Dakota"
                                >
                                  South Dakota
                                </option>
                                <option key="Tennessee" value="Tennessee">
                                  Tennessee
                                </option>
                                <option key="Texas" value="Texas">
                                  Texas
                                </option>
                                <option key="Utah" value="Utah">
                                  Utah
                                </option>
                                <option key="Vermont" value="Vermont">
                                  Vermont
                                </option>
                                <option key="Virginia" value="Virginia">
                                  Virginia
                                </option>
                                <option key="Washington" value="Washington">
                                  Washington
                                </option>
                                <option
                                  key="West Virginia"
                                  value="West Virginia"
                                >
                                  West Virginia
                                </option>
                                <option key="Wisconsin" value="Wisconsin">
                                  Wisconsin
                                </option>
                                <option key="Wyoming" value="Wyoming">
                                  Wyoming
                                </option>
                              </TextField>
                            </Grid>
                          </Grid>
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                name="zipcode"
                                label="Zip / Postal Code"
                                className={classes.textField}
                                onChange={handleChange}
                                margin="normal"
                                variant="outlined"
                                style={{ width: "100%" }}
                                value={values?.zipcode || ""}
                              />
                            </Grid>
                            {/* <Grid item xs={12} sm={4}>
                              <Typography
                                gutterBottom
                                variant="h6"
                                align="left"
                                className={classes.subTitle2}
                                style={{
                                  width: "100%",
                                  textAlign: "left",
                                  marginTop: "25px",
                                }}
                              >
                                DATE: 3/25/2020{" "}
                              </Typography>
                            </Grid> */}
                          </Grid>
                        </div>
                        <br></br>
                        <br></br>

                        <div>
                          <Typography
                            gutterBottom
                            variant="h6"
                            align="center"
                            style={{ maxWidth: "80%", margin: "0px auto 15px" }}
                            className={classes.subTitle2}
                          >
                            <b>GOVERNMENT/COMMERICAL SALES CREDIT CLAIM</b>
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="h6"
                            align="center"
                            style={{ maxWidth: "80%", margin: "0px auto 10px" }}
                            className={classes.subTitle2}
                          >
                            Carolina Skiff LLC will credit 5% of the Dealer boat cost
                            for any Carolina Skiff product sold to a licensed
                            commercial agency such as, Utility Commissions, Government
                            Agency, Local State Agency, Boat Rental, Comercial Guide
                            Fisherman or any other type of commercial business
                            approved by Carolina Skiff LLC.
                          </Typography>
                          <br></br>
                          <br></br>
                          {/* cliam form start */}
                          <Grid container spacing={2} justify="space-around">
                            <Grid item xs={12} md={3}>
                              <FormLabel
                                variant="h5"
                                align="center"
                                className={classes.subTitle2}
                                style={{
                                  width: "100%",
                                  display: "block",
                                  marginBottom: "10px",
                                }}
                              >
                                <b>BUYER NAME</b>
                              </FormLabel>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <FormLabel
                                variant="h5"
                                align="center"
                                className={classes.subTitle2}
                                style={{
                                  width: "100%",
                                  display: "block",
                                  marginBottom: "10px",
                                }}
                              >
                                <b>ITEM/PRODUCT</b>
                              </FormLabel>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <FormLabel
                                variant="h5"
                                align="center"
                                className={classes.subTitle2}
                                style={{
                                  width: "100%",
                                  display: "block",
                                  marginBottom: "10px",
                                }}
                              >
                                <b>SERIAL NO.</b>
                              </FormLabel>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <FormLabel
                                variant="h5"
                                align="center"
                                className={classes.subTitle2}
                                style={{
                                  width: "100%",
                                  display: "block",
                                  marginBottom: "10px",
                                }}
                              >
                                <b>DEALER COST</b>
                              </FormLabel>
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <FormLabel
                                variant="h5"
                                align="center"
                                className={classes.subTitle2}
                                style={{
                                  width: "100%",
                                  display: "block",
                                  marginBottom: "10px",
                                }}
                              >
                                <b>% CREDIT</b>
                              </FormLabel>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <FormLabel
                                variant="h5"
                                align="center"
                                className={classes.subTitle2}
                                style={{
                                  width: "100%",
                                  display: "block",
                                  marginBottom: "10px",
                                }}
                              >
                                <b>TOTAL CREDIT</b>
                              </FormLabel>
                            </Grid>
                          </Grid>
                          {salesData.map((data, index) => (
                            <Grid
                              container
                              spacing={2}
                              justify="space-around"
                              key={index}
                            >
                              <Grid item xs={12} md={3}>
                                <TextField
                                  required={!!errors[index]?.buyer_name}
                                  error={!!errors[index]?.buyer_name}
                                  className={classes.textField}
                                  id="buyer_name"
                                  name="buyer_name"
                                  variant="outlined"
                                  value={data.buyer_name || ""}
                                  onChange={(e) => handleDataChange(e, index)}
                                  style={{ width: "100%" }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <TextField
                                  required={!!errors[index]?.item_name}
                                  error={!!errors[index]?.item_name}
                                  id="item_name"
                                  style={{ width: "100%" }}
                                  name="item_name"
                                  onChange={(e) => handleDataChange(e, index)}
                                  value={data.item_name || ""}
                                  variant="outlined"
                                  className={classes.textField}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <OutlinedInput
                                  id="serial_no"
                                  required={!!errors[index]?.serial_no}
                                  error={!!errors[index]?.serial_no}
                                  style={{ width: "100%" }}
                                  name="serial_no"
                                  onChange={(e) => handleDataChange(e, index)}
                                  value={data.serial_no || ""}
                                  className={classes.textField}
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <OutlinedInput
                                  required={!!errors[index]?.dealer_cost}
                                  error={!!errors[index]?.dealer_cost}
                                  id="dealer_cost"
                                  style={{ width: "100%", textAlign: "center" }}
                                  aria-describedby="outlined-weight-helper-text"
                                  name="dealer_cost"
                                  onChange={(e) => handleDataChange(e, index)}
                                  value={data.dealer_cost || ""}
                                  align="center"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  type="number"
                                  endAdornment={
                                    <InputAdornment position="end"> </InputAdornment>
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={1}>
                                <OutlinedInput
                                  required={!!errors[index]?.credit}
                                  error={!!errors[index]?.credit}
                                  id="credit"
                                  style={{ width: "100%", textAlign: "center" }}
                                  aria-describedby="outlined-weight-helper-text"
                                  name="credit"
                                  onChange={(e) => handleDataChange(e, index)}
                                  value={data.credit || ""}
                                  align="center"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  type="number"
                                  endAdornment={
                                    <InputAdornment position="end"> </InputAdornment>
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <OutlinedInput
                                  required={!!errors[index]?.total_credit}
                                  error={!!errors[index]?.total_credit}
                                  id="total_credit"
                                  style={{ width: "100%" }}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  }
                                  name="total_credit"
                                  disabled
                                  value={getTotalCredit(
                                    data?.dealer_cost,
                                    data?.credit
                                  )}
                                  onChange={(e) => handleDataChange(e, index)}
                                  className={classes.textField}
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ))}
                          <br></br>
                          <Grid container justify="flex-end" spacing={4}>
                            <Grid item xs={12} sm={4}>
                              <FormControl
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                                variant="outlined"
                              >
                                {/* <b variant="h5" align="center" className={classes.subTitle2}  style={{width:"100%",marginBottom:"10px"}}>TOTAL COST OF PROJECT</b> */}
                                <FormLabel className={classes.subTitle2}>
                                  <b>Total Credit $</b>{" "}
                                </FormLabel>
                                <OutlinedInput
                                  id="grand_total_credit"
                                  endAdornment={
                                    <InputAdornment position="end"> </InputAdornment>
                                  }
                                  style={{
                                    width: "80%",
                                    maxWidth: "calc(100% - 100px)",
                                    marginLeft: "6px",
                                  }}
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    "aria-label": "weight",
                                  }}
                                  name="grand_total_credit"
                                // value={getGrandTotalCredit()}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          {/* cliam form end */}
                          <br></br>
                          <br></br>
                          <Grid container spacing={4} alignItems="center">
                            <Grid item xs={12} sm={6} md={7}>
                              <FormControl
                                sx={{ m: 1, width: "25ch" }}
                                variant="outlined"
                              >
                                {/* <FormLabel variant="h5" align="left" className={classes.subTitle2} style={{width:"100%"}}>1. Copy of paid invoice.</FormLabel>                                                                                 */}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5}>
                              <input
                                accept="image/*,application/pdf"
                                className={classes.input}
                                id="contained-button-file1"
                                type="file"
                                style={{ display: "none" }}
                                name="file1"
                                onChange={(e) => handleChangeFile1(e)}
                              />
                              <FormLabel htmlFor="contained-button-file1">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                >
                                  CHOOSE FILE
                                </Button>
                              </FormLabel>
                              <FormLabel
                                htmlFor="contained-button-file"
                                className={classes.body2}
                                style={{ marginLeft: "8px", cursor: "pointer" }}
                              >
                                {file1?.file1?.name
                                  ? file1?.file1?.name
                                  : "NO FILES UPLOADED"}
                              </FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={6} md={7}>
                              <FormControl
                                sx={{ m: 1, width: "25ch" }}
                                variant="outlined"
                              ></FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5}>
                              <input
                                accept="image/*,application/pdf"
                                className={classes.input}
                                id="contained-button-file2"
                                type="file"
                                name="file2"
                                style={{ display: "none" }}
                                onChange={(e) => handleChangeFile2(e)}
                              />
                              <FormLabel htmlFor="contained-button-file2">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                >
                                  CHOOSE FILE
                                </Button>
                              </FormLabel>
                              <FormLabel
                                htmlFor="contained-button-file"
                                className={classes.body2}
                                style={{ marginLeft: "8px", cursor: "pointer" }}
                              >
                                {file2?.file2?.name
                                  ? file2?.file2?.name
                                  : "NO FILES UPLOADED"}
                              </FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={6} md={7}>
                              <FormControl
                                sx={{ m: 1, width: "25ch" }}
                                variant="outlined"
                              ></FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5}>
                              <input
                                accept="image/*,application/pdf"
                                className={classes.input}
                                id="contained-button-file3"
                                type="file"
                                name="file3"
                                style={{ display: "none" }}
                                onChange={(e) => handleChangeFile3(e)}
                              />
                              <FormLabel htmlFor="contained-button-file3">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                >
                                  CHOOSE FILE
                                </Button>
                              </FormLabel>
                              <FormLabel
                                htmlFor="contained-button-file"
                                className={classes.body2}
                                style={{ marginLeft: "8px", cursor: "pointer" }}
                              >
                                {file3?.file3?.name
                                  ? file3?.file3?.name
                                  : "NO FILES UPLOADED"}
                              </FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5}>
                              <input
                                accept="image/*,application/pdf"
                                className={classes.input}
                                id="contained-button-file4"
                                type="file"
                                name="file4"
                                style={{ display: "none" }}
                                onChange={(e) => handleChangeFile4(e)}
                              />
                              <FormLabel htmlFor="contained-button-file4">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                >
                                  CHOOSE FILE
                                </Button>
                              </FormLabel>
                              <FormLabel
                                htmlFor="contained-button-file"
                                className={classes.body2}
                                style={{ marginLeft: "8px", cursor: "pointer" }}
                              >
                                {file4?.file4?.name
                                  ? file4?.file4?.name
                                  : "NO FILES UPLOADED"}
                              </FormLabel>
                            </Grid>
                          </Grid>
                        </div>
                        <br></br>

                        <div>
                          <Grid container>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                name="additional_info"
                                label="Additional information. (OPTIONAL)"
                                multiline
                                rows="4"
                                variant="outlined"
                                className={classes.textField}
                                onChange={handleChange}
                                margin="normal"
                                value={values?.additional_info || ""}
                                style={{ width: "100%" }}
                              />
                            </Grid>
                          </Grid>
                        </div>

                        <Grid item xs={12} align="center">
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            type="submit"
                            style={{ width: "15em", margin: "2em 1em" }}
                          >
                            SUBMIT
                          </Button>
                        </Grid>
                      </Paper>
                    </div>
                  </form>
                </DashboardLayout>
              );
            }}
          </Mutation>
        );
      }}
    </Mutation>
    //     )
    //   }}
    // </Query>
  );
}

export default withStyles(styles)(CommericalSalesCreditClaim);
