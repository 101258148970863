import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
// Component styles
import styles from "theme/skin";
import AddCareers from "./Careers/CareersAdd";
import CareersGrid from "./Careers/CareersGrid";

function Careers(props) {
  const { classes } = props;
  return (
    <DashboardLayout title="Careers">
      <div className={classes.root + " sectionContainer"}>
        <Typography gutterBottom variant="h4" className="title">
          <span>Careers</span>
        </Typography>

        <Paper className={classes.paper} style={{ margin: "2em 0px" }}>
          <Grid container>
            <Grid item xs align="center">
              <AddCareers />
            </Grid>
          </Grid>
        </Paper>

        <Typography gutterBottom variant="h4" className="title">
          <span>Current Job Listings</span>
        </Typography>
        <Paper className={classes.paper} style={{ margin: "2em 0px" }}>
          <Grid container>
            <Grid item xs align="center">
              <CareersGrid />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </DashboardLayout>
  );
}

Careers.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Careers);
