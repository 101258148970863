import React from "react";
import ReactDOM from "react-dom";
import auth from 'Auth';
//import { ApolloProvider } from "react-apollo";
//import apollo from 'apollo';
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "tachyons";
import "./index.scss";
// Material helpers
import { ThemeProvider } from "@material-ui/styles";
// Theme
import theme from "./theme";

import registerServiceWorker from "./registerServiceWorker";

const NODE_ENV = 'REACT_APP_NODE_ENV' in process.env? process.env.REACT_APP_NODE_ENV: process.env.NODE_ENV;

console.log(`ENVIRONMENT: ${NODE_ENV}`);
console.log(`REACT_APP_PUBLIC_URL: ${process.env.REACT_APP_PUBLIC_URL}`);
console.log(`REACT_APP_MEDIA_URL: ${process.env.REACT_APP_MEDIA_URL}`);

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      {/* <ApolloProvider client={client}> */}
      <App authData={auth} />
      {/* </ApolloProvider> */}
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

registerServiceWorker();
