import React, { useRef, useState, useEffect } from "react";
import { Mutation, Query, Subscription } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import {
  Paper,
  Typography,
  TextField,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormLabel,
  CircularProgress,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {
  PagingState,
  IntegratedPaging,
  RowDetailState,
} from "@devexpress/dx-react-grid";
import {
  Table,
  TableHeaderRow,
  Grid as Grids,
  PagingPanel,
  TableRowDetail,
} from "@devexpress/dx-react-grid-material-ui";
import { Dashboard as DashboardLayout } from "layouts";
import styles from "theme/skin";
import auth from "Auth";
import {
  ADD_ADVERTISING_CLAIM_FORMS,
  ADD_ADVERTISING_CLAIM_REQUIREMENTS_FORMS,
  ADD_CO_OP_REQUEST_FORMS,
  DELETE_ADVERTISING_CLAIM_FORMS,
  GET_ALL_ADVERTISNG_CLAIM_FORMS,
  UPDATE_ADVERTISING_CLAIM_FORM,
  UPDATE_ADVERTISING_CLAIM_REQUIREMENTS_DATA_FORM,
} from "../queries";
import useForm from "components/useForm";
import axios from "axios";
import apollo from "apollo";
import { StatusEnum } from "pages/FormsDatasheets/enum";
import AdvertisingClaimFormDetail from "./AdvertisingClaimFormDetail";
import moment from "moment";
import { GET_USER_INFO } from "queries";

function AdvertisingClaimForm(props) {
  const formRef = useRef(null);
  const { classes } = props;
  const APP_SERVER = apollo.APP_UPLOAD_SERVER_URL;
  const initialFormsData = new Array(11).fill(null).map(() => ({
    advertising_claim_form_id: "",
    issue_date: "",
    advertising_description: "",
    gross_cost: 0,
    approved: 0,
    cost_of_approved: 0,
    program_credit: 50,
    co_op_credit: 0,
  }));
  const { values, handleChange, resetState, setValues } = useForm(submitForm);

  const [expandedRowIds, setExpandedRowIds] = useState([0]);
  const [files, setFiles] = useState({});
  const [formsData, setFormsData] = useState(initialFormsData);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [queryData, setQueryData] = useState();
  const [edit, setEdit] = useState();
  const [userData, setUserData] = useState();

  const formattedUTCDate = moment.utc();

  function submitForm() {}

  const columns = [
    { name: "date_submitted", title: "Date Submitted" },
    { name: "form", title: "Form", getCellValue: (row) => "Co-Op Advertising" },
    { name: "submitted_by", title: "Submitted By" },
    { name: "status", title: "Status" },
  ];

  useEffect(() => {
    const allErrors = validateAllRows();
    setErrors(allErrors);
  }, [formsData]);

  useEffect(() => {
    if (edit) {
      setValues((prevState) => ({
        ...prevState,
        dealer_name: edit?.data?.dealer_name,
        additional_info: edit?.data?.additional_info,
        address: edit?.data?.address,
        city: edit?.data?.city,
        contact_name: edit?.data?.contact_name,
        country: edit?.data?.country,
        fax: edit?.data?.fax,
        id: edit?.data?.id,
        phone: edit?.data?.phone,
        state: edit?.data?.state,
        zipcode: edit?.data?.zipcode,
      }));
      setFiles((prevState) => ({
        ...prevState,
        paid_invoice_url: edit?.data?.paid_invoice_url,
        affidavit_url: edit?.data?.affidavit_url,
        claim_form_url: edit?.data?.claim_form_url,
        photo_of_sign_url: edit?.data?.photo_of_sign_url,
        tear_sheet_url: edit?.data?.tear_sheet_url,
      }));
      setFormsData((prevState) => {
        const updateCount = Math.min(
          edit?.data?.advertising_claim_form_data_requirements?.length,
          prevState?.length
        );

        const updatedFormsData = [...prevState];

        for (let i = 0; i < updateCount; i++) {
          updatedFormsData[i] = {
            ...updatedFormsData[i],
            advertising_claim_form_id:
              edit?.data?.advertising_claim_form_data_requirements?.[i]
                ?.advertising_claim_form_id,
            advertising_description:
              edit?.data?.advertising_claim_form_data_requirements?.[i]
                ?.advertising_description,
            gross_cost:
              edit?.data?.advertising_claim_form_data_requirements?.[i]
                ?.gross_cost,
            approved:
              edit?.data?.advertising_claim_form_data_requirements?.[i]
                ?.approved,
            cost_of_approved:
              edit?.data?.advertising_claim_form_data_requirements?.[i]
                ?.cost_of_approved,
            program_credit:
              edit?.data?.advertising_claim_form_data_requirements?.[i]
                ?.program_credit,
            co_op_credit:
              edit?.data?.advertising_claim_form_data_requirements?.[i]
                ?.co_op_credit,
            issue_date:
              edit?.data?.advertising_claim_form_data_requirements?.[i]
                ?.issue_date,
            id: edit?.data?.advertising_claim_form_data_requirements?.[i]?.id,
          };
        }
        return updatedFormsData;
      });
    }
  }, [edit]);

  const LastColumnCell = ({ column, value }) => (
    <Table.Cell
      style={
        column.name === "status"
          ? {
              color: StatusEnum[value.toLowerCase()]?.color,
              fontWeight: "bold",
            }
          : {}
      }
    >
      {column.name === "status"
        ? StatusEnum[value.toLowerCase()]?.label
        : value}
    </Table.Cell>
  );

  const completedAdd = () => {
    if (edit) {
      toast("Advertising claim updated Successful");
    } else {
      toast("Advertising claim created Successful");
    }
    setFiles({});
    resetState();
  };

  const toggleRowExpand = (rowId) => {
    setExpandedRowIds((prevState) =>
      prevState.includes(rowId)
        ? prevState.filter((id) => id !== rowId)
        : [...prevState, rowId]
    );
  };

  const TableRow = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => toggleRowExpand(tableRow.rowId)}
      style={{ cursor: "pointer" }}
    />
  );

  const handleFileChange = (event, param) => {
    const inputFiles = event.target.files;
    if (inputFiles.length > 0) {
      const file = inputFiles[0];
      setFiles((prevState) => ({
        ...prevState,
        [param]: file,
      }));
    }
  };

  const handleFieldChange = (formIndex, fieldName, value) => {
    const newFormsData = formsData.map((form, index) => {
      if (index === formIndex) {
        const updatedForm = { ...form, [fieldName]: value };

        if (fieldName === "gross_cost" || fieldName === "approved") {
          const grossCost = parseFloat(updatedForm.gross_cost) || 0;
          const approvedPercentage = parseFloat(updatedForm.approved) || 0;
          const costOfApproved = (grossCost * approvedPercentage) / 100;
          updatedForm.cost_of_approved = costOfApproved.toString();
          updatedForm.program_credit = "50";
          updatedForm.co_op_credit = (
            (updatedForm?.cost_of_approved * 50) /
            100
          ).toString();
        }
        return updatedForm;
      }
      return form;
    });
    setFormsData(newFormsData);
  };

  const gridRows = (data) => {
    return data
      ? data.map((item) => ({
          id: item?.id,
          date_submitted: new Date(item?.date_submitted).toLocaleDateString(),
          form: "Co-Op Advertising",
          submitted_by: item?.profile?.email,
          status: item?.status,
        }))
      : [];
  };

  const validateRow = (row, rowIndex) => {
    let filteredObj = {
      issue_date: row?.issue_date,
      advertising_description: row?.advertising_description,
      gross_cost: row?.gross_cost,
      approved: row?.approved,
      // "program_credit": row?.program_credit,
      co_op_credit: row?.co_op_credit,
    };

    let rowErrors = {};
    const isRowFilled = Object.values(filteredObj).some(
      (value) => value !== null && value !== "" && value !== 0
    );
    if (isRowFilled) {
      if (!row.issue_date) rowErrors.issue_date = "Issue date is required";
      if (!row.advertising_description)
        rowErrors.advertising_description = "Description is required";
      if (!row.gross_cost) rowErrors.gross_cost = "Gross cost is required";
      if (!row.approved) rowErrors.approved = "Approved is required";
      // if (!row.program_credit) rowErrors.program_credit = 'Program credit is required';
      if (!row.co_op_credit)
        rowErrors.co_op_credit = "Co Op credit is required";
    }

    return rowErrors;
  };

  const validateAllRows = () => {
    let allErrors = {};
    formsData &&
      formsData.forEach((row, index) => {
        let rowErrors = validateRow(row, index);
        if (Object.keys(rowErrors).length > 0) {
          allErrors[index] = rowErrors;
        } else {
          delete allErrors[index];
        }
      });
    return allErrors;
  };

  const RowDetail = ({ row }) => {
    if (queryData) {
      const rowData = queryData?.find((rows) => rows?.id === row?.id);
      return (
        <div>
          <AdvertisingClaimFormDetail
            isDisplay={true}
            comments={rowData?.co_op_request?.co_op_comments}
            userName={userData}
            isEdit={true}
            row={rowData}
            type="dealer"
            setEdit={setEdit}
            requirementDataArray={
              rowData?.advertising_claim_form_data_requirements
            }
          />
        </div>
      );
    }
    return <div>Row details not available.</div>;
  };

  return (
    <Query
      query={GET_USER_INFO}
      variables={{ id: auth.getSub() }}
      onCompleted={(data) => setUserData(data?.profiles_by_pk?.name)}
    >
      {({ data: userData, loading: userLoading, error }) => {
        return (
          <Mutation mutation={DELETE_ADVERTISING_CLAIM_FORMS}>
            {(
              deleteAdvertisingClaimRequirementForm,
              { data, loading, called, error }
            ) => {
              if (error)
                return (
                  <pre>
                    Bad:{" "}
                    {error.graphQLErrors.map(({ message }, i) => (
                      <span key={i}>{message}</span>
                    ))}
                  </pre>
                );
              return (
                <Mutation mutation={ADD_CO_OP_REQUEST_FORMS}>
                  {(addCoOpRequestForms, { data, loading, called, error }) => {
                    if (error)
                      return (
                        <pre>
                          Bad:{" "}
                          {error.graphQLErrors.map(({ message }, i) => (
                            <span key={i}>{message}</span>
                          ))}
                        </pre>
                      );
                    return (
                      <Mutation
                        mutation={
                          !edit
                            ? ADD_ADVERTISING_CLAIM_FORMS
                            : UPDATE_ADVERTISING_CLAIM_FORM
                        }
                      >
                        {(
                          addAdvertisingClaimForm,
                          { data, loading, called, error }
                        ) => {
                          if (error)
                            return (
                              <pre>
                                Bad:{" "}
                                {error.graphQLErrors.map(({ message }, i) => (
                                  <span key={i}>{message}</span>
                                ))}
                              </pre>
                            );
                          return (
                            <Mutation
                              mutation={
                                ADD_ADVERTISING_CLAIM_REQUIREMENTS_FORMS
                              }
                            >
                              {(
                                addAdvertisingClaimRequirementForm,
                                { data, loading, called, error }
                              ) => {
                                if (error)
                                  return (
                                    <pre>
                                      Bad:{" "}
                                      {error.graphQLErrors.map(
                                        ({ message }, i) => (
                                          <span key={i}>{message}</span>
                                        )
                                      )}
                                    </pre>
                                  );
                                return (
                                  <Mutation
                                    mutation={
                                      UPDATE_ADVERTISING_CLAIM_REQUIREMENTS_DATA_FORM
                                    }
                                  >
                                    {(
                                      updateAdvertisingClaimRequirementForm,
                                      { data, loading, called, error }
                                    ) => {
                                      if (error)
                                        return (
                                          <pre>
                                            Bad:{" "}
                                            {error.graphQLErrors.map(
                                              ({ message }, i) => (
                                                <span key={i}>{message}</span>
                                              )
                                            )}
                                          </pre>
                                        );
                                      return (
                                        <DashboardLayout>
                                          <form
                                            ref={formRef}
                                            onSubmit={(e) => {
                                              e.preventDefault();
                                              const formErrors =
                                                validateAllRows();
                                              setErrors(formErrors);
                                              if (edit) {
                                                if (
                                                  Object.keys(formErrors)
                                                    .length === 0
                                                ) {
                                                  setIsSubmit(true);
                                                  values.country =
                                                    values?.country;
                                                  values.updated_at =
                                                    formattedUTCDate.format();
                                                  const uploadPromises =
                                                    Object.keys(files)?.map(
                                                      (key) => {
                                                        const file = files[key];
                                                        if (
                                                          file &&
                                                          file?.name
                                                        ) {
                                                          const data =
                                                            new FormData();
                                                          data.append(
                                                            "image",
                                                            file,
                                                            file?.name
                                                          );
                                                          return axios
                                                            .post(
                                                              APP_SERVER + "/",
                                                              data
                                                            )
                                                            .then(
                                                              (response) => {
                                                                if (
                                                                  response.data
                                                                    .status ===
                                                                  200
                                                                ) {
                                                                  return {
                                                                    [key]:
                                                                      response
                                                                        ?.data
                                                                        ?.imageUrl,
                                                                  };
                                                                } else {
                                                                  toast.error(
                                                                    "Media upload failed"
                                                                  );
                                                                  throw new Error(
                                                                    `Upload failed for ${key}`
                                                                  );
                                                                }
                                                              }
                                                            );
                                                        } else {
                                                          return Promise.resolve();
                                                        }
                                                      }
                                                    );

                                                  Promise.all(uploadPromises)
                                                    .then((uploadResults) => {
                                                      const allUploadUrls =
                                                        Object.assign(
                                                          files,
                                                          ...uploadResults
                                                        );
                                                      const finalValues = {
                                                        ...values,
                                                        ...{
                                                          id: edit?.data?.id,
                                                        },
                                                        ...allUploadUrls,
                                                      };
                                                      return addAdvertisingClaimForm(
                                                        {
                                                          variables:
                                                            finalValues,
                                                        }
                                                      ).then((value) => {
                                                        const updatedFormsData =
                                                          formsData
                                                            ?.filter(
                                                              (form) =>
                                                                form?.issue_date !==
                                                                  "" && form?.id
                                                            )
                                                            ?.map((form) => ({
                                                              ...form,
                                                            }));

                                                        const bulkUpdateFormsData =
                                                          async (
                                                            updatedFormsData
                                                          ) => {
                                                            const updatePromises =
                                                              updatedFormsData?.map(
                                                                (form) => {
                                                                  const variables =
                                                                    {
                                                                      id: form?.id,
                                                                      advertising_description:
                                                                        form?.advertising_description,
                                                                      approved:
                                                                        form?.approved,
                                                                      co_op_credit:
                                                                        form?.co_op_credit,
                                                                      cost_of_approved:
                                                                        form?.cost_of_approved,
                                                                      gross_cost:
                                                                        form?.gross_cost,
                                                                      issue_date:
                                                                        form?.issue_date,
                                                                      program_credit:
                                                                        "50",
                                                                      updated_at:
                                                                        new Date().toISOString(),
                                                                    };

                                                                  return updateAdvertisingClaimRequirementForm(
                                                                    {
                                                                      variables,
                                                                    }
                                                                  );
                                                                }
                                                              );

                                                            try {
                                                              await Promise.all(
                                                                updatePromises
                                                              );
                                                            } catch (error) {
                                                              console.error(
                                                                "Failed to update items",
                                                                error
                                                              );
                                                            }
                                                          };

                                                        const handleFormOperations =
                                                          async (formsData) => {
                                                            try {
                                                              const createFormsData =
                                                                formsData
                                                                  ?.filter(
                                                                    (form) =>
                                                                      form.issue_date !==
                                                                        "" &&
                                                                      !form?.id
                                                                  )
                                                                  ?.map(
                                                                    (form) => ({
                                                                      ...form,
                                                                      advertising_claim_form_id:
                                                                        edit
                                                                          ?.data
                                                                          ?.id,
                                                                    })
                                                                  );

                                                              const deleteFormsData =
                                                                formsData?.filter(
                                                                  (item) =>
                                                                    item?.id &&
                                                                    item?.issue_date ===
                                                                      ""
                                                                );

                                                              if (
                                                                createFormsData?.length >
                                                                0
                                                              ) {
                                                                await addAdvertisingClaimRequirementForm(
                                                                  {
                                                                    variables: {
                                                                      objects:
                                                                        createFormsData,
                                                                    },
                                                                  }
                                                                );
                                                              }

                                                              if (
                                                                updatedFormsData?.length >
                                                                0
                                                              ) {
                                                                await bulkUpdateFormsData(
                                                                  updatedFormsData
                                                                );
                                                              }

                                                              if (
                                                                deleteFormsData?.length >
                                                                0
                                                              ) {
                                                                await deleteAdvertisingClaimRequirementForm(
                                                                  {
                                                                    variables: {
                                                                      ids: deleteFormsData?.map(
                                                                        (
                                                                          item
                                                                        ) =>
                                                                          item?.id
                                                                      ),
                                                                    },
                                                                  }
                                                                );
                                                              }
                                                            } catch (error) {
                                                              console.error(
                                                                "An error occurred during form operations:",
                                                                error
                                                              );
                                                            } finally {
                                                              setFormsData(
                                                                initialFormsData
                                                              );
                                                              setIsSubmit(
                                                                false
                                                              );
                                                              setEdit(
                                                                undefined
                                                              );
                                                              resetState();
                                                              setFiles({});
                                                              completedAdd();
                                                            }
                                                          };
                                                        handleFormOperations(
                                                          formsData
                                                        );
                                                      });
                                                    })
                                                    .catch((error) => {
                                                      console.error(error);
                                                    });
                                                  return false;
                                                } else {
                                                  formRef.current.reportValidity();
                                                }
                                              } else {
                                                if (
                                                  Object.keys(formErrors)
                                                    .length === 0
                                                ) {
                                                  setIsSubmit(true);
                                                  values.profile_id =
                                                    auth.getSub();
                                                  values.country =
                                                    values?.country;
                                                  const uploadPromises =
                                                    Object.keys(files)?.map(
                                                      (key) => {
                                                        const file = files[key];
                                                        if (file) {
                                                          const data =
                                                            new FormData();
                                                          data.append(
                                                            "image",
                                                            file,
                                                            file.name
                                                          );
                                                          return axios
                                                            .post(
                                                              APP_SERVER + "/",
                                                              data
                                                            )
                                                            .then(
                                                              (response) => {
                                                                if (
                                                                  response.data
                                                                    .status ===
                                                                  200
                                                                ) {
                                                                  return {
                                                                    [key]:
                                                                      response
                                                                        .data
                                                                        .imageUrl,
                                                                  };
                                                                } else {
                                                                  toast.error(
                                                                    "Media upload failed"
                                                                  );
                                                                  throw new Error(
                                                                    `Upload failed for ${key}`
                                                                  );
                                                                }
                                                              }
                                                            );
                                                        } else {
                                                          return Promise.resolve();
                                                        }
                                                      }
                                                    );

                                                  Promise.all(uploadPromises)
                                                    .then((uploadResults) => {
                                                      const allUploadUrls =
                                                        Object.assign(
                                                          {},
                                                          ...uploadResults
                                                        );

                                                      const requestData = {
                                                        date_submitted:
                                                          formattedUTCDate.format(),
                                                        profile_id:
                                                          auth.getSub(),
                                                        form_type:
                                                          "co-op-advertising-claim",
                                                        status: "new",
                                                      };
                                                      return addCoOpRequestForms(
                                                        {
                                                          variables: {
                                                            objects:
                                                              requestData,
                                                          },
                                                        }
                                                      ).then((value) => {
                                                        const co_op_requests_id =
                                                          value?.data
                                                            ?.insert_co_op_requests
                                                            ?.returning?.[0]
                                                            ?.id;
                                                        const finalValues = {
                                                          ...values,
                                                          ...allUploadUrls,
                                                          co_op_requests_id,
                                                        };
                                                        addAdvertisingClaimForm(
                                                          {
                                                            variables: {
                                                              objects: [
                                                                finalValues,
                                                              ],
                                                            },
                                                          }
                                                        ).then((value) => {
                                                          const advertisingClaimFormId =
                                                            value?.data
                                                              ?.insert_advertising_claim_form
                                                              ?.returning?.[0]
                                                              ?.id;
                                                          const updatedFormsData =
                                                            formsData
                                                              ?.filter(
                                                                (form) =>
                                                                  form.issue_date !==
                                                                  ""
                                                              )
                                                              ?.map((form) => ({
                                                                ...form,
                                                                advertising_claim_form_id:
                                                                  advertisingClaimFormId,
                                                              }));
                                                          addAdvertisingClaimRequirementForm(
                                                            {
                                                              variables: {
                                                                objects:
                                                                  updatedFormsData,
                                                              },
                                                            }
                                                          ).then(() => {
                                                            setFormsData(
                                                              initialFormsData
                                                            );
                                                            setIsSubmit(false);
                                                            setEdit(undefined);
                                                            setFiles({});
                                                            completedAdd();
                                                          });
                                                        });
                                                      });
                                                    })
                                                    .catch((error) => {
                                                      console.error(error);
                                                    });

                                                  return false;
                                                } else {
                                                  formRef.current.reportValidity();
                                                }
                                              }
                                            }}
                                            autoComplete="off"
                                          >
                                            <div
                                              className={
                                                classes.root +
                                                " sectionContainer"
                                              }
                                            >
                                              <Typography
                                                gutterBottom
                                                variant="h4"
                                                className="title"
                                              >
                                                <span>
                                                  CO-OP ADVERTISING CLAIM FORM
                                                </span>
                                              </Typography>
                                              <Typography
                                                gutterBottom
                                                variant="h5"
                                                className={classes.subtitle3}
                                                align="left"
                                              >
                                                FORMS WAITING FOR APPROVAL
                                              </Typography>
                                              {!edit && (
                                                <Subscription
                                                  subscription={
                                                    GET_ALL_ADVERTISNG_CLAIM_FORMS
                                                  }
                                                  variables={{
                                                    profile_id: auth.getSub(),
                                                  }}
                                                >
                                                  {({
                                                    data,
                                                    loading,
                                                    error: err,
                                                  }) => {
                                                    if (
                                                      data &&
                                                      data !== queryData
                                                    ) {
                                                      setQueryData(
                                                        data?.advertising_claim_form
                                                      );
                                                    }
                                                    if (loading || !queryData)
                                                      return (
                                                        <div
                                                          className="flex center"
                                                          style={{
                                                            padding: "2em 0",
                                                          }}
                                                        >
                                                          <CircularProgress color="secondary" />
                                                        </div>
                                                      );
                                                    if (error)
                                                      return (
                                                        <p>
                                                          Error Loading
                                                          Advertising Claim
                                                          Forms
                                                        </p>
                                                      );
                                                    return (
                                                      <div className="custom-table-event">
                                                        {queryData && (
                                                          <Grids
                                                            rows={gridRows(
                                                              data?.advertising_claim_form
                                                            )}
                                                            columns={columns}
                                                          >
                                                            <RowDetailState
                                                              expandedRowIds={
                                                                expandedRowIds
                                                              }
                                                              onExpandedRowIdsChange={
                                                                setExpandedRowIds
                                                              }
                                                            />
                                                            <PagingState
                                                              defaultCurrentPage={
                                                                0
                                                              }
                                                              pageSize={10}
                                                            />
                                                            <IntegratedPaging />
                                                            <Table
                                                              cellComponent={(
                                                                props
                                                              ) => (
                                                                <LastColumnCell
                                                                  {...props}
                                                                />
                                                              )}
                                                              rowComponent={
                                                                TableRow
                                                              }
                                                            />
                                                            <TableHeaderRow />
                                                            <PagingPanel />
                                                            <TableRowDetail
                                                              contentComponent={
                                                                RowDetail
                                                              }
                                                            />
                                                          </Grids>
                                                        )}
                                                      </div>
                                                    );
                                                  }}
                                                </Subscription>
                                              )}
                                              <br></br>
                                              <br></br>
                                              <Typography
                                                gutterBottom
                                                variant="h5"
                                                className={classes.subtitle3}
                                                style={{
                                                  backgroundColor: "#BED4E5",
                                                  textAlign: "left",
                                                  padding: "1em",
                                                  color: "#23333F",
                                                  margin: "0px",
                                                }}
                                              >
                                                CO-OP ADVERTISING CLAIM FORM
                                              </Typography>
                                              <Paper
                                                className={classes.paper}
                                                style={{
                                                  padding: "2em",
                                                  backgroundColor: "#F9FCFF",
                                                  boxShadow: "unset",
                                                }}
                                              >
                                                <Typography
                                                  gutterBottom
                                                  variant="h5"
                                                  align="center"
                                                  className={classes.subTitle2}
                                                  style={{
                                                    margin: "0px auto 15px",
                                                    maxWidth: "80%",
                                                  }}
                                                >
                                                  <strong>
                                                    CAROLINA SKIFF:
                                                  </strong>{" "}
                                                  3231 Fulford Road Waycross
                                                  Georgia 31503{" "}
                                                  <strong>PHONE:</strong>{" "}
                                                  912-287-0547{" "}
                                                  <strong>FAX:</strong>{" "}
                                                  912-287-0533
                                                </Typography>
                                                <Typography
                                                  gutterBottom
                                                  variant="h6"
                                                  align="center"
                                                  style={{
                                                    margin: "0px auto",
                                                    maxWidth: "80%",
                                                  }}
                                                  className={classes.subTitle2}
                                                >
                                                  This Claim Form is for Co-op
                                                  Advertising credits. All
                                                  required paper work must be
                                                  submitted with this claim
                                                  form.
                                                </Typography>
                                                <br></br>
                                                <br></br>
                                                <div>
                                                  <Grid container spacing={4}>
                                                    <Grid item xs={12} sm={4}>
                                                      <TextField
                                                        required
                                                        name="dealer_name"
                                                        label="Dealer Name"
                                                        className={
                                                          classes.textField
                                                        }
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        value={
                                                          values?.dealer_name ||
                                                          ""
                                                        }
                                                        variant="outlined"
                                                      />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                      <TextField
                                                        required
                                                        name="contact_name"
                                                        label="Contact Name"
                                                        className={
                                                          classes.textField
                                                        }
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        value={
                                                          values?.contact_name ||
                                                          ""
                                                        }
                                                        variant="outlined"
                                                      />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                      <TextField
                                                        required
                                                        name="phone"
                                                        label="Phone"
                                                        className={
                                                          classes.textField
                                                        }
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        value={
                                                          values?.phone || ""
                                                        }
                                                        variant="outlined"
                                                      />
                                                    </Grid>
                                                  </Grid>

                                                  <Grid container spacing={4}>
                                                    <Grid item xs={12} sm={4}>
                                                      <TextField
                                                        required
                                                        name="fax"
                                                        label="Fax"
                                                        className={
                                                          classes.textField
                                                        }
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        value={
                                                          values?.fax || ""
                                                        }
                                                        variant="outlined"
                                                      />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                      <TextField
                                                        required
                                                        name="address"
                                                        label="Address"
                                                        className={
                                                          classes.textField
                                                        }
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        value={
                                                          values?.address || ""
                                                        }
                                                        variant="outlined"
                                                      />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                      <TextField
                                                        name="address2"
                                                        label="Address line 2 (Suites, Apt. # etc)"
                                                        className={
                                                          classes.textField
                                                        }
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        variant="outlined"
                                                        value={
                                                          values?.address2 || ""
                                                        }
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>

                                                  <Grid container spacing={4}>
                                                    <Grid item xs={12} sm={4}>
                                                      <TextField
                                                        required
                                                        name="city"
                                                        label="City"
                                                        className={
                                                          classes.textField
                                                        }
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        variant="outlined"
                                                        value={
                                                          values?.city || ""
                                                        }
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                      <TextField
                                                        required
                                                        name="country"
                                                        label="Country"
                                                        select
                                                        className={
                                                          classes.textField
                                                        }
                                                        onChange={handleChange}
                                                        value={
                                                          values?.country || ""
                                                        }
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        variant="outlined"
                                                        SelectProps={{
                                                          native: true,
                                                          MenuProps: {
                                                            className:
                                                              classes.menu,
                                                          },
                                                        }}
                                                        margin="normal"
                                                      >
                                                        <option
                                                          key=""
                                                          value=""
                                                        />
                                                        <option
                                                          key="United States"
                                                          value="United States"
                                                        >
                                                          United States
                                                        </option>
                                                      </TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                      <TextField
                                                        required
                                                        name="state"
                                                        label="State"
                                                        variant="outlined"
                                                        select
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        className={
                                                          classes.textField
                                                        }
                                                        value={
                                                          values?.state || ""
                                                        }
                                                        onChange={handleChange}
                                                        SelectProps={{
                                                          native: true,
                                                          MenuProps: {
                                                            className:
                                                              classes.menu,
                                                          },
                                                        }}
                                                        margin="normal"
                                                      >
                                                        <option
                                                          key=""
                                                          value=""
                                                        />
                                                        <option
                                                          key="Alabama"
                                                          value="Alabama"
                                                        >
                                                          Alabama
                                                        </option>
                                                        <option
                                                          key="Alaska"
                                                          value="Alaska"
                                                        >
                                                          Alaska
                                                        </option>
                                                        <option
                                                          key="Arizona"
                                                          value="Arizona"
                                                        >
                                                          Arizona
                                                        </option>
                                                        <option
                                                          key="Arkansas"
                                                          value="Arkansas"
                                                        >
                                                          Arkansas
                                                        </option>
                                                        <option
                                                          key="California"
                                                          value="California"
                                                        >
                                                          California
                                                        </option>
                                                        <option
                                                          key="Colorado"
                                                          value="Colorado"
                                                        >
                                                          Colorado
                                                        </option>
                                                        <option
                                                          key="Connecticut"
                                                          value="Connecticut"
                                                        >
                                                          Connecticut
                                                        </option>
                                                        <option
                                                          key="Delaware"
                                                          value="Delaware"
                                                        >
                                                          Delaware
                                                        </option>
                                                        <option
                                                          key="Florida"
                                                          value="Florida"
                                                        >
                                                          Florida
                                                        </option>
                                                        <option
                                                          key="Georgia"
                                                          value="Georgia"
                                                        >
                                                          Georgia
                                                        </option>
                                                        <option
                                                          key="Hawaii"
                                                          value="Hawaii"
                                                        >
                                                          Hawaii
                                                        </option>
                                                        <option
                                                          key="Idaho"
                                                          value="Idaho"
                                                        >
                                                          Idaho
                                                        </option>
                                                        <option
                                                          key="Illinois"
                                                          value="Illinois"
                                                        >
                                                          Illinois
                                                        </option>
                                                        <option
                                                          key="Indiana"
                                                          value="Indiana"
                                                        >
                                                          Indiana
                                                        </option>
                                                        <option
                                                          key="Iowa"
                                                          value="Iowa"
                                                        >
                                                          Iowa
                                                        </option>
                                                        <option
                                                          key="Kansas"
                                                          value="Kansas"
                                                        >
                                                          Kansas
                                                        </option>
                                                        <option
                                                          key="Kentucky"
                                                          value="Kentucky"
                                                        >
                                                          Kentucky
                                                        </option>
                                                        <option
                                                          key="Louisiana"
                                                          value="Louisiana"
                                                        >
                                                          Louisiana
                                                        </option>
                                                        <option
                                                          key="Maine"
                                                          value="Maine"
                                                        >
                                                          Maine
                                                        </option>
                                                        <option
                                                          key="Maryland"
                                                          value="Maryland"
                                                        >
                                                          Maryland
                                                        </option>
                                                        <option
                                                          key="Massachusetts"
                                                          value="Massachusetts"
                                                        >
                                                          Massachusetts
                                                        </option>
                                                        <option
                                                          key="Michigan"
                                                          value="Michigan"
                                                        >
                                                          Michigan
                                                        </option>
                                                        <option
                                                          key="Minnesota"
                                                          value="Minnesota"
                                                        >
                                                          Minnesota
                                                        </option>
                                                        <option
                                                          key="Mississippi"
                                                          value="Mississippi"
                                                        >
                                                          Mississippi
                                                        </option>
                                                        <option
                                                          key="Missouri"
                                                          value="Missouri"
                                                        >
                                                          Missouri
                                                        </option>
                                                        <option
                                                          key="Montana"
                                                          value="Montana"
                                                        >
                                                          Montana
                                                        </option>
                                                        <option
                                                          key="Nebraska"
                                                          value="Nebraska"
                                                        >
                                                          Nebraska
                                                        </option>
                                                        <option
                                                          key="Nevada"
                                                          value="Nevada"
                                                        >
                                                          Nevada
                                                        </option>
                                                        <option
                                                          key="New Hampshire"
                                                          value="New Hampshire"
                                                        >
                                                          New Hampshire
                                                        </option>
                                                        <option
                                                          key="New Jersey"
                                                          value="New Jersey"
                                                        >
                                                          New Jersey
                                                        </option>
                                                        <option
                                                          key="New Mexico"
                                                          value="New Mexico"
                                                        >
                                                          New Mexico
                                                        </option>
                                                        <option
                                                          key="New York"
                                                          value="New York"
                                                        >
                                                          New York
                                                        </option>
                                                        <option
                                                          key="North Carolina"
                                                          value="North Carolina"
                                                        >
                                                          North Carolina
                                                        </option>
                                                        <option
                                                          key="North Dakota"
                                                          value="North Dakota"
                                                        >
                                                          North Dakota
                                                        </option>
                                                        <option
                                                          key="Ohio"
                                                          value="Ohio"
                                                        >
                                                          Ohio
                                                        </option>
                                                        <option
                                                          key="Oklahoma"
                                                          value="Oklahoma"
                                                        >
                                                          Oklahoma
                                                        </option>
                                                        <option
                                                          key="Oregon"
                                                          value="Oregon"
                                                        >
                                                          Oregon
                                                        </option>
                                                        <option
                                                          key="Pennsylvania"
                                                          value="Pennsylvania"
                                                        >
                                                          Pennsylvania
                                                        </option>
                                                        <option
                                                          key="Rhode Island"
                                                          value="Rhode Island"
                                                        >
                                                          Rhode Island
                                                        </option>
                                                        <option
                                                          key="South Carolina"
                                                          value="South Carolina"
                                                        >
                                                          South Carolina
                                                        </option>
                                                        <option
                                                          key="South Dakota"
                                                          value="South Dakota"
                                                        >
                                                          South Dakota
                                                        </option>
                                                        <option
                                                          key="Tennessee"
                                                          value="Tennessee"
                                                        >
                                                          Tennessee
                                                        </option>
                                                        <option
                                                          key="Texas"
                                                          value="Texas"
                                                        >
                                                          Texas
                                                        </option>
                                                        <option
                                                          key="Utah"
                                                          value="Utah"
                                                        >
                                                          Utah
                                                        </option>
                                                        <option
                                                          key="Vermont"
                                                          value="Vermont"
                                                        >
                                                          Vermont
                                                        </option>
                                                        <option
                                                          key="Virginia"
                                                          value="Virginia"
                                                        >
                                                          Virginia
                                                        </option>
                                                        <option
                                                          key="Washington"
                                                          value="Washington"
                                                        >
                                                          Washington
                                                        </option>
                                                        <option
                                                          key="West Virginia"
                                                          value="West Virginia"
                                                        >
                                                          West Virginia
                                                        </option>
                                                        <option
                                                          key="Wisconsin"
                                                          value="Wisconsin"
                                                        >
                                                          Wisconsin
                                                        </option>
                                                        <option
                                                          key="Wyoming"
                                                          value="Wyoming"
                                                        >
                                                          Wyoming
                                                        </option>
                                                      </TextField>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={4}>
                                                    <Grid item xs={12} sm={4}>
                                                      <TextField
                                                        name="zipcode"
                                                        label="Zip / Postal Code"
                                                        className={
                                                          classes.textField
                                                        }
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        variant="outlined"
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        value={
                                                          values?.zipcode || ""
                                                        }
                                                      />
                                                    </Grid>
                                                    {/* <Grid item xs={12} sm={4}>
                                                                                                            <Typography
                                                                                                                gutterBottom
                                                                                                                variant="h6"
                                                                                                                align="left"
                                                                                                                className={classes.subTitle2}
                                                                                                                style={{
                                                                                                                    width: "100%",
                                                                                                                    textAlign: "left",
                                                                                                                    marginTop: "25px",
                                                                                                                }}
                                                                                                            >
                                                                                                                DATE: 3/25/2020{" "}
                                                                                                            </Typography>
                                                                                                        </Grid> */}
                                                  </Grid>
                                                </div>
                                                <br></br>
                                                <br></br>

                                                <div>
                                                  <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                    align="center"
                                                    style={{
                                                      maxWidth: "80%",
                                                      margin: "0px auto 15px",
                                                    }}
                                                    className={
                                                      classes.subTitle2
                                                    }
                                                  >
                                                    <b>
                                                      CO-OP ADVERTISING CLAIM
                                                      FORM
                                                    </b>
                                                  </Typography>
                                                  <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                    align="center"
                                                    style={{
                                                      maxWidth: "80%",
                                                      margin: "0px auto 10px",
                                                    }}
                                                    className={
                                                      classes.subTitle2
                                                    }
                                                  >
                                                    For qualifications and
                                                    requirements of the Co-op
                                                    advertising program, please
                                                    check your Dealer Manual
                                                    under the Marketing Guide
                                                    section.
                                                  </Typography>

                                                  <br></br>
                                                  <br></br>
                                                  {/* cliam form start */}
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    justify="space-around"
                                                  >
                                                    <Grid item xs={12} md={2}>
                                                      <FormLabel
                                                        variant="h5"
                                                        align="center"
                                                        className={
                                                          classes.subTitle2
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          display: "block",
                                                          marginBottom: "10px",
                                                        }}
                                                      >
                                                        <b>ISSUE DATE</b>
                                                      </FormLabel>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                      <FormLabel
                                                        variant="h5"
                                                        align="center"
                                                        className={
                                                          classes.subTitle2
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          display: "block",
                                                          marginBottom: "10px",
                                                        }}
                                                      >
                                                        <b>
                                                          ADVERTISING
                                                          DESCRIPTION
                                                        </b>
                                                      </FormLabel>
                                                    </Grid>
                                                    <Grid item xs={12} md={1}>
                                                      <FormLabel
                                                        variant="h5"
                                                        align="center"
                                                        className={
                                                          classes.subTitle2
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          display: "block",
                                                          marginBottom: "10px",
                                                        }}
                                                      >
                                                        <b>GROSS COST</b>
                                                      </FormLabel>
                                                    </Grid>
                                                    <Grid item xs={12} md={1}>
                                                      <FormLabel
                                                        variant="h5"
                                                        align="center"
                                                        className={
                                                          classes.subTitle2
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          display: "block",
                                                          marginBottom: "10px",
                                                        }}
                                                      >
                                                        <b>% APPROVED</b>
                                                      </FormLabel>
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                      <FormLabel
                                                        variant="h5"
                                                        align="center"
                                                        className={
                                                          classes.subTitle2
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          display: "block",
                                                          marginBottom: "10px",
                                                        }}
                                                      >
                                                        <b>
                                                          COST OF<br></br>{" "}
                                                          APPROVED %
                                                        </b>
                                                      </FormLabel>
                                                    </Grid>
                                                    <Grid item xs={12} md={1}>
                                                      <FormLabel
                                                        variant="h5"
                                                        align="center"
                                                        className={
                                                          classes.subTitle2
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          display: "block",
                                                          marginBottom: "10px",
                                                        }}
                                                      >
                                                        <b>
                                                          PROGRAM<br></br>{" "}
                                                          CREDIT
                                                        </b>
                                                      </FormLabel>
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                      <FormLabel
                                                        variant="h5"
                                                        align="center"
                                                        className={
                                                          classes.subTitle2
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          display: "block",
                                                          marginBottom: "10px",
                                                        }}
                                                      >
                                                        <b>
                                                          CO-OP<br></br> CREDIT
                                                        </b>
                                                      </FormLabel>
                                                    </Grid>
                                                  </Grid>
                                                  {formsData &&
                                                    formsData?.length > 0 &&
                                                    formsData?.map(
                                                      (form, index) => {
                                                        return (
                                                          <>
                                                            <Grid
                                                              container
                                                              spacing={2}
                                                              justify="space-around"
                                                            >
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                md={2}
                                                              >
                                                                <TextField
                                                                  required={
                                                                    !!errors[
                                                                      index
                                                                    ]
                                                                      ?.issue_date
                                                                  }
                                                                  error={
                                                                    !!errors[
                                                                      index
                                                                    ]
                                                                      ?.issue_date
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                  variant="outlined"
                                                                  id="issue_date"
                                                                  name="issue_date"
                                                                  type="date"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleFieldChange(
                                                                      index,
                                                                      "issue_date",
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  }
                                                                  value={
                                                                    form?.issue_date ||
                                                                    ""
                                                                  }
                                                                  className={
                                                                    classes.textField
                                                                  }
                                                                  InputLabelProps={{
                                                                    shrink: true,
                                                                  }}
                                                                />
                                                              </Grid>
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                md={3}
                                                              >
                                                                <OutlinedInput
                                                                  error={
                                                                    !!errors[
                                                                      index
                                                                    ]
                                                                      ?.advertising_description
                                                                  }
                                                                  required={
                                                                    !!errors[
                                                                      index
                                                                    ]
                                                                      ?.advertising_description
                                                                  }
                                                                  name="advertising_description"
                                                                  id="advertising_description"
                                                                  endAdornment={
                                                                    <InputAdornment position="end">
                                                                      {" "}
                                                                    </InputAdornment>
                                                                  }
                                                                  value={
                                                                    form?.advertising_description ||
                                                                    ""
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                  aria-describedby="outlined-weight-helper-text"
                                                                  inputProps={{
                                                                    "aria-label":
                                                                      "weight",
                                                                  }}
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleFieldChange(
                                                                      index,
                                                                      "advertising_description",
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  }
                                                                />
                                                              </Grid>
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                md={1}
                                                              >
                                                                <OutlinedInput
                                                                  error={
                                                                    !!errors[
                                                                      index
                                                                    ]
                                                                      ?.gross_cost
                                                                  }
                                                                  required={
                                                                    !!errors[
                                                                      index
                                                                    ]
                                                                      ?.gross_cost
                                                                  }
                                                                  name="gross_cost"
                                                                  id="gross_cost"
                                                                  type="number"
                                                                  value={
                                                                    form?.gross_cost ||
                                                                    ""
                                                                  }
                                                                  startAdornment={
                                                                    <InputAdornment position="start">
                                                                      $
                                                                    </InputAdornment>
                                                                  }
                                                                  endAdornment={
                                                                    <InputAdornment position="end">
                                                                      {" "}
                                                                    </InputAdornment>
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                  aria-describedby="outlined-weight-helper-text"
                                                                  inputProps={{
                                                                    "aria-label":
                                                                      "weight",
                                                                    pattern:
                                                                      "\\d*\\.?\\d+",
                                                                    min: "0",
                                                                  }}
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    const value =
                                                                      e.target
                                                                        .value;
                                                                    if (
                                                                      value ===
                                                                        "" ||
                                                                      (!isNaN(
                                                                        value
                                                                      ) &&
                                                                        Number(
                                                                          value
                                                                        ) >= 0)
                                                                    ) {
                                                                      handleFieldChange(
                                                                        index,
                                                                        "gross_cost",
                                                                        value.toString()
                                                                      );
                                                                    }
                                                                  }}
                                                                />
                                                              </Grid>
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                md={1}
                                                              >
                                                                <OutlinedInput
                                                                  error={
                                                                    !!errors[
                                                                      index
                                                                    ]?.approved
                                                                  }
                                                                  required={
                                                                    !!errors[
                                                                      index
                                                                    ]?.approved
                                                                  }
                                                                  name="approved"
                                                                  id="approved"
                                                                  type="number"
                                                                  value={
                                                                    form?.approved ||
                                                                    ""
                                                                  }
                                                                  endAdornment={
                                                                    <InputAdornment position="end">
                                                                      %
                                                                    </InputAdornment>
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                  aria-describedby="outlined-weight-helper-text"
                                                                  inputProps={{
                                                                    "aria-label":
                                                                      "weight",
                                                                    pattern:
                                                                      "\\d*\\.?\\d+",
                                                                    min: "0",
                                                                  }}
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    const value =
                                                                      e.target
                                                                        .value;
                                                                    if (
                                                                      value ===
                                                                        "" ||
                                                                      (!isNaN(
                                                                        value
                                                                      ) &&
                                                                        Number(
                                                                          value
                                                                        ) >= 0)
                                                                    ) {
                                                                      handleFieldChange(
                                                                        index,
                                                                        "approved",
                                                                        value.toString()
                                                                      );
                                                                    }
                                                                  }}
                                                                />
                                                              </Grid>
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                md={2}
                                                              >
                                                                <OutlinedInput
                                                                  error={
                                                                    !!errors[
                                                                      index
                                                                    ]
                                                                      ?.cost_of_approved
                                                                  }
                                                                  required={
                                                                    !!errors[
                                                                      index
                                                                    ]
                                                                      ?.cost_of_approved
                                                                  }
                                                                  name="cost_of_approved"
                                                                  type="number"
                                                                  id="cost_of_approved"
                                                                  endAdornment={
                                                                    <InputAdornment position="end">
                                                                      {" "}
                                                                    </InputAdornment>
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                  startAdornment={
                                                                    <InputAdornment position="start">
                                                                      $
                                                                    </InputAdornment>
                                                                  }
                                                                  aria-describedby="outlined-weight-helper-text"
                                                                  inputProps={{
                                                                    "aria-label":
                                                                      "weight",
                                                                    pattern:
                                                                      "\\d*\\.?\\d+",
                                                                    min: "0",
                                                                  }}
                                                                  value={(
                                                                    (form?.gross_cost *
                                                                      form?.approved) /
                                                                    100
                                                                  ).toString()}
                                                                  disabled
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    const value =
                                                                      e.target
                                                                        .value;
                                                                    if (
                                                                      value ===
                                                                        "" ||
                                                                      (!isNaN(
                                                                        value
                                                                      ) &&
                                                                        Number(
                                                                          value
                                                                        ) >= 0)
                                                                    ) {
                                                                      handleFieldChange(
                                                                        index,
                                                                        "cost_of_approved",
                                                                        value.toString()
                                                                      );
                                                                    }
                                                                  }}
                                                                />
                                                              </Grid>
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                md={1}
                                                              >
                                                                <OutlinedInput
                                                                  // error={!!(errors[index]?.program_credit)}
                                                                  // required={!!(errors[index]?.program_credit)}
                                                                  name="program_credit"
                                                                  id="program_credit"
                                                                  type="number"
                                                                  defaultValue={
                                                                    50
                                                                  }
                                                                  endAdornment={
                                                                    <InputAdornment position="end">
                                                                      %
                                                                    </InputAdornment>
                                                                  }
                                                                  disabled
                                                                  value={50}
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                    textAlign:
                                                                      "center",
                                                                  }}
                                                                  aria-describedby="outlined-weight-helper-text"
                                                                  align="center"
                                                                  inputProps={{
                                                                    "aria-label":
                                                                      "weight",
                                                                    pattern:
                                                                      "\\d*\\.?\\d+",
                                                                    min: "0",
                                                                  }}
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleFieldChange(
                                                                      index,
                                                                      "program_credit",
                                                                      "50"
                                                                    );
                                                                  }}
                                                                />
                                                              </Grid>
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                md={2}
                                                              >
                                                                <OutlinedInput
                                                                  // error={!!(errors[index]?.co_op_credit)}
                                                                  // required={!!(errors[index]?.co_op_credit)}
                                                                  id="co_op_credit"
                                                                  name="co_op_credit"
                                                                  type="number"
                                                                  endAdornment={
                                                                    <InputAdornment position="end">
                                                                      {" "}
                                                                    </InputAdornment>
                                                                  }
                                                                  disabled
                                                                  value={(
                                                                    (form?.cost_of_approved *
                                                                      50) /
                                                                    100
                                                                  ).toString()}
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                  startAdornment={
                                                                    <InputAdornment position="start">
                                                                      $
                                                                    </InputAdornment>
                                                                  }
                                                                  aria-describedby="outlined-weight-helper-text"
                                                                  inputProps={{
                                                                    "aria-label":
                                                                      "weight",
                                                                    pattern:
                                                                      "\\d*\\.?\\d+",
                                                                    // "min": "0"
                                                                  }}
                                                                />
                                                              </Grid>
                                                            </Grid>
                                                            <Grid
                                                              container
                                                              spacing={2}
                                                              justify="space-around"
                                                              key={form?.id}
                                                            >
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                md={2}
                                                              >
                                                                {errors[index]
                                                                  ?.issue_date && (
                                                                  <div className="error-message">
                                                                    !required
                                                                  </div>
                                                                )}
                                                              </Grid>

                                                              <Grid
                                                                item
                                                                xs={12}
                                                                md={3}
                                                              >
                                                                {errors[index]
                                                                  ?.advertising_description && (
                                                                  <div className="error-message">
                                                                    !required
                                                                  </div>
                                                                )}
                                                              </Grid>
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                md={1}
                                                              >
                                                                {errors[index]
                                                                  ?.gross_cost && (
                                                                  <div className="error-message">
                                                                    !required
                                                                  </div>
                                                                )}
                                                              </Grid>
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                md={1}
                                                              >
                                                                {errors[index]
                                                                  ?.approved && (
                                                                  <div className="error-message">
                                                                    !required
                                                                  </div>
                                                                )}
                                                              </Grid>
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                md={2}
                                                              >
                                                                {errors[index]
                                                                  ?.cost_of_approved && (
                                                                  <div className="error-message">
                                                                    !required
                                                                  </div>
                                                                )}
                                                              </Grid>
                                                              {/* <Grid item xs={12} md={1}>
                                                                                                                        {errors[index]?.program_credit && (
                                                                                                                            <div className="error-message">!required</div>
                                                                                                                        )}
                                                                                                                    </Grid> */}
                                                              {/* <Grid item xs={12} md={2}>
                                                                                                                        {errors[index]?.co_op_credit && (
                                                                                                                            <div className="error-message">!required</div>
                                                                                                                        )}
                                                                                                                    </Grid> */}
                                                            </Grid>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  <br></br>
                                                  <Grid
                                                    container
                                                    justify="flex-end"
                                                    spacing={4}
                                                  >
                                                    <Grid item xs={12} sm={4}>
                                                      <FormControl
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "row",
                                                          alignItems: "center",
                                                        }}
                                                        variant="outlined"
                                                      >
                                                        {/* <b variant="h5" align="center" className={classes.subTitle2}  style={{width:"100%",marginBottom:"10px"}}>TOTAL COST OF PROJECT</b> */}
                                                        <FormLabel
                                                          className={
                                                            classes.subTitle2
                                                          }
                                                        >
                                                          <b>Total Credit $</b>{" "}
                                                        </FormLabel>
                                                        <OutlinedInput
                                                          id="outlined-adornment-weight"
                                                          endAdornment={
                                                            <InputAdornment position="end">
                                                              {" "}
                                                            </InputAdornment>
                                                          }
                                                          value={formsData
                                                            ?.reduce(
                                                              (sum, item) => {
                                                                return (
                                                                  sum +
                                                                  parseFloat(
                                                                    item?.co_op_credit
                                                                  )
                                                                );
                                                              },
                                                              0
                                                            )
                                                            .toFixed(2)}
                                                          style={{
                                                            width: "80%",
                                                            maxWidth:
                                                              "calc(100% - 100px)",
                                                            marginLeft: "6px",
                                                          }}
                                                          aria-describedby="outlined-weight-helper-text"
                                                          inputProps={{
                                                            "aria-label":
                                                              "weight",
                                                          }}
                                                        />
                                                      </FormControl>
                                                    </Grid>
                                                  </Grid>
                                                  {/* cliam form end */}
                                                  <br></br>
                                                  <br></br>

                                                  <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                    align="left"
                                                    className={
                                                      classes.subTitle2
                                                    }
                                                  >
                                                    <b>REQUIREMENTS</b>
                                                  </Typography>
                                                  <br></br>
                                                  <br></br>

                                                  <Grid
                                                    container
                                                    spacing={4}
                                                    alignItems="center"
                                                  >
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                      md={7}
                                                    >
                                                      <FormControl
                                                        sx={{
                                                          m: 1,
                                                          width: "25ch",
                                                        }}
                                                        variant="outlined"
                                                      >
                                                        <FormLabel
                                                          variant="h5"
                                                          align="left"
                                                          className={
                                                            classes.subTitle2
                                                          }
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          1. Copy of paid
                                                          invoice.
                                                        </FormLabel>
                                                      </FormControl>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                      md={5}
                                                    >
                                                      <input
                                                        accept="image/*,application/pdf"
                                                        className={
                                                          classes.input
                                                        }
                                                        id="contained-button-file1"
                                                        type="file"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        onChange={(e) =>
                                                          handleFileChange(
                                                            e,
                                                            "paid_invoice_url"
                                                          )
                                                        }
                                                      />
                                                      <FormLabel htmlFor="contained-button-file1">
                                                        <Button
                                                          variant="contained"
                                                          color="primary"
                                                          component="span"
                                                        >
                                                          CHOOSE FILE
                                                        </Button>
                                                      </FormLabel>
                                                      <FormLabel
                                                        htmlFor="contained-button-file1"
                                                        className={
                                                          classes.body2
                                                        }
                                                        style={{
                                                          marginLeft: "8px",
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {files?.paid_invoice_url
                                                          ?.name
                                                          ? files
                                                              ?.paid_invoice_url
                                                              ?.name
                                                          : files?.paid_invoice_url ||
                                                            "NO FILES UPLOADED"}
                                                      </FormLabel>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                      md={7}
                                                    >
                                                      <FormControl
                                                        sx={{
                                                          m: 1,
                                                          width: "25ch",
                                                        }}
                                                        variant="outlined"
                                                      >
                                                        <FormLabel
                                                          variant="h5"
                                                          align="left"
                                                          className={
                                                            classes.subTitle2
                                                          }
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          2. Full page tear
                                                          sheet.
                                                        </FormLabel>
                                                      </FormControl>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                      md={5}
                                                    >
                                                      <input
                                                        accept="image/*,application/pdf"
                                                        className={
                                                          classes.input
                                                        }
                                                        id="contained-button-file2"
                                                        type="file"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        onChange={(e) =>
                                                          handleFileChange(
                                                            e,
                                                            "tear_sheet_url"
                                                          )
                                                        }
                                                      />
                                                      <FormLabel htmlFor="contained-button-file2">
                                                        <Button
                                                          variant="contained"
                                                          color="primary"
                                                          component="span"
                                                        >
                                                          CHOOSE FILE
                                                        </Button>
                                                      </FormLabel>
                                                      <FormLabel
                                                        htmlFor="contained-button-file2"
                                                        className={
                                                          classes.body2
                                                        }
                                                        style={{
                                                          marginLeft: "8px",
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {files?.tear_sheet_url
                                                          ?.name
                                                          ? files
                                                              ?.tear_sheet_url
                                                              ?.name
                                                          : files?.tear_sheet_url ||
                                                            "NO FILES UPLOADED"}
                                                      </FormLabel>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                      md={7}
                                                    >
                                                      <FormControl
                                                        sx={{
                                                          m: 1,
                                                          width: "25ch",
                                                        }}
                                                        variant="outlined"
                                                      >
                                                        <FormLabel
                                                          variant="h5"
                                                          align="left"
                                                          className={
                                                            classes.subTitle2
                                                          }
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          3. Claim Form.
                                                        </FormLabel>
                                                      </FormControl>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                      md={5}
                                                    >
                                                      <input
                                                        accept="image/*,application/pdf"
                                                        className={
                                                          classes.input
                                                        }
                                                        id="contained-button-file3"
                                                        type="file"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        onChange={(e) =>
                                                          handleFileChange(
                                                            e,
                                                            "claim_form_url"
                                                          )
                                                        }
                                                      />
                                                      <FormLabel htmlFor="contained-button-file3">
                                                        <Button
                                                          variant="contained"
                                                          color="primary"
                                                          component="span"
                                                        >
                                                          CHOOSE FILE
                                                        </Button>
                                                      </FormLabel>
                                                      <FormLabel
                                                        htmlFor="contained-button-file3"
                                                        className={
                                                          classes.body2
                                                        }
                                                        style={{
                                                          marginLeft: "8px",
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {files?.claim_form_url
                                                          ?.name
                                                          ? files
                                                              ?.claim_form_url
                                                              ?.name
                                                          : files?.claim_form_url ||
                                                            "NO FILES UPLOADED"}
                                                      </FormLabel>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                      md={7}
                                                    >
                                                      <FormControl
                                                        sx={{
                                                          m: 1,
                                                          width: "25ch",
                                                        }}
                                                        variant="outlined"
                                                      >
                                                        <FormLabel
                                                          variant="h5"
                                                          align="left"
                                                          className={
                                                            classes.subTitle2
                                                          }
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          4. Affidavit of
                                                          performance (if Radio
                                                          or TV)
                                                        </FormLabel>
                                                      </FormControl>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                      md={5}
                                                    >
                                                      <input
                                                       accept="image/*,application/pdf"
                                                        className={
                                                          classes.input
                                                        }
                                                        id="contained-button-file4"
                                                        type="file"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        onChange={(e) =>
                                                          handleFileChange(
                                                            e,
                                                            "affidavit_url"
                                                          )
                                                        }
                                                      />
                                                      <FormLabel htmlFor="contained-button-file4">
                                                        <Button
                                                          variant="contained"
                                                          color="primary"
                                                          component="span"
                                                        >
                                                          CHOOSE FILE
                                                        </Button>
                                                      </FormLabel>
                                                      <FormLabel
                                                        htmlFor="contained-button-file4"
                                                        className={
                                                          classes.body2
                                                        }
                                                        style={{
                                                          marginLeft: "8px",
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {files?.affidavit_url
                                                          ?.name
                                                          ? files?.affidavit_url
                                                              ?.name
                                                          : files?.affidavit_url ||
                                                            "NO FILES UPLOADED"}
                                                      </FormLabel>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                      md={7}
                                                    >
                                                      <FormControl
                                                        sx={{
                                                          m: 1,
                                                          width: "25ch",
                                                        }}
                                                        variant="outlined"
                                                      >
                                                        <FormLabel
                                                          variant="h5"
                                                          align="left"
                                                          className={
                                                            classes.subTitle2
                                                          }
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          5. Photo of sign. (if
                                                          Billboard)
                                                        </FormLabel>
                                                      </FormControl>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                      md={5}
                                                    >
                                                      <input
                                                        accept="image/*,application/pdf"
                                                        className={
                                                          classes.input
                                                        }
                                                        id="contained-button-file5"
                                                        type="file"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        onChange={(e) =>
                                                          handleFileChange(
                                                            e,
                                                            "photo_of_sign_url"
                                                          )
                                                        }
                                                      />
                                                      <FormLabel htmlFor="contained-button-file5">
                                                        <Button
                                                          variant="contained"
                                                          color="primary"
                                                          component="span"
                                                        >
                                                          CHOOSE FILE
                                                        </Button>
                                                      </FormLabel>
                                                      <FormLabel
                                                        htmlFor="contained-button-file5"
                                                        className={
                                                          classes.body2
                                                        }
                                                        style={{
                                                          marginLeft: "8px",
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {files
                                                          ?.photo_of_sign_url
                                                          ?.name
                                                          ? files
                                                              ?.photo_of_sign_url
                                                              ?.name
                                                          : files?.photo_of_sign_url ||
                                                            "NO FILES UPLOADED"}
                                                      </FormLabel>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                      md={7}
                                                    >
                                                      <FormControl
                                                        sx={{
                                                          m: 1,
                                                          width: "25ch",
                                                        }}
                                                        variant="outlined"
                                                      >
                                                        <FormLabel
                                                          variant="h5"
                                                          align="left"
                                                          className={
                                                            classes.subTitle2
                                                          }
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          6. Sample of each
                                                          item. (if Hats,
                                                          Shirts, Key Chain,
                                                          etc.)
                                                        </FormLabel>
                                                      </FormControl>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                      md={5}
                                                    ></Grid>
                                                  </Grid>
                                                </div>
                                                <br></br>

                                                <div>
                                                  <Grid container>
                                                    <Grid item xs={12} sm={12}>
                                                      <TextField
                                                        name="additional_info"
                                                        label="Additional information. (OPTIONAL)"
                                                        multiline
                                                        rows="4"
                                                        variant="outlined"
                                                        className={
                                                          classes.textField
                                                        }
                                                        onChange={handleChange}
                                                        margin="normal"
                                                        value={
                                                          values?.additional_info ||
                                                          ""
                                                        }
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </div>

                                                <Grid
                                                  item
                                                  xs={12}
                                                  align="center"
                                                >
                                                  {edit && (
                                                    <Button
                                                      color="primary"
                                                      variant="contained"
                                                      className={classes.button}
                                                      onClick={() => {
                                                        setEdit(false);
                                                        resetState();
                                                        setFiles({});
                                                        setFormsData(
                                                          initialFormsData
                                                        );
                                                      }}
                                                      style={{
                                                        width: "15em",
                                                        margin: "2em 1em",
                                                      }}
                                                    >
                                                      CANCEL
                                                    </Button>
                                                  )}
                                                  <Button
                                                    color="primary"
                                                    variant="contained"
                                                    className={classes.button}
                                                    type="submit"
                                                    style={{
                                                      width: "15em",
                                                      margin: "2em 1em",
                                                    }}
                                                    disabled={isSubmit}
                                                  >
                                                    SUBMIT
                                                  </Button>
                                                  {/* <Button
                                                        color="secondary"
                                                        variant="contained"
                                                        className={classes.button}
                                                        type="submit"
                                                        style={{ width: "15em", margin: "2em 1em" }}
                                                    >
                                                        EDIT FORM
                                                    </Button>
                                                    <Button
                                                        color="dark"
                                                        variant="contained"
                                                        className={classes.button}
                                                        type="submit"
                                                        // onClick={this.clearForm}
                                                        style={{
                                                            width: "15em",
                                                            margin: "2em 1em",
                                                            backgroundColor: "#23333F",
                                                            color: "#ffffff",
                                                        }}
                                                    >
                                                        DOWNLOAD
                                                    </Button> */}
                                                </Grid>
                                              </Paper>
                                            </div>
                                          </form>
                                        </DashboardLayout>
                                      );
                                    }}
                                  </Mutation>
                                );
                              }}
                            </Mutation>
                          );
                        }}
                      </Mutation>
                    );
                  }}
                </Mutation>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
}

export default withStyles(styles)(AdvertisingClaimForm);
