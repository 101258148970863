import React, { Component } from "react";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import {
    Button
} from '@material-ui/core';

const styles = {
    button: {
        margin: 8,
    },
    actions: {
        display: 'flex',
        justifyContent: 'center'
    },
    completedContainer: {
        textAlign: 'center',
        padding: '2em 0px'
    },
    completedTitle: {
        fontSize: 14,
        color: '#1f3340',
        textTransform: 'uppercase',
        textAlign: 'center'  
    }
};
const UPDATE_LEADS = gql`
   mutation updateLeadFulfilledBy($id:uuid!, $fulfilledby:String!, $fulfilledon:date!){
    update_leads(
        where: {id: {_eq: $id}},
        _set: {
          fulfilledby: $fulfilledby,
          fulfilledon: $fulfilledon
        }
      ) {
        affected_rows
        returning {
            id
            lead_id
            lead_type
            created_at
            status
            address
            country
            email
            phone
            heard_about
            magazines
            time_frame
            ownership
            interests
            age
            annual_income
            brand
            lead_date
            name
            fulfilledby
            fulfilledon
        }
      }
   }
`;

export default class LeadsFulfilledBy extends Component {

    constructor({ tag, id }) {
        super()
        this.state = {
            tag: tag,
            id: id
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.tag !== prevProps.tag) {
            this.setState(
                {
                    tag: this.props.tag
                }
            )
        }
    }


    componentWillReceiveProps = () => {
        //console.log("componentWillReceiveProps")
    }

    completedAdd = payload => {
        this.setState(
            { tag: 'fulfilledby' }
        )
    }

    render() {
        const tag = this.state.tag
        return (
            <Mutation
                mutation={UPDATE_LEADS}
            >
                {(leads, { data }) => {
                    if (tag) {
                        return (
                          <div style={styles.completedContainer}>
                            {" "}
                            <span style={styles.completedTitle}>Fulfilled {tag}</span>
                          </div>
                        );
                    }
                    else {
                        return (
                            <div style={styles.completedContainer}>
                            <p style={styles.completedTitle}>Completed Lead By:</p>
                            <div style={styles.actions}>
                                <Button color="primary" variant="contained" style={styles.button} onClick={e => {
                                    leads({
                                        variables: {
                                            id: this.state.id,
                                            fulfilledby: "via Email",
                                            fulfilledon: "now()"
                                        },
                                    })
                                    this.completedAdd()
                                }}>Via Email</Button>
                                <Button color="primary" variant="contained" style={styles.button} onClick={e => {
                                    leads({
                                        variables: {
                                            id: this.state.id,
                                            fulfilledby: "Via Phone",
                                            fulfilledon: "now()"
                                        },
                                    })
                                    this.completedAdd()
                                }}>Via Phone</Button>
                                <Button color="primary" variant="contained" style={styles.button} onClick={e => {
                                    leads({
                                        variables: {
                                            id: this.state.id,
                                            fulfilledby: "Via Other",
                                            fulfilledon: "now()"
                                        },
                                    })
                                    this.completedAdd()
                                }}>Via Other</Button>
                                </div>
                            </div>
                        )
                    }
                }
                }
            </Mutation>
        );
    }

}

