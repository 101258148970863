import React from "react";
import { Query } from "react-apollo";
import { getAllDealerWarrantyClaims } from "../../queries";
import PropTypes from "prop-types";
import {
  Paper,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { Print } from "@material-ui/icons";
import {
  SelectionState,
  RowDetailState,
  SearchState,
  PagingState,
  SortingState,
  IntegratedSorting,
  IntegratedPaging,
  IntegratedFiltering,
  IntegratedSelection
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel
} from "@devexpress/dx-react-grid-material-ui";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
import { withStyles } from "@material-ui/core";
import GridContainer from "@material-ui/core/Grid";
import WarrantyClaimMedia from "../Administration/WarrantyClaims/WarrantyClaimMedia";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { WarrantyClaimPdfDocument } from "../Administration/WarrantyClaims/WarrantyClaimPdfDocument";

// Component styles
import styles from "theme/skin";

const RowDetail = ({ row }) => (
  <div>
    <PDFDownloadLink
        document={<WarrantyClaimPdfDocument data={row} />}
        fileName={"warranty_claim_" +  row.warranty_claims_id + "_" +  row.first_name + "_" +  row.last_name +".pdf"}
        color="secondary"
        className="MuiButtonBase-root MuiButton-root MuiButton-containedSecondary MuiButton-contained"
        >
        {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Print To Pdf"
        }
        </PDFDownloadLink>
    <table cellSpacing="0" className="detailTable">
            <tbody>
            <tr>
                <td>Status:</td>
                <td>{row.status}</td>
            </tr>
            <tr>
                <td>Comments:</td>
                <td>{row.comments}</td>
            </tr>
            <tr>
                <td>Created Date/Time:</td>
                <td>{row.submittedon}</td>
            </tr>
            <tr>
                <td>Associate Name:</td>
                <td>{row.associate_name}</td>
            </tr>
            <tr>
                <td>Associate Email:</td>
                <td>{row.associate_email}</td>
            </tr>
            <tr>
                <td>Dealer Name:</td>
                <td>{row.admin_name}</td>
            </tr>
            <tr>
                <td>Dealer Email:</td>
                <td>{row.admin_email}</td>
            </tr>
            <tr>
                <td>HIN:</td>
                <td>{row.hull_identification_number}</td>
            </tr>
            <tr>
                <td>Purchase Date:</td>
                <td>{row.purchase_date}</td>
            </tr>
            <tr>
                <td>Customer Name:</td>
                <td>{row.first_name} {row.last_name}</td>
            </tr>
            <tr>
                <td>Customer Email:</td>
                <td>{row.email}</td>
            </tr>
            <tr>
                <td>Customer Address:</td>
                <td>{row.address}<br/>{row.city}, {row.region} {row.country} {row.zipcode} </td>
            </tr>
            <tr>
                <td>Customer Phone:</td>
                <td>{row.phone}</td>
            </tr>
            <tr>
                <td>Brand:</td>
                <td>{row.brand}</td>
            </tr>
            <tr>
                <td>Model:</td>
                <td>{row.model}</td>
            </tr>
            <tr>
                <td>Older Model:</td>
                <td>{row.boat_model ? (row.boat_model.older_model ? "Yes" : "No") : "-"}</td>
            </tr>
            <tr>
                <td>Motor Details:</td>
                <td>{row.motor_details}</td>
            </tr>
            <tr>
                <td>Issue(s):</td>
                <td>{row.issues}</td>
            </tr>
            <tr>
                <td>Repair(s):</td>
                <td>{row.repairs}</td>
            </tr>
            <tr>
                <td>Part(s):</td>
                <td>{row.parts}</td>
            </tr>
            <tr>
                <td>Labor Hours ($):</td>
                <td>{row.labor_hours}</td>
            </tr>
            <tr>
                <td>Labor Total ($):</td>
                <td>{row.labor_total}</td>
            </tr>
            <tr>
                <td>Parts Used ($):</td>
                <td>{row.parts_used}</td>
            </tr>
            <tr>
                <td>Other ($):</td>
                <td>{row.other}</td>
            </tr>
            <tr>
                <td>Claim Total ($):</td>
                <td>{row.claim_total}</td>
            </tr>
            <tr>
                <td colSpan="2">Photo(s)/Videos/Files: </td>
            </tr>
            </tbody>
        </table>
      {row.photos !== null ? ( 
            <WarrantyClaimMedia profileid={row.profile_id} photos={row.photos} />
        ) : (
            ""
        )}
  </div>
);

class Claims extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "name", title: "Name" , getCellValue: row => (row.first_name + row.last_name ? row.first_name + ' ' + row.last_name : undefined),},
        { name: "email", title: "Email" },
        { name: "phone", title: "Phone" },
        { name: 'address', title: 'Location' , getCellValue: row => (row.address + row.city + row.region + row.country ? row.address + ' ' + row.city + ' ' + row.region + ' ' + row.country : undefined),},
        { name: "status", title: "Status" },
        { name: "submittedon", title: "Submitted Date" }
      ]
    };
    this.changeSearchValue = value => this.setState({ searchValue: value });
  }

  render() {
    const { columns, selection, searchValue } = this.state;
    const { classes } = this.props;

    return (
      <DashboardLayout title="Warranty Claims">
        <Query query={getAllDealerWarrantyClaims}>
          {({ data, loading, error }) => {
            if (loading)
            return (
              <div className="flex center" style={{ padding: "2em 0" }}>
                <CircularProgress color="secondary" />
              </div>
            );
            if (error) return <p>Error</p>;
            return (
              <div className={classes.root + " sectionContainer"}>
                <Typography gutterBottom variant="h4" className="title">
                  <span>Warranty Claims</span>
                </Typography>

                <Paper className="tableStyles">
                  <div className={classes.header}>
                    <GridContainer container justify="space-between" className={classes.iconHeaderContainer}>
                      <GridContainer item xs={12} sm={3}>
                      </GridContainer>
                    </GridContainer>
                  </div>
                  <Grid rows={data.warranty_claims} columns={columns}>
                    <SelectionState
                      selection={selection}
                      onSelectionChange={this.changeSelection}
                    />
                    <SearchState
                      value={searchValue}
                      onValueChange={this.changeSearchValue}
                    />
                    <SortingState
                      defaultSorting={[{ columnName: 'warranty_claims_id', direction: 'desc' }]}
                    />
                    <PagingState defaultCurrentPage={0} pageSize={10} />
                    <IntegratedSelection />
                    <IntegratedFiltering />
                    <IntegratedSorting />
                    <IntegratedPaging />
                    <RowDetailState defaultExpandedRowIds={[0]} />
                    <Table />
                    <TableHeaderRow showSortingControls />
                    <TableRowDetail contentComponent={RowDetail} />
                    <TableSelection showSelectAll />
                    <PagingPanel />
                    <Toolbar />
                    <SearchPanel />
                  </Grid>
                </Paper>
              </div>
            );
          }}
        </Query>
      </DashboardLayout>
    );
  }
}
Claims.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Claims);
