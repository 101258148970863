import React, { Component } from "react";
import { Query, Subscription } from "react-apollo";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
  SearchPanel,
  Toolbar
} from "@devexpress/dx-react-grid-material-ui";
import {
  RowDetailState,
  PagingState,
  SearchState,
  IntegratedPaging,
  IntegratedFiltering
} from "@devexpress/dx-react-grid";
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Typography, withStyles } from "@material-ui/core";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { WarrantyClaimPdfDocument } from "./WarrantyClaimPdfDocument";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import WarrantyClaimUpdate from './WarrantyClaimUpdate';
import WarrantyClaimMedia from "./WarrantyClaimMedia";

export const getNewClaims = gql`
    subscription getNewClaims {
        warranty_claims(
            where: { status: { _eq: "new" } }, order_by: {submittedon: desc}
        ) {
            id
            profile_id
            admin_name
            admin_email
            hull_identification_number
            purchase_date
            brand
            model
            first_name
            last_name
            phone
            email
            warranty_claims_id
            address
            address2
            city
            region
            country
            zipcode
            motor_details
            issues
            repairs
            parts
            photos
            labor_hours
            labor_total
            parts_used
            sublet
            other
            claim_total
            status
            submittedon
            fulfilledon
            comments
            associate_name
            associate_email
            boat_model {
                older_model
            }
        }
    }
`
export const getClaimsUnderReview = gql`
    subscription getUnderReviewClaims {
        warranty_claims(
            where: { status: { _eq: "under review" } }, order_by: {submittedon: desc}
        ) {
            id
            profile_id
            admin_name
            admin_email
            hull_identification_number
            purchase_date
            brand
            model
            first_name
            last_name
            phone
            email
            warranty_claims_id
            address
            address2
            city
            region
            country
            zipcode
            motor_details
            issues
            repairs
            parts
            photos
            labor_hours
            labor_total
            parts_used
            sublet
            other
            claim_total
            status
            submittedon
            fulfilledon
            comments
            associate_name
            associate_email
            boat_model {
                older_model
            }
        }
    }
`
export const getApprovedClaims = gql`
subscription getApprovedClaims {
    warranty_claims(
        where: { status: { _eq: "approved" } }, order_by: {submittedon: desc}
    ) {
        id
        profile_id
        admin_name
        admin_email
        hull_identification_number
        purchase_date
        brand
        model
        first_name
        last_name
        phone
        email
        warranty_claims_id
        address
        address2
        city
        region
        country
        zipcode
        motor_details
        issues
        repairs
        parts
        photos
        labor_hours
        labor_total
        parts_used
        sublet
        other
        claim_total
        status
        submittedon
        fulfilledon
        comments
        associate_name
        associate_email
        boat_model {
            older_model
        }
    }
}
`
export const getDeniedClaims = gql`
    subscription getDeniedClaims {
        warranty_claims(
            where: { status: { _eq: "denied" } }, order_by: {submittedon: desc}
        ) {
            id
            profile_id
            admin_name
            admin_email
            hull_identification_number
            purchase_date
            brand
            model
            first_name
            last_name
            phone
            email
            warranty_claims_id
            address
            address2
            city
            region
            country
            zipcode
            labor_hours
            labor_total
            parts_used
            sublet
            parts
            photos
            other
            claim_total
            status
            submittedon
            fulfilledon
            comments
            associate_name
            associate_email
            boat_model {
                older_model
            }
        }
    }
`

const WarrantyClaimsRowDetail = ({ row }) => (
    <div>
        <PDFDownloadLink
        document={<WarrantyClaimPdfDocument data={row} />}
        fileName={"warranty_claim_" +  row.warranty_claims_id + "_" +  row.first_name + "_" +  row.last_name +".pdf"}
        color="secondary"
        className="MuiButtonBase-root MuiButton-root MuiButton-containedSecondary MuiButton-contained"
        >
        {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Print To Pdf"
        }
        </PDFDownloadLink>
        <table cellSpacing="0" className="detailTable">
            <tbody>
            <tr>
                <td>Status:</td>
                <td>{row.status}</td>
            </tr>
            <tr>
                <td>Comments:</td>
                <td>{row.comments}</td>
            </tr>
            <tr>
                <td>Created Date/Time:</td>
                <td>{row.submittedon}</td>
            </tr>
            <tr>
                <td>Associate Name:</td>
                <td>{row.associate_name}</td>
            </tr>
            <tr>
                <td>Associate Email:</td>
                <td>{row.associate_email}</td>
            </tr>
            <tr>
                <td>Dealer Name:</td>
                <td>{row.admin_name}</td>
            </tr>
            <tr>
                <td>Dealer Email:</td>
                <td>{row.admin_email}</td>
            </tr>
            <tr>
                <td>HIN:</td>
                <td>{row.hull_identification_number}</td>
            </tr>
            <tr>
                <td>Purchase Date:</td>
                <td>{row.purchase_date}</td>
            </tr>
            <tr>
                <td>Customer Name:</td>
                <td>{row.first_name} {row.last_name}</td>
            </tr>
            <tr>
                <td>Customer Email:</td>
                <td>{row.email}</td>
            </tr>
            <tr>
                <td>Customer Address:</td>
                <td>{row.address}<br/>{row.city}, {row.region} {row.country} {row.zipcode} </td>
            </tr>
            <tr>
                <td>Customer Phone:</td>
                <td>{row.phone}</td>
            </tr>
            <tr>
                <td>Brand:</td>
                <td>{row.brand}</td>
            </tr>
            <tr>
                <td>Model:</td>
                <td>{row.model}</td>
            </tr>
            <tr>
                <td>Older Model:</td>
                <td>{row.boat_model ? (row.boat_model.older_model ? "Yes" : "No") : "-"}</td>
            </tr>
            <tr>
                <td>Motor Details:</td>
                <td>{row.motor_details}</td>
            </tr>
            <tr>
                <td>Issue(s):</td>
                <td>{row.issues}</td>
            </tr>
            <tr>
                <td>Repair(s):</td>
                <td>{row.repairs}</td>
            </tr>
            <tr>
                <td>Part(s):</td>
                <td>{row.parts}</td>
            </tr>
            <tr>
                <td>Labor Hours ($):</td>
                <td>{row.labor_hours}</td>
            </tr>
            <tr>
                <td>Labor Total ($):</td>
                <td>{row.labor_total}</td>
            </tr>
            <tr>
                <td>Parts Used ($):</td>
                <td>{row.parts_used}</td>
            </tr>
            <tr>
                <td>Other ($):</td>
                <td>{row.other}</td>
            </tr>
            <tr>
                <td>Claim Total ($):</td>
                <td>{row.claim_total}</td>
            </tr>
            <tr>
                <td colSpan="2">Photo(s)/Videos/Files: </td>
            </tr>
            </tbody>
        </table>
      {row.photos !== null ? ( 
            <WarrantyClaimMedia profileid={row.profile_id} photos={row.photos} />
        ) : (
            ""
        )}
      <WarrantyClaimUpdate status={row.status} warranty={row} />
    </div>
  );

const styles = theme => ({
  button: {
    margin: theme.spacing()
  },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 275
  },
    navBar: {
        display : 'flex',
        flexWrap: 'no-wrap',
    },
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class WarrantyClaimsTabs extends Component {
  constructor(props,{id,rowData}) {
    super(props);
    this.state = {
      id: id,
      rowData: rowData,
      newClaimsColumns: [
        { name: "name", title: "Name" , getCellValue: row => (row.first_name + row.last_name ? row.first_name + ' ' + row.last_name : undefined),},
        { name: "email", title: "Email" },
        { name: "phone", title: "Phone" },
        { name: 'address', title: 'Location' , getCellValue: row => (row.address + row.city + row.region + row.country ? row.address + ' ' + row.city + ' ' + row.region + ' ' + row.country : undefined),},
        { name: "submittedon", title: "Submitted Date" }
      ],
      approvedClaimsColumns: [
        { name: "name", title: "Name" , getCellValue: row => (row.first_name + row.last_name ? row.first_name + ' ' + row.last_name : undefined),},
        { name: "email", title: "Email" },
        { name: 'address', title: 'Location' , getCellValue: row => (row.address + row.city + row.region + row.country ? row.address + ' ' + row.city + ' ' + row.region + ' ' + row.country : undefined),},
        { name: "status", title: "Status" },
        { name: "submittedon", title: "Submitted Date" }
      ],
      underReviewColumns: [
        { name: "name", title: "Name" , getCellValue: row => (row.first_name + row.last_name ? row.first_name + ' ' + row.last_name : undefined),},
        { name: "email", title: "Email" },
        { name: 'address', title: 'Location' , getCellValue: row => (row.address + row.city + row.region + row.country ? row.address + ' ' + row.city + ' ' + row.region + ' ' + row.country : undefined),},
        { name: "status", title: "Status" },
        { name: "submittedon", title: "Submitted Date" }
      ],
      deniedClaimsColumns: [
        { name: "name", title: "Name" , getCellValue: row => (row.first_name + row.last_name ? row.first_name + ' ' + row.last_name : undefined),},
        { name: "claim_total", title: "Claim Total" },
        { name: "status", title: "Status" },
        { name: 'submittedon', title: 'Date' }
      ],
      activeTabIndex: 0,
    };
    this.changeNewClaimsSearchValue = value => this.setState({ newClaimsSearchValue: value });
    this.changeApprovedClaimsSearchValue = value => this.setState({ approvedClaimsSearchValue: value });
    this.changeClaimsUnderReviewSearchValue = value => this.setState({ claimsUnderReviewSearchValue: value });
    this.changeDeniedClaimsSearchValue = value => this.setState({ deniedClaimsSearchValue: value });
  }

  handleTabChange = (event, value) => {
      this.setState({ activeTabIndex: value });
  };

  render() {
     const { classes } = this.props;
     const {
       newClaimsColumns,
       approvedClaimsColumns,
       underReviewColumns,
       deniedClaimsColumns,
       activeTabIndex,
       newClaimsSearchValue,
       approvedClaimsSearchValue,
       claimsUnderReviewSearchValue,
       deniedClaimsSearchValue
     } = this.state;
    return (
      <div>
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs value={activeTabIndex} onChange={this.handleTabChange}>
              <Tab label="New Claims" />
              <Tab label="Approved Claims" />
              <Tab label="Claims Under Review" />
              <Tab label="Denied Claims" />
            </Tabs>
          </AppBar>
          {activeTabIndex === 0 && (
            <TabContainer>
              <Subscription subscription={getNewClaims}>
                    {({ data, loading, error }) => {
                    if (loading) return <p>Loading...</p>;
                    if (error) return <p>Error</p>;
                    return (
                        <div className={classes.root}>
                        <Paper>
                            <Grid rows={data.warranty_claims} columns={newClaimsColumns}>
                            <SearchState
                                value={newClaimsSearchValue}
                                onValueChange={this.changeNewClaimsSearchValue}
                            />
                            <PagingState defaultCurrentPage={0} pageSize={10} />
                            <IntegratedFiltering />
                            <IntegratedPaging />
                            <RowDetailState defaultExpandedRowIds={[0]} />
                            <Table />
                            <TableHeaderRow />
                            <TableRowDetail contentComponent={WarrantyClaimsRowDetail} />
                            <PagingPanel />
                            <Toolbar />
                            <SearchPanel />
                            </Grid>
                        </Paper>
                        </div>
                    );
                    }}
                </Subscription>
            </TabContainer>
          )}
          {activeTabIndex === 1 && (
            <TabContainer>
              <Subscription subscription={getApprovedClaims}>
                    {({ data, loading, error }) => {
                    if (loading) return <p>Loading...</p>;
                    if (error) return <p>Error</p>;
                    return (
                        <div className={classes.root}>
                        <Paper>
                            <Grid rows={data.warranty_claims} columns={approvedClaimsColumns}>
                                <SearchState
                                    value={approvedClaimsSearchValue}
                                    onValueChange={this.changeApprovedClaimsSearchValue}
                                />
                                <PagingState defaultCurrentPage={0} pageSize={10} />
                                <IntegratedFiltering />
                                <IntegratedPaging />
                                <RowDetailState defaultExpandedRowIds={[0]} />
                                <Table />
                                <TableHeaderRow />
                                <TableRowDetail contentComponent={WarrantyClaimsRowDetail} />
                                <PagingPanel />
                                <Toolbar />
                                <SearchPanel />
                            </Grid>
                        </Paper>
                        </div>
                    );
                    }}
                </Subscription>
            </TabContainer>
          )}
          {activeTabIndex === 2 && (
            <TabContainer>
              <Subscription subscription={getClaimsUnderReview}>
                    {({ data, loading, error }) => {
                    if (loading) return <p>Loading...</p>;
                    if (error) return <p>Error</p>;
                    return (
                        <div className={classes.root}>
                        <Paper>
                            <Grid rows={data.warranty_claims} columns={underReviewColumns}>
                                <SearchState
                                    value={claimsUnderReviewSearchValue}
                                    onValueChange={this.changeClaimsUnderReviewSearchValue}
                                />
                                <PagingState defaultCurrentPage={0} pageSize={10} />
                                <IntegratedFiltering />
                                <IntegratedPaging />
                                <RowDetailState defaultExpandedRowIds={[0]} />
                                <Table />
                                <TableHeaderRow />
                                <TableRowDetail contentComponent={WarrantyClaimsRowDetail} />
                                <PagingPanel />
                                <Toolbar />
                                <SearchPanel />
                            </Grid>
                        </Paper>
                        </div>
                    );
                    }}
                </Subscription>
            </TabContainer>
          )}
          {activeTabIndex === 3 && (
            <TabContainer>
              <Subscription subscription={getDeniedClaims}>
                    {({ data, loading, error }) => {
                    if (loading) return <p>Loading...</p>;
                    if (error) return <p>Error</p>;
                    return (
                        <div className={classes.root}>
                        <Paper>
                            <Grid rows={data.warranty_claims} columns={deniedClaimsColumns}>
                            <SearchState
                                value={deniedClaimsSearchValue}
                                onValueChange={this.changeDeniedClaimsSearchValue}
                            />
                            <PagingState defaultCurrentPage={0} pageSize={10} />
                            <IntegratedFiltering />
                            <IntegratedPaging />
                            <RowDetailState defaultExpandedRowIds={[0]} />
                            <Table />
                            <TableHeaderRow />
                            <TableRowDetail contentComponent={WarrantyClaimsRowDetail} />
                            <PagingPanel />
                            <Toolbar />
                            <SearchPanel />
                            </Grid>
                        </Paper>
                        </div>
                    );
                    }}
                </Subscription>
            </TabContainer>
          )}
        </div>
      </div>
    );
  }
}

WarrantyClaimsTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WarrantyClaimsTabs);