import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Card,
  CardContent
} from "@material-ui/core";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  table: {
    maxWidth: 475
  },
  button: {
    margin: theme.spacing()
  },
  card: {
    display: "flex",
    alignItems: "center",
    background: "#f7fcff"
  },
  icon: {
    width: "60px",
    height: "60px"
    //padding: "0px 3em"
  },
  media: {
    flex: 1,
    textAlign: "center"
  },
  cardContent: {
    flex: 2,
    backgroundColor: "#ffffff"
  },
  name: {
    fontWeight: 500
  },
  secondaryTitle: {
    fontSize: "1em"
  }
});
function FactoryContacts(props) {
  const { classes } = props;
  return (
    <DashboardLayout title="Factory Contacts">
      <div className={classes.root + " sectionContainer"}>
        <Typography gutterBottom variant="h4" className="title">
          <span>FACTORY CONTACTS</span>
        </Typography>

        <div>
          <Typography gutterBottom variant="h5" className="secondaryTitle">
            <span>Accounts receivable</span>
          </Typography>
          <Grid container spacing={3} direction="row" alignItems="center">
            <Grid item xs={12} sm={6} lg={6}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText primary="AR ACCOUNTANT" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="800-244-7543 X142" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="AR@CSKIFF.COM" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText primary="AR ACCOUNTANT" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="800-244-7543 X142" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="AR@CSKIFF.COM" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>

        <div>
          <Typography gutterBottom variant="h5" className="secondaryTitle">
            <span>CONSUMER SERVICES</span>
          </Typography>
          <Grid container spacing={3} direction="row" alignItems="center">
            <Grid item xs={12} sm={6} lg={6}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText primary="CONSUMERSERVICES@CSKIFF.COM" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>

        <div>
          <Typography gutterBottom variant="h5" className="secondaryTitle">
            <span>MARKETING & ADVERTISING</span>
          </Typography>
          <Grid container spacing={3} direction="row" alignItems="center">
            <Grid item xs={12} sm={6} lg={6}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText primary="MANAGER OF SALES/MARKETING" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="800-422-7543 X117" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="MARKETING@CSKIFF.COM" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>

        <div>
          <Typography gutterBottom variant="h5" className="secondaryTitle">
            <span>PURCHASING</span>
          </Typography>
          <Grid container spacing={3} direction="row" alignItems="center">
            <Grid item xs={12} sm={6} lg={6}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText primary="PURCHASING MANAGER" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="800-422-7543 X248 " />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="PURCHASING@CSKIFF.COM" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>

        <div>
          <Typography gutterBottom variant="h5" className="secondaryTitle">
            <span>SALES</span>
          </Typography>
          <Grid container spacing={3} direction="row" alignItems="center">
            <Grid item xs={12} sm={6} lg={6}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText primary="SALES REPRESENTATIVE" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="800-422-7543 X125" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="SALES@CSKIFF.COM" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText primary="MANAGER OF SALES/MARKETING" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="800-422-7543 X117" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="SALES@CSKIFF.COM" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>

        <div>
          <Typography gutterBottom variant="h5" className="secondaryTitle">
            <span>WARRANTY</span>
          </Typography>
          <Grid container spacing={3} direction="row" alignItems="center">
            <Grid item xs={12} sm={6} lg={6}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText primary="800-422-7543 X263" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="WARRANTY@CSKIFF.COM" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </DashboardLayout>
  );
}

FactoryContacts.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FactoryContacts);
