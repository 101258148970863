import { createHttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { split, concat, ApolloLink } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { SubscriptionClient } from "subscriptions-transport-ws";

class ApolloUtils {
  APP_UPLOAD_SERVER_URL = process.env.REACT_APP_MEDIA_URL;

  authLink = token => {
    return new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          //...context.headers,
          authorization: `Bearer ${token}`
        }
      });
      return forward(operation);
    });
  };

  httpLink = createHttpLink({
    uri: `https://${process.env.REACT_APP_GRAPHQL_ENDPOINT}`
  });

  wsLink = token => {
    return new WebSocketLink(
      new SubscriptionClient(
        `wss://${process.env.REACT_APP_GRAPHQL_ENDPOINT}`,
        {
          reconnect: true,
          connectionParams: {
            headers: {
              authorization: `Bearer ${token}`
            }
          }
        }
      )
    );
  };

  link = token => {
    return split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === "OperationDefinition" && operation === "subscription";
      },
      this.wsLink(token),
      this.httpLink
    );
  };

  clientForToken = token => {
    const clientConfig = {
      link: concat(this.authLink(token), this.link(token)),
      cache: new InMemoryCache(),
      onError: ({ networkError, graphQLErrors }) => {
        console.log("graphQLErrors", graphQLErrors);
        console.log("networkError", networkError);
      }
    };
    const client = new ApolloClient(clientConfig);
    return client;
  };
}

export default new ApolloUtils();